import type {SyntheticEvent} from 'react';



export const rangeArray = <ArrayItemsType extends any = any>(
    start = 1,
    end = 1,
    step = 1,
    fn?: (curVal: number) => ArrayItemsType
) => {

    function* range(
        start?: number,
        end?: number,
        step?: number,
        fn?: (curVal: number) => ArrayItemsType
    ): IterableIterator<ArrayItemsType | number> {
        while (start <= end) {
            yield fn ? fn(start) : start;
            start += step;
        }
    }

    return Array.from(range(start, end, step, fn));
};



export const getTypeOf = (val: any): string => ({}.toString.call(val).slice(8, -1).toLowerCase());



export const toFixedTrunc = (x: number | string, n: number): string | number => {
    if (getTypeOf(x) !== 'number' && getTypeOf(x) !== 'string') return x;
    const v = (typeof x === 'string' ? x : x.toString()).split('.');
    if (n <= 0) return v[0];
    let f = v[1] || '';
    if (f.length > n) return `${v[0]}.${f.substr(0, n)}`;
    while (f.length < n) f += '0';
    return `${v[0]}.${f}`;
};



/**
 * Format number to a format of 1,234,567.89
 *
 * @param {number|string} number - The number to convert
 * @param {number} [minFloatDigits=0] - The minimum number of fraction digits to use
 * @param {number} [maxFloatDigits=2] - The maximum number of fraction digits to use
 *
 * @return {string|number} Formatted number converted to a string
 */
export function numberFormat(
    number: number | string,
    minFloatDigits: number = 0,
    maxFloatDigits: number = 2
): number | string {
    return (number && Intl && Intl.NumberFormat)
        ? Intl.NumberFormat('en-US', {minimumFractionDigits: minFloatDigits, maximumFractionDigits: maxFloatDigits}).format(+toFixedTrunc(number, maxFloatDigits))
        : number;
}



export function getRandomInt(min: number, max: number):number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}



export const findBodyScrollIgnoreElement = (el: Element | HTMLElement): boolean | void => {
    while (el && el !== document.body) {
        if (el.getAttribute('data-bodyscrolllockignore') !== null) {
            return true;
        }

        el = el.parentElement;
    }
};



export const isTouchSupported = () => ('ontouchstart' in document?.documentElement);
export const isMobile = () => !!(navigator && navigator.userAgent && (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)));
export const isAndroid = () => !!(navigator && navigator.userAgent && (/(android)/i.test(navigator.userAgent)));

//Note: track https://stackoverflow.com/questions/9038625/detect-if-device-is-ios from time to time to find more recent solutions
export const isIOS = () => {
    if (!navigator?.platform) return false;

    const iOS_1to12 = /iPad|iPhone|iPod/.test(navigator.platform);
    const iOS13_iPad = (navigator.platform === 'MacIntel' && navigator?.maxTouchPoints > 1);

    const iOS1to12quirk = function() {
        const audio = new Audio(); // temporary Audio object
        audio.volume = 0.5; // has no effect on iOS <= 12
        return audio.volume === 1;
    };

    return !window.MSStream && (iOS_1to12 || iOS13_iPad || iOS1to12quirk());
};



export const getCSSVariable = (CSSVariableName: string) => {
    return getComputedStyle?.(document?.documentElement)?.getPropertyValue(`--${CSSVariableName}`);
};



export function easeLinear(t: number, b: number, _c: number, d: number): number {
    const c = _c - b;
    return c * t / d + b;
}



export const preventDefaultHandler = (e: SyntheticEvent) => e?.preventDefault?.();



// TODO: Add the same helper for the bytes with 2**x0 values
const NUMBER_METRIC_FORMAT_RX_SI_GRAD = [
    {value: 1, symbol: ''},
    {value: 1e3, symbol: 'k'},
    {value: 1e6, symbol: 'M'},
    {value: 1e9, symbol: 'G'},
    {value: 1e12, symbol: 'T'},
    {value: 1e15, symbol: 'P'},
    {value: 1e18, symbol: 'E'}
];

const NUMBER_METRIC_FORMAT_RX = /\.0+$|(\.[0-9]*[1-9])0+$/;

export const numberMetricFormat = (num?: number, digits?: number) => {
    if (num === undefined) return;

    let i;
    for (i = NUMBER_METRIC_FORMAT_RX_SI_GRAD.length - 1; i > 0; i--) {
        if (num >= NUMBER_METRIC_FORMAT_RX_SI_GRAD[i].value) {
            break;
        }
    }

    if (num < 1 && num > 0) {
        return num.toFixed(2);
    }

    return (
        (num / NUMBER_METRIC_FORMAT_RX_SI_GRAD[i].value).toFixed(digits).replace(NUMBER_METRIC_FORMAT_RX, '$1') +
        NUMBER_METRIC_FORMAT_RX_SI_GRAD[i].symbol
    );
};



export function formatBytes(bytes?: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`;
}



export const convertToBytes = (value: number, unit?: 'k' | 'm' | 'g') => {
    if (!value) return;

    switch (unit.toLowerCase()) {
        case 'k':
            return value * 2 ** 10;
        case 'm':
            return value * 2 ** 20;
        case 'g':
            return value * 2 ** 30;
        default:
            return value;
    }
};



const english_ordinal_rules = window?.Intl?.PluralRules ? new Intl.PluralRules('en', {type: 'ordinal'}) : null;
const suffixes: Record<string, string> = {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th'
};

export function ordinalNumber(number: number) {
    if (!english_ordinal_rules) return number;

    const suffix = suffixes[english_ordinal_rules?.select?.(number)];
    return `${number}${suffix}`;
}



export function findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
    let l = array.length;
    while (l--) {
        if (predicate(array[l], l, array))
            return l;
    }
    return -1;
}



export function cookiesAreEnabled() {
    try {
        document.cookie = 'cookietest=1; SameSite=Strict; Secure';
        const ret = document.cookie.indexOf('cookietest=') !== -1;
        document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT; SameSite=Strict; Secure';
        return ret;
    } catch (e) {
        return false;
    }
}

// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#testing_for_availability
export function storageAvailable(type: 'localStorage' | 'sessionStorage') {
    var storage: Storage;
    try {
        storage = window[type];
        var x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch (e) {
        return e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            (storage && storage.length !== 0);
    }
}

const isUpperCase = (s: string) => s.toUpperCase() === s;

export const kebabize = (s: string, saveCase = false) => s
    .split('')
    .reduce((prev, curr, i) => {
        const prevLast = prev[prev.length - 1];
        if (isUpperCase(curr) && i !== 0 && !isUpperCase(prevLast)) {
            return [...prev, '-', saveCase ? curr : curr.toLowerCase()];
        }
        return [...prev, curr];
    }, [])
    .join('');

export const getMonthShortName = (date: Date) => date.toLocaleString('default', { month: 'short' });

export function camelCaseToTitle(camelCase: string) {
    // Add a space before all uppercase letters, then capitalize the first letter and lower the rest
    return camelCase.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
}
