import {ReactNode, useLayoutEffect} from 'react';
import cn from 'classnames';

//Types
import type {IModalParams} from '~components/Modal/types';
import type {IReporterCaptureExceptionParams} from '~utils/remoteReportHelper';
//Components
import Modal from '~components/Modal';
//Utils
import {captureException} from '~utils/remoteReportHelper';



const defaultTitle = 'You have encountered an error';
const defaultErrorBody = 'Sorry for that, we are looking to fix this issue as soon as possible';

export interface IErrorModalParams {
    title?: ReactNode
    className?: string
    modalBody?: ReactNode
    reportData?: IReporterCaptureExceptionParams
}

type IErrorModalProps = IModalParams & IErrorModalParams;

export const DEFAULT_ERROR_MODAL_ID = Symbol('DEFAULT_ERROR_MODAL_ID');

export default function ErrorModal(
    {onClose, title = defaultTitle, modalBody = defaultErrorBody, className, reportData, id = DEFAULT_ERROR_MODAL_ID}: IErrorModalProps
) {
    //Optionally, if "reportData" not empty, sends report about error to the Sentry
    useLayoutEffect(() => {
        if (reportData) captureException(reportData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal id={id} onClose={onClose} className={cn('common-modals without-footer error-modal', className)}>
            <Modal.CloseButton/>

            <Modal.Body className="common-modals__body">
                <h2 className="common-modals__title">{title}</h2>
                <div className="common-modals__content">{modalBody}</div>
            </Modal.Body>
        </Modal>
    );
}
