import TagManager from 'react-gtm-module';

//Config
import {MKTG_TOOLS} from '~config/constants';
import {tagManagerArgs} from '~mktgTools/GTM/config';



export function init() {
    if (MKTG_TOOLS.GTM_IS_ON) {
        TagManager.initialize(tagManagerArgs);
    }
}
