import {Fragment, MouseEvent} from 'react';
import {Link} from 'react-router-dom';
import cn from 'classnames';

//Types
import {HomeUserTypesEnum} from '~context/landingPageUser/types';
//Hooks
import {useLandingPageUser} from '~context/landingPageUser';
//Config
import { BLOG_URL } from '~config/constants';



export default function SwitchUserLinks({linkClassName}: {linkClassName?: string}) {
    const {toggleUserType, activeUserType} = useLandingPageUser();

    const switchLandingUser = (e: MouseEvent<HTMLElement>) => {
        e?.preventDefault?.();
        const userType = e?.currentTarget?.dataset?.['userType'] as HomeUserTypesEnum;
        userType && toggleUserType(userType);
    };

    return (
        <Fragment>
            <Link
                to="#"
                className={cn(linkClassName, {'is-active': activeUserType === HomeUserTypesEnum.TALENT})}
                data-user-type={HomeUserTypesEnum.TALENT}
                onClick={switchLandingUser}
            >
                Talent
            </Link>
            <Link
                to="?enterprise"
                className={cn(linkClassName, {'is-active': activeUserType === HomeUserTypesEnum.ENTERPRISE})}
                data-user-type={HomeUserTypesEnum.ENTERPRISE}
                onClick={switchLandingUser}
            >
                Enterprise
            </Link>
            <a
                href={BLOG_URL}
                className={linkClassName}
                rel="noopener noreferrer"
            >
                Blog
            </a>
        </Fragment>
    );
}

