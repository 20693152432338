// Types
import {HomeUserTypesEnum} from '~context/landingPageUser/types';
// Hooks
import useURLQuery from '~hooks/useURLQuery';



const enterpriseQueryString = HomeUserTypesEnum.ENTERPRISE.toLowerCase();

function setUser(user: HomeUserTypesEnum): void {
    const searchString = user === HomeUserTypesEnum.ENTERPRISE ? `?${enterpriseQueryString}` : '';

    if (window.location.search !== searchString) {
        window.history.replaceState({}, '', `${window.location.pathname}${searchString}`);
    }
}

interface UseHomeUserSearchParamsReturn {
    user: HomeUserTypesEnum;
    setUser: typeof setUser;
}
/**
 * Read query param to derive initial user type and apply user type changes to the address-bar
 *
 * Default user is HomeUserTypesEnum.TALENT = no params defined in the address-bar
 * If user set to HomeUserTypesEnum.ENTERPRISE the address-bar will contain `?enterprise`
 */
export function useHomeUserSearchParams(): UseHomeUserSearchParamsReturn {
    const URLQuery = useURLQuery();

    const user = URLQuery.get(enterpriseQueryString) === null ? HomeUserTypesEnum.TALENT : HomeUserTypesEnum.ENTERPRISE;

    return {
        user,
        setUser
    };
}
