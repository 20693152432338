import {useLayoutEffect, useState} from 'react';

//Utils
import {getRandomInt} from '~utils/helpers';



function useRandomNumber(): number {
    const [randomNumber, setRandomNumber] = useState<number>(getRandomInt(1, Number.MAX_SAFE_INTEGER));

    useLayoutEffect(() => {
        setRandomNumber(getRandomInt(1, Number.MAX_SAFE_INTEGER));
    }, []);

    return randomNumber;
}

export default useRandomNumber;
