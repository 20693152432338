import {useLayoutEffect} from 'react';
import cn from 'classnames';

//Types
import {IUseSubscribeOnIntersectionObserverParams} from '~hooks/useSubscribeOnIntersectionObserver';
//Hooks
import useSubscribeOnIntersectionObserver from '~hooks/useSubscribeOnIntersectionObserver';



interface IIntersectionTriggerProps extends IUseSubscribeOnIntersectionObserverParams {
    onUpdate?(isVisible: boolean | null): void
    className?: string
}

export default function IntersectionTrigger({onUpdate, className, ...intersectionOption}: IIntersectionTriggerProps) {
    const {intersectionTargetRef, isVisible, isIndeterminate} = useSubscribeOnIntersectionObserver(intersectionOption);

    useLayoutEffect(() => {
        onUpdate?.(isIndeterminate ? null : isVisible);
    }, [isVisible, isIndeterminate, onUpdate]);

    return (
        <div
            ref={intersectionTargetRef}
            className={cn('intersection-trigger', className, {'is-visible': isVisible})}
        />
    );
}

