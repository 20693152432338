import { Link } from 'react-router-dom';

//Config
import {loginRoute, registerChooseTypeRoute} from '~config/routes';



export default function HeaderAuthLinks() {
    return (
        <div className="header-auth">
            <Link to={loginRoute} className="site-header__link">Log in</Link>
            <Link to={registerChooseTypeRoute} className="header-auth__btn btn">Get started</Link>
        </div>
    );
}

