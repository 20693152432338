import MQBreakpoints from '../styles/utils/_export-variables.module.scss';

//Config
import {
    emailConfirmRoute,
    forgotPasswordRoute,
    homeRoute,
    linkedInRoute,
    loginRoute,
    registerChooseTypeRoute,
    registerEnterpriseRoute,
    registerTalentRoute,
    resetPasswordRoute
} from '~config/routes';
//Utils
import { convertToBytes } from '~utils/helpers';



export const PROJECT_NAME = 'Talentprise';
export const APP_LANGUAGE = 'en';

const {
    REACT_APP_LANDING_URL,
    REACT_APP_GRAPHQL_URL,
    REACT_APP_GRAPHQL_WS_URL,
    REACT_APP_GOOGLE_CLIENT_ID,
    REACT_APP_LINKEDIN_CLIENT_ID,
    REACT_APP_CV_PARSE_AWAIT_RESPONSE_TIMEOUT,
    REACT_APP_SENTRY_DSN,
    REACT_APP_SMS_VERIFY_INTERVAL,
    REACT_APP_BANNER_CAROUSEL_INTERVAL,
    REACT_APP_MAX_IMG_UPLOAD_SIZE,
    REACT_APP_MAX_PDF_UPLOAD_SIZE,
    REACT_APP_ASSESSMENT_TIMER_DURATION_MINUTES,
    REACT_APP_PING_ASSESSMENT_INTERVAL,
    REACT_APP_GOOGLE_TAG_MANAGER_ID,
    REACT_APP_GTM_ENABLED,
    REACT_APP_BLOG_URL,
    REACT_APP_PAGINATION_ITEMS_PER_PAGE,
    REACT_APP_USERSNAP_PROJECT_API_KEY,
    REACT_APP_USERSNAP_GLOBAL_API_KEY,
    REACT_APP_USERSNAP_BOOTSTRAP_TIMEOUT,
    REACT_APP_IDLE_LOGOUT_TIMEOUT,
    REACT_APP_STRIPE_PUBLISH_KEY,
    REACT_APP_ONBOARDING_STEPS_DEV_HELPER,
    NODE_ENV
} = process.env;

export const STRIPE_PUBLISH_KEY = REACT_APP_STRIPE_PUBLISH_KEY;

export const IS_DEV = NODE_ENV === 'development';

export const IS_ONBOARDING_STEPS_DEV_HELPER_ENABLED = REACT_APP_ONBOARDING_STEPS_DEV_HELPER === 'on';

export const LANDING_URL = REACT_APP_LANDING_URL;
export const GRAPHQL_URL = REACT_APP_GRAPHQL_URL;
export const GRAPHQL_WS_URL = REACT_APP_GRAPHQL_WS_URL;
export const GOOGLE_CLIENT_ID = REACT_APP_GOOGLE_CLIENT_ID;
export const LINKEDIN_CLIENT_ID = REACT_APP_LINKEDIN_CLIENT_ID;
export const CV_PARSE_AWAIT_RESPONSE_TIMEOUT = REACT_APP_CV_PARSE_AWAIT_RESPONSE_TIMEOUT;
export const SENTRY_DSN = REACT_APP_SENTRY_DSN;
export const USERSNAP_PROJECT_API_KEY = REACT_APP_USERSNAP_PROJECT_API_KEY;
export const USERSNAP_GLOBAL_API_KEY = REACT_APP_USERSNAP_GLOBAL_API_KEY;
export const USERSNAP_BOOTSTRAP_TIMEOUT =
    +REACT_APP_USERSNAP_BOOTSTRAP_TIMEOUT * 1000 || 5000;
export const SMS_VERIFY_INTERVAL =
    +REACT_APP_SMS_VERIFY_INTERVAL * 1000 || 30_000;
export const BANNER_CAROUSEL_INTERVAL =
    +REACT_APP_BANNER_CAROUSEL_INTERVAL * 1000 || 10_000;
export const BLOG_URL = REACT_APP_BLOG_URL || '/blog';

export const MAX_IMG_UPLOAD_SIZE =
    convertToBytes(+REACT_APP_MAX_IMG_UPLOAD_SIZE, 'm') || undefined;
export const MAX_PDF_UPLOAD_SIZE =
    convertToBytes(+REACT_APP_MAX_PDF_UPLOAD_SIZE, 'm') || undefined;

export const PAGES_WITHOUT_HEADER = [
    registerChooseTypeRoute,
    registerTalentRoute,
    registerEnterpriseRoute,
    loginRoute,
    emailConfirmRoute,
    linkedInRoute,
    forgotPasswordRoute,
    resetPasswordRoute
];

export const PAGES_WITH_FOOTER = [homeRoute];

export const URL_ORIGIN = new URL(window.location.href).origin;
export const HOST = new URL(window.location.href).host;

export const VIDEO_CONTROLLERS_VISIBLE_TIMEOUT = 3000;

export const THROTTLE_DELAY_EXTERNAL_API = 60_000; // one minute

export const ASSESSMENT_TIMER_DURATION_MINUTES =
    +REACT_APP_ASSESSMENT_TIMER_DURATION_MINUTES || 20;
export const PING_ASSESSMENT_INTERVAL =
    +REACT_APP_PING_ASSESSMENT_INTERVAL * 1000 || 30_000;

export const PAGINATION_ITEMS_PER_PAGE =
    +REACT_APP_PAGINATION_ITEMS_PER_PAGE || 10;

export const TALENT_COMPARISON_CHART_LABELS_ORDER = [
    'educations',
    'experiences',
    'expertises',
    'skills',
    'competencies'
] as const;

export const TALENT_COMPARISON_CHART_LABELS_MAP = {
    educations: 'education',
    experiences: 'experience',
    expertises: 'expertise',
    skills: 'skills',
    competencies: 'competencies'
} as const;


export const DEFAULT_INFO_MODAL_BTN_LABEL = 'Ok';
export const DEFAULT_CONFIRM_MODAL_AGREE_BTN_LABEL = 'Ok';
export const DEFAULT_CONFIRM_MODAL_CANCEL_BTN_LABEL = 'Cancel';

export const GOOGLE_TAG_MANAGER_ID = REACT_APP_GOOGLE_TAG_MANAGER_ID;
export const MKTG_TOOLS = {
    GTM_IS_ON:
        REACT_APP_GTM_ENABLED?.toLowerCase?.() === 'on' &&
        !!GOOGLE_TAG_MANAGER_ID // Google Tag Manager
};

export const IDLE_LOGOUT_COOKIE_NAME = 'talentprise-idle-token';
export const IDLE_LOGOUT_TIMEOUT =
    (+REACT_APP_IDLE_LOGOUT_TIMEOUT || 60 * 8) * 1000 * 60;

export const HH_GUIDANCE_COOKIE_NAME = 'talentprise-hh-guidance-shown';

export const DEFAULT_SELECT_ASYNC_DEBOUNCE = 500;

export const CSSBreakpoints = Object.entries(MQBreakpoints).reduce<
    Record<string, number>
>((acc, [key, val]) => {
    if (key && val) {
        acc[key] = parseFloat(val);
    }
    return acc;
}, {});
