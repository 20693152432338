import {ReactNode} from 'react';

//Types
import type {IErrorModalParams} from '~components/modals/commonModals/ErrorModal';
import type {IInfoModalParams} from '~components/modals/commonModals/InfoModal';
import type {IConfirmModalParams} from '~components/modals/commonModals/ConfirmDialog';



export interface IContextProps {
    openErrorModal(modalParams?: IErrorModalParams): void
    openInfoModal(modalParams?: IInfoModalParams): void
    openConfirmModal(modalParams?: IConfirmModalParams): void
}

export enum CommonModalTypesEnum {
    ERROR = 'ERROR',
    CONFIRM = 'CONFIRM',
    INFO = 'INFO'
}

export interface ICommonModalsProviderProps {
    children: ReactNode
}

export interface IModalState {
    openedModalType: CommonModalTypesEnum | null,
    modalParams: any
}
