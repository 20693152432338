import {RetryLink} from '@apollo/client/link/retry';
import {onError} from '@apollo/client/link/error';

import {RETRY_LINK_CONFIG} from '~config/apollo';



export const errorLink = onError(({ networkError }) => {
    // eslint-disable-next-line no-console
    if (networkError) console.warn(`[Network error]: ${networkError}`);
});

export const retryLink = new RetryLink(RETRY_LINK_CONFIG);
