import {
    Suspense,
    useCallback,
    useEffect,
    useLayoutEffect,
    useState
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';

//Components
import HeaderUserArea from '~components/AppHeader/HeaderUserArea';
import IntersectionTrigger from '~components/IntersectionTrigger';
import HeaderSearch from '~components/AppHeader/HeaderSearch';
import SwitchUserLinks from '~pages/Home/SwitchUserLinks';
import MobileNav from '~components/MobileNav';
import HamburgerBtn from '~components/HamburgerBtn';
//Icons
import LogoIcon from '~icons/LogoIcon';
//Config
import {enterpriseTalentBoardRoute, homeRoute, notFoundRoute, talentDashboardRoute} from '~config/routes';
import { PAGES_WITHOUT_HEADER } from '~config/constants';
//Hooks
import useDimensions from '~hooks/useDimensions';
import { useAuthContext } from '~context/auth';
// GraphQL
import { ProfileTypeEnum, SubscriptionStatusEnum } from '~graphqlResources';



export default function AppHeader() {
    const [headerIsAtTheTop, setHeaderIsAtTheTop] = useState<boolean | null>(
        null
    );
    const [mobileNavIsVisible, setMobileNavIsVisible] = useState<boolean>(
        false
    );
    const { pathname } = useLocation();
    const { ismobile, isdesktop } = useDimensions();
    const { isAuthenticated, profile, subscription } = useAuthContext();

    const isHidden = PAGES_WITHOUT_HEADER.includes(pathname);
    const isHome = pathname === homeRoute;
    const isNotFound = pathname === notFoundRoute;
    const isFlattable = isHome || isNotFound;
    const isSubscription =
        subscription?.status === SubscriptionStatusEnum.ACTIVE &&
        subscription?.availableCredits !== 0;

    useLayoutEffect(() => {
        if (pathname) {
            document.documentElement.classList.toggle('is-home-page', isHome);
            setMobileNavIsVisible(false);
        }
    }, [pathname, isHome]);

    useEffect(() => {
        if (!isAuthenticated) setMobileNavIsVisible(false);
    }, [isAuthenticated]);

    const onIntersectionTrigger = useCallback(
        (isVisible) => {
            setHeaderIsAtTheTop(isVisible);
        },
        [setHeaderIsAtTheTop]
    );

    const toggleMobileNav = useCallback(() => {
        setMobileNavIsVisible((prevState) => !prevState);
    }, [setMobileNavIsVisible]);

    const conditionalLogoWithLink = () => {
        if (profile?.type === ProfileTypeEnum.ENTERPRISE) {
            return (
                <Link to={enterpriseTalentBoardRoute} className="site-header__logo">
                    <LogoIcon/>
                </Link>
            );
        }

        if (profile?.type === ProfileTypeEnum.TALENT) {
            return (
                <Link to={talentDashboardRoute} className="site-header__logo">
                    <LogoIcon/>
                </Link>
            );
        }

        return (
            <div className="site-header__logo">
                <LogoIcon/>
            </div>
        );
    };

    return (
        <>
            {isFlattable && (
                <IntersectionTrigger
                    className="site-header-intersection-trigger"
                    onUpdate={onIntersectionTrigger}
                />
            )}

            <header
                className={cn('site-header', {
                    'is-hidden': isHidden,
                    'is-processing': isHome && headerIsAtTheTop === null,
                    'is-flat': isFlattable && headerIsAtTheTop,
                    'with-mobile-menu': mobileNavIsVisible
                })}
            >
                <div className="site-header__start">
                    {conditionalLogoWithLink()}

                    {pathname === homeRoute && (
                        <nav className="site-header__nav">
                            <SwitchUserLinks linkClassName="site-header__link"/>
                        </nav>
                    )}
                </div>

                {ismobile ? (
                    <>
                        <div className="site-header__middle"/>
                        <div className="site-header__end">
                            {ismobile && (
                                <HamburgerBtn
                                    onClick={toggleMobileNav}
                                    isActive={mobileNavIsVisible}
                                />
                            )}
                        </div>

                        <MobileNav isOn={mobileNavIsVisible}/>

                        {pathname !== homeRoute && profile?.onboarded && (
                            <HeaderUserArea/>
                        )}
                    </>
                ) : (
                    <Suspense fallback="">
                        <div className="site-header__middle">
                            {!isHome && !isdesktop && isSubscription && (
                                <HeaderSearch/>
                            )}
                        </div>

                        <div className="site-header__end">
                            <HeaderUserArea/>
                        </div>
                    </Suspense>
                )}
            </header>
        </>
    );
}
