import { useEffect, useRef } from 'react';
import cn from 'classnames';
import {
    clearAllBodyScrollLocks,
    disableBodyScroll,
    enableBodyScroll
} from 'body-scroll-lock';

//Utils
import { findBodyScrollIgnoreElement } from '~utils/helpers';
//hooks
import { useAuthContext } from '~context/auth';
//Components
import HeaderAuthLinks from '~components/AppHeader/HeaderAuthLinks';
import HeaderMobileUser from '~components/AppHeader/HeaderMobileUser';



interface IMobileNavProps {
    isOn?: boolean;
}

export default function MobileNav({ isOn }: IMobileNavProps) {
    const mobileNavRef = useRef<HTMLDivElement>();
    const { profile, isAuthenticated, subscription, logout } = useAuthContext();

    useEffect(() => {
        if (mobileNavRef?.current) {
            if (isOn) {
                disableBodyScroll(mobileNavRef.current, {
                    allowTouchMove: findBodyScrollIgnoreElement
                });
                document.documentElement.classList.add('hide-scroll');
            } else {
                enableBodyScroll(mobileNavRef.current);
                document.documentElement.classList.remove('hide-scroll');
            }
        }

        return () => {
            document.documentElement.classList.remove('hide-scroll');
            clearAllBodyScrollLocks();
        };
    }, [isOn]);

    return (
        <div
            className={cn('mobile-nav', { 'is-visible': isOn })}
            ref={mobileNavRef}
            data-bodyscrolllockignore="true"
        >
            <div className="mobile-nav__inner">
                {isAuthenticated && profile ? (
                    <HeaderMobileUser
                        profile={profile}
                        subscription={subscription}
                        logout={logout}
                    />
                ) : (
                    <HeaderAuthLinks/>
                )}
            </div>
        </div>
    );
}
