import {Suspense, lazy} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

//Components
import MainApp from './MainApp';
import ErrorCatch from '~components/ErrorCatch';
//Providers
import {HelmetProvider} from 'react-helmet-async';
//Config
import {dashboardMarketingRoute} from '~config/routes/marketingPortal';
// Utils
import {chunkErrorHandler, cleanUp} from '~utils/chunkErrorHandler';



function App() {
    return (
        <ErrorCatch>
            <HelmetProvider>
                <Router>
                    <AppSwitcher/>
                </Router>
            </HelmetProvider>
        </ErrorCatch>
    );
}

export default App;

function AppSwitcher() {
    return (
        <Suspense fallback="">
            <Switch>
                <Route
                    path={dashboardMarketingRoute}
                    component={lazy(() => import('./MarketingPortalApp').then(cleanUp).catch(chunkErrorHandler))}
                />
                <Route component={MainApp}/>
            </Switch>
        </Suspense>
    );
}
