import { BLOG_URL } from './constants';



export const DOCUMENT_LINKS_PRIVACY_POLICY = `${BLOG_URL}/privacy-policy/`;

export const DOCUMENT_LINKS_TERMS_AND_CONDITIONS = `${BLOG_URL}/terms-and-conditions/`;

export const DOCUMENT_LINKS_COOKIE_POLICY = `${BLOG_URL}/cookie-policy/`;

export const REQUEST_FOR_PROPOSAL_LINK =
    'https://talentprise.com/request-for-proposal/';

export const THIRD_PARTY_LINKS_MEDIUM = 'https://medium.com/@talentprise';

export const THIRD_PARTY_LINKS_FACEBOOK =
    'https://www.facebook.com/Talentprise';

export const THIRD_PARTY_LINKS_TWITTER = 'https://twitter.com/talentprise';

export const THIRD_PARTY_LINKS_LINKEDIN =
    'https://www.linkedin.com/company/mytalentprise/';

export const THIRD_PARTY_LINKS_INSTAGRAM =
    'https://www.instagram.com/talentprise/';
