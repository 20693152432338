import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';

import './styles/main.scss';

//App
import App from '~app/App';
//Web vitals
import reportWebVitals from './reportWebVitals';



const render = () => {
    ReactDOM.render(
        <StrictMode>
            <App/>
        </StrictMode>,
        document.getElementById('root')
    );
};

render();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
