import {ComponentType, createContext, ReactNode, useCallback, useContext, useState} from 'react';

// Components
import FullScreenLoader from '~components/Loader/FullScreenLoader';



interface LoaderProviderProps {
    children: ReactNode;
}

interface LoaderState {
    component: ComponentType<any> | null;
    props: any;
}

interface LoaderContextType {
    showFullScreenLoader: (props: any) => void;
    hideLoader: () => void;
}

const LoaderContext = createContext<LoaderContextType>({
    showFullScreenLoader: () => {},
    hideLoader: () => {}
});

export default function LoaderProvider({ children }: LoaderProviderProps) {
    const [loaderState, setLoaderState] = useState<LoaderState>({
        component: null,
        props: {}
    });

    const showFullScreenLoader = useCallback((props: any) => {
        setLoaderState({
            component: FullScreenLoader,
            props
        });
    }, []);

    const hideLoader = useCallback(() => {
        setLoaderState({
            component: null,
            props: {}
        });
    }, []);

    const LoaderComponent = loaderState.component;

    return <LoaderContext.Provider value={{ showFullScreenLoader, hideLoader }}>
        {children}

        {LoaderComponent && <LoaderComponent {...loaderState.props}/>}
    </LoaderContext.Provider>;
}

export const useFullScreenLoader = () => useContext(LoaderContext);
