import {ApolloError, ApolloQueryResult, OperationVariables} from '@apollo/client';
import {Dispatch, ReactNode} from 'react';

// Types
import {StepStatusEnum} from '~graphqlResources';
import {TALENT_PROFILE_ROUTE_OPERATION_TYPE} from '~config/routes';
import {UseQuery} from '~types';



export enum WizardJobPrefStepEnum {
    OBJECTIVE = 'OBJECTIVE',
    PRIVACY = 'PRIVACY',
    CONTACTS = 'CONTACTS',
    JOB_TYPE = 'JOB_TYPE',
    ATTENDANCE = 'ATTENDANCE',
    RELOCATION = 'RELOCATION',
    AVAILABILITY = 'AVAILABILITY'
}

export enum WizardBiographyStepEnum {
    CV = 'CV',
    EDUCATION = 'EDUCATION',
    EXPERIENCE = 'EXPERIENCE',
    EXPERTISE = 'EXPERTISE',
    PERSONAL_SKILLS = 'PERSONAL_SKILLS',
    IT_SKILLS = 'IT_SKILLS',
    CERTIFICATES = 'CERTIFICATES',
    LANGUAGES = 'LANGUAGES',
    PHOTO = 'PHOTO'
}

enum WizardBiographyAdditionalStepEnum {
    IMPORT = 'IMPORT'
}

export const WizardBiographyStepExtEnum = {
    ...WizardBiographyAdditionalStepEnum,
    ...WizardBiographyStepEnum
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type WizardBiographyStepExtEnum = WizardBiographyAdditionalStepEnum | WizardBiographyStepEnum;

export enum WizardPartEnum {
    JOB_PREFERENCES = 'JOB_PREFERENCES',
    BIOGRAPHY = 'BIOGRAPHY',
    ASSESSMENT = 'ASSESSMENT'
}

export enum EnterpriseWizardPartEnum {
    ENTERPRISE = 'ENTERPRISE'
}

export type WizardPartType = WizardPartEnum | EnterpriseWizardPartEnum;

// IMPORTANT: Steps should be named the same as `stepKey` on `getEnterpriseStepsStatus` query
export enum WizardEnterpriseStepsEnum {
    CONTACTS = 'CONTACTS',
    CAREER = 'CAREER',
    // The PRIVACY step should be hidden and saved, if the customer ask to return Privacy step.
    // PRIVACY = 'PRIVACY',
    DETAILS = 'DETAILS',
    COMPANY_LOGO = 'COMPANY_LOGO'
}

export type WizardStepsType = WizardJobPrefStepEnum | WizardBiographyStepExtEnum | WizardEnterpriseStepsEnum;

export interface IWizardStep<Type extends string> {
    id: Type
    label: string
    isCompleted: boolean
    isDone: boolean
    isSkipped: boolean
    withoutSidebar: boolean
    withCompactSidebar: boolean
    doNotShowStepInSidebar: boolean
    path: string
}

export enum WizardReadyStateEnum {
    IDLE = 'IDLE',
    PENDING = 'PENDING',
    SUCCEEDED = 'SUCCEEDED',
    FAILED = 'FAILED'
}

export enum WizardActionTypesEnum {
    INITIALIZE = 'INITIALIZE',
    INITIALIZE_DASHBOARD = 'INITIALIZE_DASHBOARD',
    GO_TO_STEP = 'GO_TO_STEP',
    SET_READY_STATE = 'SET_READY_STATE',
    COMPLETE_PART = 'COMPLETE_PART',
    COMPLETE_STEP = 'COMPLETE_STEP'
}

export interface IWizardState {
    activeWizardPart: WizardPartType | null
    activeStep: WizardStepsType | null
    parts: any
    biographySteps: any
    jobPrefSteps: any
    enterpriseSteps: any
    biographyStepsOrder: WizardBiographyStepExtEnum[]
    jobPrefStepsOrder: WizardJobPrefStepEnum[]
    enterpriseStepsOrder: WizardEnterpriseStepsEnum[]
    partsOrder: WizardPartType[]
    readyState: WizardReadyStateEnum
}

export interface IWizardAction {
    type: WizardActionTypesEnum,
    payload?: Partial<IWizardState> & {
        stepToComplete?: WizardStepsType
        isSkipAction?: boolean
        stepNext?: boolean
        stepPrev?: boolean
        partStatusMap?: Partial<Record<WizardPartType, StepStatusEnum>>
    }
}

export interface IWizardProviderParams<TData = any, TVariables = OperationVariables> {
    wizardConfig: IWizardConfig<TData, TVariables>,
    children: ReactNode
}

export interface IWizardContextProps extends IWizardState {
    goToStep: goToStepFn
    nextStep: nextStepFn
    prevStep: prevStepFn
    completeStep: completeStepFn
    submitWizardPart(): void
    initLastStep: initLastStepFn
    bootstrap: bootstrapFn
    bootstrapDashboard: () => void | Promise<void>
    profileOperation: TALENT_PROFILE_ROUTE_OPERATION_TYPE
    checkStepStatus: CheckStepStatusFn
}

export interface IWizardStepDetails {
    wizardPart: WizardPartType
    wizardStep: WizardStepsType
}

export type initLastStepFn = (params: {
    wizardPart: WizardPartType
}) => WizardStepsType | Promise<WizardStepsType>;

export type bootstrapFn = (params: {
    wizardPart: WizardPartType
    wizardStep?: WizardStepsType
}) => void | Promise<void>;

export type goToStepFn = ({wizardPart, wizardStep}: IWizardStepDetails) => void;
export type nextStepFn = ({withAnimatedAppearance}?: {withAnimatedAppearance?: StepAppearanceStateEnum}) => void;
export type prevStepFn = ({withAnimatedAppearance}?: {withAnimatedAppearance?: StepAppearanceStateEnum}) => void;
interface ICompleteStepFnParams {
    wizardStep?: WizardStepsType
    withAnimatedAppearance?: StepAppearanceStateEnum
    isSkipAction?: boolean
    wizardPart?: WizardPartType
}
export type completeStepFn = ({wizardStep, withAnimatedAppearance, isSkipAction}?: ICompleteStepFnParams) => void;

export interface IGetNearestAllowedStepParams {
    wizardPart: WizardPartType
    stepsSource?: any
}

export interface IGetWizardPartResponse {
    steps: any
    stepsOrder: WizardBiographyStepExtEnum[] | WizardJobPrefStepEnum[] | WizardEnterpriseStepsEnum[]
}

export type CheckStepStatusFn = () => Promise<boolean>

export enum StepAppearanceStateEnum {
    SLIDE_RIGHT = 'slide-right',
    SLIDE_LEFT = 'slide-left',
    SLIDE_UP = 'slide-up',
    SLIDE_DOWN = 'slide-down',
    SLIDE_FROM_RIGHT = 'slide-from-right',
    SLIDE_FROM_LEFT = 'slide-from-left',
    SLIDE_FROM_UP = 'slide-from-up',
    SLIDE_FROM_DOWN = 'slide-from-down',
    FADE_IN = 'fade-in'
}


export interface IWizardConfig<TData = any, TVariables = OperationVariables> {
    partsOrder: WizardPartType[],
    stepsStatusQueryHook: UseQuery<TData, TVariables>
    profileOperation: TALENT_PROFILE_ROUTE_OPERATION_TYPE;
    shouldRedirect(params: {activeStepIsChanged: boolean; activeWizardPart: WizardPartType; pathname: string}): boolean;
    getRedirectPath?(params: {activeWizardPart: WizardPartType; activeStep: string}): string;
    allowToGoToStep?(): boolean;
    initLastStep?(params: {
        args: {wizardPart: WizardPartType};
        getWizardQuery: (variables?: Partial<TVariables>) => Promise<ApolloQueryResult<TData>>;
        getWizardPart: (wizardPart: WizardPartType) => IGetWizardPartResponse;
        handleGraphQLError: () => (err: ApolloError) => void;
        getNearestAllowedStep: (params: IGetNearestAllowedStepParams) => WizardStepsType;
    }): Promise<WizardStepsType> | WizardStepsType;
    bootstrap(params: {
        args: {wizardPart: WizardPartType; wizardStep?: WizardStepsType};
        dispatch: Dispatch<IWizardAction>;
        getWizardQuery: (variables?: Partial<TVariables>) => Promise<ApolloQueryResult<TData>>;
        getWizardPart: (wizardPart: WizardPartType) => IGetWizardPartResponse;
        handleGraphQLError: () => (err: ApolloError) => void;
        isAllowedToOpenStep: (params: IWizardStepDetails & {stepsSource?: any}) => boolean;
        getNearestAllowedStep: (params: IGetNearestAllowedStepParams) => WizardStepsType;
    }): Promise<void> | void;
    bootstrapDashboard?(params: {
        dispatch: Dispatch<IWizardAction>;
        getWizardQuery: (variables?: Partial<TVariables>) => Promise<ApolloQueryResult<TData>>;
        handleGraphQLError: () => (err: ApolloError) => void;
    }): Promise<void> | void;
    checkStepStatus(params: {
        getWizardQuery: (variables?: Partial<TVariables>) => Promise<ApolloQueryResult<TData>>;
        activeWizardPart: WizardPartType;
        activeStep: string;
    }): Promise<boolean>;
    redirectPathOnPartComplete?: string;
}
