// Types
import { WizardEnterpriseStepsEnum } from '~context/wizard/types';
import { TALENT_PROFILE_ROUTE_OPERATION_TYPE } from './talent';
// Utils
import { generateStepsPath } from '~utils/wizardUtils';



export const ENTERPRISE_ROUTE_NAMESPACE = '/enterprise';

export const enterpriseProfileRoute = ENTERPRISE_ROUTE_NAMESPACE;
export const enterpriseDashboardRoute = `${ENTERPRISE_ROUTE_NAMESPACE}/dashboard`;
export const enterpriseSearchRoute = `${ENTERPRISE_ROUTE_NAMESPACE}/search`;
export const enterpriseTalentDetailsRoute = `${ENTERPRISE_ROUTE_NAMESPACE}/talent-details/`;
export const enterpriseTalentDetailsConfRoute = `${enterpriseTalentDetailsRoute}:talentId`;
export const enterpriseChoosePlanRoute = `${ENTERPRISE_ROUTE_NAMESPACE}/choose-plan`;
export const enterpriseSubscriptionsRoute = `${ENTERPRISE_ROUTE_NAMESPACE}/subscriptions`;
export const enterprisePaymentBaseRoute = `${ENTERPRISE_ROUTE_NAMESPACE}/payment/`;
export const enterprisePaymentRoute = `${enterprisePaymentBaseRoute}:subscriptionId`;
export const enterpriseSuccessPaymentRoute = `${ENTERPRISE_ROUTE_NAMESPACE}/success-payment`;
/*
 * Enterprise subscription routes
 */
export const enterpriseSubscriptionRoute = `${ENTERPRISE_ROUTE_NAMESPACE}/subscription`;
export const enterpriseSubscriptionPlansRoute = `${enterpriseSubscriptionRoute}/plans`;
export const enterpriseSubscriptionHistoryRoute = `${enterpriseSubscriptionRoute}/history`;
export const enterpriseSubscriptionPaymentMethodRoute = `${enterpriseSubscriptionRoute}/payment-method`;
export const enterpriseSubscriptionBillingHistoryRoute = `${enterpriseSubscriptionRoute}/billing-history`;
/*
 * Enterprise profile routes
 */
export const enterpriseWizardRouteBase = `${enterpriseProfileRoute}/${TALENT_PROFILE_ROUTE_OPERATION_TYPE.SETUP}`;

// Company logo and Enterprise details steps are on the single page
const generatedWizardRoutesPath = generateStepsPath(WizardEnterpriseStepsEnum);
generatedWizardRoutesPath[WizardEnterpriseStepsEnum.COMPANY_LOGO] =
    generatedWizardRoutesPath[WizardEnterpriseStepsEnum.DETAILS];

export const enterpriseWizardRoutesPath = generatedWizardRoutesPath;
export const enterpriseCongratulationRoute = `${enterpriseWizardRouteBase}/congratulation`;

export const enterpriseEditRouteBase = `${enterpriseProfileRoute}/${TALENT_PROFILE_ROUTE_OPERATION_TYPE.EDIT}`;

export const enterpriseEditPersonalInfoRoute = `${enterpriseEditRouteBase}/personal-info`;
export const enterpriseEditDetailsRoute = `${enterpriseEditRouteBase}/details`;

/*
 * Enterprise headhunter pool routes
 */
export const enterpriseHHPoolBaseRoute = `${ENTERPRISE_ROUTE_NAMESPACE}/headhunter-pool`;
export const enterpriseCreateHHPoolRoute = `${enterpriseHHPoolBaseRoute}/create`;
export const enterpriseEditHHPoolRoute = `${enterpriseHHPoolBaseRoute}/edit/`;
export const enterpriseEditHHPoolConfRoute = `${enterpriseEditHHPoolRoute}:HHPoolId`;
export const enterpriseViewHHPoolRoute = `${enterpriseHHPoolBaseRoute}/`;
export const enterpriseViewHHPoolConfRoute = `${enterpriseHHPoolBaseRoute}/:HHPoolId`;

export const enterpriseMyTalentPoolRoute = `${ENTERPRISE_ROUTE_NAMESPACE}/my-talent-pool`;

export const enterpriseTalentBoardRoute = `${ENTERPRISE_ROUTE_NAMESPACE}/talent-board`;
/*
 * Enterprise job post routes
 */
export const enterpriseJobPostRoute = `${ENTERPRISE_ROUTE_NAMESPACE}/job-post`;
export const enterpriseCreateJobPostRoute = `${enterpriseJobPostRoute}/create`;
export const enterpriseEditJobPostRoute = `${enterpriseJobPostRoute}/edit/`;
export const enterpriseEditJobPostConfRoute = `${enterpriseEditJobPostRoute}:JobPostId`;
export const enterpriseMyJobPostsRoute = `${ENTERPRISE_ROUTE_NAMESPACE}/my-job-posts`;
