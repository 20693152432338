import {useCallback, useState} from 'react';
import 'intersection-observer';
import {useIntersectionObserver} from '@researchgate/react-intersection-observer';



//Copied from "@researchgate/react-intersection-observer" types
interface IUseIntersectionObserverOptions {
    /**
     * The element that is used as the viewport for checking visibility of the target.
     * Can be specified as string for selector matching within the document.
     * Defaults to the browser viewport if not specified or if null.
     */
    root?: string | Element | null;
    /**
     * Margin around the root. Can have values similar to the CSS margin property,
     * e.g. "10px 20px 30px 40px" (top, right, bottom, left).
     * If the root element is specified, the values can be percentages.
     * This set of values serves to grow or shrink each side of the root element's
     * bounding box before computing intersections.
     * Defaults to all zeros.
     */
    rootMargin?: string;
    /**
     * Either a single number or an array of numbers which indicate at what percentage
     * of the target's visibility the observer's callback should be executed.
     * If you only want to detect when visibility passes the 50% mark, you can use a value of 0.5.
     * If you want the callback run every time visibility passes another 25%,
     * you would specify the array [0, 0.25, 0.5, 0.75, 1].
     * The default is 0 (meaning as soon as even one pixel is visible, the callback will be run).
     * A value of 1.0 means that the threshold isn't considered passed until every pixel is visible.
     */
    threshold?: number | number[];
    /**
     * Controls whether the element should stop being observed by its IntersectionObserver instance.
     * Defaults to false.
     */
    disabled?: boolean;
}

export interface IUseSubscribeOnIntersectionObserverParams extends IUseIntersectionObserverOptions {
    once?: boolean
    visibleWhenDisabled?: boolean
    threshold?: number
}

// Note: if for some reason functionality of this hook will not be enough then:
//  1) Overwriting of "threshold" field type should be removed
//  2) Whole "IntersectionObserverEntry" could be returned as a response of this hook
function useSubscribeOnIntersectionObserver({once, ...options}: IUseSubscribeOnIntersectionObserverParams = {}) {
    const [isVisible, setIsVisible] = useState<boolean | null>(null);
    const {threshold = 0, disabled, visibleWhenDisabled = false} = options;

    const handleChange = useCallback(
        ({isIntersecting, intersectionRatio}: IntersectionObserverEntry, unobserve: () => void) => {
            if (isIntersecting && once) {
                unobserve();
            }

            setIsVisible(isIntersecting && intersectionRatio >= threshold);
        },
        [once, threshold]
    );

    const [intersectionTargetRef] = useIntersectionObserver(handleChange, options);

    return {
        intersectionTargetRef,
        isVisible: !!(disabled && visibleWhenDisabled) || !!isVisible,
        isIndeterminate: isVisible === null
    };
}

export default useSubscribeOnIntersectionObserver;
