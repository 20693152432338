import { useAuthContext } from '~context/auth';
import { SubscriptionStatusEnum } from '~graphqlResources';



export default function HeaderCredits() {
    const { subscription } = useAuthContext();
    if (!subscription) return null;
    return (
        <div className="user-menu-dropdown__progress-wrapper">
            <div className="user-menu-dropdown__credits-wrapper">
                <p>
                    Credits{' '}
                    {subscription?.product?.hasTrial && (
                        <span className="user-menu-dropdown__trial">Trial</span>
                    )}
                </p>

                <p>
                    {subscription.status !== SubscriptionStatusEnum.ACTIVE
                        ? '0/0'
                        : `${subscription.availableCredits} /
                          ${subscription.initialCredits}`}
                </p>
            </div>
            {subscription.availableCredits !== 0 && (
                <progress
                    className="user-menu-dropdown__progress"
                    value={subscription.availableCredits}
                    max={subscription.initialCredits}
                />
            )}
        </div>
    );
}
