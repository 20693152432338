import usePrevious from '~hooks/usePrevious';



function useHasChanged<Value = any>(value: Value): boolean {
    const prevVal = usePrevious(value);
    return prevVal !== value;
}

export default useHasChanged;
