import { useEffect } from 'react';



function useOnUnmount(onUnmountHandler: () => void) {
    useEffect(() => () => {
        onUnmountHandler();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
}

export default useOnUnmount;
