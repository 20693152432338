import {SVGProps} from 'react';



function MediumIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0.5 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 .5C5.374.5 0 5.872 0 12.5c0 6.627 5.374 12 12 12 6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12zm6.974 17.557v-.235l-1.092-1.072a.326.326 0 01-.124-.313V8.563a.326.326 0 01.124-.313L19 7.178v-.235h-3.869l-2.758 6.88-3.138-6.88H5.176v.235l1.308 1.575a.54.54 0 01.176.457v6.188a.713.713 0 01-.189.614L5 17.796v.235h4.17v-.235l-1.471-1.784a.737.737 0 01-.202-.614v-5.352l3.66 7.985h.425l3.143-7.985v6.365c0 .17 0 .202-.111.313l-1.13 1.098v.235h5.49z"
                fill="currentColor"
            />
        </svg>
    );
}

export default MediumIcon;
