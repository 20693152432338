import { SVGProps } from 'react';



function CrossIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20.011 20.011"
            width="1em"
            height="1em"
            {...props}
        >
            <g transform="translate(-5216 -3611.99)" fill="currentColor">
                <rect
                    width={4.994}
                    height={23.305}
                    rx={2.497}
                    transform="rotate(45 -1743.818 8122.156)"
                />
                <rect
                    width={4.994}
                    height={23.305}
                    rx={2.497}
                    transform="rotate(-45 6972.32 -4488.508)"
                />
            </g>
        </svg>
    );
}

export default CrossIcon;
