export const MARKETING_PORTAL_ROUTE_NAMESPACE = '/marketing-portal';

export const dashboardMarketingRoute = MARKETING_PORTAL_ROUTE_NAMESPACE;
export const jobsStatisticsMarketingRoute = `${MARKETING_PORTAL_ROUTE_NAMESPACE}/jobs-statistics`;
export const pendingRequestsMarketingRoute = `${MARKETING_PORTAL_ROUTE_NAMESPACE}/pending-requests`;
export const requestsHistoryMarketingRoute = `${MARKETING_PORTAL_ROUTE_NAMESPACE}/requests-history`;
export const pendingJobPostsRequestsMarketingRoute = `${MARKETING_PORTAL_ROUTE_NAMESPACE}/pending-job-posts-requests`;
export const approvalHistoryMarketingRoute = `${MARKETING_PORTAL_ROUTE_NAMESPACE}/approval-history`;

export const companyProfilesMarketingRoute = `${MARKETING_PORTAL_ROUTE_NAMESPACE}/company-profiles`;

export const loginMarketingRoute = `${MARKETING_PORTAL_ROUTE_NAMESPACE}/login`;
