export const homeRoute = '/';
export const registerRouteBase = '/register';
export const registerChooseTypeRoute = `${registerRouteBase}/choose-type`;
export const registerTalentRoute = `${registerRouteBase}/talent`;
export const registerEnterpriseRoute = `${registerRouteBase}/enterprise`;
export const loginRoute = '/login';
export const forgotPasswordRoute = '/forgot-password';
export const notFoundRoute = '/404';
export const previewComponentsRoute = '/preview-components';
export const linkedInRoute = '/linkedin';
export const emailConfirmRoute = '/email-confirm';
export const resetPasswordRoute = '/reset-password';
export const deleteProfileSuccessRoute = '/delete-profile-success';
