import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useReducer
} from 'react';
import { useHistory } from 'react-router-dom';

//Utils
import LStorage from '~utils/LStorage';
import {
    bindRemoteReporterUser,
    unbindRemoteReporterUser
} from '~utils/remoteReportHelper';
//Types
import type {
    JwtType,
    SubscriptionUpdatedSubscription,
    SubscriptionUpdatedSubscriptionVariables
} from '~graphqlResources';
//GraphQL
import {
    ProfileTypeEnum,
    SubscriptionStatusEnum,
    SubscriptionUpdatedDocument,
    useFindSubscriptionLazyQuery,
    useGetProfileLazyQuery,
    useLogoutMutation
} from '~graphqlResources';
import {
    AuthActionTypes,
    AuthLoadingState,
    checkAuthFnType,
    IAuthProvider,
    IContextProps,
    loginFnType,
    logoutFnType,
    setLoadingStateFnType,
    updateTokenFnType
} from '~context/auth/types';
//Apollo
import client, {subscriptionClient} from '~app/apolloClient';
//Reducer
import { authReducer } from '~context/auth/authReducer';
import { getAuthInitialState } from '~context/auth/authInitialState';
//Config
import { LOCAL_STORE_TOKEN } from '~config/localStore';
import { enterpriseChoosePlanRoute, loginRoute } from '~config/routes';
import { parseResponseErrors } from '~utils/formHelpers';
import { TypedDocumentNode } from '@apollo/client';



const AuthContext = createContext<Partial<IContextProps>>({});

export const AuthProvider = ({
    children,
    tokenName = LOCAL_STORE_TOKEN,
    loginPath = loginRoute,
    useProfileLazyQuery = useGetProfileLazyQuery,
    queryKey = 'getProfile',
    onAuthClear,
    onAuthSet,
    onCleanInit
}: IAuthProvider) => {
    const [state, dispatch] = useReducer(
        authReducer,
        getAuthInitialState(tokenName)
    );
    const { push } = useHistory();
    const setAuthLoadingState: setLoadingStateFnType = useCallback(
        (loadingState) => {
            dispatch({
                type: AuthActionTypes.SET_LOADING_STATE,
                payload: {
                    loading: loadingState
                }
            });
        },
        [dispatch]
    );
    const [logoutUser] = useLogoutMutation({
        onError() {
            processLogout({ loadingState: AuthLoadingState.FAILED });
        }
    });
    const [
        findSubscription,
        { subscribeToMore }
    ] = useFindSubscriptionLazyQuery({
        fetchPolicy: 'network-only',
        variables: { onlyActive: false },
        onCompleted({ findSubscription: subscription }) {
            dispatch({
                type: AuthActionTypes.SET_SUBSCRIPTION,
                payload: {
                    subscription: subscription,
                    loading: AuthLoadingState.SUCCEEDED
                }
            });
        },
        onError(e) {
            setAuthLoadingState(AuthLoadingState.SUCCEEDED);
        }
    });
    useEffect(() => {
        if (
            subscribeToMore &&
            state.profile?.type === ProfileTypeEnum.ENTERPRISE
        ) {
            return subscribeToMore({
                document: SubscriptionUpdatedDocument as TypedDocumentNode<
                    SubscriptionUpdatedSubscription,
                    SubscriptionUpdatedSubscriptionVariables
                >,
                updateQuery: (prev, { subscriptionData }) => {
                    if (!subscriptionData.data) return prev;
                    dispatch({
                        type: AuthActionTypes.SET_SUBSCRIPTION,
                        payload: {
                            subscription:
                                subscriptionData.data.subscriptionUpdated
                        }
                    });
                    return {
                        ...prev,
                        subscriptionData: {
                            ...subscriptionData.data.subscriptionUpdated
                        }
                    };
                }
            });
        }
        if (state.token && state.profile) {
            state.profile?.type === ProfileTypeEnum.ENTERPRISE
                ? findSubscription()
                : setAuthLoadingState(AuthLoadingState.SUCCEEDED);
        }
    }, [
        setAuthLoadingState,
        findSubscription,
        subscribeToMore,
        state.token,
        state.profile,
        state.subscription
    ]);

    const [getProfile] = useProfileLazyQuery({
        fetchPolicy: 'network-only',
        onCompleted({ [queryKey as 'getProfile']: profile }) {
            dispatch({
                type: AuthActionTypes.CHECK_AUTH,
                payload: {
                    profile
                }
            });

            const { __typename, fullName, id, ...userForReport } = profile;
            bindRemoteReporterUser({
                username: fullName,
                systemId: id,
                ...userForReport
            });

            onAuthSet?.(profile);
        },
        onError(e) {
            try {
                const errors = parseResponseErrors(e);

                logout({
                    loadingState: AuthLoadingState.FAILED,
                    onFinish: () => push(loginPath),
                    onlyLocal: errors.code === 'UNAUTHENTICATED'
                });
            } catch {
                push(loginPath);
            }
        }
    });

    const checkAuth: checkAuthFnType = useCallback(() => {
        setAuthLoadingState(AuthLoadingState.PENDING);
        getProfile();
    }, [getProfile, setAuthLoadingState]);

    const refreshAuth = useCallback<checkAuthFnType>(() => {
        getProfile();
        findSubscription();
    }, [getProfile, findSubscription]);

    const login: loginFnType = async (userData) => {
        await client.clearStore();
        const {
            profile,
            jwt: { token, expiresIn },
            subscription
        } = userData;
        const jwtToken = { token, expiresIn };

        LStorage.addToStorage(tokenName, jwtToken);

        dispatch({
            type: AuthActionTypes.LOGIN,
            payload: {
                token: jwtToken,
                profile,
                loading: AuthLoadingState.SUCCEEDED,
                subscription
            }
        });

        const { __typename, fullName, id, ...userForReport } = profile;
        bindRemoteReporterUser({
            username: fullName,
            systemId: id,
            ...userForReport
        });

        onAuthSet?.(profile);
        if (
            profile.type === ProfileTypeEnum.ENTERPRISE &&
            (subscription?.status !== SubscriptionStatusEnum.ACTIVE ||
                subscription?.availableCredits === 0)
        ) {
            push(enterpriseChoosePlanRoute);
        }
    };

    const processLogout: logoutFnType = useCallback(
        ({ loadingState, onFinish }) => {
            LStorage.removeFromStorage(tokenName);

            dispatch({
                type: AuthActionTypes.LOGOUT,
                payload: {
                    loading: loadingState
                }
            });

            unbindRemoteReporterUser();

            onAuthClear?.();

            subscriptionClient.close();
            client.clearStore();
            onFinish?.();
        },
        [tokenName, onAuthClear]
    );

    const logout: logoutFnType = ({
        loadingState = AuthLoadingState.SUCCEEDED,
        onFinish,
        onlyLocal
    } = {}) => {
        if (onlyLocal || !state?.token?.token) {
            return processLogout({ loadingState, onFinish });
        }

        logoutUser().finally(() => processLogout({ loadingState, onFinish }));
    };

    const updateToken: updateTokenFnType = (token) => {
        dispatch({
            type: AuthActionTypes.UPDATE_TOKEN,
            payload: {
                token
            }
        });
    };

    useEffect(() => {
        if (!getAuthInitialState(tokenName).token) {
            setAuthLoadingState(AuthLoadingState.SUCCEEDED);

            onCleanInit?.();
        } else {
            checkAuth();
        }
    }, [tokenName, onCleanInit, setAuthLoadingState, checkAuth]);

    useEffect(() => {
        function listenLocalStoreUpdate(event: StorageEvent) {
            if (event.key !== `${LStorage.name}/${tokenName}`) return;

            if (event.newValue === null) {
                processLogout({
                    onFinish: () => push(loginPath)
                });
            } else {
                const token = LStorage.getParsedFromStorage<JwtType>(tokenName);
                updateToken(token);
                refreshAuth();
            }
        }

        window.addEventListener('storage', listenLocalStoreUpdate);
        return () =>
            window.removeEventListener('storage', listenLocalStoreUpdate);
    }, [refreshAuth, push, processLogout, loginPath, tokenName]);

    return (
        <AuthContext.Provider
            value={{
                profile: state?.profile,
                token: state?.token,
                subscription: state?.subscription,
                isAuthenticated: !!state?.token?.token,
                loading: state?.loading,
                setAuthLoadingState,
                refreshAuth,
                login,
                logout,
                updateToken
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => useContext(AuthContext);
