import { useEffect, useRef } from 'react';



function usePrevious<Value = any>(value: Value): Value {
    const ref = useRef<Value>(value);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export default usePrevious;
