import { ChangeEvent, KeyboardEvent } from 'react';
import { ApolloError } from '@apollo/client';
import { ControllerFieldState, FieldValues, FormState, get, Path, UseFormSetError } from 'react-hook-form';
import { OptionTypeDefault } from '~components/CustomSelectInput/types';



interface IGetInputStates {
    isDirty: boolean
    isTouched: boolean
    isInvalid: boolean
    error: string | undefined
}

export function getInputStates<Values extends FieldValues>(inputName: Path<Values>, formState: FormState<Values>): IGetInputStates {
    return {
        isDirty: !!formState.dirtyFields?.[inputName],
        isTouched: !!formState.touchedFields?.[inputName],
        isInvalid: !!formState.errors?.[inputName],
        error: get(formState.errors, inputName)?.message
    };
}

export function getControllerInputStates(fieldState: ControllerFieldState): IGetInputStates {
    return {
        isDirty: fieldState.isDirty,
        isTouched: fieldState.isTouched,
        isInvalid: fieldState.invalid,
        error: fieldState.error?.message
    };
}

interface IParseResponseErrors {
    code: string
    errors: any
}

export function parseResponseErrors(err: ApolloError): IParseResponseErrors {
    const formErrors = {
        code: 'UNKNOWN_CODE',
        errors: {}
    };

    if (err?.graphQLErrors?.[0]?.extensions?.code) {
        formErrors.code = err?.graphQLErrors?.[0]?.extensions?.code;
    }

    if (err?.graphQLErrors?.[0]?.extensions?.errors) {
        formErrors.errors = err?.graphQLErrors?.[0]?.extensions?.errors;
    }

    return formErrors;
}

export function parseResponseErrorMessage(err: ApolloError) {
    const formErrors = parseResponseErrors(err);
    if (formErrors.code === 'GRAPHQL_VALIDATION_FAILED') {
        const firstErrorMessage = Object.values(formErrors.errors)?.[0];

        return firstErrorMessage as string | undefined;
    } else {
        // TODO: Open Modal window with error message which should be implemented in TALEN-578 task
    }
}


export const transformSubmitErrorKeys = <TFieldValues extends FieldValues = FieldValues>(
    setError: UseFormSetError<TFieldValues>,
    mapConfig: Record<string, Path<TFieldValues>>
): UseFormSetError<TFieldValues> => (fieldName, errorOptions, options) => {
        setError(
            mapConfig[fieldName] || fieldName,
            errorOptions,
            options
        );
    };

/*
 * React-select option helpers
 */
// TODO: Replace direct creation of options by this helper function
export function makeSelectOption(
    value: any,
    label: string = value?.toString(),
    additionalFields?: Record<string, any>
): OptionTypeDefault {
    if ((value === undefined || value === null) && !label) return value;

    return {
        value,
        label,
        ...additionalFields
    };
}

export function getSelectOptionValue(option?: OptionTypeDefault): string {
    return option?.value;
}

export function numberInputPreventNonDigitsOnKeyPress(e: KeyboardEvent<HTMLInputElement>) {
    if (!/\d/.test(e.key)) {
        e.preventDefault();
    }
}

export function numberInputPreventNonDigitsOnInput(e: ChangeEvent<HTMLInputElement>) {
    if (/\D/.test(e.target?.value)) {
        e.preventDefault();
        e.target.value = e.target.value.replace(/\D/g, '');
    }
}
