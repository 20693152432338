import {createContext, MouseEvent, useCallback, useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import noop from 'lodash.noop';
import cn from 'classnames';
import PubSub from 'pubsub-js';
import {useLocation} from 'react-router-dom';
import {clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';

//Config
import {CLOSE_ALL_MODAL_EVENT, CLOSE_MODAL_BY_ID_EVENT} from '~config/pubSubEvents';
//Icons
import CrossIcon from '~icons/CrossIcon';
//Helpers
import {findBodyScrollIgnoreElement, getTypeOf} from '~utils/helpers';
//Hooks
import useHasChanged from '~hooks/useHasChanged';
//Types
import {
    IContextProps, CustomOnClose, ICloseModalById, IModalBodyProps, IModalFooterProps, IModalHeaderProps, IModalProps
} from '~components/Modal/types';



export const closeAllModals = (customOnClose?: CustomOnClose) => (PubSub.publish(CLOSE_ALL_MODAL_EVENT, customOnClose));
export const closeModalById = (params: ICloseModalById) => PubSub.publish(CLOSE_MODAL_BY_ID_EVENT, params);

const ModalContext = createContext<Partial<IContextProps>>({});



const Modal = ({onClose = noop, children, className, id}: IModalProps) => {
    const [isOpened, setIsOpened] = useState(false);
    const {pathname} = useLocation();

    const el = useRef<HTMLElement>(document.querySelector('.modal'));
    const customOnClose = useRef<CustomOnClose | null>(null);
    const pubSubCloseAllModalsToken = useRef<string | null>(null);
    const pubSubCloseModalByIdToken = useRef<string | null>(null);
    const contextProviderValue = useRef<IContextProps>({close: noop});

    const pathnameIsChanged = useHasChanged(pathname);

    const closeModal = useCallback((e?: MouseEvent<HTMLElement> | string, customOnCloseFn: CustomOnClose = null) => {
        customOnClose.current = customOnCloseFn;
        setIsOpened(false);

        if (el.current.childElementCount <= 1) {
            enableBodyScroll(el.current);
        }
    }, [setIsOpened]);

    useEffect(() => {
        pubSubCloseAllModalsToken.current = PubSub.subscribe(CLOSE_ALL_MODAL_EVENT, closeModal);
        pubSubCloseModalByIdToken.current = PubSub.subscribe(CLOSE_MODAL_BY_ID_EVENT,
            (pubSubEventName: string, params: ICloseModalById) => {
                params.modalId === id && closeModal(pubSubEventName, params.onClose);
            }
        );
        const timeoutID = setTimeout(() => setIsOpened(true));
        document.documentElement.classList.add('modal-active');
        const elChildElementsCount = el.current.childElementCount;

        if (elChildElementsCount <= 1) {
            disableBodyScroll(el.current, {
                reserveScrollBarGap: true,
                allowTouchMove: findBodyScrollIgnoreElement
            });
        }

        return () => {
            clearTimeout(timeoutID);
            document.documentElement.classList.remove('modal-active');
            customOnClose.current = null;
            PubSub.unsubscribe(pubSubCloseAllModalsToken.current);
            PubSub.unsubscribe(pubSubCloseModalByIdToken.current);

            if (elChildElementsCount <= 1) {
                clearAllBodyScrollLocks();
            }
        };
    }, [closeModal, id]);

    useEffect(() => {
        if (contextProviderValue.current) {
            contextProviderValue.current = {
                close: closeModal
            };
        }
    });

    const onRouteChange = () => isOpened && closeModal();

    const handleBackdropTransition = () => {
        if (!isOpened) {
            getTypeOf(customOnClose.current) === 'function' ? customOnClose.current() : onClose();
            customOnClose.current = null;
        }
    };

    if (pathnameIsChanged) onRouteChange();

    return ReactDOM.createPortal(
        <div className="modal__container">
            <div className={cn('modal__inner', className, {'is-open': isOpened})} data-bodyscrolllockignore="true">
                <div className="modal__content">
                    <ModalContext.Provider value={contextProviderValue.current}>
                        {children}
                    </ModalContext.Provider>
                </div>
            </div>

            <div
                className="modal__backdrop"
                onClick={closeModal}
                onTransitionEnd={handleBackdropTransition}
            />
        </div>,
        el.current
    );
};

Modal.Header = ({children, className}: IModalHeaderProps) => (
    <ModalContext.Consumer>
        {({close}) => (
            <div className={cn('modal__header', className)}>
                {children}
                <div className="modal__close" onClick={close}>
                    <CrossIcon/>
                </div>
            </div>
        )}
    </ModalContext.Consumer>
);

Modal.CloseButton = ({className}: IModalHeaderProps) => (
    <ModalContext.Consumer>
        {({close}) => (
            <div className={cn('modal__close', className)} onClick={close}>
                <CrossIcon/>
            </div>
        )}
    </ModalContext.Consumer>
);

Modal.Body = ({children, className}: IModalBodyProps) => (
    <div className={cn('modal__body', className)}>
        {children}
    </div>
);

Modal.Footer = ({children, className}: IModalFooterProps) => (
    <div className={cn('modal__footer', className)}>
        {children}
    </div>
);

export default Modal;
