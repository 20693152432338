//Types
import { AuthLoadingState, IAuthState } from '~context/auth/types';
import { JwtType } from '~graphqlResources';
//Utils
import LStorage from '~utils/LStorage';
//Config
import { LOCAL_STORE_TOKEN } from '~config/localStore';



export const authInitialState: IAuthState = {
    token: null,
    profile: null,
    loading: AuthLoadingState.IDLE,
    subscription: null
};

export function getAuthInitialState(tokenName = LOCAL_STORE_TOKEN) {
    const initialState = { ...authInitialState };

    if (LStorage.getFromStorage(tokenName)) {
        const token = LStorage.getParsedFromStorage<JwtType>(tokenName);

        if (token.expiresIn * 1000 < Date.now()) {
            LStorage.removeFromStorage(tokenName);
        } else {
            initialState.token = token;
        }
    }

    return initialState;
}
