import type {IWizardStep, WizardStepsType} from '~context/wizard/types';
import type {PaginationFieldsFragment} from '~graphqlResources';



interface IStepsEnum {
    [x: string]: string
}

interface ISteps<Type extends WizardStepsType> {
    [x: string]: IWizardStep<Type>
}

interface IExtendStepFnParams {
    key: string,
    val: string,
    step: IWizardStep<string>
}
type extendStepFn = ({key, val, step}: IExtendStepFnParams) => void | IWizardStep<string>;

export function generateStepsStructure<Type extends WizardStepsType>(stepsEnum: IStepsEnum, extendFn?: extendStepFn): ISteps<Type> {
    return Object.entries(stepsEnum).reduce((accum: any, [key, val]) => {
        const step = {
            id: val,
            label: val.charAt(0).toUpperCase() + val.slice(1).toLowerCase().replace('_', ' '),
            isCompleted: false,
            isDone: false,
            isSkipped: false,
            withoutSidebar: false,
            withCompactSidebar: false,
            doNotShowStepInSidebar: false,
            path: val.toLowerCase().replace('_', '-')
        };

        accum[key] =
            extendFn
                ? extendFn({key, val, step: {...step}}) ?? step
                : step;

        return accum;
    }, {});
}

export function generateStepsPath<Type extends WizardStepsType>(stepsEnum: IStepsEnum): ISteps<Type> {
    return Object.entries(stepsEnum).reduce((accum: any, [key, val]) => {
        accum[key] =  val.toLowerCase().replace('_', '-');
        return accum;
    }, {});
}

export function shouldShowInfoMessage(meta?: PaginationFieldsFragment) {
    if (!meta) return false;

    return meta.totalItems >= meta.itemsPerPage;
}
