import {ReactNode} from 'react';



export enum HomeUserTypesEnum {
    TALENT = 'TALENT',
    ENTERPRISE = 'ENTERPRISE'
}

export interface IHomeSectionActiveUser {
    activeUserType: HomeUserTypesEnum
}

export interface ILandingPageUserProps {
    children: ReactNode
}

export type toggleUserTypeFn = (newUserType: HomeUserTypesEnum) => void

export interface IContextProps extends IHomeSectionActiveUser {
    toggleUserType: toggleUserTypeFn
}
