import { ReactNode } from 'react';

//Types
import {
    JwtType,
    LoginUserMutation,
    ProfileFieldsFragment,
    SubscriptionFieldsFragment,
    SubscriptionNotificationFieldsFragment,
    useGetProfileLazyQuery
} from '~graphqlResources';



export enum AuthLoadingState {
    IDLE = 'IDLE',
    PENDING = 'PENDING',
    SUCCEEDED = 'SUCCEEDED',
    FAILED = 'FAILED',
}

export enum AuthActionTypes {
    SET_LOADING_STATE = 'SET_LOADING_STATE',
    CHECK_AUTH = 'CHECK_AUTH',
    UPDATE_TOKEN = 'UPDATE_TOKEN',
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    SET_SUBSCRIPTION = 'SET_SUBSCRIPTION',
}

export interface IAuthState {
    token: JwtType | null;
    profile: ProfileFieldsFragment | null;
    loading: AuthLoadingState;
    subscription:
        | SubscriptionFieldsFragment
        | SubscriptionNotificationFieldsFragment;
}

export interface IAuthAction {
    type: AuthActionTypes;
    payload?: {
        token?: JwtType | null;
        profile?: ProfileFieldsFragment | null;
        loading?: AuthLoadingState;
        subscription?:
            | SubscriptionFieldsFragment
            | SubscriptionNotificationFieldsFragment;
    };
}

export type loginFnType = (user: LoginUserMutation['login']) => void;
export type updateTokenFnType = (token: JwtType | null) => void;
export type logoutFnType = (props?: {
    loadingState?: AuthLoadingState;
    onFinish?(): void;
    onlyLocal?: boolean;
}) => void;
export type setLoadingStateFnType = (loadingState: AuthLoadingState) => void;
export type checkAuthFnType = () => void;

export interface IContextProps extends IAuthState {
    login: loginFnType;
    logout: logoutFnType;
    updateToken: updateTokenFnType;
    setAuthLoadingState: setLoadingStateFnType;
    isAuthenticated: boolean;
    refreshAuth: checkAuthFnType;
}

export interface IAuthProvider {
    children: ReactNode;
    tokenName?: string;
    loginPath?: string;
    onAuthSet?: (profile: ProfileFieldsFragment) => void;
    onAuthClear?: () => void;
    onCleanInit?: () => void;
    useProfileLazyQuery?: typeof useGetProfileLazyQuery;
    queryKey?: string;
}
