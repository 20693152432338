import type {HTMLAttributes} from 'react';
import {LANDING_URL} from '~config/constants';



export default function LandingLink({
    children,
    ...props
}: HTMLAttributes<HTMLAnchorElement>) {
    return (
        <a href={LANDING_URL} aria-label="Navigate to the home page" {...props}>
            {children}
        </a>
    );
}
