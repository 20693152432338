import { lazy } from 'react';

//Components
import HeaderAuthLinks from '~components/AppHeader/HeaderAuthLinks';
//Context
import { useAuthContext } from '~context/auth';
//Types
import { AuthLoadingState } from '~context/auth/types';
// Utils
import { chunkErrorHandler, cleanUp } from '~utils/chunkErrorHandler';


// Dynamic imports
const HeaderUser = lazy(() =>
    import('~components/AppHeader/HeaderUser')
        .then(cleanUp)
        .catch(chunkErrorHandler)
);

export default function HeaderUserArea() {
    const {
        isAuthenticated,
        loading,
        profile,
        subscription,
        logout
    } = useAuthContext();

    if (
        loading === AuthLoadingState.IDLE ||
        loading === AuthLoadingState.PENDING
    ) {
        return <div className="header-user-area"/>;
    }

    return (
        <div className="header-user-area">
            {isAuthenticated && profile ? (
                <HeaderUser
                    profile={profile}
                    subscription={subscription}
                    logout={logout}
                />
            ) : (
                <HeaderAuthLinks/>
            )}
        </div>
    );
}
