//Types
import {WizardBiographyStepExtEnum, WizardJobPrefStepEnum, WizardPartEnum} from '~context/wizard/types';
import {TrendAreaKeyEnum} from '~graphqlResources';
//Utils
import {generateStepsPath} from '~utils/wizardUtils';



export enum TALENT_PROFILE_ROUTE_OPERATION_TYPE {
    SETUP = 'setup',
    EDIT = 'edit'
}

export const TALENT_ROUTE_NAMESPACE = '/talent';

export const talentProfileRoute = `${TALENT_ROUTE_NAMESPACE}/profile`;
export const talentDashboardRoute = `${talentProfileRoute}/dashboard`;

/*
 * Talent profile setup
 */
export const wizardRouteBase = `${talentProfileRoute}/${TALENT_PROFILE_ROUTE_OPERATION_TYPE.SETUP}`;
export const wizardCongratulationRoute = `${wizardRouteBase}/congratulation`;
export const wizardDashboardRoute = `${wizardRouteBase}/start`;

export const jobPrefRoutesPath = generateStepsPath(WizardJobPrefStepEnum);
export const biographyRoutesPath = generateStepsPath(WizardBiographyStepExtEnum);

export const wizardPartsRoutePath = {
    [WizardPartEnum.JOB_PREFERENCES]: 'job-preferences',
    [WizardPartEnum.BIOGRAPHY]: 'biography',
    [WizardPartEnum.ASSESSMENT]: 'assessment'
};
export const wizardStepsRoutePath: any = {
    [WizardPartEnum.JOB_PREFERENCES]: jobPrefRoutesPath,
    [WizardPartEnum.BIOGRAPHY]: biographyRoutesPath,
    [WizardPartEnum.ASSESSMENT]: {}
};

/*
 * Talent profile settings
 */
export const profileEditRouteBase = `${talentProfileRoute}/${TALENT_PROFILE_ROUTE_OPERATION_TYPE.EDIT}`;
export const profileEditPersonalInfoRoute = `${profileEditRouteBase}/personal-info`;
export const profileEditPersonalCVRoute = `${profileEditRouteBase}/cv`;
export const profileEditJobPrefRoute = `${profileEditRouteBase}/${wizardPartsRoutePath[WizardPartEnum.JOB_PREFERENCES]}`;
export const profileEditBiographyRoute = `${profileEditRouteBase}/${wizardPartsRoutePath[WizardPartEnum.BIOGRAPHY]}`;
export const profileEditJobPrefObjectiveRoute = `${profileEditJobPrefRoute}/${jobPrefRoutesPath[WizardJobPrefStepEnum.OBJECTIVE]}`;
export const profileEditBiographyEducationRoute = `${profileEditBiographyRoute}/${biographyRoutesPath[WizardBiographyStepExtEnum.EDUCATION]}`;
export const profileEditBiographyExperienceRoute = `${profileEditBiographyRoute}/${biographyRoutesPath[WizardBiographyStepExtEnum.EXPERIENCE]}`;
export const profileEditBiographyExpertiseRoute = `${profileEditBiographyRoute}/${biographyRoutesPath[WizardBiographyStepExtEnum.EXPERTISE]}`;
export const profileEditBiographyItSkillsRoute = `${profileEditBiographyRoute}/${biographyRoutesPath[WizardBiographyStepExtEnum.IT_SKILLS]}`;
export const profileEditBiographyCertificatesRoute = `${profileEditBiographyRoute}/${biographyRoutesPath[WizardBiographyStepExtEnum.CERTIFICATES]}`;
export const profileEditBiographyPersonalSkillsRoute = `${profileEditBiographyRoute}/${biographyRoutesPath[WizardBiographyStepExtEnum.PERSONAL_SKILLS]}`;
export const profileEditBiographyLanguagesRoute = `${profileEditBiographyRoute}/${biographyRoutesPath[WizardBiographyStepExtEnum.LANGUAGES]}`;
export const getProfileEditRoute = (part: WizardPartEnum, step: WizardBiographyStepExtEnum | WizardJobPrefStepEnum | TrendAreaKeyEnum) => `${profileEditRouteBase}/${wizardPartsRoutePath[part]}/${wizardStepsRoutePath[part][step]}`;
export const profileEditJobPrefJobTypeRoute = `${profileEditJobPrefRoute}/${jobPrefRoutesPath[WizardJobPrefStepEnum.JOB_TYPE]}`;

/*
 * Talent job post routes
 */
export const talentJobsRoute = `${talentProfileRoute}/jobs`;
export const talentMyJobsRoute = `${talentProfileRoute}/my-jobs`;
