import Spinner from '~components/Loader/Spinner';



interface FullScreenLoaderProps {
    withSpinner?: boolean;
    title?: string;
    description?: string;
}

export default function FullScreenLoader({ title, description, withSpinner }: FullScreenLoaderProps) {
    return (
        <div className="full-screen-loader">
            <div className="full-screen-loader__content">
                {withSpinner && (
                    <div className="full-screen-loader__spinner-wrapper">
                        <Spinner/>
                    </div>
                )}
                <h2 className="full-screen-loader__content__title">{title}</h2>
                <p className="full-screen-loader__content__descriotion">{description}</p>
            </div>
        </div>
    );
}
