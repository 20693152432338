import {split, from, ApolloClient, InMemoryCache} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {createUploadLink} from 'apollo-upload-client';
import {getMainDefinition} from '@apollo/client/utilities';
import {WebSocketLink} from '@apollo/client/link/ws';
import {SubscriptionClient} from 'subscriptions-transport-ws';

//Constants
import {GRAPHQL_URL, GRAPHQL_WS_URL} from '~config/constants';
//Config
import {LOCAL_STORE_TOKEN} from '~config/localStore';
import typeDefs from '~graphql/apolloClientSideSchema/typeDefs';
import typePolicies from '~graphql/apolloClientSideSchema/typePolicies';
import {errorLink, retryLink} from './commonApolloLinks';
//Utils
import LStorage from '~utils/LStorage';
//Types
import {JwtType} from '~graphqlResources';



const link = createUploadLink({
    uri: GRAPHQL_URL
});

function getAuthHeader() {
    const jwtToken = LStorage.getParsedFromStorage<JwtType>(LOCAL_STORE_TOKEN);

    return ({
        Authorization: jwtToken?.token
            ? `Bearer ${jwtToken?.token}`
            : ''
    });
}

export const subscriptionClient = new SubscriptionClient(GRAPHQL_WS_URL, {
    reconnect: true,
    lazy: true,
    connectionParams: getAuthHeader
});

const wsLink = new WebSocketLink(subscriptionClient);

const authLink = setContext((_, {headers}) => {
    return {
        headers: {
            ...headers,
            ...getAuthHeader()
        }
    };
});

const splitLink = split(
    ({query}) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    retryLink.concat(link)
);

const cache = new InMemoryCache({
    typePolicies
});

const client = new ApolloClient({
    link: from([errorLink, authLink, splitLink]),
    typeDefs,
    cache,
    connectToDevTools: process.env.NODE_ENV !== 'production'
});

export default client;
