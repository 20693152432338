import {createContext, useCallback, useContext, useState} from 'react';

//Types
import {
    HomeUserTypesEnum,
    IContextProps,
    ILandingPageUserProps,
    toggleUserTypeFn
} from '~context/landingPageUser/types';
// Hooks
import {useHomeUserSearchParams} from './useHomeUserSearchParams';



const LandingPageUserPContext = createContext<Partial<IContextProps>>({});

export default function LandingPageUserProvider({children}: ILandingPageUserProps) {
    const {user, setUser} = useHomeUserSearchParams();

    const [activeUserType, setActiveUserType] = useState<HomeUserTypesEnum>(user);

    const toggleUserType: toggleUserTypeFn = useCallback(
        (userType) => {
            setActiveUserType(userType);
            setUser(userType);
        },
        [setActiveUserType, setUser]
    );

    return (
        <LandingPageUserPContext.Provider
            value={{
                activeUserType,
                toggleUserType
            }}
        >
            {children}
        </LandingPageUserPContext.Provider>
    );
}

export const useLandingPageUser = () => useContext(LandingPageUserPContext);
