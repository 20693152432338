import {createContext, useCallback, useContext, useState} from 'react';

//Types
import {CommonModalTypesEnum, ICommonModalsProviderProps, IContextProps, IModalState} from '~context/commonModals/types';
import {IErrorModalParams} from '~components/modals/commonModals/ErrorModal';
import {IInfoModalParams} from '~components/modals/commonModals/InfoModal';
import {IConfirmModalParams} from '~components/modals/commonModals/ConfirmDialog';
//Components
import ErrorModal from '~components/modals/commonModals/ErrorModal';
import InfoModal from '~components/modals/commonModals/InfoModal';
import ConfirmDialog from '~components/modals/commonModals/ConfirmDialog';



const commonModals = {
    [CommonModalTypesEnum.ERROR]: ErrorModal,
    [CommonModalTypesEnum.CONFIRM]: ConfirmDialog,
    [CommonModalTypesEnum.INFO]: InfoModal
};

const defaultModalState: IModalState = {
    openedModalType: null,
    modalParams: {}
};

const CommonModalsContext = createContext<Partial<IContextProps>>({});

export default function CommonModalsProvider({children}: ICommonModalsProviderProps) {
    const [modalState, setModalState] = useState<IModalState>(defaultModalState);


    const closeModal = useCallback(
        () => {
            setModalState(defaultModalState);
        },
        [setModalState]
    );

    const openErrorModal = useCallback(
        (modalParams: IErrorModalParams = {}) => {
            setModalState({
                openedModalType: CommonModalTypesEnum.ERROR,
                modalParams: {
                    ...modalParams,
                    onClose: closeModal
                }
            });
        },
        [closeModal]
    );

    const openInfoModal = useCallback(
        (modalParams: IInfoModalParams) => {
            setModalState({
                openedModalType: CommonModalTypesEnum.INFO,
                modalParams: {
                    ...modalParams,
                    onClose: async () => {
                        await modalParams.onConfirm?.();
                        closeModal();
                    }
                }
            });
        },
        [closeModal]
    );

    const openConfirmModal = useCallback(
        (modalParams: IConfirmModalParams) => {
            setModalState({
                openedModalType: CommonModalTypesEnum.CONFIRM,
                modalParams: {
                    ...modalParams,
                    onClose: closeModal
                }
            });
        },
        [closeModal]
    );

    const ModalComponent =  modalState.openedModalType ? commonModals?.[modalState.openedModalType] : null;

    return (
        <CommonModalsContext.Provider
            value={{
                openErrorModal,
                openInfoModal,
                openConfirmModal
            }}
        >
            {children}

            {modalState.openedModalType && ModalComponent && <ModalComponent {...modalState.modalParams}/>}
        </CommonModalsContext.Provider>
    );
}

export const useCommonModals = () => useContext(CommonModalsContext);
