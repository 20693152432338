import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';



export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AdminLoginResponse = {
  __typename?: 'AdminLoginResponse';
  jwt: JwtType;
  profile: AdminProfileType;
};

export type AdminProfileType = {
  __typename?: 'AdminProfileType';
  email: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['Float'];
  type: AdminProfileTypeEnum;
};

export enum AdminProfileTypeEnum {
  ADMIN = 'ADMIN'
}

export type AssessmentAnswerType = {
  __typename?: 'AssessmentAnswerType';
  id: Scalars['Int'];
  questionId: Scalars['Int'];
  score: Scalars['Int'];
  text: Scalars['String'];
};

export type AssessmentQuestionType = {
  __typename?: 'AssessmentQuestionType';
  answers: Array<AssessmentAnswerType>;
  id: Scalars['Int'];
  label: Scalars['String'];
  multipleChoice: Scalars['Boolean'];
  name: Scalars['String'];
  questionType: Scalars['String'];
  text: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  toolTip?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export enum AssessmentQuestionTypeEnum {
  COMPETENCIES = 'COMPETENCIES',
  MOTIVATIONS = 'MOTIVATIONS',
  TRAITS = 'TRAITS',
  VALUES = 'VALUES'
}

export type AssessmentQuestionsType = {
  __typename?: 'AssessmentQuestionsType';
  items?: Maybe<Array<AssessmentQuestionType>>;
  meta: PaginationMeta;
};

export type AssessmentResultType = {
  __typename?: 'AssessmentResultType';
  question: AssessmentQuestionType;
  score: Scalars['Float'];
};

export type AssessmentResultsType = {
  __typename?: 'AssessmentResultsType';
  attempts?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<AssessmentResultType>>;
};

export type AttendanceType = {
  __typename?: 'AttendanceType';
  id: Scalars['Int'];
  value: AttendanceValueEnum;
};

export enum AttendanceValueEnum {
  COMMUTE_TO_OFFICE_DAILY = 'COMMUTE_TO_OFFICE_DAILY',
  HYBRID_OF_OFFICE_AND_REMOTE = 'HYBRID_OF_OFFICE_AND_REMOTE',
  WORK_REMOTELY = 'WORK_REMOTELY'
}

export type AttendancesType = {
  __typename?: 'AttendancesType';
  items: Array<AttendanceType>;
};

export type AvailabilityType = {
  __typename?: 'AvailabilityType';
  id: Scalars['Int'];
  value: AvailabilityValueEnum;
};

export enum AvailabilityValueEnum {
  IN_ONE_TO_FOUR_WEEKS = 'IN_ONE_TO_FOUR_WEEKS',
  IN_ONE_TO_THREE_MONTHS = 'IN_ONE_TO_THREE_MONTHS',
  MORE_THAN_THREE_MONTHS = 'MORE_THAN_THREE_MONTHS',
  NEXT_DAY = 'NEXT_DAY'
}

export type BestTalentType = {
  __typename?: 'BestTalentType';
  badge: TalentBadgeEnum;
  profile: ProfileType;
  score: Scalars['Int'];
  talentId: Scalars['Int'];
};

export type BillingHistoryType = {
  __typename?: 'BillingHistoryType';
  amount: Scalars['Int'];
  currency: Scalars['String'];
  date?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  invoiceId: Scalars['String'];
  invoiceNumber: Scalars['String'];
  pdf: Scalars['String'];
  product: Scalars['String'];
  status: Scalars['String'];
};

export type CvCertificate = {
  __typename?: 'CVCertificate';
  certificate?: Maybe<CvEntity>;
  certifiedIn?: Maybe<Scalars['String']>;
  issuedBy?: Maybe<Scalars['String']>;
};

export type CvEducation = {
  __typename?: 'CVEducation';
  campus?: Maybe<CvEntity>;
  course?: Maybe<CvEntity>;
  degree?: Maybe<CvEntity>;
  graduatedIn?: Maybe<Scalars['String']>;
  university?: Maybe<CvEntity>;
};

export type CvEmployer = {
  __typename?: 'CVEmployer';
  headquarter?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  industry?: Maybe<CvEntity>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['Float']>;
  size?: Maybe<CvEntity>;
  type?: Maybe<CvEntity>;
  website?: Maybe<Scalars['String']>;
};

export type CvEntity = {
  __typename?: 'CVEntity';
  id?: Maybe<Scalars['Float']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CvExperience = {
  __typename?: 'CVExperience';
  employer?: Maybe<CvEmployer>;
  from?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  jobType?: Maybe<Scalars['String']>;
  keyActivities?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  occupation?: Maybe<CvEntity>;
  to?: Maybe<Scalars['String']>;
};

export type CvLanguage = {
  __typename?: 'CVLanguage';
  language?: Maybe<CvEntity>;
  level?: Maybe<CvEntity>;
};

export type CampusType = {
  __typename?: 'CampusType';
  id: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type CampusesType = {
  __typename?: 'CampusesType';
  items: Array<CampusType>;
};

export type CareerType = {
  __typename?: 'CareerType';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type CareersType = {
  __typename?: 'CareersType';
  items?: Maybe<Array<CareerType>>;
  meta: PaginationMeta;
};

export type CertificateType = {
  __typename?: 'CertificateType';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  createdByProfile?: Maybe<ProfileType>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  label: Scalars['String'];
  name: Scalars['String'];
  status?: Maybe<CustomEntityStatusEnum>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
};

export type CertificatesType = {
  __typename?: 'CertificatesType';
  items?: Maybe<Array<CertificateType>>;
  meta: PaginationMeta;
};

export type ChatProfileStatusType = {
  __typename?: 'ChatProfileStatusType';
  isOnline: Scalars['Boolean'];
  profileId: Scalars['Int'];
};

export type ChatProfileType = {
  __typename?: 'ChatProfileType';
  employment: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['Int'];
  isOnline: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  lastActivity: Scalars['DateTime'];
  picture?: Maybe<Scalars['String']>;
  type: ProfileTypeEnum;
};

export type ChatType = {
  __typename?: 'ChatType';
  hasUnreadMessages: Scalars['Boolean'];
  id: Scalars['Int'];
  profiles: Array<ChatProfileType>;
};

export type ChatsType = {
  __typename?: 'ChatsType';
  items?: Maybe<Array<ChatType>>;
  meta: PaginationMeta;
};

export type ComparisonScoreType = {
  __typename?: 'ComparisonScoreType';
  average: TalentScore;
  talent: TalentScore;
};

export enum ContactMethodEnum {
  CHAT = 'CHAT',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE'
}

export type ContactMethodType = {
  __typename?: 'ContactMethodType';
  id: Scalars['Int'];
  value: ContactMethodEnum;
};

export type CreditUsageStatType = {
  __typename?: 'CreditUsageStatType';
  items?: Maybe<Array<CreditUsageType>>;
  meta: PaginationMeta;
};

export type CreditUsageType = {
  __typename?: 'CreditUsageType';
  createdAt?: Maybe<Scalars['DateTime']>;
  creditsAfter: Scalars['Int'];
  creditsBefore: Scalars['Int'];
  creditsUsed: Scalars['Int'];
  id: Scalars['Int'];
};

export type CursorPaginationMeta = {
  __typename?: 'CursorPaginationMeta';
  bookmark?: Maybe<Scalars['String']>;
  hasMore: Scalars['Boolean'];
  itemCount?: Maybe<Scalars['Int']>;
};

export type CursorPaginationRequest = {
  bookmark?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};

export enum CustomEntityStatusEnum {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}


export enum EmailValidationForEnum {
  SIGN_IN = 'SIGN_IN',
  SIGN_UP = 'SIGN_UP'
}

export type EmployerIndustriesType = {
  __typename?: 'EmployerIndustriesType';
  items?: Maybe<Array<EmployerIndustryType>>;
  meta: PaginationMeta;
};

export type EmployerIndustryType = {
  __typename?: 'EmployerIndustryType';
  id: Scalars['Int'];
  label: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type EmployerLabelType = {
  __typename?: 'EmployerLabelType';
  id: Scalars['Int'];
  label: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type EmployerSizeType = {
  __typename?: 'EmployerSizeType';
  id: Scalars['Int'];
  label: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type EmployerSizesType = {
  __typename?: 'EmployerSizesType';
  items?: Maybe<Array<EmployerSizeType>>;
  meta: PaginationMeta;
};

export type EmployerType = {
  __typename?: 'EmployerType';
  createdAt?: Maybe<Scalars['DateTime']>;
  employerLabel?: Maybe<EmployerLabelType>;
  headquarter?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  industry?: Maybe<EmployerIndustryType>;
  label: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  profileId?: Maybe<Scalars['Float']>;
  size?: Maybe<EmployerSizeType>;
  type?: Maybe<EmployerTypeType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
  website?: Maybe<Scalars['String']>;
};

export type EmployerTypeType = {
  __typename?: 'EmployerTypeType';
  id: Scalars['Int'];
  label: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type EmployerTypesType = {
  __typename?: 'EmployerTypesType';
  items?: Maybe<Array<EmployerTypeType>>;
  meta: PaginationMeta;
};

export type EmployersType = {
  __typename?: 'EmployersType';
  items?: Maybe<Array<EmployerType>>;
  meta: PaginationMeta;
};

export enum EnterpriseAccountEnum {
  IN_HOUSE = 'IN_HOUSE',
  RECRUITMENT = 'RECRUITMENT'
}

export type EnterpriseAccountStatType = {
  __typename?: 'EnterpriseAccountStatType';
  inHouse: Scalars['Float'];
  recruitment: Scalars['Float'];
  total: Scalars['Int'];
};

export type EnterpriseCompaniesStatType = {
  __typename?: 'EnterpriseCompaniesStatType';
  count: Scalars['Int'];
  employer: Scalars['String'];
};

export type EnterpriseCompaniesStatsType = {
  __typename?: 'EnterpriseCompaniesStatsType';
  items?: Maybe<Array<EnterpriseCompaniesStatType>>;
  meta: PaginationMeta;
};

export type EnterpriseDetailsType = {
  __typename?: 'EnterpriseDetailsType';
  employer: EmployerType;
  employerType?: Maybe<EmployerTypeType>;
  headquarter?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  jobTitle: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['Int']>;
};

export enum EnterpriseFileTypeEnum {
  LOGO = 'LOGO',
  PERSONAL_PHOTO = 'PERSONAL_PHOTO'
}

export type EnterpriseLocationStatType = {
  __typename?: 'EnterpriseLocationStatType';
  count: Scalars['Int'];
  country: Scalars['String'];
};

export type EnterpriseLocationStatsType = {
  __typename?: 'EnterpriseLocationStatsType';
  items?: Maybe<Array<EnterpriseLocationStatType>>;
  meta: PaginationMeta;
};

export type EnterpriseMonthlyRegisteredStatType = {
  __typename?: 'EnterpriseMonthlyRegisteredStatType';
  count: Scalars['Int'];
  date: Scalars['DateTime'];
};

export type EnterpriseMonthlyRegisteredStatsType = {
  __typename?: 'EnterpriseMonthlyRegisteredStatsType';
  items: Array<EnterpriseMonthlyRegisteredStatType>;
};

export type EnterpriseNoteType = {
  __typename?: 'EnterpriseNoteType';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  text: Scalars['String'];
};

export type EnterpriseNotesType = {
  __typename?: 'EnterpriseNotesType';
  items?: Maybe<Array<EnterpriseNoteType>>;
  meta: PaginationMeta;
};

export enum EnterprisePrivacyEnum {
  ENTERPRISE_ONLY = 'ENTERPRISE_ONLY',
  NOT_VISIBLE_TO_ANY = 'NOT_VISIBLE_TO_ANY',
  TALENT_AND_ENTERPRISE = 'TALENT_AND_ENTERPRISE',
  TALENT_AND_IN_HOUSE_ENTERPRISE = 'TALENT_AND_IN_HOUSE_ENTERPRISE',
  TALENT_ONLY = 'TALENT_ONLY'
}

export type EnterprisePrivacyType = {
  __typename?: 'EnterprisePrivacyType';
  id: Scalars['Int'];
  value: EnterprisePrivacyEnum;
};

export enum EnterpriseSkippableStepEnum {
  COMPANY_LOGO = 'COMPANY_LOGO'
}

export enum EnterpriseStepStatusEnum {
  DONE = 'DONE',
  PENDING = 'PENDING',
  SKIP = 'SKIP'
}

export type EnterpriseStepStatusType = {
  __typename?: 'EnterpriseStepStatusType';
  enterpriseId: Scalars['Int'];
  status: EnterpriseStepStatusEnum;
  stepKey: EnterpriseWizardStepEnum;
};

export type EnterpriseStepsStatusType = {
  __typename?: 'EnterpriseStepsStatusType';
  items: Array<EnterpriseStepStatusType>;
};

export type EnterpriseType = {
  __typename?: 'EnterpriseType';
  accountType: EnterpriseAccountEnum;
  details?: Maybe<EnterpriseDetailsType>;
  id: Scalars['Int'];
  profile?: Maybe<ProfileType>;
  profileId: Scalars['Int'];
};

export enum EnterpriseWizardStepEnum {
  CAREER = 'CAREER',
  COMPANY_LOGO = 'COMPANY_LOGO',
  CONTACTS = 'CONTACTS',
  DETAILS = 'DETAILS',
  PRIVACY = 'PRIVACY'
}

export enum ExperienceRangeEnum {
  MORE_THAN_10_YEARS_OF_EXPERIENCE = 'MORE_THAN_10_YEARS_OF_EXPERIENCE',
  ONE_TO_THREE_YEARS_OF_EXPERIENCE = 'ONE_TO_THREE_YEARS_OF_EXPERIENCE',
  SIX_TO_TEN_YEARS_OF_EXPERIENCE = 'SIX_TO_TEN_YEARS_OF_EXPERIENCE',
  THREE_TO_SIX_YEARS_OF_EXPERIENCE = 'THREE_TO_SIX_YEARS_OF_EXPERIENCE',
  UP_TO_ONE_YEAR_OF_EXPERIENCE = 'UP_TO_ONE_YEAR_OF_EXPERIENCE'
}

export type ExperienceType = {
  __typename?: 'ExperienceType';
  employer?: Maybe<EmployerType>;
  from?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  jobTitle?: Maybe<Scalars['String']>;
  jobType?: Maybe<JobType>;
  keyActivities?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  occupation?: Maybe<OccupationType>;
  to?: Maybe<Scalars['DateTime']>;
};

export type ExperiencesType = {
  __typename?: 'ExperiencesType';
  items?: Maybe<Array<ExperienceType>>;
  meta: PaginationMeta;
};

export type ExperiencesUnion = ExperiencesType | SimpleExperiencesType;

export type ExpertiseType = {
  __typename?: 'ExpertiseType';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  createdByProfile?: Maybe<ProfileType>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  label: Scalars['String'];
  name: Scalars['String'];
  status?: Maybe<CustomEntityStatusEnum>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
};

export type ExpertisesType = {
  __typename?: 'ExpertisesType';
  items?: Maybe<Array<ExpertiseType>>;
  meta: PaginationMeta;
};

export enum FileTypeEnum {
  CV = 'CV',
  EMPLOYER_LOGO = 'EMPLOYER_LOGO',
  PERSONAL_PHOTO = 'PERSONAL_PHOTO'
}

export enum FullStepsListEnum {
  ASSESSMENT = 'ASSESSMENT',
  ATTENDANCE = 'ATTENDANCE',
  AVAILABILITY = 'AVAILABILITY',
  BIOGRAPHY = 'BIOGRAPHY',
  CERTIFICATES = 'CERTIFICATES',
  CONTACTS = 'CONTACTS',
  CV = 'CV',
  EDUCATION = 'EDUCATION',
  EXPERIENCE = 'EXPERIENCE',
  EXPERTISE = 'EXPERTISE',
  IT_SKILLS = 'IT_SKILLS',
  JOB_PREFERENCES = 'JOB_PREFERENCES',
  JOB_TYPE = 'JOB_TYPE',
  LANGUAGES = 'LANGUAGES',
  OBJECTIVE = 'OBJECTIVE',
  PERSONAL_SKILLS = 'PERSONAL_SKILLS',
  PHOTO = 'PHOTO',
  PRIVACY = 'PRIVACY',
  RELOCATION = 'RELOCATION'
}

export type GetBestTalentsType = {
  __typename?: 'GetBestTalentsType';
  headhunterPool: HeadhunterPoolType;
  items: Array<BestTalentType>;
};

export type HhPoolFilterType = {
  badges?: Maybe<Array<Scalars['String']>>;
  certificates?: Maybe<Array<Scalars['Int']>>;
  educations?: Maybe<Array<Scalars['Int']>>;
  employers?: Maybe<Array<Scalars['Int']>>;
  expertises?: Maybe<Array<Scalars['Int']>>;
  itSkills?: Maybe<Array<Scalars['Int']>>;
  languages?: Maybe<Array<Scalars['Int']>>;
  locations?: Maybe<Array<Scalars['String']>>;
  matchPercentage?: Maybe<Scalars['Float']>;
  occupations?: Maybe<Array<Scalars['Int']>>;
  onlyActiveJobSeeker?: Maybe<Scalars['Boolean']>;
  onlySaved?: Maybe<Scalars['Boolean']>;
  yearOfExperienceRange?: Maybe<Array<Scalars['Float']>>;
};

export enum HhPoolSortByEnum {
  BADGE = 'BADGE',
  LOCATION = 'LOCATION',
  MATCH = 'MATCH',
  OCCUPATION = 'OCCUPATION',
  YEARS_OF_EXPERIENCE = 'YEARS_OF_EXPERIENCE'
}

export type HeadhunterPoolDataType = {
  __typename?: 'HeadhunterPoolDataType';
  filters?: Maybe<HeadhunterPoolExtendedFilterType>;
  talents?: Maybe<HeadhunterPoolTalentsType>;
};

export type HeadhunterPoolExtendedFilterType = {
  __typename?: 'HeadhunterPoolExtendedFilterType';
  badges?: Maybe<Array<Scalars['String']>>;
  certificates?: Maybe<Array<NameWithIdResponse>>;
  educations?: Maybe<Array<NameWithIdResponse>>;
  employers?: Maybe<Array<NameWithIdResponse>>;
  expertises?: Maybe<Array<NameWithIdResponse>>;
  itSkills?: Maybe<Array<NameWithIdResponse>>;
  languages?: Maybe<Array<NameWithIdResponse>>;
  locations?: Maybe<Array<Scalars['String']>>;
  matchRange?: Maybe<Array<Scalars['Int']>>;
  occupations?: Maybe<Array<NameWithIdResponse>>;
  yearOfExperienceRange?: Maybe<Array<Scalars['Float']>>;
};

export type HeadhunterPoolNotification = {
  __typename?: 'HeadhunterPoolNotification';
  enterpriseId: Scalars['Float'];
  id: Scalars['Float'];
  newTalentsCount?: Maybe<Scalars['Float']>;
  status: HeadhunterPoolStatusEnum;
  totalTalentsCount?: Maybe<Scalars['Float']>;
};

export enum HeadhunterPoolStatusEnum {
  DONE = 'DONE',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING'
}

export type HeadhunterPoolTalentsType = {
  __typename?: 'HeadhunterPoolTalentsType';
  items: Array<TalentWithMatchingScoreType>;
  meta: PaginationMeta;
};

export type HeadhunterPoolType = {
  __typename?: 'HeadhunterPoolType';
  attendances: Array<AttendanceType>;
  campuses: Array<CampusType>;
  certificates: Array<CertificateType>;
  courses: Array<UniversityCourseType>;
  currentWork: Scalars['Boolean'];
  degrees: Array<UniversityDegreeType>;
  employers: Array<EmployerType>;
  enterpriseId: Scalars['Float'];
  expertises: Array<ExpertiseType>;
  id: Scalars['Float'];
  industries: Array<EmployerIndustryType>;
  itSkills: Array<ItSkillType>;
  jobTitles: Array<Scalars['String']>;
  jobTypes: Array<JobType>;
  label: Scalars['String'];
  languages: Array<LanguageType>;
  locations: Array<Scalars['String']>;
  mostRecentJobTitle: Scalars['Boolean'];
  name: Scalars['String'];
  newTalentsCount?: Maybe<Scalars['Float']>;
  relocation: Scalars['Boolean'];
  skills: Array<SkillType>;
  status: HeadhunterPoolStatusEnum;
  totalTalentsCount?: Maybe<Scalars['Float']>;
  traitsAndCompetencies: Array<AssessmentQuestionType>;
  universities: Array<UniversityType>;
  value: Scalars['String'];
  valuesAndMotivations: Array<AssessmentQuestionType>;
  yearsOfExperience: Array<ExperienceRangeEnum>;
};

export type HeadhunterPoolsType = {
  __typename?: 'HeadhunterPoolsType';
  items: Array<HeadhunterPoolType>;
};

export type HeadhunterStatByEnterpriseAccountType = {
  __typename?: 'HeadhunterStatByEnterpriseAccountType';
  numberOfAgencyHeadhunters: Scalars['Int'];
  numberOfInHouseHeadhunters: Scalars['Int'];
};

export type HeadhunterTopLocationStatType = {
  __typename?: 'HeadhunterTopLocationStatType';
  count: Scalars['Int'];
  country: Scalars['String'];
};

export type HeadhunterTopLocationStatsType = {
  __typename?: 'HeadhunterTopLocationStatsType';
  items: Array<HeadhunterTopLocationStatType>;
};

export type HhPoolSortType = {
  order?: Maybe<OrderEnum>;
  sortBy?: Maybe<HhPoolSortByEnum>;
};

export type ItSkillLevelType = {
  __typename?: 'ItSkillLevelType';
  id: Scalars['Int'];
  label: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type ItSkillLevelsType = {
  __typename?: 'ItSkillLevelsType';
  items?: Maybe<Array<ItSkillLevelType>>;
  meta: PaginationMeta;
};

export type ItSkillType = {
  __typename?: 'ItSkillType';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  createdByProfile?: Maybe<ProfileType>;
  id: Scalars['Int'];
  label: Scalars['String'];
  name: Scalars['String'];
  status?: Maybe<CustomEntityStatusEnum>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
};

export type ItSkillsType = {
  __typename?: 'ItSkillsType';
  items?: Maybe<Array<ItSkillType>>;
  meta: PaginationMeta;
};

export type JobApplicantFilterType = {
  __typename?: 'JobApplicantFilterType';
  stub?: Maybe<Scalars['Boolean']>;
};

export type JobFilterType = {
  __typename?: 'JobFilterType';
  locations: Array<Scalars['String']>;
  titles: Array<Scalars['String']>;
  types: Array<JobTypeValueEnum>;
};

export type JobPostType = {
  __typename?: 'JobPostType';
  applicants?: Maybe<Scalars['Int']>;
  applied?: Maybe<Scalars['Boolean']>;
  appliedAt?: Maybe<Scalars['DateTime']>;
  attendance?: Maybe<AttendanceType>;
  certificates?: Maybe<Array<CertificateType>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  enterprise?: Maybe<EnterpriseType>;
  enterpriseId: Scalars['Int'];
  expertises?: Maybe<Array<ExpertiseType>>;
  id: Scalars['Int'];
  industries?: Maybe<Array<EmployerIndustryType>>;
  itSkills?: Maybe<Array<ItSkillType>>;
  jobType?: Maybe<JobType>;
  location: Scalars['String'];
  occupation?: Maybe<OccupationType>;
  postedAt?: Maybe<Scalars['DateTime']>;
  relocation: Scalars['String'];
  slug: Scalars['String'];
  status: JobStatusEnum;
  statusReason?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  viewed?: Maybe<Scalars['Boolean']>;
  yearsOfExperience?: Maybe<Array<ExperienceRangeEnum>>;
};

export type JobPostsType = {
  __typename?: 'JobPostsType';
  items: Array<JobPostType>;
  meta: PaginationMeta;
};

export type JobPostsWithFiltersType = {
  __typename?: 'JobPostsWithFiltersType';
  filters: JobFilterType;
  jobs: JobPostsType;
};

export type JobStatType = {
  __typename?: 'JobStatType';
  count: Scalars['Int'];
  date: Scalars['DateTime'];
};

export type JobStatsType = {
  __typename?: 'JobStatsType';
  items: Array<JobStatType>;
};

export enum JobStatusEnum {
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
  LIVE = 'LIVE',
  PENDING = 'PENDING'
}

export type JobTalentsType = {
  __typename?: 'JobTalentsType';
  filters?: Maybe<JobApplicantFilterType>;
  talents?: Maybe<JobTalentsWithPaginationType>;
};

export type JobTalentsWithPaginationType = {
  __typename?: 'JobTalentsWithPaginationType';
  items?: Maybe<Array<TalentWithJobScoreType>>;
  meta: PaginationMeta;
};

export type JobType = {
  __typename?: 'JobType';
  id: Scalars['Int'];
  value: JobTypeValueEnum;
};

export enum JobTypeValueEnum {
  APPRENTICESHIP = 'APPRENTICESHIP',
  CONTRACT = 'CONTRACT',
  FREELANCE = 'FREELANCE',
  FULL_TIME = 'FULL_TIME',
  INTERNSHIP = 'INTERNSHIP',
  PART_TIME = 'PART_TIME'
}

export type JobTypes = {
  __typename?: 'JobTypes';
  items: Array<JobType>;
};

export enum JoinJobFieldEnum {
  ATTENDANCE = 'ATTENDANCE',
  CERTIFICATES = 'CERTIFICATES',
  ENTERPRISE = 'ENTERPRISE',
  EXPERTISES = 'EXPERTISES',
  INDUSTRIES = 'INDUSTRIES',
  IT_SKILLS = 'IT_SKILLS',
  JOB_TYPE = 'JOB_TYPE',
  NUMBER_OF_APPLICANTS = 'NUMBER_OF_APPLICANTS',
  OCCUPATION = 'OCCUPATION'
}

export type JwtType = {
  __typename?: 'JwtType';
  expiresIn: Scalars['Float'];
  token: Scalars['String'];
};

export type KeywordCounterType = {
  __typename?: 'KeywordCounterType';
  count: Scalars['Int'];
  keyword: SearchKeywordsEnum;
};

export type LanguageLevelType = {
  __typename?: 'LanguageLevelType';
  id: Scalars['Int'];
  label: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type LanguageLevelsType = {
  __typename?: 'LanguageLevelsType';
  items?: Maybe<Array<LanguageLevelType>>;
  meta: PaginationMeta;
};

export type LanguageType = {
  __typename?: 'LanguageType';
  id: Scalars['Int'];
  label: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type LanguagesType = {
  __typename?: 'LanguagesType';
  items?: Maybe<Array<LanguageType>>;
  meta: PaginationMeta;
};

export type LinkType = {
  __typename?: 'LinkType';
  link: Scalars['String'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  jwt: JwtType;
  profile: ProfileType;
  subscription?: Maybe<SubscriptionType>;
};

export type MessageType = {
  __typename?: 'MessageType';
  chatId: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  from: ChatProfileType;
  fromId: Scalars['Int'];
  id: Scalars['Int'];
  text: Scalars['String'];
  to: ChatProfileType;
  toId: Scalars['Int'];
  unread: Scalars['Boolean'];
};

export type MessagesType = {
  __typename?: 'MessagesType';
  items?: Maybe<Array<MessageType>>;
  meta: PaginationMeta;
};

export type Mutation = {
  __typename?: 'Mutation';
  addEnterpriseNoteToTalent: SuccessResponse;
  addExpertisesToTalent: SuccessResponse;
  addSkillsToTalent: SuccessResponse;
  addTalentToFavorites: SuccessResponseWithObjectId;
  adminLogin: AdminLoginResponse;
  applyJobPost: SuccessResponse;
  assessmentProgressPing: SuccessResponse;
  confirmEmailAndLogin: LoginResponse;
  createCertificate: CertificateType;
  createChat: ChatType;
  createEmployer: EmployerType;
  createEnterpriseDetails: EnterpriseDetailsType;
  createExperience: ExperienceType;
  createExpertise: ExpertiseType;
  createHeadhunterPool: SuccessResponse;
  createItSkill: ItSkillType;
  createJobPost: SuccessResponse;
  createMessage: MessageType;
  createSubscription: SubscriptionType;
  createTalentCertificate: TalentCertificateType;
  createTalentEducation: TalentEducationType;
  createTalentItSkill: TalentItSkillType;
  createTalentLanguage: TalentLanguageType;
  createUniversity: UniversityType;
  createUniversityCourse: UniversityCourseType;
  deleteExperience: SuccessResponse;
  deleteHeadhunterPool: SuccessResponse;
  deleteProfile: SuccessResponse;
  deleteTalentCertificate: SuccessResponse;
  deleteTalentEducation: SuccessResponse;
  deleteTalentFromFavorites: SuccessResponse;
  deleteTalentItSkill: SuccessResponse;
  deleteTalentLanguage: SuccessResponse;
  login: LoginResponse;
  loginBySocialToken: LoginResponse;
  logout: SuccessResponse;
  markMessageAsRead: SuccessResponse;
  markNotificationAsRead: SuccessResponse;
  mergeCertificate: SuccessResponse;
  mergeEmployer: SuccessResponse;
  mergeExpertise: SuccessResponse;
  mergeItSkill: SuccessResponse;
  onboardingComplete: SuccessResponse;
  recalculate: SuccessResponse;
  registration: SuccessResponse;
  removeEnterpriseFile: SuccessResponse;
  removeExpertisesFromTalent: SuccessResponse;
  removePaymentMethod: SuccessResponse;
  removeSkillsFromTalent: SuccessResponse;
  removeTalentFile: SuccessResponse;
  replaceTalentExpertises: SuccessResponse;
  replaceTalentSkills: SuccessResponse;
  resetPassword: SuccessResponse;
  saveAssessmentAnswers: SuccessResponse;
  savePaymentMethod: PaymentMethodType;
  saveSimpleExperience: SimpleExperienceType;
  saveTelephone: SuccessResponse;
  sendNotification: SuccessResponse;
  sendTelephoneVerificationSms: SendTelephoneVerificationSmsResponse;
  skipEnterpriseStep: SuccessResponse;
  skipStep: SuccessResponse;
  switchAutoChargeSubscriptionOption: SubscriptionType;
  switchJobPostState: SuccessResponse;
  updateCertificate: CertificateType;
  updateContacts: SuccessResponse;
  updateDefaultPaymentMethod: PaymentMethodType;
  updateEmployer: EmployerType;
  updateEnterpriseCareers: SuccessResponse;
  updateEnterpriseContacts: SuccessResponse;
  updateEnterpriseDetails: EnterpriseDetailsType;
  /** @deprecated https://jira.light-it.tools/browse/TALEN-2326 */
  updateEnterprisePrivacy: SuccessResponse;
  updateExperience: ExperienceType;
  updateExpertise: ExpertiseType;
  updateFullName: SuccessResponse;
  updateHeadhunterPool: SuccessResponse;
  updateItSkill: ItSkillType;
  updateJobPost: SuccessResponse;
  updateJobPostByAdmin: SuccessResponse;
  updateTalentAttendances: SuccessResponse;
  updateTalentAvailability: SuccessResponse;
  updateTalentCertificate: TalentCertificateType;
  updateTalentEducation: TalentEducationType;
  updateTalentItSkill: TalentItSkillType;
  updateTalentJobTypes: SuccessResponse;
  updateTalentLanguage: TalentLanguageType;
  updateTalentObjective: SuccessResponse;
  updateTalentPrivacy: SuccessResponse;
  updateTalentRelocation: SuccessResponse;
  updateTalentSummary: SuccessResponse;
  updateUniversity: UniversityType;
  uploadEmployerLogo: Scalars['String'];
  uploadEnterpriseFile: SuccessResponse;
  uploadEnterpriseLogo: LinkType;
  uploadTalentFile: SuccessResponse;
};


export type MutationAddEnterpriseNoteToTalentArgs = {
  talentId: Scalars['Int'];
  text: Scalars['String'];
};


export type MutationAddExpertisesToTalentArgs = {
  expertises: Array<Scalars['Int']>;
};


export type MutationAddSkillsToTalentArgs = {
  skills: Array<Scalars['Int']>;
};


export type MutationAddTalentToFavoritesArgs = {
  talentId: Scalars['Int'];
};


export type MutationAdminLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationApplyJobPostArgs = {
  jobId: Scalars['Int'];
};


export type MutationAssessmentProgressPingArgs = {
  talentAssessmentId: Scalars['Int'];
};


export type MutationConfirmEmailAndLoginArgs = {
  token: Scalars['String'];
};


export type MutationCreateCertificateArgs = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};


export type MutationCreateChatArgs = {
  members: Array<Scalars['Int']>;
};


export type MutationCreateEmployerArgs = {
  industryId: Scalars['Int'];
  name: Scalars['String'];
  sizeId: Scalars['Int'];
  website?: Maybe<Scalars['String']>;
};


export type MutationCreateEnterpriseDetailsArgs = {
  employerId: Scalars['Int'];
  employerTypeId?: Maybe<Scalars['Int']>;
  headquarter?: Maybe<Scalars['String']>;
  jobTitle: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
};


export type MutationCreateExperienceArgs = {
  employerId: Scalars['Int'];
  from: Scalars['DateTime'];
  jobTitle: Scalars['String'];
  jobTypeValue?: Maybe<JobTypeValueEnum>;
  keyActivities?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  occupationId: Scalars['Int'];
  to?: Maybe<Scalars['DateTime']>;
};


export type MutationCreateExpertiseArgs = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};


export type MutationCreateHeadhunterPoolArgs = {
  attendances: Array<AttendanceValueEnum>;
  campusIds?: Maybe<Array<Scalars['Float']>>;
  certificateIds?: Maybe<Array<Scalars['Float']>>;
  courseIds?: Maybe<Array<Scalars['Float']>>;
  currentWork?: Maybe<Scalars['Boolean']>;
  degreeIds?: Maybe<Array<Scalars['Float']>>;
  employerIds?: Maybe<Array<Scalars['Float']>>;
  expertiseIds?: Maybe<Array<Scalars['Float']>>;
  industryIds?: Maybe<Array<Scalars['Float']>>;
  itSkillIds?: Maybe<Array<Scalars['Float']>>;
  jobTitles?: Maybe<Array<Scalars['String']>>;
  jobTypes: Array<JobTypeValueEnum>;
  languageIds?: Maybe<Array<Scalars['Float']>>;
  locations: Array<Scalars['String']>;
  mostRecentJobTitle?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  relocation?: Maybe<Scalars['Boolean']>;
  skillIds?: Maybe<Array<Scalars['Float']>>;
  traitsAndCompetencyIds?: Maybe<Array<Scalars['Float']>>;
  universityIds?: Maybe<Array<Scalars['Float']>>;
  valuesAndMotivationIds?: Maybe<Array<Scalars['Float']>>;
  yearsOfExperience?: Maybe<Array<ExperienceRangeEnum>>;
};


export type MutationCreateItSkillArgs = {
  name: Scalars['String'];
};


export type MutationCreateJobPostArgs = {
  attendanceValue: AttendanceValueEnum;
  certificateIds?: Maybe<Array<Scalars['Int']>>;
  description: Scalars['String'];
  expertiseIds?: Maybe<Array<Scalars['Int']>>;
  industryIds?: Maybe<Array<Scalars['Int']>>;
  itSkillIds?: Maybe<Array<Scalars['Int']>>;
  jobTypeValue: JobTypeValueEnum;
  location: Scalars['String'];
  occupationId: Scalars['Int'];
  relocation: Scalars['Boolean'];
  title: Scalars['String'];
  yearsOfExperience?: Maybe<Array<ExperienceRangeEnum>>;
};


export type MutationCreateMessageArgs = {
  chatId: Scalars['Int'];
  text: Scalars['String'];
};


export type MutationCreateSubscriptionArgs = {
  paymentMethodId?: Maybe<Scalars['Int']>;
  productId: Scalars['Int'];
};


export type MutationCreateTalentCertificateArgs = {
  certificateId: Scalars['Int'];
  certifiedIn?: Maybe<Scalars['DateTime']>;
  issuedBy?: Maybe<Scalars['String']>;
};


export type MutationCreateTalentEducationArgs = {
  campusId: Scalars['Int'];
  courseId: Scalars['Int'];
  degreeId: Scalars['Int'];
  graduatedIn: Scalars['DateTime'];
  universityId: Scalars['Int'];
};


export type MutationCreateTalentItSkillArgs = {
  itSkillId: Scalars['Int'];
  levelId: Scalars['Int'];
  yearsOfExperience: Scalars['Float'];
};


export type MutationCreateTalentLanguageArgs = {
  languageId?: Maybe<Scalars['Int']>;
  levelId?: Maybe<Scalars['Int']>;
};


export type MutationCreateUniversityArgs = {
  campus: Scalars['String'];
  name: Scalars['String'];
};


export type MutationCreateUniversityCourseArgs = {
  name: Scalars['String'];
};


export type MutationDeleteExperienceArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteHeadhunterPoolArgs = {
  headhunterPoolId: Scalars['Int'];
};


export type MutationDeleteTalentCertificateArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTalentEducationArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTalentFromFavoritesArgs = {
  talentId: Scalars['Int'];
};


export type MutationDeleteTalentItSkillArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTalentLanguageArgs = {
  id: Scalars['Int'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationLoginBySocialTokenArgs = {
  callback: Scalars['String'];
  provider: ProviderEnum;
  token: Scalars['String'];
};


export type MutationMarkMessageAsReadArgs = {
  id: Scalars['Int'];
};


export type MutationMarkNotificationAsReadArgs = {
  id: Scalars['Int'];
};


export type MutationMergeCertificateArgs = {
  certificateId: Scalars['Int'];
  targetCertificateId: Scalars['Int'];
};


export type MutationMergeEmployerArgs = {
  employerId: Scalars['Int'];
  targetEmployerId: Scalars['Int'];
};


export type MutationMergeExpertiseArgs = {
  expertiseId: Scalars['Int'];
  targetExpertiseId: Scalars['Int'];
};


export type MutationMergeItSkillArgs = {
  itSkillId: Scalars['Int'];
  targetItSkillId: Scalars['Int'];
};


export type MutationRecalculateArgs = {
  employerIds: Array<Scalars['Float']>;
  enterpriseEmployerIds: Array<Scalars['Float']>;
  enterpriseIds: Array<Scalars['Float']>;
  entityIds: Array<Scalars['Float']>;
  recalculateAll?: Maybe<Scalars['Boolean']>;
  strategy?: Maybe<RecalculateStrategy>;
  target?: Maybe<RecalculateStrategy>;
};


export type MutationRegistrationArgs = {
  email: Scalars['String'];
  enterpriseAccountType?: Maybe<EnterpriseAccountEnum>;
  fullName: Scalars['String'];
  password: Scalars['String'];
  profileType: ProfileTypeEnum;
};


export type MutationRemoveEnterpriseFileArgs = {
  type: EnterpriseFileTypeEnum;
};


export type MutationRemoveExpertisesFromTalentArgs = {
  expertises: Array<Scalars['Int']>;
};


export type MutationRemovePaymentMethodArgs = {
  paymentMethodId: Scalars['Int'];
};


export type MutationRemoveSkillsFromTalentArgs = {
  skills: Array<Scalars['Int']>;
};


export type MutationRemoveTalentFileArgs = {
  type: FileTypeEnum;
};


export type MutationReplaceTalentExpertisesArgs = {
  expertises: Array<Scalars['Int']>;
};


export type MutationReplaceTalentSkillsArgs = {
  skills: Array<Scalars['Int']>;
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationSaveAssessmentAnswersArgs = {
  answers: Array<Scalars['Int']>;
  talentAssessmentId: Scalars['Int'];
};


export type MutationSavePaymentMethodArgs = {
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  cardBrand: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  externalId: Scalars['String'];
  last4: Scalars['String'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};


export type MutationSaveSimpleExperienceArgs = {
  occupationId: Scalars['Int'];
};


export type MutationSaveTelephoneArgs = {
  verificationCode?: Maybe<Scalars['Float']>;
};


export type MutationSendNotificationArgs = {
  additionalValue?: Maybe<Scalars['Int']>;
  enterpriseProfileId?: Maybe<Scalars['Int']>;
  notificationType?: Maybe<NotificationTypeEnum>;
  notificationValue?: Maybe<Scalars['Int']>;
  talentProfileId?: Maybe<Scalars['Int']>;
};


export type MutationSendTelephoneVerificationSmsArgs = {
  telephone: Scalars['String'];
};


export type MutationSkipEnterpriseStepArgs = {
  step: EnterpriseSkippableStepEnum;
};


export type MutationSkipStepArgs = {
  step: SkippableStepEnum;
};


export type MutationSwitchJobPostStateArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateCertificateArgs = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<CustomEntityStatusEnum>;
};


export type MutationUpdateContactsArgs = {
  contactMethodValue: ContactMethodEnum;
  countryCode?: Maybe<Scalars['String']>;
  location: Scalars['String'];
};


export type MutationUpdateDefaultPaymentMethodArgs = {
  paymentMethodId: Scalars['Int'];
};


export type MutationUpdateEmployerArgs = {
  employerId: Scalars['Int'];
  headquarter?: Maybe<Scalars['String']>;
  industryId?: Maybe<Scalars['Int']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sizeId?: Maybe<Scalars['Int']>;
  typeId?: Maybe<Scalars['Int']>;
  website?: Maybe<Scalars['String']>;
};


export type MutationUpdateEnterpriseCareersArgs = {
  careerIds: Array<Scalars['Int']>;
};


export type MutationUpdateEnterpriseContactsArgs = {
  countryCode?: Maybe<Scalars['String']>;
  location: Scalars['String'];
  telephone?: Maybe<Scalars['String']>;
};


export type MutationUpdateEnterpriseDetailsArgs = {
  employerId: Scalars['Int'];
  employerTypeId?: Maybe<Scalars['Int']>;
  enterpriseDetailsId: Scalars['Int'];
  headquarter?: Maybe<Scalars['String']>;
  jobTitle: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
};


export type MutationUpdateEnterprisePrivacyArgs = {
  value: EnterprisePrivacyEnum;
};


export type MutationUpdateExperienceArgs = {
  employerId?: Maybe<Scalars['Int']>;
  experienceId: Scalars['Int'];
  from?: Maybe<Scalars['DateTime']>;
  jobTitle?: Maybe<Scalars['String']>;
  jobTypeValue?: Maybe<JobTypeValueEnum>;
  keyActivities?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  occupationId?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type MutationUpdateExpertiseArgs = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<CustomEntityStatusEnum>;
};


export type MutationUpdateFullNameArgs = {
  fullName: Scalars['String'];
};


export type MutationUpdateHeadhunterPoolArgs = {
  attendances: Array<AttendanceValueEnum>;
  campusIds?: Maybe<Array<Scalars['Float']>>;
  certificateIds?: Maybe<Array<Scalars['Float']>>;
  courseIds?: Maybe<Array<Scalars['Float']>>;
  currentWork?: Maybe<Scalars['Boolean']>;
  degreeIds?: Maybe<Array<Scalars['Float']>>;
  employerIds?: Maybe<Array<Scalars['Float']>>;
  expertiseIds?: Maybe<Array<Scalars['Float']>>;
  id: Scalars['Float'];
  industryIds?: Maybe<Array<Scalars['Float']>>;
  itSkillIds?: Maybe<Array<Scalars['Float']>>;
  jobTitles?: Maybe<Array<Scalars['String']>>;
  jobTypes: Array<JobTypeValueEnum>;
  languageIds?: Maybe<Array<Scalars['Float']>>;
  locations: Array<Scalars['String']>;
  mostRecentJobTitle?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  relocation?: Maybe<Scalars['Boolean']>;
  skillIds?: Maybe<Array<Scalars['Float']>>;
  traitsAndCompetencyIds?: Maybe<Array<Scalars['Float']>>;
  universityIds?: Maybe<Array<Scalars['Float']>>;
  valuesAndMotivationIds?: Maybe<Array<Scalars['Float']>>;
  yearsOfExperience?: Maybe<Array<ExperienceRangeEnum>>;
};


export type MutationUpdateItSkillArgs = {
  id: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<CustomEntityStatusEnum>;
};


export type MutationUpdateJobPostArgs = {
  attendanceValue: AttendanceValueEnum;
  certificateIds?: Maybe<Array<Scalars['Int']>>;
  description?: Maybe<Scalars['String']>;
  expertiseIds?: Maybe<Array<Scalars['Int']>>;
  id: Scalars['Int'];
  industryIds?: Maybe<Array<Scalars['Int']>>;
  itSkillIds?: Maybe<Array<Scalars['Int']>>;
  jobTypeValue: JobTypeValueEnum;
  location?: Maybe<Scalars['String']>;
  occupationId?: Maybe<Scalars['Int']>;
  relocation?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  yearsOfExperience?: Maybe<Array<ExperienceRangeEnum>>;
};


export type MutationUpdateJobPostByAdminArgs = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  occupationId?: Maybe<Scalars['Int']>;
  status: JobStatusEnum;
  statusReason?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};


export type MutationUpdateTalentAttendancesArgs = {
  values: Array<AttendanceValueEnum>;
};


export type MutationUpdateTalentAvailabilityArgs = {
  value: AvailabilityValueEnum;
};


export type MutationUpdateTalentCertificateArgs = {
  certificateId?: Maybe<Scalars['Int']>;
  certifiedIn?: Maybe<Scalars['DateTime']>;
  issuedBy?: Maybe<Scalars['String']>;
  talentCertificateId: Scalars['Int'];
};


export type MutationUpdateTalentEducationArgs = {
  campusId: Scalars['Int'];
  courseId?: Maybe<Scalars['Int']>;
  degreeId?: Maybe<Scalars['Int']>;
  educationId: Scalars['Int'];
  graduatedIn?: Maybe<Scalars['DateTime']>;
  universityId?: Maybe<Scalars['Int']>;
};


export type MutationUpdateTalentItSkillArgs = {
  itSkillId?: Maybe<Scalars['Int']>;
  levelId?: Maybe<Scalars['Int']>;
  talentItSkillId: Scalars['Int'];
  yearsOfExperience?: Maybe<Scalars['Float']>;
};


export type MutationUpdateTalentJobTypesArgs = {
  values: Array<JobTypeValueEnum>;
};


export type MutationUpdateTalentLanguageArgs = {
  languageId?: Maybe<Scalars['Int']>;
  levelId?: Maybe<Scalars['Int']>;
  talentLanguageId: Scalars['Int'];
};


export type MutationUpdateTalentObjectiveArgs = {
  value: ObjectiveValueEnum;
};


export type MutationUpdateTalentPrivacyArgs = {
  value: PrivacyValueEnum;
};


export type MutationUpdateTalentRelocationArgs = {
  countries?: Maybe<Array<Scalars['String']>>;
  value: RelocationValueEnum;
};


export type MutationUpdateTalentSummaryArgs = {
  summary: Scalars['String'];
};


export type MutationUpdateUniversityArgs = {
  campus: Scalars['String'];
  universityId: Scalars['Int'];
};


export type MutationUploadEmployerLogoArgs = {
  file: Scalars['Upload'];
};


export type MutationUploadEnterpriseFileArgs = {
  file: Scalars['Upload'];
  type: EnterpriseFileTypeEnum;
};


export type MutationUploadEnterpriseLogoArgs = {
  file: Scalars['Upload'];
};


export type MutationUploadTalentFileArgs = {
  file: Scalars['Upload'];
  type: FileTypeEnum;
};

export type NameWithIdResponse = {
  __typename?: 'NameWithIdResponse';
  id: Scalars['Int'];
  label: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type NotificationType = {
  __typename?: 'NotificationType';
  additionalText?: Maybe<Scalars['String']>;
  additionalValue?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  notificationValue?: Maybe<Scalars['String']>;
  profileId: Scalars['Int'];
  type: NotificationTypeEnum;
  unread?: Maybe<Scalars['Boolean']>;
};

export enum NotificationTypeEnum {
  BEFORE_SUBSCRIPTION_EXPIRATION = 'BEFORE_SUBSCRIPTION_EXPIRATION',
  ENTERPRISE_JOINED_FROM_FORTUNE_500 = 'ENTERPRISE_JOINED_FROM_FORTUNE_500',
  ENTERPRISE_VIEWED_APPLICANT_PROFILE = 'ENTERPRISE_VIEWED_APPLICANT_PROFILE',
  JOB_POST_WAS_EXPIRED = 'JOB_POST_WAS_EXPIRED',
  JOINED_NEW_ACTIVE_JOB_SEEKERS_TO_HH_POOL = 'JOINED_NEW_ACTIVE_JOB_SEEKERS_TO_HH_POOL',
  JOINED_TALENTS_FROM_TO_HH_POOL = 'JOINED_TALENTS_FROM_TO_HH_POOL',
  JOINED_TALENTS_WITH_EXP_FROM_EMPLOYER_TO_HH_POOL = 'JOINED_TALENTS_WITH_EXP_FROM_EMPLOYER_TO_HH_POOL',
  JOINED_TALENTS_WITH_EXP_IN_INDUSTRY_TO_HH_POOL = 'JOINED_TALENTS_WITH_EXP_IN_INDUSTRY_TO_HH_POOL',
  NEW_ACTIVE_JOB_SEEKER = 'NEW_ACTIVE_JOB_SEEKER',
  NEW_ELITE_AND_ADVANCE_TALENT_IN_HH_POOL = 'NEW_ELITE_AND_ADVANCE_TALENT_IN_HH_POOL',
  NEW_TALENT_IN_HH_POOL = 'NEW_TALENT_IN_HH_POOL',
  RECRUITER_DOWNLOADED_TALENT_RESUME = 'RECRUITER_DOWNLOADED_TALENT_RESUME',
  RECRUITER_IS_SEARCHING_TALENT_OCCUPATION = 'RECRUITER_IS_SEARCHING_TALENT_OCCUPATION',
  RECRUITER_JOINED_FROM = 'RECRUITER_JOINED_FROM',
  SOMEONE_JOINED_FROM_FORTUNE_500 = 'SOMEONE_JOINED_FROM_FORTUNE_500',
  SUBSCRIPTION_PURCHASING = 'SUBSCRIPTION_PURCHASING',
  SUBSCRIPTION_TERMINATION = 'SUBSCRIPTION_TERMINATION',
  TALENT_APPLIED_FOR_A_JOB_POST = 'TALENT_APPLIED_FOR_A_JOB_POST',
  TALENT_GOT_NEW_BADGE = 'TALENT_GOT_NEW_BADGE',
  TALENT_GOT_NEW_PROFILE_VIEWS = 'TALENT_GOT_NEW_PROFILE_VIEWS',
  TALENT_JOINED_FROM_FORTUNE_500 = 'TALENT_JOINED_FROM_FORTUNE_500',
  TALENT_RECEIVED_NEW_JOB_POST = 'TALENT_RECEIVED_NEW_JOB_POST',
  TALENT_WAS_ADDED_TO_MY_TALENT_POOL = 'TALENT_WAS_ADDED_TO_MY_TALENT_POOL'
}

export type NotificationsType = {
  __typename?: 'NotificationsType';
  items?: Maybe<Array<NotificationType>>;
  meta: PaginationMeta;
};

export type ObjectiveType = {
  __typename?: 'ObjectiveType';
  id: Scalars['Int'];
  value: ObjectiveValueEnum;
};

export enum ObjectiveValueEnum {
  ACTIVELY_SEARCHING_FOR_A_JOB = 'ACTIVELY_SEARCHING_FOR_A_JOB',
  EXPLORING_JOBS_TO_FOR_CAREER_ADVANCEMENT = 'EXPLORING_JOBS_TO_FOR_CAREER_ADVANCEMENT',
  HAVE_FREE_TIME_TO_TAKE_EXTRA_LOAD = 'HAVE_FREE_TIME_TO_TAKE_EXTRA_LOAD'
}

export type OccupationType = {
  __typename?: 'OccupationType';
  countOfActiveJobPosts?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  label: Scalars['String'];
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type OccupationsType = {
  __typename?: 'OccupationsType';
  items?: Maybe<Array<OccupationType>>;
  meta: PaginationMeta;
};

export enum OrderByEnum {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum OrderEnum {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type PaginateBillingHistoryType = {
  __typename?: 'PaginateBillingHistoryType';
  items?: Maybe<Array<BillingHistoryType>>;
  meta: CursorPaginationMeta;
};

export type PaginationMeta = {
  __typename?: 'PaginationMeta';
  currentPage: Scalars['Int'];
  itemCount: Scalars['Int'];
  itemsPerPage: Scalars['Int'];
  totalItems: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PaginationRequest = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type PaymentMethodType = {
  __typename?: 'PaymentMethodType';
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  cardBrand: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  externalId: Scalars['String'];
  id: Scalars['Int'];
  isDefault: Scalars['Boolean'];
  last4: Scalars['String'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  profileId: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentMethodsType = {
  __typename?: 'PaymentMethodsType';
  items?: Maybe<Array<PaymentMethodType>>;
  meta: PaginationMeta;
};

export type PendingRequestsType = {
  __typename?: 'PendingRequestsType';
  certificate: Scalars['Int'];
  expertises: Scalars['Int'];
  itSkills: Scalars['Int'];
  job: Scalars['Int'];
};

export type PrivacyType = {
  __typename?: 'PrivacyType';
  id: Scalars['Int'];
  value: PrivacyValueEnum;
};

export enum PrivacyValueEnum {
  ALL_RECRUITERS_ALL = 'ALL_RECRUITERS_ALL',
  ALL_RECRUITERS_EXCEPT_CURRENT_AND_PREVIOUS_EMPLOYERS = 'ALL_RECRUITERS_EXCEPT_CURRENT_AND_PREVIOUS_EMPLOYERS',
  ALL_RECRUITERS_EXCEPT_CURRENT_EMPLOYER = 'ALL_RECRUITERS_EXCEPT_CURRENT_EMPLOYER',
  EXTERNAL_RECRUITERS = 'EXTERNAL_RECRUITERS',
  HIDDEN_PROFILE = 'HIDDEN_PROFILE',
  IN_HOUSE_RECRUITERS_CURRENT_EMPLOYER_ONLY = 'IN_HOUSE_RECRUITERS_CURRENT_EMPLOYER_ONLY',
  IN_HOUSE_RECRUITERS_EXCEPT_CURRENT_AND_PREVIOUS_EMPLOYERS = 'IN_HOUSE_RECRUITERS_EXCEPT_CURRENT_AND_PREVIOUS_EMPLOYERS',
  IN_HOUSE_RECRUITERS_EXCEPT_CURRENT_EMPLOYER = 'IN_HOUSE_RECRUITERS_EXCEPT_CURRENT_EMPLOYER'
}

export type ProfileType = {
  __typename?: 'ProfileType';
  email: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['Float'];
  location?: Maybe<Scalars['String']>;
  onboarded: Scalars['Boolean'];
  picture?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  type: ProfileTypeEnum;
};

export type Query = {
  __typename?: 'Query';
  emailValidation: SuccessResponse;
  emailVerification: SuccessResponse;
  findBillingHistory: PaginateBillingHistoryType;
  findCampuses: CampusesType;
  findCareers: CareersType;
  findCertificates: CertificatesType;
  findCreditUsageStat: CreditUsageStatType;
  findEmployerIndustries: EmployerIndustriesType;
  findEmployerSizes: EmployerSizesType;
  findEmployerTypes: EmployerTypesType;
  findEmployers: EmployersType;
  findEnterpriseCareers: CareersType;
  findExpertises: ExpertisesType;
  findItSkillLevels: ItSkillLevelsType;
  findItSkills: ItSkillsType;
  findJobPost: JobPostType;
  findJobPosts: JobPostsType;
  findJobPostsWithFilters: JobPostsWithFiltersType;
  findJobTalents: JobTalentsType;
  findLanguageLevels: LanguageLevelsType;
  findLanguages: LanguagesType;
  findOccupations: OccupationsType;
  findOccupationsWithJobPosts: OccupationsType;
  findPaymentMethods: PaymentMethodsType;
  findPublicJobPost: JobPostType;
  findSkills: SkillsType;
  findSubscription?: Maybe<SubscriptionType>;
  findSubscriptionProducts: SubscriptionProductsType;
  findSubscriptions: SubscriptionsType;
  findTalent: TalentType;
  findTalents: TalentsType;
  findUniversities: UniversitiesType;
  findUniversityCourses: UniversityCoursesType;
  findUniversityDegrees: UniversityDegreesType;
  getAdminProfile: AdminProfileType;
  getAssessmentQuestionnaire: StartQuestionnaireType;
  getAssessmentQuestions: AssessmentQuestionsType;
  getAssessmentResults: AssessmentResultsType;
  getBestTalents: GetBestTalentsType;
  getChatByMembers?: Maybe<ChatType>;
  getCountOfPendingRequests: PendingRequestsType;
  getCountOfUnreadNotification: UnreadNotificationsType;
  getEnterpriseDetails: EnterpriseDetailsType;
  getEnterpriseNoteByTalent: EnterpriseNotesType;
  /** @deprecated https://jira.light-it.tools/browse/TALEN-2326 */
  getEnterprisePrivacy?: Maybe<EnterprisePrivacyType>;
  getEnterpriseStatByAccountType: EnterpriseAccountStatType;
  getEnterpriseStatByLocation: EnterpriseLocationStatsType;
  getEnterpriseStatByMonthlyRegistered: EnterpriseMonthlyRegisteredStatsType;
  getEnterpriseStatByTopCompanies: EnterpriseCompaniesStatsType;
  getEnterpriseStepsStatus: EnterpriseStepsStatusType;
  getExperiences: ExperiencesUnion;
  getHeadhunterPool: HeadhunterPoolType;
  getHeadhunterPoolData: HeadhunterPoolDataType;
  getHeadhunterPools: HeadhunterPoolsType;
  getHeadhunterStatByEnterpriseAccount: HeadhunterStatByEnterpriseAccountType;
  getHeadhunterStatByTopLocations: HeadhunterTopLocationStatsType;
  getJobByMonthlyAppliedStat: JobStatsType;
  getJobByMonthlyCreatedStat: JobStatsType;
  getListOfChats: ChatsType;
  getListOfMessages: MessagesType;
  getNotifications: NotificationsType;
  getOwnTalentProfile: TalentType;
  getProfile: ProfileType;
  getStepsStatus: StepsStatusType;
  getTalent: TalentType;
  getTalentAttendances: AttendancesType;
  getTalentAvailability?: Maybe<AvailabilityType>;
  getTalentCV: TalentCvType;
  getTalentCertificates: TalentCertificatesType;
  getTalentComparisonScore: ComparisonScoreType;
  getTalentEducations: TalentEducationsType;
  getTalentItSkills: TalentItSkillsType;
  getTalentJobTypes: JobTypes;
  getTalentLanguages: TalentLanguagesType;
  getTalentObjective?: Maybe<ObjectiveType>;
  getTalentPercentile: TalentPercentileType;
  getTalentPoolData: HeadhunterPoolDataType;
  getTalentPrivacy?: Maybe<PrivacyType>;
  getTalentQualities: TalentQualitiesType;
  getTalentRelocation?: Maybe<RelocationType>;
  getTalentStatByBadge: TalentBadgeStatType;
  getTalentStatByLocation: TalentLocationStatsType;
  getTalentStatByMonthlyRegistered: TalentMonthlyRegisteredStatsType;
  getTalentStatByOccupation: TalentOccupationStatsType;
  getTopVisitors?: Maybe<Array<EmployerType>>;
  getTrends: TrendsType;
  getVisibilityStats: VisibilityStatsType;
  hasCurrentlyWorkingExperience: SuccessResponse;
  hasUnreadChats: UnreadChatsType;
  sendResetPasswordLink: SuccessResponse;
  upfrontEmailValidation: SuccessResponse;
};


export type QueryEmailValidationArgs = {
  email: Scalars['String'];
};


export type QueryEmailVerificationArgs = {
  token: Scalars['String'];
};


export type QueryFindBillingHistoryArgs = {
  pagination: CursorPaginationRequest;
};


export type QueryFindCampusesArgs = {
  universityIds: Array<Scalars['Float']>;
};


export type QueryFindCareersArgs = {
  name?: Maybe<Scalars['String']>;
  pagination: PaginationRequest;
};


export type QueryFindCertificatesArgs = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pagination: PaginationRequest;
  statuses?: Maybe<Array<CustomEntityStatusEnum>>;
};


export type QueryFindCreditUsageStatArgs = {
  dateRange?: Maybe<Array<Scalars['String']>>;
  pagination: PaginationRequest;
};


export type QueryFindEmployerIndustriesArgs = {
  name?: Maybe<Scalars['String']>;
  pagination?: Maybe<PaginationRequest>;
};


export type QueryFindEmployerSizesArgs = {
  name?: Maybe<Scalars['String']>;
  pagination?: Maybe<PaginationRequest>;
};


export type QueryFindEmployerTypesArgs = {
  name?: Maybe<Scalars['String']>;
  pagination?: Maybe<PaginationRequest>;
};


export type QueryFindEmployersArgs = {
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Array<Scalars['String']>>;
  pagination?: Maybe<PaginationRequest>;
};


export type QueryFindEnterpriseCareersArgs = {
  name?: Maybe<Scalars['String']>;
  pagination: PaginationRequest;
};


export type QueryFindExpertisesArgs = {
  description?: Maybe<Scalars['String']>;
  extractTalentIdFromAuthToken?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pagination: PaginationRequest;
  statuses?: Maybe<Array<CustomEntityStatusEnum>>;
};


export type QueryFindItSkillLevelsArgs = {
  name?: Maybe<Scalars['String']>;
  pagination?: Maybe<PaginationRequest>;
};


export type QueryFindItSkillsArgs = {
  name?: Maybe<Scalars['String']>;
  pagination: PaginationRequest;
  statuses?: Maybe<Array<CustomEntityStatusEnum>>;
};


export type QueryFindJobPostArgs = {
  id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryFindJobPostsArgs = {
  jobTypes?: Maybe<Array<JobTypeValueEnum>>;
  join?: Maybe<Array<JoinJobFieldEnum>>;
  locations?: Maybe<Array<Scalars['String']>>;
  occupationId?: Maybe<Scalars['Float']>;
  occupationSlug?: Maybe<Scalars['String']>;
  onlyApplied?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<Array<Scalars['String']>>;
  pagination?: Maybe<PaginationRequest>;
  slug?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<JobStatusEnum>>;
  titles?: Maybe<Array<Scalars['String']>>;
};


export type QueryFindJobPostsWithFiltersArgs = {
  jobTypes?: Maybe<Array<JobTypeValueEnum>>;
  join?: Maybe<Array<JoinJobFieldEnum>>;
  locations?: Maybe<Array<Scalars['String']>>;
  occupationId?: Maybe<Scalars['Float']>;
  occupationSlug?: Maybe<Scalars['String']>;
  onlyApplied?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<Array<Scalars['String']>>;
  pagination?: Maybe<PaginationRequest>;
  slug?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<JobStatusEnum>>;
  titles?: Maybe<Array<Scalars['String']>>;
};


export type QueryFindJobTalentsArgs = {
  jobId: Scalars['Int'];
  onlyApplied?: Maybe<Scalars['Boolean']>;
  pagination: PaginationRequest;
  sort: TalentSortType;
};


export type QueryFindLanguageLevelsArgs = {
  name?: Maybe<Scalars['String']>;
  pagination?: Maybe<PaginationRequest>;
};


export type QueryFindLanguagesArgs = {
  name?: Maybe<Scalars['String']>;
  pagination?: Maybe<PaginationRequest>;
};


export type QueryFindOccupationsArgs = {
  name?: Maybe<Scalars['String']>;
  pagination?: Maybe<PaginationRequest>;
};


export type QueryFindOccupationsWithJobPostsArgs = {
  slug?: Maybe<Scalars['String']>;
};


export type QueryFindPaymentMethodsArgs = {
  pagination: PaginationRequest;
};


export type QueryFindPublicJobPostArgs = {
  id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryFindSkillsArgs = {
  description?: Maybe<Scalars['String']>;
  extractTalentIdFromAuthToken?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pagination: PaginationRequest;
};


export type QueryFindSubscriptionArgs = {
  onlyActive?: Maybe<Scalars['Boolean']>;
};


export type QueryFindSubscriptionsArgs = {
  orderBy?: Maybe<Scalars['String']>;
  pagination: PaginationRequest;
  status?: Maybe<SubscriptionStatusEnum>;
};


export type QueryFindTalentArgs = {
  headhunterPoolId?: Maybe<Scalars['Int']>;
  jobId?: Maybe<Scalars['Int']>;
  talentId?: Maybe<Scalars['Float']>;
};


export type QueryFindTalentsArgs = {
  byEnterpriseCareers?: Maybe<Scalars['Boolean']>;
  byEnterpriseRegion?: Maybe<Scalars['Boolean']>;
  careerId?: Maybe<Scalars['Float']>;
  ids?: Maybe<Array<Scalars['Float']>>;
  join?: Maybe<Array<TalentJoinEnum>>;
  keywords?: Maybe<Array<SearchKeywordsEnum>>;
  name?: Maybe<Scalars['String']>;
  onlyFavorite?: Maybe<Scalars['Boolean']>;
  onlyOnboarded?: Maybe<Scalars['Boolean']>;
  pagination: PaginationRequest;
  profileIds?: Maybe<Array<Scalars['Float']>>;
  randomOrder?: Maybe<Scalars['Boolean']>;
};


export type QueryFindUniversitiesArgs = {
  name?: Maybe<Scalars['String']>;
  pagination?: Maybe<PaginationRequest>;
};


export type QueryFindUniversityCoursesArgs = {
  name?: Maybe<Scalars['String']>;
  pagination?: Maybe<PaginationRequest>;
};


export type QueryFindUniversityDegreesArgs = {
  name?: Maybe<Scalars['String']>;
  pagination?: Maybe<PaginationRequest>;
};


export type QueryGetBestTalentsArgs = {
  headhunterPoolId: Scalars['Int'];
  talentId: Scalars['Int'];
};


export type QueryGetChatByMembersArgs = {
  members: Array<Scalars['Int']>;
};


export type QueryGetEnterpriseNoteByTalentArgs = {
  pagination: PaginationRequest;
  talentId: Scalars['Int'];
};


export type QueryGetEnterpriseStatByLocationArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryGetEnterpriseStatByMonthlyRegisteredArgs = {
  year?: Maybe<Scalars['Int']>;
};


export type QueryGetEnterpriseStatByTopCompaniesArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryGetExperiencesArgs = {
  pagination: PaginationRequest;
};


export type QueryGetHeadhunterPoolArgs = {
  headhunterPoolId: Scalars['Int'];
};


export type QueryGetHeadhunterPoolDataArgs = {
  filters: HhPoolFilterType;
  headhunterPoolId: Scalars['Int'];
  pagination: PaginationRequest;
  sort: HhPoolSortType;
};


export type QueryGetJobByMonthlyAppliedStatArgs = {
  year?: Maybe<Scalars['Int']>;
};


export type QueryGetJobByMonthlyCreatedStatArgs = {
  year?: Maybe<Scalars['Int']>;
};


export type QueryGetListOfChatsArgs = {
  pagination: PaginationRequest;
};


export type QueryGetListOfMessagesArgs = {
  chatId: Scalars['Int'];
  pagination: PaginationRequest;
};


export type QueryGetNotificationsArgs = {
  pagination: PaginationRequest;
  profileId?: Maybe<Scalars['Int']>;
};


export type QueryGetStepsStatusArgs = {
  talentId?: Maybe<Scalars['Float']>;
  wizard?: Maybe<WizardEnum>;
};


export type QueryGetTalentCertificatesArgs = {
  pagination: PaginationRequest;
};


export type QueryGetTalentComparisonScoreArgs = {
  headhunterPoolId?: Maybe<Scalars['Int']>;
  jobId?: Maybe<Scalars['Int']>;
  talentId?: Maybe<Scalars['Float']>;
};


export type QueryGetTalentEducationsArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryGetTalentItSkillsArgs = {
  pagination: PaginationRequest;
};


export type QueryGetTalentLanguagesArgs = {
  pagination: PaginationRequest;
};


export type QueryGetTalentPercentileArgs = {
  headhunterPoolId?: Maybe<Scalars['Int']>;
  jobId?: Maybe<Scalars['Int']>;
  talentId?: Maybe<Scalars['Float']>;
};


export type QueryGetTalentPoolDataArgs = {
  filters: HhPoolFilterType;
  pagination: PaginationRequest;
  sort: HhPoolSortType;
};


export type QueryGetTalentStatByLocationArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryGetTalentStatByMonthlyRegisteredArgs = {
  year?: Maybe<Scalars['Int']>;
};


export type QueryGetTalentStatByOccupationArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QuerySendResetPasswordLinkArgs = {
  email: Scalars['String'];
};


export type QueryUpfrontEmailValidationArgs = {
  email: Scalars['String'];
  profileType: ProfileTypeEnum;
  validateFor: EmailValidationForEnum;
};

export type RawTalentCv = {
  __typename?: 'RawTalentCV';
  certificates?: Maybe<Array<CvCertificate>>;
  educations?: Maybe<Array<CvEducation>>;
  experiences?: Maybe<Array<CvExperience>>;
  expertises?: Maybe<Array<CvEntity>>;
  itSkills?: Maybe<Array<CvEntity>>;
  languages?: Maybe<Array<CvLanguage>>;
  personalSkills?: Maybe<Array<CvEntity>>;
  summary?: Maybe<Scalars['String']>;
};

export enum RecalculateStrategy {
  BY_JOB_STRATEGY = 'BY_JOB_STRATEGY',
  BY_POOL = 'BY_POOL',
  BY_TALENT = 'BY_TALENT',
  BY_TALENT_STRATEGY = 'BY_TALENT_STRATEGY'
}

export type RelocationType = {
  __typename?: 'RelocationType';
  countries: Array<Scalars['String']>;
  id: Scalars['Int'];
  value: RelocationValueEnum;
};

export enum RelocationValueEnum {
  CAN_RELOCATE_TO_SOME_COUNTRIES = 'CAN_RELOCATE_TO_SOME_COUNTRIES',
  NOT_OPEN_FOR_RELOCATION = 'NOT_OPEN_FOR_RELOCATION',
  OPEN_FOR_RELOCATION = 'OPEN_FOR_RELOCATION'
}

export enum SearchKeywordsEnum {
  CERTIFICATE = 'CERTIFICATE',
  COURSE = 'COURSE',
  DEGREE = 'DEGREE',
  EMPLOYER = 'EMPLOYER',
  EXPERTISE = 'EXPERTISE',
  INDUSTRY = 'INDUSTRY',
  IT_SKILL = 'IT_SKILL',
  JOB_TITLE = 'JOB_TITLE',
  UNIVERSITY = 'UNIVERSITY'
}

export type SendTelephoneVerificationSmsResponse = {
  __typename?: 'SendTelephoneVerificationSmsResponse';
  success: Scalars['Boolean'];
  verificationCode?: Maybe<Scalars['Float']>;
};

export type SimpleExperienceType = {
  __typename?: 'SimpleExperienceType';
  id: Scalars['Int'];
  occupation: OccupationType;
};

export type SimpleExperiencesType = {
  __typename?: 'SimpleExperiencesType';
  items?: Maybe<Array<SimpleExperienceType>>;
  meta: PaginationMeta;
};

export type SkillType = {
  __typename?: 'SkillType';
  description: Scalars['String'];
  id: Scalars['Int'];
  label: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type SkillsType = {
  __typename?: 'SkillsType';
  items?: Maybe<Array<SkillType>>;
  meta: PaginationMeta;
};

export enum SkippableStepEnum {
  CERTIFICATES = 'CERTIFICATES',
  CV = 'CV',
  EXPERIENCE = 'EXPERIENCE',
  EXPERTISE = 'EXPERTISE',
  IT_SKILLS = 'IT_SKILLS',
  PERSONAL_SKILLS = 'PERSONAL_SKILLS',
  PHOTO = 'PHOTO'
}

export enum SortTalentByEnum {
  BADGE = 'BADGE',
  LOCATION = 'LOCATION',
  MATCH = 'MATCH',
  OCCUPATION = 'OCCUPATION',
  YEARS_OF_EXPERIENCE = 'YEARS_OF_EXPERIENCE'
}

export type StartQuestionnaireType = {
  __typename?: 'StartQuestionnaireType';
  assessmentQuestions: AssessmentQuestionsType;
  startDate: Scalars['DateTime'];
  talentAssessmentId: Scalars['Int'];
};

export enum StatChangeEnum {
  DECREASE = 'DECREASE',
  INCREASE = 'INCREASE',
  NO_CHANGES = 'NO_CHANGES'
}

export enum StatTypeEnum {
  PROFILE_CLICKS = 'PROFILE_CLICKS',
  PROFILE_MATCH = 'PROFILE_MATCH',
  PROFILE_TO_POOL = 'PROFILE_TO_POOL'
}

export enum StepStatusEnum {
  DONE = 'DONE',
  PENDING = 'PENDING',
  SKIP = 'SKIP'
}

export type StepStatusType = {
  __typename?: 'StepStatusType';
  parentStepKey?: Maybe<WizardEnum>;
  status: StepStatusEnum;
  stepKey: FullStepsListEnum;
  talentId: Scalars['Int'];
};

export type StepsStatusType = {
  __typename?: 'StepsStatusType';
  items: Array<StepStatusType>;
};

export type Subscription = {
  __typename?: 'Subscription';
  chatNotificationUpdated: UnreadChatsType;
  chatUpdated: ChatType;
  headhunterUpdated: HeadhunterPoolNotification;
  messageSent: MessageType;
  notificationCounterUpdated: UnreadNotificationsType;
  notificationUpdated: NotificationType;
  profileStatusChanged: ChatProfileStatusType;
  subscriptionUpdated: SubscriptionNotification;
  talentCvUpdated: TalentCv;
};


export type SubscriptionMessageSentArgs = {
  chatId: Scalars['Int'];
};

export type SubscriptionNotification = {
  __typename?: 'SubscriptionNotification';
  availableCredits: Scalars['Int'];
  cancelAtPeriodEnd?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expireAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  initialCredits: Scalars['Int'];
  product?: Maybe<SubscriptionProductType>;
  productId: Scalars['Int'];
  profileId: Scalars['Int'];
  status: SubscriptionStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SubscriptionProductPriceType = {
  __typename?: 'SubscriptionProductPriceType';
  currency: Scalars['String'];
  externalId: Scalars['String'];
  id: Scalars['Int'];
  interval: Scalars['String'];
  intervalCount: Scalars['Int'];
  unitAmount: Scalars['Int'];
};

export type SubscriptionProductType = {
  __typename?: 'SubscriptionProductType';
  createdAt?: Maybe<Scalars['DateTime']>;
  discount: Scalars['Int'];
  displayedName: Scalars['String'];
  externalId: Scalars['String'];
  externalPriceId: Scalars['String'];
  hasTrial: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
  price?: Maybe<SubscriptionProductPriceType>;
  priceId: Scalars['Int'];
  talentCredits: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SubscriptionProductsType = {
  __typename?: 'SubscriptionProductsType';
  items?: Maybe<Array<SubscriptionProductType>>;
  meta: PaginationMeta;
};

export enum SubscriptionStatusEnum {
  ACTIVE = 'ACTIVE',
  AWAITS_PAYMENT = 'AWAITS_PAYMENT',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  FAILED_PAYMENT = 'FAILED_PAYMENT'
}

export type SubscriptionType = {
  __typename?: 'SubscriptionType';
  availableCredits: Scalars['Int'];
  cancelAtPeriodEnd?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expireAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  initialCredits: Scalars['Int'];
  product?: Maybe<SubscriptionProductType>;
  productId: Scalars['Int'];
  profileId: Scalars['Int'];
  status: SubscriptionStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SubscriptionsType = {
  __typename?: 'SubscriptionsType';
  items: Array<SubscriptionType>;
  meta: PaginationMeta;
};

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  success: Scalars['Boolean'];
};

export type SuccessResponseWithObjectId = {
  __typename?: 'SuccessResponseWithObjectId';
  id?: Maybe<Scalars['Int']>;
  success: Scalars['Boolean'];
};

export enum TalentBadgeEnum {
  ADVANCED = 'ADVANCED',
  COMPETENT = 'COMPETENT',
  ELITE = 'ELITE',
  RISING = 'RISING'
}

export type TalentBadgeStatType = {
  __typename?: 'TalentBadgeStatType';
  advanced: Scalars['Float'];
  competent: Scalars['Float'];
  elite: Scalars['Float'];
  rising: Scalars['Float'];
  total: Scalars['Int'];
};

export type TalentBiographyType = {
  __typename?: 'TalentBiographyType';
  certificates?: Maybe<Array<TalentCertificateType>>;
  educations?: Maybe<Array<TalentEducationType>>;
  experiences?: Maybe<Array<ExperienceType>>;
  expertises?: Maybe<Array<ExpertiseType>>;
  itSkills?: Maybe<Array<TalentItSkillType>>;
  languages?: Maybe<Array<TalentLanguageType>>;
  skills?: Maybe<Array<SkillType>>;
};

export type TalentCv = {
  __typename?: 'TalentCV';
  entity?: Maybe<TalentCvType>;
  success: Scalars['Boolean'];
  talentId: Scalars['Int'];
};

export type TalentCertificateType = {
  __typename?: 'TalentCertificateType';
  certificate: CertificateType;
  certifiedIn?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  issuedBy?: Maybe<Scalars['String']>;
};

export type TalentCertificatesType = {
  __typename?: 'TalentCertificatesType';
  items?: Maybe<Array<TalentCertificateType>>;
  meta: PaginationMeta;
};

export type TalentCvType = {
  __typename?: 'TalentCvType';
  link?: Maybe<Scalars['String']>;
  originName?: Maybe<Scalars['String']>;
  raw?: Maybe<RawTalentCv>;
  summary?: Maybe<Scalars['String']>;
};

export type TalentEducationType = {
  __typename?: 'TalentEducationType';
  course?: Maybe<UniversityCourseType>;
  degree?: Maybe<UniversityDegreeType>;
  graduatedIn?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  university?: Maybe<UniversityType>;
};

export type TalentEducationsType = {
  __typename?: 'TalentEducationsType';
  items?: Maybe<Array<TalentEducationType>>;
  meta: PaginationMeta;
};

export type TalentItSkillType = {
  __typename?: 'TalentItSkillType';
  biographyId: Scalars['Int'];
  id: Scalars['Int'];
  itSkill: ItSkillType;
  level: ItSkillLevelType;
  yearsOfExperience: Scalars['Float'];
};

export type TalentItSkillsType = {
  __typename?: 'TalentItSkillsType';
  items?: Maybe<Array<TalentItSkillType>>;
  meta: PaginationMeta;
};

export type TalentJobPreferenceType = {
  __typename?: 'TalentJobPreferenceType';
  attendances?: Maybe<Array<AttendanceType>>;
  availability?: Maybe<AvailabilityType>;
  jobTypes?: Maybe<Array<JobType>>;
  relocation?: Maybe<RelocationType>;
};

export enum TalentJoinEnum {
  CONTACT_METHOD = 'CONTACT_METHOD',
  CV = 'CV',
  EDUCATION = 'EDUCATION',
  EXPERIENCE = 'EXPERIENCE',
  FAVORITE = 'FAVORITE',
  PROFILE = 'PROFILE',
  SCORE = 'SCORE',
  TOTAL_YEARS_OF_EXPERIENCE = 'TOTAL_YEARS_OF_EXPERIENCE'
}

export type TalentLanguageType = {
  __typename?: 'TalentLanguageType';
  id: Scalars['Int'];
  language: LanguageType;
  level: LanguageLevelType;
};

export type TalentLanguagesType = {
  __typename?: 'TalentLanguagesType';
  items?: Maybe<Array<TalentLanguageType>>;
  meta: PaginationMeta;
};

export type TalentLocationStatType = {
  __typename?: 'TalentLocationStatType';
  count: Scalars['Int'];
  country: Scalars['String'];
};

export type TalentLocationStatsType = {
  __typename?: 'TalentLocationStatsType';
  items?: Maybe<Array<TalentLocationStatType>>;
  meta: PaginationMeta;
};

export type TalentMonthlyRegisteredStatType = {
  __typename?: 'TalentMonthlyRegisteredStatType';
  count: Scalars['Int'];
  date: Scalars['DateTime'];
};

export type TalentMonthlyRegisteredStatsType = {
  __typename?: 'TalentMonthlyRegisteredStatsType';
  items: Array<TalentMonthlyRegisteredStatType>;
};

export type TalentOccupationStatType = {
  __typename?: 'TalentOccupationStatType';
  count: Scalars['Int'];
  occupation: Scalars['String'];
};

export type TalentOccupationStatsType = {
  __typename?: 'TalentOccupationStatsType';
  items?: Maybe<Array<TalentOccupationStatType>>;
  meta: PaginationMeta;
};

export type TalentPercentileType = {
  __typename?: 'TalentPercentileType';
  numberOfTalents: Scalars['Int'];
  occupation: OccupationType;
  percentile: Scalars['Float'];
};

export type TalentQualitiesType = {
  __typename?: 'TalentQualitiesType';
  badge?: Maybe<TalentBadgeEnum>;
  topQualities: Array<TopQuality>;
};

export type TalentScore = {
  __typename?: 'TalentScore';
  badge?: Maybe<TalentBadgeEnum>;
  competencies: Scalars['Float'];
  educations: Scalars['Float'];
  experiences: Scalars['Float'];
  expertises: Scalars['Float'];
  skills: Scalars['Float'];
  topQualities: Array<TopQuality>;
};

export type TalentSortType = {
  order?: Maybe<OrderByEnum>;
  sortBy?: Maybe<SortTalentByEnum>;
};

export type TalentType = {
  __typename?: 'TalentType';
  biography?: Maybe<TalentBiographyType>;
  comparisonScore?: Maybe<ComparisonScoreType>;
  competencies?: Maybe<AssessmentResultsType>;
  contactMethod?: Maybe<ContactMethodType>;
  cv?: Maybe<TalentCvType>;
  favorite?: Maybe<Scalars['Boolean']>;
  id: Scalars['Float'];
  jobPreference: TalentJobPreferenceType;
  matchingScore?: Maybe<Scalars['Int']>;
  profile?: Maybe<ProfileType>;
  profileId: Scalars['Float'];
  score?: Maybe<TalentScore>;
  yearsOfExperience?: Maybe<Scalars['Float']>;
};


export type TalentTypeMatchingScoreArgs = {
  headhunterPoolId?: Maybe<Scalars['Float']>;
  talentId: Scalars['Float'];
};

export type TalentWithJobScoreType = {
  __typename?: 'TalentWithJobScoreType';
  applied?: Maybe<Scalars['Boolean']>;
  biography?: Maybe<TalentBiographyType>;
  comparisonScore?: Maybe<ComparisonScoreType>;
  competencies?: Maybe<AssessmentResultsType>;
  contactMethod?: Maybe<ContactMethodType>;
  cv?: Maybe<TalentCvType>;
  favorite?: Maybe<Scalars['Boolean']>;
  id: Scalars['Float'];
  jobPreference: TalentJobPreferenceType;
  matchingScore?: Maybe<Scalars['Float']>;
  profile?: Maybe<ProfileType>;
  profileId: Scalars['Float'];
  score?: Maybe<TalentScore>;
  yearsOfExperience?: Maybe<Scalars['Float']>;
};

export type TalentWithMatchingScoreType = {
  __typename?: 'TalentWithMatchingScoreType';
  biography?: Maybe<TalentBiographyType>;
  comparisonScore?: Maybe<ComparisonScoreType>;
  competencies?: Maybe<AssessmentResultsType>;
  contactMethod?: Maybe<ContactMethodType>;
  cv?: Maybe<TalentCvType>;
  favorite?: Maybe<Scalars['Boolean']>;
  id: Scalars['Float'];
  jobPreference: TalentJobPreferenceType;
  matchingScore?: Maybe<Scalars['Float']>;
  profile?: Maybe<ProfileType>;
  profileId: Scalars['Float'];
  score?: Maybe<TalentScore>;
  yearsOfExperience?: Maybe<Scalars['Float']>;
};

export type TalentsType = {
  __typename?: 'TalentsType';
  items?: Maybe<Array<TalentType>>;
  keywords?: Maybe<Array<KeywordCounterType>>;
  meta: PaginationMeta;
};

export type TopQuality = {
  __typename?: 'TopQuality';
  questionName: Scalars['String'];
  questionType: AssessmentQuestionTypeEnum;
};

export enum TrendAreaKeyEnum {
  ATTENDANCE = 'ATTENDANCE',
  CERTIFICATES = 'CERTIFICATES',
  EXPERTISE = 'EXPERTISE',
  IT_SKILLS = 'IT_SKILLS',
  JOB_TYPE = 'JOB_TYPE',
  PERSONAL_SKILLS = 'PERSONAL_SKILLS',
  RELOCATION = 'RELOCATION'
}

export type TrendType = {
  __typename?: 'TrendType';
  areaKey: TrendAreaKeyEnum;
  hasSuggestion: Scalars['Boolean'];
  talentSettings?: Maybe<Array<Scalars['String']>>;
  trends?: Maybe<Array<Scalars['String']>>;
};

export type TrendsType = {
  __typename?: 'TrendsType';
  items?: Maybe<Array<TrendType>>;
};

export type UniversitiesType = {
  __typename?: 'UniversitiesType';
  items?: Maybe<Array<UniversityType>>;
  meta: PaginationMeta;
};

export type UniversityCampusType = {
  __typename?: 'UniversityCampusType';
  campus: CampusType;
  custom: Scalars['Boolean'];
};

export type UniversityCourseType = {
  __typename?: 'UniversityCourseType';
  id: Scalars['Int'];
  label: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type UniversityCoursesType = {
  __typename?: 'UniversityCoursesType';
  items?: Maybe<Array<UniversityCourseType>>;
  meta: PaginationMeta;
};

export type UniversityDegreeType = {
  __typename?: 'UniversityDegreeType';
  id: Scalars['Int'];
  label: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type UniversityDegreesType = {
  __typename?: 'UniversityDegreesType';
  items?: Maybe<Array<UniversityDegreeType>>;
  meta: PaginationMeta;
};

export type UniversityType = {
  __typename?: 'UniversityType';
  campuses: Array<UniversityCampusType>;
  id: Scalars['Int'];
  label: Scalars['String'];
  name: Scalars['String'];
  rank?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type UnreadChatsType = {
  __typename?: 'UnreadChatsType';
  countOfUnreadChats?: Maybe<Scalars['Int']>;
};

export type UnreadNotificationsType = {
  __typename?: 'UnreadNotificationsType';
  countOfUnreadNotifications?: Maybe<Scalars['Int']>;
};


export type VisibilityStatType = {
  __typename?: 'VisibilityStatType';
  statChange: StatChangeEnum;
  type: StatTypeEnum;
  value: Scalars['Int'];
};

export type VisibilityStatsType = {
  __typename?: 'VisibilityStatsType';
  items: Array<VisibilityStatType>;
};

export enum WizardEnum {
  ASSESSMENT = 'ASSESSMENT',
  BIOGRAPHY = 'BIOGRAPHY',
  JOB_PREFERENCES = 'JOB_PREFERENCES'
}

export enum ProfileTypeEnum {
  ENTERPRISE = 'ENTERPRISE',
  TALENT = 'TALENT'
}

export enum ProviderEnum {
  GOOGLE = 'GOOGLE',
  LINKEDIN = 'LINKEDIN'
}

export type ProfileFieldsFragment = (
  { __typename?: 'ProfileType' }
  & Pick<ProfileType, 'id' | 'email' | 'location' | 'telephone' | 'picture' | 'fullName' | 'type' | 'onboarded'>
);

export type TokenFieldsFragment = (
  { __typename?: 'JwtType' }
  & Pick<JwtType, 'token' | 'expiresIn'>
);

export type LoginResponseFragment = (
  { __typename?: 'LoginResponse' }
  & { profile: (
    { __typename?: 'ProfileType' }
    & ProfileFieldsFragment
  ), jwt: (
    { __typename?: 'JwtType' }
    & TokenFieldsFragment
  ), subscription?: Maybe<(
    { __typename?: 'SubscriptionType' }
    & SubscriptionFieldsFragment
  )> }
);

export type RegisterUserMutationVariables = Exact<{
  email: Scalars['String'];
  fullName: Scalars['String'];
  password: Scalars['String'];
  profileType: ProfileTypeEnum;
  enterpriseAccountType?: Maybe<EnterpriseAccountEnum>;
}>;


export type RegisterUserMutation = (
  { __typename?: 'Mutation' }
  & { registration: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type LoginUserMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginUserMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResponse' }
    & LoginResponseFragment
  ) }
);

export type LinkedinLoginMutationVariables = Exact<{
  token: Scalars['String'];
  callback: Scalars['String'];
}>;


export type LinkedinLoginMutation = (
  { __typename?: 'Mutation' }
  & { loginBySocialToken: (
    { __typename?: 'LoginResponse' }
    & LoginResponseFragment
  ) }
);

export type GoogleLoginMutationVariables = Exact<{
  token: Scalars['String'];
  callback: Scalars['String'];
}>;


export type GoogleLoginMutation = (
  { __typename?: 'Mutation' }
  & { loginBySocialToken: (
    { __typename?: 'LoginResponse' }
    & LoginResponseFragment
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & { logout: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type EmailValidationQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type EmailValidationQuery = (
  { __typename?: 'Query' }
  & { emailValidation: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type EmailVerificationQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type EmailVerificationQuery = (
  { __typename?: 'Query' }
  & { emailVerification: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type ConfirmEmailAndLoginMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ConfirmEmailAndLoginMutation = (
  { __typename?: 'Mutation' }
  & { confirmEmailAndLogin: (
    { __typename?: 'LoginResponse' }
    & LoginResponseFragment
  ) }
);

export type SendResetPasswordLinkQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendResetPasswordLinkQuery = (
  { __typename?: 'Query' }
  & { sendResetPasswordLink: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type UpfrontEmailValidationQueryVariables = Exact<{
  email: Scalars['String'];
  profileType?: Maybe<ProfileTypeEnum>;
  validateFor?: Maybe<EmailValidationForEnum>;
}>;


export type UpfrontEmailValidationQuery = (
  { __typename?: 'Query' }
  & { upfrontEmailValidation: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type ChatProfileFragment = (
  { __typename?: 'ChatProfileType' }
  & Pick<ChatProfileType, 'fullName' | 'id' | 'picture' | 'employment' | 'isOnline' | 'jobTitle' | 'lastActivity' | 'type'>
);

export type ChatFragment = (
  { __typename?: 'ChatType' }
  & Pick<ChatType, 'hasUnreadMessages' | 'id'>
  & { profiles: Array<(
    { __typename?: 'ChatProfileType' }
    & ChatProfileFragment
  )> }
);

export type ChatMessageFragment = (
  { __typename?: 'MessageType' }
  & Pick<MessageType, 'chatId' | 'createdAt' | 'fromId' | 'id' | 'text' | 'toId' | 'unread'>
  & { from: (
    { __typename?: 'ChatProfileType' }
    & ChatProfileFragment
  ), to: (
    { __typename?: 'ChatProfileType' }
    & ChatProfileFragment
  ) }
);

export type GetChatsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type GetChatsQuery = (
  { __typename?: 'Query' }
  & { getListOfChats: (
    { __typename?: 'ChatsType' }
    & { items?: Maybe<Array<(
      { __typename?: 'ChatType' }
      & ChatFragment
    )>>, meta: (
      { __typename?: 'PaginationMeta' }
      & PaginationFieldsFragment
    ) }
  ) }
);

export type GetChatMessagesQueryVariables = Exact<{
  chatId: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type GetChatMessagesQuery = (
  { __typename?: 'Query' }
  & { getListOfMessages: (
    { __typename?: 'MessagesType' }
    & { items?: Maybe<Array<(
      { __typename?: 'MessageType' }
      & ChatMessageFragment
    )>>, meta: (
      { __typename?: 'PaginationMeta' }
      & PaginationFieldsFragment
    ) }
  ) }
);

export type HasUnreadChatsQueryVariables = Exact<{ [key: string]: never; }>;


export type HasUnreadChatsQuery = (
  { __typename?: 'Query' }
  & { hasUnreadChats: (
    { __typename?: 'UnreadChatsType' }
    & Pick<UnreadChatsType, 'countOfUnreadChats'>
  ) }
);

export type GetChatByMembersQueryVariables = Exact<{
  members: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type GetChatByMembersQuery = (
  { __typename?: 'Query' }
  & { getChatByMembers?: Maybe<(
    { __typename?: 'ChatType' }
    & ChatFragment
  )> }
);

export type CreateChatMutationVariables = Exact<{
  members: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type CreateChatMutation = (
  { __typename?: 'Mutation' }
  & { createChat: (
    { __typename?: 'ChatType' }
    & ChatFragment
  ) }
);

export type CreateMessageMutationVariables = Exact<{
  chatId: Scalars['Int'];
  text: Scalars['String'];
}>;


export type CreateMessageMutation = (
  { __typename?: 'Mutation' }
  & { createMessage: (
    { __typename?: 'MessageType' }
    & ChatMessageFragment
  ) }
);

export type MarkMessageAsReadMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type MarkMessageAsReadMutation = (
  { __typename?: 'Mutation' }
  & { markMessageAsRead: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type ChatNotificationSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ChatNotificationSubscription = (
  { __typename?: 'Subscription' }
  & { chatNotificationUpdated: (
    { __typename?: 'UnreadChatsType' }
    & Pick<UnreadChatsType, 'countOfUnreadChats'>
  ) }
);

export type ChatUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ChatUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { chatUpdated: (
    { __typename?: 'ChatType' }
    & ChatFragment
  ) }
);

export type ChatMessageSentSubscriptionVariables = Exact<{
  chatId: Scalars['Int'];
}>;


export type ChatMessageSentSubscription = (
  { __typename?: 'Subscription' }
  & { messageSent: (
    { __typename?: 'MessageType' }
    & ChatMessageFragment
  ) }
);

export type ProfileStatusChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ProfileStatusChangedSubscription = (
  { __typename?: 'Subscription' }
  & { profileStatusChanged: (
    { __typename?: 'ChatProfileStatusType' }
    & Pick<ChatProfileStatusType, 'isOnline' | 'profileId'>
  ) }
);

export type FindTalentQueryVariables = Exact<{
  talentId: Scalars['Float'];
  poolId?: Maybe<Scalars['Float']>;
  jobId?: Maybe<Scalars['Int']>;
  hhPoolId?: Maybe<Scalars['Int']>;
  withMatchingScore?: Scalars['Boolean'];
}>;


export type FindTalentQuery = (
  { __typename?: 'Query' }
  & { findTalent: (
    { __typename?: 'TalentType' }
    & MakeOptional<Pick<TalentType, 'id' | 'matchingScore'>, 'matchingScore'>
    & { cv?: Maybe<(
      { __typename?: 'TalentCvType' }
      & Pick<TalentCvType, 'link' | 'originName' | 'summary'>
    )>, contactMethod?: Maybe<(
      { __typename?: 'ContactMethodType' }
      & Pick<ContactMethodType, 'id' | 'value'>
    )>, profile?: Maybe<(
      { __typename?: 'ProfileType' }
      & Pick<ProfileType, 'id' | 'fullName' | 'location' | 'picture' | 'email' | 'telephone'>
    )>, jobPreference: (
      { __typename?: 'TalentJobPreferenceType' }
      & { attendances?: Maybe<Array<(
        { __typename?: 'AttendanceType' }
        & Pick<AttendanceType, 'id' | 'value'>
      )>>, availability?: Maybe<(
        { __typename?: 'AvailabilityType' }
        & Pick<AvailabilityType, 'id' | 'value'>
      )>, jobTypes?: Maybe<Array<(
        { __typename?: 'JobType' }
        & Pick<JobType, 'id' | 'value'>
      )>>, relocation?: Maybe<(
        { __typename?: 'RelocationType' }
        & Pick<RelocationType, 'id' | 'value' | 'countries'>
      )> }
    ), comparisonScore?: Maybe<(
      { __typename?: 'ComparisonScoreType' }
      & { average: (
        { __typename?: 'TalentScore' }
        & Pick<TalentScore, 'educations' | 'experiences' | 'expertises' | 'skills' | 'competencies'>
      ), talent: (
        { __typename?: 'TalentScore' }
        & Pick<TalentScore, 'badge' | 'competencies' | 'educations' | 'experiences' | 'expertises'>
        & { topQualities: Array<(
          { __typename?: 'TopQuality' }
          & Pick<TopQuality, 'questionName' | 'questionType'>
        )> }
      ) }
      & TalentComparisonFieldsFragment
    )>, competencies?: Maybe<(
      { __typename?: 'AssessmentResultsType' }
      & Pick<AssessmentResultsType, 'attempts' | 'duration'>
      & { items?: Maybe<Array<(
        { __typename?: 'AssessmentResultType' }
        & AssessmentResultFieldsFragment
      )>> }
    )>, biography?: Maybe<(
      { __typename?: 'TalentBiographyType' }
      & { educations?: Maybe<Array<(
        { __typename?: 'TalentEducationType' }
        & EducationFieldsFragment
      )>>, experiences?: Maybe<Array<(
        { __typename?: 'ExperienceType' }
        & ExperienceFieldsFragment
      )>>, certificates?: Maybe<Array<(
        { __typename?: 'TalentCertificateType' }
        & CertificatesFullFieldsFragment
      )>>, expertises?: Maybe<Array<(
        { __typename?: 'ExpertiseType' }
        & ExpertiseFullFieldsFragment
      )>>, itSkills?: Maybe<Array<(
        { __typename?: 'TalentItSkillType' }
        & ItSkillsFullFieldsFragment
      )>>, skills?: Maybe<Array<(
        { __typename?: 'SkillType' }
        & SkillsFullFieldsFragment
      )>>, languages?: Maybe<Array<(
        { __typename?: 'TalentLanguageType' }
        & LanguageFullFieldsFragment
      )>> }
    )> }
    & FindTalentsFavoriteFieldFragment
  ) }
);

export type GetTalentPercentileQueryVariables = Exact<{
  talentId: Scalars['Float'];
  hhPoolId?: Maybe<Scalars['Int']>;
}>;


export type GetTalentPercentileQuery = (
  { __typename?: 'Query' }
  & { getTalentPercentile: (
    { __typename?: 'TalentPercentileType' }
    & Pick<TalentPercentileType, 'numberOfTalents' | 'percentile'>
    & { occupation: (
      { __typename?: 'OccupationType' }
      & Pick<OccupationType, 'id' | 'name'>
    ) }
  ) }
);

export type GetSimilarTalentsQueryVariables = Exact<{
  talentId: Scalars['Int'];
  poolId: Scalars['Int'];
}>;


export type GetSimilarTalentsQuery = (
  { __typename?: 'Query' }
  & { getBestTalents: (
    { __typename?: 'GetBestTalentsType' }
    & { headhunterPool: (
      { __typename?: 'HeadhunterPoolType' }
      & Pick<HeadhunterPoolType, 'name'>
    ), items: Array<(
      { __typename?: 'BestTalentType' }
      & Pick<BestTalentType, 'score' | 'badge' | 'talentId'>
      & { profile: (
        { __typename?: 'ProfileType' }
        & Pick<ProfileType, 'picture' | 'fullName'>
      ) }
    )> }
  ) }
);

export type EnterpriseTalentNoteFieldsFragment = (
  { __typename?: 'EnterpriseNoteType' }
  & Pick<EnterpriseNoteType, 'id' | 'createdAt' | 'text'>
);

export type GetTalentNotesQueryVariables = Exact<{
  talentId: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type GetTalentNotesQuery = (
  { __typename?: 'Query' }
  & { getEnterpriseNoteByTalent: (
    { __typename?: 'EnterpriseNotesType' }
    & { meta: (
      { __typename?: 'PaginationMeta' }
      & PaginationFieldsFragment
    ), items?: Maybe<Array<(
      { __typename?: 'EnterpriseNoteType' }
      & EnterpriseTalentNoteFieldsFragment
    )>> }
  ) }
);

export type SaveTalentNoteMutationVariables = Exact<{
  talentId: Scalars['Int'];
  text: Scalars['String'];
}>;


export type SaveTalentNoteMutation = (
  { __typename?: 'Mutation' }
  & { addEnterpriseNoteToTalent: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type AddTalentToFavoritesMutationVariables = Exact<{
  talentId: Scalars['Int'];
}>;


export type AddTalentToFavoritesMutation = (
  { __typename?: 'Mutation' }
  & { addTalentToFavorites: (
    { __typename?: 'SuccessResponseWithObjectId' }
    & Pick<SuccessResponseWithObjectId, 'success'>
  ) }
);

export type RemoveTalentFromFavoritesMutationVariables = Exact<{
  talentId: Scalars['Int'];
}>;


export type RemoveTalentFromFavoritesMutation = (
  { __typename?: 'Mutation' }
  & { deleteTalentFromFavorites: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type FindTalentsFavoriteFieldFragment = (
  { __typename?: 'TalentType' }
  & Pick<TalentType, 'favorite'>
);

export type FindTalentsQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  profileIds?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  keywords?: Maybe<Array<SearchKeywordsEnum> | SearchKeywordsEnum>;
  pagination?: Maybe<PaginationRequest>;
  byEnterpriseRegion?: Maybe<Scalars['Boolean']>;
  byEnterpriseCareers?: Maybe<Scalars['Boolean']>;
  randomOrder?: Maybe<Scalars['Boolean']>;
  careerId?: Maybe<Scalars['Float']>;
}>;


export type FindTalentsQuery = (
  { __typename?: 'Query' }
  & { findTalents: (
    { __typename?: 'TalentsType' }
    & { items?: Maybe<Array<(
      { __typename?: 'TalentType' }
      & Pick<TalentType, 'id' | 'yearsOfExperience'>
      & { profile?: Maybe<(
        { __typename?: 'ProfileType' }
        & TalentSummaryProfileFieldsFragment
      )>, score?: Maybe<(
        { __typename?: 'TalentScore' }
        & Pick<TalentScore, 'badge'>
      )>, biography?: Maybe<(
        { __typename?: 'TalentBiographyType' }
        & { experiences?: Maybe<Array<(
          { __typename?: 'ExperienceType' }
          & TalentSummaryExperiencesFieldsFragment
        )>>, educations?: Maybe<Array<(
          { __typename?: 'TalentEducationType' }
          & TalentSummaryEducationsFieldsFragment
        )>> }
      )> }
      & FindTalentsFavoriteFieldFragment
    )>>, keywords?: Maybe<Array<(
      { __typename?: 'KeywordCounterType' }
      & Pick<KeywordCounterType, 'count' | 'keyword'>
    )>>, meta: (
      { __typename?: 'PaginationMeta' }
      & PaginationFieldsFragment
    ) }
  ) }
);

export type FindTalentForCompareQueryVariables = Exact<{
  talentId: Scalars['Float'];
  poolId: Scalars['Float'];
  hhPoolId?: Maybe<Scalars['Int']>;
}>;


export type FindTalentForCompareQuery = (
  { __typename?: 'Query' }
  & { findTalent: (
    { __typename?: 'TalentType' }
    & Pick<TalentType, 'id' | 'matchingScore'>
    & { profile?: Maybe<(
      { __typename?: 'ProfileType' }
      & TalentSummaryProfileFieldsFragment
    )>, comparisonScore?: Maybe<(
      { __typename?: 'ComparisonScoreType' }
      & TalentComparisonFieldsFragment
    )>, jobPreference: (
      { __typename?: 'TalentJobPreferenceType' }
      & { attendances?: Maybe<Array<(
        { __typename?: 'AttendanceType' }
        & Pick<AttendanceType, 'id' | 'value'>
      )>>, availability?: Maybe<(
        { __typename?: 'AvailabilityType' }
        & Pick<AvailabilityType, 'id' | 'value'>
      )>, jobTypes?: Maybe<Array<(
        { __typename?: 'JobType' }
        & Pick<JobType, 'id' | 'value'>
      )>>, relocation?: Maybe<(
        { __typename?: 'RelocationType' }
        & Pick<RelocationType, 'id' | 'value' | 'countries'>
      )> }
    ) }
    & FindTalentsFavoriteFieldFragment
  ) }
);

export type PoolDataFavoriteFieldFragment = (
  { __typename?: 'TalentWithMatchingScoreType' }
  & Pick<TalentWithMatchingScoreType, 'favorite'>
);

export type HhPoolCardFieldsFragment = (
  { __typename?: 'HeadhunterPoolType' }
  & Pick<HeadhunterPoolType, 'id' | 'name' | 'totalTalentsCount' | 'newTalentsCount' | 'label' | 'value' | 'status'>
);

export type GetHhPoolsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHhPoolsQuery = (
  { __typename?: 'Query' }
  & { getHeadhunterPools: (
    { __typename?: 'HeadhunterPoolsType' }
    & { items: Array<(
      { __typename?: 'HeadhunterPoolType' }
      & HhPoolCardFieldsFragment
    )> }
  ) }
);

export type GetHhPoolBasicQueryVariables = Exact<{
  hhPoolId: Scalars['Int'];
}>;


export type GetHhPoolBasicQuery = (
  { __typename?: 'Query' }
  & { getHeadhunterPool: (
    { __typename?: 'HeadhunterPoolType' }
    & Pick<HeadhunterPoolType, 'id' | 'name' | 'totalTalentsCount'>
  ) }
);

export type GetFavoriteTalentCountQueryVariables = Exact<{
  onlyFavorite?: Maybe<Scalars['Boolean']>;
  pagination?: Maybe<PaginationRequest>;
}>;


export type GetFavoriteTalentCountQuery = (
  { __typename?: 'Query' }
  & { findTalents: (
    { __typename?: 'TalentsType' }
    & { meta: (
      { __typename?: 'PaginationMeta' }
      & Pick<PaginationMeta, 'totalItems'>
    ) }
  ) }
);

export type GetHhPoolFullQueryVariables = Exact<{
  hhPoolId: Scalars['Int'];
}>;


export type GetHhPoolFullQuery = (
  { __typename?: 'Query' }
  & { getHeadhunterPool: (
    { __typename?: 'HeadhunterPoolType' }
    & Pick<HeadhunterPoolType, 'id' | 'name' | 'currentWork' | 'mostRecentJobTitle' | 'jobTitles' | 'yearsOfExperience' | 'locations' | 'relocation'>
    & { universities: Array<(
      { __typename?: 'UniversityType' }
      & UniversityBaseFieldsFragment
    )>, courses: Array<(
      { __typename?: 'UniversityCourseType' }
      & CourseFieldsFragment
    )>, degrees: Array<(
      { __typename?: 'UniversityDegreeType' }
      & DegreeFieldsFragment
    )>, campuses: Array<(
      { __typename?: 'CampusType' }
      & Pick<CampusType, 'id' | 'name' | 'label' | 'value'>
    )>, expertises: Array<(
      { __typename?: 'ExpertiseType' }
      & ExpertiseFieldsFragment
    )>, industries: Array<(
      { __typename?: 'EmployerIndustryType' }
      & IndustryFieldsFragment
    )>, employers: Array<(
      { __typename?: 'EmployerType' }
      & EmployerFieldsFragment
    )>, attendances: Array<(
      { __typename?: 'AttendanceType' }
      & Pick<AttendanceType, 'id' | 'value'>
    )>, jobTypes: Array<(
      { __typename?: 'JobType' }
      & Pick<JobType, 'id' | 'value'>
    )>, certificates: Array<(
      { __typename?: 'CertificateType' }
      & CertificatesFieldsFragment
    )>, itSkills: Array<(
      { __typename?: 'ItSkillType' }
      & ItSkillsFieldsFragment
    )>, languages: Array<(
      { __typename?: 'LanguageType' }
      & LanguageFieldsFragment
    )>, traitsAndCompetencies: Array<(
      { __typename?: 'AssessmentQuestionType' }
      & AssessmentQuestionFieldFragment
    )>, valuesAndMotivations: Array<(
      { __typename?: 'AssessmentQuestionType' }
      & AssessmentQuestionFieldFragment
    )>, skills: Array<(
      { __typename?: 'SkillType' }
      & SkillsFieldsFragment
    )> }
  ) }
);

export type GetHhPoolDataQueryVariables = Exact<{
  hhPoolId: Scalars['Int'];
  filters?: HhPoolFilterType;
  pagination?: PaginationRequest;
  sort?: HhPoolSortType;
}>;


export type GetHhPoolDataQuery = (
  { __typename?: 'Query' }
  & { getHeadhunterPoolData: (
    { __typename?: 'HeadhunterPoolDataType' }
    & { talents?: Maybe<(
      { __typename?: 'HeadhunterPoolTalentsType' }
      & { items: Array<(
        { __typename?: 'TalentWithMatchingScoreType' }
        & Pick<TalentWithMatchingScoreType, 'id' | 'yearsOfExperience' | 'matchingScore'>
        & { profile?: Maybe<(
          { __typename?: 'ProfileType' }
          & TalentSummaryProfileFieldsFragment
        )>, score?: Maybe<(
          { __typename?: 'TalentScore' }
          & Pick<TalentScore, 'badge'>
          & TalentScoreFieldsFragment
        )>, biography?: Maybe<(
          { __typename?: 'TalentBiographyType' }
          & { experiences?: Maybe<Array<(
            { __typename?: 'ExperienceType' }
            & TalentSummaryExperiencesFieldsFragment
          )>>, educations?: Maybe<Array<(
            { __typename?: 'TalentEducationType' }
            & { university?: Maybe<(
              { __typename?: 'UniversityType' }
              & Pick<UniversityType, 'name'>
            )> }
            & TalentSummaryEducationsFieldsFragment
          )>> }
        )> }
        & PoolDataFavoriteFieldFragment
      )>, meta: (
        { __typename?: 'PaginationMeta' }
        & PaginationFieldsFragment
      ) }
    )>, filters?: Maybe<(
      { __typename?: 'HeadhunterPoolExtendedFilterType' }
      & PoolFiltersFieldsFragment
    )> }
  ) }
);

export type GetTalentPoolDataQueryVariables = Exact<{
  filters?: HhPoolFilterType;
  pagination?: PaginationRequest;
  sort?: HhPoolSortType;
}>;


export type GetTalentPoolDataQuery = (
  { __typename?: 'Query' }
  & { getTalentPoolData: (
    { __typename?: 'HeadhunterPoolDataType' }
    & { talents?: Maybe<(
      { __typename?: 'HeadhunterPoolTalentsType' }
      & { items: Array<(
        { __typename?: 'TalentWithMatchingScoreType' }
        & Pick<TalentWithMatchingScoreType, 'id' | 'yearsOfExperience'>
        & { profile?: Maybe<(
          { __typename?: 'ProfileType' }
          & TalentSummaryProfileFieldsFragment
        )>, score?: Maybe<(
          { __typename?: 'TalentScore' }
          & Pick<TalentScore, 'badge'>
        )>, biography?: Maybe<(
          { __typename?: 'TalentBiographyType' }
          & { experiences?: Maybe<Array<(
            { __typename?: 'ExperienceType' }
            & TalentSummaryExperiencesFieldsFragment
          )>>, educations?: Maybe<Array<(
            { __typename?: 'TalentEducationType' }
            & { university?: Maybe<(
              { __typename?: 'UniversityType' }
              & Pick<UniversityType, 'name'>
            )> }
            & TalentSummaryEducationsFieldsFragment
          )>> }
        )> }
        & PoolDataFavoriteFieldFragment
      )>, meta: (
        { __typename?: 'PaginationMeta' }
        & PaginationFieldsFragment
      ) }
    )>, filters?: Maybe<(
      { __typename?: 'HeadhunterPoolExtendedFilterType' }
      & PoolFiltersFieldsFragment
    )> }
  ) }
);

export type CreateHhPoolMutationVariables = Exact<{
  attendances: Array<AttendanceValueEnum> | AttendanceValueEnum;
  campusIds?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  certificateIds?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  courseIds?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  currentWork?: Maybe<Scalars['Boolean']>;
  degreeIds?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  employerIds?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  expertiseIds?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  industryIds?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  itSkillIds?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  jobTitles?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  jobTypes: Array<JobTypeValueEnum> | JobTypeValueEnum;
  languageIds?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  locations: Array<Scalars['String']> | Scalars['String'];
  mostRecentJobTitle?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  relocation?: Maybe<Scalars['Boolean']>;
  skillIds?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  traitsAndCompetencyIds?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  universityIds?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  valuesAndMotivationIds?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  yearsOfExperience?: Maybe<Array<ExperienceRangeEnum> | ExperienceRangeEnum>;
}>;


export type CreateHhPoolMutation = (
  { __typename?: 'Mutation' }
  & { createHeadhunterPool: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type EditHhPoolMutationVariables = Exact<{
  id: Scalars['Float'];
  attendances: Array<AttendanceValueEnum> | AttendanceValueEnum;
  campusIds?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  certificateIds?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  courseIds?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  currentWork?: Maybe<Scalars['Boolean']>;
  degreeIds?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  employerIds?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  expertiseIds?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  industryIds?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  itSkillIds?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  jobTitles?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  jobTypes: Array<JobTypeValueEnum> | JobTypeValueEnum;
  languageIds?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  locations: Array<Scalars['String']> | Scalars['String'];
  mostRecentJobTitle?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  relocation?: Maybe<Scalars['Boolean']>;
  skillIds?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  traitsAndCompetencyIds?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  universityIds?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  valuesAndMotivationIds?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  yearsOfExperience?: Maybe<Array<ExperienceRangeEnum> | ExperienceRangeEnum>;
}>;


export type EditHhPoolMutation = (
  { __typename?: 'Mutation' }
  & { updateHeadhunterPool: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type DeleteHhPoolMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteHhPoolMutation = (
  { __typename?: 'Mutation' }
  & { deleteHeadhunterPool: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type GetAssessmentQuestionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAssessmentQuestionsQuery = (
  { __typename?: 'Query' }
  & { getAssessmentQuestions: (
    { __typename?: 'AssessmentQuestionsType' }
    & { items?: Maybe<Array<(
      { __typename?: 'AssessmentQuestionType' }
      & Pick<AssessmentQuestionType, 'id' | 'name' | 'questionType'>
    )>> }
  ) }
);

export type HeadhunterUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type HeadhunterUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { headhunterUpdated: (
    { __typename?: 'HeadhunterPoolNotification' }
    & Pick<HeadhunterPoolNotification, 'id' | 'totalTalentsCount' | 'newTalentsCount' | 'status'>
  ) }
);

export type CreateJobPostMutationVariables = Exact<{
  title: Scalars['String'];
  description: Scalars['String'];
  attendanceValue: AttendanceValueEnum;
  certificateIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  expertiseIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  industryIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  itSkillIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  jobTypeValue: JobTypeValueEnum;
  location: Scalars['String'];
  occupationId: Scalars['Int'];
  relocation: Scalars['Boolean'];
  yearsOfExperience?: Maybe<Array<ExperienceRangeEnum> | ExperienceRangeEnum>;
}>;


export type CreateJobPostMutation = (
  { __typename?: 'Mutation' }
  & { createJobPost: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type EditJobPostMutationVariables = Exact<{
  id: Scalars['Int'];
  title: Scalars['String'];
  description: Scalars['String'];
  attendanceValue: AttendanceValueEnum;
  certificateIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  expertiseIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  industryIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  itSkillIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  jobTypeValue: JobTypeValueEnum;
  location: Scalars['String'];
  occupationId: Scalars['Int'];
  relocation: Scalars['Boolean'];
  yearsOfExperience?: Maybe<Array<ExperienceRangeEnum> | ExperienceRangeEnum>;
}>;


export type EditJobPostMutation = (
  { __typename?: 'Mutation' }
  & { updateJobPost: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type SwitchJobPostStateMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SwitchJobPostStateMutation = (
  { __typename?: 'Mutation' }
  & { switchJobPostState: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type ApplyJobPostMutationVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type ApplyJobPostMutation = (
  { __typename?: 'Mutation' }
  & { applyJobPost: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type FindJobPostsQueryVariables = Exact<{
  join?: Maybe<Array<JoinJobFieldEnum> | JoinJobFieldEnum>;
  orderBy?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  statuses?: Maybe<Array<JobStatusEnum> | JobStatusEnum>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  onlyApplied?: Maybe<Scalars['Boolean']>;
}>;


export type FindJobPostsQuery = (
  { __typename?: 'Query' }
  & { findJobPosts: (
    { __typename?: 'JobPostsType' }
    & { items: Array<(
      { __typename?: 'JobPostType' }
      & Pick<JobPostType, 'id' | 'title' | 'applicants' | 'status' | 'postedAt'>
    )>, meta: (
      { __typename?: 'PaginationMeta' }
      & PaginationFieldsFragment
    ) }
  ) }
);

export type FindJobPostForEditPageQueryVariables = Exact<{
  jobPostId?: Maybe<Scalars['Int']>;
}>;


export type FindJobPostForEditPageQuery = (
  { __typename?: 'Query' }
  & { findJobPost: (
    { __typename?: 'JobPostType' }
    & Pick<JobPostType, 'id' | 'title' | 'description' | 'location' | 'relocation' | 'status' | 'statusReason' | 'yearsOfExperience'>
    & { attendance?: Maybe<(
      { __typename?: 'AttendanceType' }
      & Pick<AttendanceType, 'id' | 'value'>
    )>, certificates?: Maybe<Array<(
      { __typename?: 'CertificateType' }
      & CertificatesFieldsFragment
    )>>, expertises?: Maybe<Array<(
      { __typename?: 'ExpertiseType' }
      & ExpertiseFieldsFragment
    )>>, industries?: Maybe<Array<(
      { __typename?: 'EmployerIndustryType' }
      & IndustryFieldsFragment
    )>>, itSkills?: Maybe<Array<(
      { __typename?: 'ItSkillType' }
      & ItSkillsFieldsFragment
    )>>, jobType?: Maybe<(
      { __typename?: 'JobType' }
      & Pick<JobType, 'id' | 'value'>
    )>, occupation?: Maybe<(
      { __typename?: 'OccupationType' }
      & OccupationFragmentFragment
    )> }
  ) }
);

export type FindJobPostDetailsQueryVariables = Exact<{
  jobPostId?: Maybe<Scalars['Int']>;
}>;


export type FindJobPostDetailsQuery = (
  { __typename?: 'Query' }
  & { findJobPost: (
    { __typename?: 'JobPostType' }
    & Pick<JobPostType, 'id' | 'title' | 'description' | 'applicants' | 'createdAt' | 'location' | 'relocation' | 'status' | 'statusReason' | 'yearsOfExperience'>
    & { attendance?: Maybe<(
      { __typename?: 'AttendanceType' }
      & Pick<AttendanceType, 'id' | 'value'>
    )>, certificates?: Maybe<Array<(
      { __typename?: 'CertificateType' }
      & CertificatesFieldsFragment
    )>>, expertises?: Maybe<Array<(
      { __typename?: 'ExpertiseType' }
      & ExpertiseFieldsFragment
    )>>, industries?: Maybe<Array<(
      { __typename?: 'EmployerIndustryType' }
      & IndustryFieldsFragment
    )>>, itSkills?: Maybe<Array<(
      { __typename?: 'ItSkillType' }
      & ItSkillsFieldsFragment
    )>>, jobType?: Maybe<(
      { __typename?: 'JobType' }
      & Pick<JobType, 'id' | 'value'>
    )>, occupation?: Maybe<(
      { __typename?: 'OccupationType' }
      & OccupationFragmentFragment
    )> }
  ) }
);

export type FindJobPostTalentsQueryVariables = Exact<{
  jobPostId: Scalars['Int'];
  onlyApplied?: Maybe<Scalars['Boolean']>;
  pagination?: Maybe<PaginationRequest>;
  sort?: TalentSortType;
}>;


export type FindJobPostTalentsQuery = (
  { __typename?: 'Query' }
  & { findJobTalents: (
    { __typename?: 'JobTalentsType' }
    & { talents?: Maybe<(
      { __typename?: 'JobTalentsWithPaginationType' }
      & { items?: Maybe<Array<(
        { __typename?: 'TalentWithJobScoreType' }
        & Pick<TalentWithJobScoreType, 'id' | 'yearsOfExperience' | 'favorite' | 'applied'>
        & { profile?: Maybe<(
          { __typename?: 'ProfileType' }
          & TalentSummaryProfileFieldsFragment
        )>, score?: Maybe<(
          { __typename?: 'TalentScore' }
          & Pick<TalentScore, 'badge'>
        )>, biography?: Maybe<(
          { __typename?: 'TalentBiographyType' }
          & { experiences?: Maybe<Array<(
            { __typename?: 'ExperienceType' }
            & TalentSummaryExperiencesFieldsFragment
          )>>, educations?: Maybe<Array<(
            { __typename?: 'TalentEducationType' }
            & { university?: Maybe<(
              { __typename?: 'UniversityType' }
              & Pick<UniversityType, 'name'>
            )> }
            & TalentSummaryEducationsFieldsFragment
          )>> }
        )> }
      )>>, meta: (
        { __typename?: 'PaginationMeta' }
        & PaginationFieldsFragment
      ) }
    )> }
  ) }
);

export type FindJobPostDetailsForTalentsQueryVariables = Exact<{
  jobPostId?: Maybe<Scalars['Int']>;
}>;


export type FindJobPostDetailsForTalentsQuery = (
  { __typename?: 'Query' }
  & { findJobPost: (
    { __typename?: 'JobPostType' }
    & Pick<JobPostType, 'id' | 'title' | 'description' | 'applied' | 'createdAt' | 'location' | 'yearsOfExperience'>
    & { attendance?: Maybe<(
      { __typename?: 'AttendanceType' }
      & Pick<AttendanceType, 'id' | 'value'>
    )>, certificates?: Maybe<Array<(
      { __typename?: 'CertificateType' }
      & CertificatesFieldsFragment
    )>>, expertises?: Maybe<Array<(
      { __typename?: 'ExpertiseType' }
      & ExpertiseFieldsFragment
    )>>, industries?: Maybe<Array<(
      { __typename?: 'EmployerIndustryType' }
      & IndustryFieldsFragment
    )>>, itSkills?: Maybe<Array<(
      { __typename?: 'ItSkillType' }
      & ItSkillsFieldsFragment
    )>>, jobType?: Maybe<(
      { __typename?: 'JobType' }
      & Pick<JobType, 'id' | 'value'>
    )>, enterprise?: Maybe<(
      { __typename?: 'EnterpriseType' }
      & Pick<EnterpriseType, 'id' | 'accountType'>
      & { details?: Maybe<(
        { __typename?: 'EnterpriseDetailsType' }
        & Pick<EnterpriseDetailsType, 'logo'>
        & { employer: (
          { __typename?: 'EmployerType' }
          & Pick<EmployerType, 'id' | 'name' | 'logo'>
        ) }
      )> }
    )> }
  ) }
);

export type FindMyJobDetailsForTalentQueryVariables = Exact<{
  jobPostId?: Maybe<Scalars['Int']>;
}>;


export type FindMyJobDetailsForTalentQuery = (
  { __typename?: 'Query' }
  & { findJobPost: (
    { __typename?: 'JobPostType' }
    & Pick<JobPostType, 'id' | 'title' | 'description' | 'applicants' | 'viewed' | 'createdAt' | 'appliedAt' | 'location' | 'relocation' | 'status' | 'statusReason' | 'yearsOfExperience'>
    & { attendance?: Maybe<(
      { __typename?: 'AttendanceType' }
      & Pick<AttendanceType, 'id' | 'value'>
    )>, certificates?: Maybe<Array<(
      { __typename?: 'CertificateType' }
      & CertificatesFieldsFragment
    )>>, expertises?: Maybe<Array<(
      { __typename?: 'ExpertiseType' }
      & ExpertiseFieldsFragment
    )>>, industries?: Maybe<Array<(
      { __typename?: 'EmployerIndustryType' }
      & IndustryFieldsFragment
    )>>, itSkills?: Maybe<Array<(
      { __typename?: 'ItSkillType' }
      & ItSkillsFieldsFragment
    )>>, jobType?: Maybe<(
      { __typename?: 'JobType' }
      & Pick<JobType, 'id' | 'value'>
    )>, occupation?: Maybe<(
      { __typename?: 'OccupationType' }
      & OccupationFragmentFragment
    )> }
  ) }
);

export type PaymentMethodFieldFragment = (
  { __typename?: 'PaymentMethodType' }
  & Pick<PaymentMethodType, 'id' | 'externalId' | 'profileId' | 'last4' | 'cardBrand' | 'expYear' | 'expMonth' | 'name' | 'phone' | 'email' | 'city' | 'country' | 'addressLine1' | 'addressLine2' | 'postalCode' | 'state' | 'isDefault' | 'createdAt'>
);

export type SavePaymentMethosMutationVariables = Exact<{
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  cardBrand: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  externalId: Scalars['String'];
  last4: Scalars['String'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
}>;


export type SavePaymentMethosMutation = (
  { __typename?: 'Mutation' }
  & { savePaymentMethod: (
    { __typename?: 'PaymentMethodType' }
    & PaymentMethodFieldFragment
  ) }
);

export type RemovePaymentMethodMutationVariables = Exact<{
  paymentMethodId: Scalars['Int'];
}>;


export type RemovePaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { removePaymentMethod: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type UpdateDefaultPaymentMethodMutationVariables = Exact<{
  paymentMethodId: Scalars['Int'];
}>;


export type UpdateDefaultPaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { updateDefaultPaymentMethod: (
    { __typename?: 'PaymentMethodType' }
    & PaymentMethodFieldFragment
  ) }
);

export type FindPaymentMethodsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type FindPaymentMethodsQuery = (
  { __typename?: 'Query' }
  & { findPaymentMethods: (
    { __typename?: 'PaymentMethodsType' }
    & { items?: Maybe<Array<(
      { __typename?: 'PaymentMethodType' }
      & PaymentMethodFieldFragment
    )>>, meta: (
      { __typename?: 'PaginationMeta' }
      & PaginationFieldsFragment
    ) }
  ) }
);

export type CreateSubscriptionMutationVariables = Exact<{
  paymentMethodId?: Maybe<Scalars['Int']>;
  productId: Scalars['Int'];
}>;


export type CreateSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { createSubscription: (
    { __typename?: 'SubscriptionType' }
    & Pick<SubscriptionType, 'id' | 'availableCredits' | 'initialCredits' | 'status' | 'expireAt' | 'createdAt'>
  ) }
);

export type SwitchAutoChargeSubscriptionOptionMutationVariables = Exact<{ [key: string]: never; }>;


export type SwitchAutoChargeSubscriptionOptionMutation = (
  { __typename?: 'Mutation' }
  & { switchAutoChargeSubscriptionOption: (
    { __typename?: 'SubscriptionType' }
    & SubscriptionFieldsFragment
  ) }
);

export type SubscriptionProductsFieldFragment = (
  { __typename?: 'SubscriptionProductType' }
  & Pick<SubscriptionProductType, 'id' | 'discount' | 'externalId' | 'talentCredits' | 'displayedName' | 'externalPriceId' | 'hasTrial' | 'name'>
  & { price?: Maybe<(
    { __typename?: 'SubscriptionProductPriceType' }
    & Pick<SubscriptionProductPriceType, 'unitAmount' | 'currency' | 'interval' | 'intervalCount'>
  )> }
);

export type FindSubscriptionProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type FindSubscriptionProductsQuery = (
  { __typename?: 'Query' }
  & { findSubscriptionProducts: (
    { __typename?: 'SubscriptionProductsType' }
    & { items?: Maybe<Array<(
      { __typename?: 'SubscriptionProductType' }
      & SubscriptionProductsFieldFragment
    )>> }
  ) }
);

export type FindSubscriptionQueryVariables = Exact<{
  onlyActive?: Maybe<Scalars['Boolean']>;
}>;


export type FindSubscriptionQuery = (
  { __typename?: 'Query' }
  & { findSubscription?: Maybe<(
    { __typename?: 'SubscriptionType' }
    & SubscriptionFieldsFragment
  )> }
);

export type FindSubscriptionsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  status?: Maybe<SubscriptionStatusEnum>;
}>;


export type FindSubscriptionsQuery = (
  { __typename?: 'Query' }
  & { findSubscriptions: (
    { __typename?: 'SubscriptionsType' }
    & { items: Array<(
      { __typename?: 'SubscriptionType' }
      & SubscriptionFieldsFragment
    )>, meta: (
      { __typename?: 'PaginationMeta' }
      & PaginationFieldsFragment
    ) }
  ) }
);

export type FindCreditUsageStatQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  dateRange?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type FindCreditUsageStatQuery = (
  { __typename?: 'Query' }
  & { findCreditUsageStat: (
    { __typename?: 'CreditUsageStatType' }
    & { items?: Maybe<Array<(
      { __typename?: 'CreditUsageType' }
      & Pick<CreditUsageType, 'id' | 'createdAt' | 'creditsBefore' | 'creditsAfter' | 'creditsUsed'>
    )>>, meta: (
      { __typename?: 'PaginationMeta' }
      & PaginationFieldsFragment
    ) }
  ) }
);

export type FindBillingHistoryQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  bookmark?: Maybe<Scalars['String']>;
}>;


export type FindBillingHistoryQuery = (
  { __typename?: 'Query' }
  & { findBillingHistory: (
    { __typename?: 'PaginateBillingHistoryType' }
    & { items?: Maybe<Array<(
      { __typename?: 'BillingHistoryType' }
      & Pick<BillingHistoryType, 'invoiceId' | 'invoiceNumber' | 'product' | 'status' | 'date' | 'amount' | 'pdf'>
    )>>, meta: (
      { __typename?: 'CursorPaginationMeta' }
      & Pick<CursorPaginationMeta, 'bookmark' | 'hasMore' | 'itemCount'>
    ) }
  ) }
);

export type SubscriptionUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type SubscriptionUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { subscriptionUpdated: (
    { __typename?: 'SubscriptionNotification' }
    & SubscriptionNotificationFieldsFragment
  ) }
);

export type UploadEnterpriseFileMutationVariables = Exact<{
  file: Scalars['Upload'];
  type: EnterpriseFileTypeEnum;
}>;


export type UploadEnterpriseFileMutation = (
  { __typename?: 'Mutation' }
  & { uploadEnterpriseFile: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type RemoveEnterpriseFileMutationVariables = Exact<{
  type: EnterpriseFileTypeEnum;
}>;


export type RemoveEnterpriseFileMutation = (
  { __typename?: 'Mutation' }
  & { removeEnterpriseFile: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type FindCareersQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type FindCareersQuery = (
  { __typename?: 'Query' }
  & { findCareers: (
    { __typename?: 'CareersType' }
    & { items?: Maybe<Array<(
      { __typename?: 'CareerType' }
      & Pick<CareerType, 'id' | 'name'>
    )>>, meta: (
      { __typename?: 'PaginationMeta' }
      & Pick<PaginationMeta, 'totalItems' | 'itemCount'>
    ) }
  ) }
);

export type FindEnterpriseCareersQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type FindEnterpriseCareersQuery = (
  { __typename?: 'Query' }
  & { findEnterpriseCareers: (
    { __typename?: 'CareersType' }
    & { items?: Maybe<Array<(
      { __typename?: 'CareerType' }
      & Pick<CareerType, 'id' | 'name'>
    )>>, meta: (
      { __typename?: 'PaginationMeta' }
      & Pick<PaginationMeta, 'totalItems' | 'itemCount'>
    ) }
  ) }
);

export type UpdateEnterpriseCareersMutationVariables = Exact<{
  careerIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type UpdateEnterpriseCareersMutation = (
  { __typename?: 'Mutation' }
  & { updateEnterpriseCareers: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type UpdateEnterpriseContactsMutationVariables = Exact<{
  location: Scalars['String'];
  countryCode?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
}>;


export type UpdateEnterpriseContactsMutation = (
  { __typename?: 'Mutation' }
  & { updateEnterpriseContacts: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type EnterpriseDetailsFragment = (
  { __typename?: 'EnterpriseDetailsType' }
  & Pick<EnterpriseDetailsType, 'headquarter' | 'id' | 'jobTitle' | 'logo'>
  & { employer: (
    { __typename?: 'EmployerType' }
    & EmployerFragment
  ), employerType?: Maybe<(
    { __typename?: 'EmployerTypeType' }
    & Pick<EmployerTypeType, 'id' | 'name' | 'label' | 'value'>
  )> }
);

export type GetEnterpriseDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEnterpriseDetailsQuery = (
  { __typename?: 'Query' }
  & { getEnterpriseDetails: (
    { __typename?: 'EnterpriseDetailsType' }
    & EnterpriseDetailsFragment
  ) }
);

export type CreateEnterpriseDetailsMutationVariables = Exact<{
  employerId: Scalars['Int'];
  headquarter?: Maybe<Scalars['String']>;
  jobTitle: Scalars['String'];
  employerTypeId?: Maybe<Scalars['Int']>;
  logo?: Maybe<Scalars['String']>;
}>;


export type CreateEnterpriseDetailsMutation = (
  { __typename?: 'Mutation' }
  & { createEnterpriseDetails: (
    { __typename?: 'EnterpriseDetailsType' }
    & EnterpriseDetailsFragment
  ) }
);

export type UpdateEnterpriseDetailsMutationVariables = Exact<{
  enterpriseDetailsId: Scalars['Int'];
  employerId: Scalars['Int'];
  employerTypeId?: Maybe<Scalars['Int']>;
  headquarter?: Maybe<Scalars['String']>;
  jobTitle: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
}>;


export type UpdateEnterpriseDetailsMutation = (
  { __typename?: 'Mutation' }
  & { updateEnterpriseDetails: (
    { __typename?: 'EnterpriseDetailsType' }
    & EnterpriseDetailsFragment
  ) }
);

export type UploadEnterpriseLogoMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadEnterpriseLogoMutation = (
  { __typename?: 'Mutation' }
  & { uploadEnterpriseLogo: (
    { __typename?: 'LinkType' }
    & Pick<LinkType, 'link'>
  ) }
);

export type GetEnterpriseStepsStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEnterpriseStepsStatusQuery = (
  { __typename?: 'Query' }
  & { getEnterpriseStepsStatus: (
    { __typename?: 'EnterpriseStepsStatusType' }
    & { items: Array<(
      { __typename?: 'EnterpriseStepStatusType' }
      & Pick<EnterpriseStepStatusType, 'enterpriseId' | 'status' | 'stepKey'>
    )> }
  ) }
);

export type SkipEnterpriseStepMutationVariables = Exact<{
  step: EnterpriseSkippableStepEnum;
}>;


export type SkipEnterpriseStepMutation = (
  { __typename?: 'Mutation' }
  & { skipEnterpriseStep: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type UpdateEnterprisePrivacyMutationVariables = Exact<{
  value: EnterprisePrivacyEnum;
}>;


export type UpdateEnterprisePrivacyMutation = (
  { __typename?: 'Mutation' }
  & { updateEnterprisePrivacy: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type GetEnterprisePrivacyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEnterprisePrivacyQuery = (
  { __typename?: 'Query' }
  & { getEnterprisePrivacy?: Maybe<(
    { __typename?: 'EnterprisePrivacyType' }
    & Pick<EnterprisePrivacyType, 'id' | 'value'>
  )> }
);

export type AssessmentResultQuestionFragment = (
  { __typename?: 'AssessmentQuestionType' }
  & Pick<AssessmentQuestionType, 'id' | 'name' | 'questionType' | 'toolTip'>
);

export type AssessmentResultFieldsFragment = (
  { __typename?: 'AssessmentResultType' }
  & Pick<AssessmentResultType, 'score'>
  & { question: (
    { __typename?: 'AssessmentQuestionType' }
    & AssessmentResultQuestionFragment
  ) }
);

export type TalentComparisonFieldsFragment = (
  { __typename?: 'ComparisonScoreType' }
  & { average: (
    { __typename?: 'TalentScore' }
    & TalentScoreFieldsFragment
  ), talent: (
    { __typename?: 'TalentScore' }
    & Pick<TalentScore, 'badge'>
    & TalentScoreFieldsFragment
  ) }
);

export type CvEntityFieldsFragment = (
  { __typename?: 'CVEntity' }
  & Pick<CvEntity, 'id' | 'name' | 'value' | 'label'>
);

export type CvEmployerFieldsFragment = (
  { __typename?: 'CVEmployer' }
  & Pick<CvEmployer, 'id' | 'name' | 'website' | 'headquarter' | 'rank'>
  & { industry?: Maybe<(
    { __typename?: 'CVEntity' }
    & CvEntityFieldsFragment
  )>, size?: Maybe<(
    { __typename?: 'CVEntity' }
    & CvEntityFieldsFragment
  )>, type?: Maybe<(
    { __typename?: 'CVEntity' }
    & CvEntityFieldsFragment
  )> }
);

export type RawTalentCvFieldsFragment = (
  { __typename?: 'RawTalentCV' }
  & Pick<RawTalentCv, 'summary'>
  & { certificates?: Maybe<Array<(
    { __typename?: 'CVCertificate' }
    & Pick<CvCertificate, 'certifiedIn' | 'issuedBy'>
    & { certificate?: Maybe<(
      { __typename?: 'CVEntity' }
      & CvEntityFieldsFragment
    )> }
  )>>, educations?: Maybe<Array<(
    { __typename?: 'CVEducation' }
    & Pick<CvEducation, 'graduatedIn'>
    & { campus?: Maybe<(
      { __typename?: 'CVEntity' }
      & CvEntityFieldsFragment
    )>, course?: Maybe<(
      { __typename?: 'CVEntity' }
      & CvEntityFieldsFragment
    )>, degree?: Maybe<(
      { __typename?: 'CVEntity' }
      & CvEntityFieldsFragment
    )>, university?: Maybe<(
      { __typename?: 'CVEntity' }
      & CvEntityFieldsFragment
    )> }
  )>>, experiences?: Maybe<Array<(
    { __typename?: 'CVExperience' }
    & Pick<CvExperience, 'from' | 'jobTitle' | 'jobType' | 'keyActivities' | 'location' | 'to'>
    & { employer?: Maybe<(
      { __typename?: 'CVEmployer' }
      & CvEmployerFieldsFragment
    )>, occupation?: Maybe<(
      { __typename?: 'CVEntity' }
      & CvEntityFieldsFragment
    )> }
  )>>, expertises?: Maybe<Array<(
    { __typename?: 'CVEntity' }
    & CvEntityFieldsFragment
  )>>, itSkills?: Maybe<Array<(
    { __typename?: 'CVEntity' }
    & CvEntityFieldsFragment
  )>>, languages?: Maybe<Array<(
    { __typename?: 'CVLanguage' }
    & { language?: Maybe<(
      { __typename?: 'CVEntity' }
      & CvEntityFieldsFragment
    )>, level?: Maybe<(
      { __typename?: 'CVEntity' }
      & CvEntityFieldsFragment
    )> }
  )>>, personalSkills?: Maybe<Array<(
    { __typename?: 'CVEntity' }
    & CvEntityFieldsFragment
  )>> }
);

export type UniversityBaseFieldsFragment = (
  { __typename?: 'UniversityType' }
  & Pick<UniversityType, 'id' | 'name' | 'label' | 'value'>
);

export type CourseFieldsFragment = (
  { __typename?: 'UniversityCourseType' }
  & Pick<UniversityCourseType, 'id' | 'name' | 'label' | 'value'>
);

export type DegreeFieldsFragment = (
  { __typename?: 'UniversityDegreeType' }
  & Pick<UniversityDegreeType, 'id' | 'name' | 'label' | 'value'>
);

export type EducationFieldsFragment = (
  { __typename?: 'TalentEducationType' }
  & Pick<TalentEducationType, 'id' | 'graduatedIn'>
  & { university?: Maybe<(
    { __typename?: 'UniversityType' }
    & Pick<UniversityType, 'rank'>
    & UniversityFieldsFragment
  )>, course?: Maybe<(
    { __typename?: 'UniversityCourseType' }
    & CourseFieldsFragment
  )>, degree?: Maybe<(
    { __typename?: 'UniversityDegreeType' }
    & DegreeFieldsFragment
  )> }
);

export type ExpertiseFieldsFragment = (
  { __typename?: 'ExpertiseType' }
  & Pick<ExpertiseType, 'id' | 'name' | 'label' | 'value'>
);

export type IndustryFieldsFragment = (
  { __typename?: 'EmployerIndustryType' }
  & Pick<EmployerIndustryType, 'id' | 'name' | 'label' | 'value'>
);

export type EmployerFieldsFragment = (
  { __typename?: 'EmployerType' }
  & Pick<EmployerType, 'id' | 'name' | 'label' | 'value'>
);

export type ExpertiseFullFieldsFragment = (
  { __typename?: 'ExpertiseType' }
  & Pick<ExpertiseType, 'description'>
  & ExpertiseFieldsFragment
);

export type PaginationFieldsFragment = (
  { __typename?: 'PaginationMeta' }
  & Pick<PaginationMeta, 'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'>
);

export type NameWithIdFragment = (
  { __typename?: 'NameWithIdResponse' }
  & Pick<NameWithIdResponse, 'id' | 'name' | 'label' | 'value'>
);

export type PoolFiltersFieldsFragment = (
  { __typename?: 'HeadhunterPoolExtendedFilterType' }
  & Pick<HeadhunterPoolExtendedFilterType, 'badges' | 'locations' | 'yearOfExperienceRange'>
  & { matchPercentage: HeadhunterPoolExtendedFilterType['matchRange'] }
  & { certificates?: Maybe<Array<(
    { __typename?: 'NameWithIdResponse' }
    & NameWithIdFragment
  )>>, educations?: Maybe<Array<(
    { __typename?: 'NameWithIdResponse' }
    & NameWithIdFragment
  )>>, employers?: Maybe<Array<(
    { __typename?: 'NameWithIdResponse' }
    & NameWithIdFragment
  )>>, expertises?: Maybe<Array<(
    { __typename?: 'NameWithIdResponse' }
    & NameWithIdFragment
  )>>, itSkills?: Maybe<Array<(
    { __typename?: 'NameWithIdResponse' }
    & NameWithIdFragment
  )>>, languages?: Maybe<Array<(
    { __typename?: 'NameWithIdResponse' }
    & NameWithIdFragment
  )>>, occupations?: Maybe<Array<(
    { __typename?: 'NameWithIdResponse' }
    & NameWithIdFragment
  )>> }
);

export type CertificatesFieldsFragment = (
  { __typename?: 'CertificateType' }
  & Pick<CertificateType, 'id' | 'name' | 'label' | 'value'>
);

export type ItSkillsFieldsFragment = (
  { __typename?: 'ItSkillType' }
  & Pick<ItSkillType, 'id' | 'name' | 'label' | 'value'>
);

export type AssessmentQuestionFieldFragment = (
  { __typename?: 'AssessmentQuestionType' }
  & Pick<AssessmentQuestionType, 'id' | 'name' | 'questionType' | 'label' | 'value'>
);

export type LanguageFieldsFragment = (
  { __typename?: 'LanguageType' }
  & Pick<LanguageType, 'id' | 'name' | 'label' | 'value'>
);

export type SkillsFieldsFragment = (
  { __typename?: 'SkillType' }
  & Pick<SkillType, 'id' | 'name' | 'label' | 'value'>
);

export type SkillsFullFieldsFragment = (
  { __typename?: 'SkillType' }
  & Pick<SkillType, 'description'>
  & SkillsFieldsFragment
);

export type ItSkillsFullFieldsFragment = (
  { __typename?: 'TalentItSkillType' }
  & Pick<TalentItSkillType, 'id' | 'biographyId' | 'yearsOfExperience'>
  & { itSkill: (
    { __typename?: 'ItSkillType' }
    & ItSkillsFieldsFragment
  ), level: (
    { __typename?: 'ItSkillLevelType' }
    & Pick<ItSkillLevelType, 'id' | 'name' | 'label' | 'value'>
  ) }
);

export type CertificatesFullFieldsFragment = (
  { __typename?: 'TalentCertificateType' }
  & Pick<TalentCertificateType, 'id' | 'certifiedIn' | 'issuedBy'>
  & { certificate: (
    { __typename?: 'CertificateType' }
    & Pick<CertificateType, 'description'>
    & CertificatesFieldsFragment
  ) }
);

export type LanguageFullFieldsFragment = (
  { __typename?: 'TalentLanguageType' }
  & Pick<TalentLanguageType, 'id'>
  & { language: (
    { __typename?: 'LanguageType' }
    & LanguageFieldsFragment
  ), level: (
    { __typename?: 'LanguageLevelType' }
    & Pick<LanguageLevelType, 'id' | 'name' | 'label' | 'value'>
  ) }
);

export type SubscriptionNotificationFieldsFragment = (
  { __typename?: 'SubscriptionNotification' }
  & Pick<SubscriptionNotification, 'availableCredits' | 'initialCredits' | 'status' | 'expireAt' | 'cancelAtPeriodEnd' | 'createdAt'>
  & { product?: Maybe<(
    { __typename?: 'SubscriptionProductType' }
    & Pick<SubscriptionProductType, 'id' | 'name' | 'displayedName' | 'hasTrial'>
    & { price?: Maybe<(
      { __typename?: 'SubscriptionProductPriceType' }
      & Pick<SubscriptionProductPriceType, 'currency' | 'interval' | 'unitAmount'>
    )> }
  )> }
);

export type SubscriptionFieldsFragment = (
  { __typename?: 'SubscriptionType' }
  & Pick<SubscriptionType, 'availableCredits' | 'initialCredits' | 'status' | 'expireAt' | 'cancelAtPeriodEnd' | 'createdAt'>
  & { product?: Maybe<(
    { __typename?: 'SubscriptionProductType' }
    & Pick<SubscriptionProductType, 'id' | 'name' | 'displayedName' | 'hasTrial'>
    & { price?: Maybe<(
      { __typename?: 'SubscriptionProductPriceType' }
      & Pick<SubscriptionProductPriceType, 'currency' | 'interval' | 'unitAmount'>
    )> }
  )> }
);

export type TalentSummaryProfileFieldsFragment = (
  { __typename?: 'ProfileType' }
  & Pick<ProfileType, 'id' | 'fullName' | 'picture' | 'location'>
);

export type TalentSummaryExperiencesFieldsFragment = (
  { __typename?: 'ExperienceType' }
  & Pick<ExperienceType, 'jobTitle'>
  & { occupation?: Maybe<(
    { __typename?: 'OccupationType' }
    & Pick<OccupationType, 'name'>
  )>, employer?: Maybe<(
    { __typename?: 'EmployerType' }
    & Pick<EmployerType, 'name'>
  )> }
);

export type TalentSummaryEducationsFieldsFragment = (
  { __typename?: 'TalentEducationType' }
  & { degree?: Maybe<(
    { __typename?: 'UniversityDegreeType' }
    & Pick<UniversityDegreeType, 'name'>
  )> }
);

export type TalentCvFieldsFragment = (
  { __typename?: 'TalentCvType' }
  & Pick<TalentCvType, 'link' | 'originName' | 'summary'>
);

export type TrendFieldsFragment = (
  { __typename?: 'TrendType' }
  & Pick<TrendType, 'areaKey' | 'hasSuggestion' | 'talentSettings' | 'trends'>
);

export type AdminLoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type AdminLoginMutation = (
  { __typename?: 'Mutation' }
  & { adminLogin: (
    { __typename?: 'AdminLoginResponse' }
    & { profile: (
      { __typename?: 'AdminProfileType' }
      & AdminProfileFragment
    ), jwt: (
      { __typename?: 'JwtType' }
      & TokenFieldsFragment
    ) }
  ) }
);

export type GetAdminProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdminProfileQuery = (
  { __typename?: 'Query' }
  & { getAdminProfile: (
    { __typename?: 'AdminProfileType' }
    & AdminProfileFragment
  ) }
);

export type UploadEmployerLogoMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadEmployerLogoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadEmployerLogo'>
);

export type MergeEmployerMutationVariables = Exact<{
  employerId: Scalars['Int'];
  targetEmployerId: Scalars['Int'];
}>;


export type MergeEmployerMutation = (
  { __typename?: 'Mutation' }
  & { mergeEmployer: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type GetEnterpriseStatByAccountTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEnterpriseStatByAccountTypeQuery = (
  { __typename?: 'Query' }
  & { getEnterpriseStatByAccountType: (
    { __typename?: 'EnterpriseAccountStatType' }
    & EnterpriseAccountStatFragment
  ) }
);

export type GetEnterpriseStatByMonthlyRegisteredQueryVariables = Exact<{
  year?: Maybe<Scalars['Int']>;
}>;


export type GetEnterpriseStatByMonthlyRegisteredQuery = (
  { __typename?: 'Query' }
  & { getEnterpriseStatByMonthlyRegistered: (
    { __typename?: 'EnterpriseMonthlyRegisteredStatsType' }
    & { items: Array<(
      { __typename?: 'EnterpriseMonthlyRegisteredStatType' }
      & Pick<EnterpriseMonthlyRegisteredStatType, 'count' | 'date'>
    )> }
  ) }
);

export type GetEnterpriseStatByLocationQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type GetEnterpriseStatByLocationQuery = (
  { __typename?: 'Query' }
  & { getEnterpriseStatByLocation: (
    { __typename?: 'EnterpriseLocationStatsType' }
    & { items?: Maybe<Array<(
      { __typename?: 'EnterpriseLocationStatType' }
      & Pick<EnterpriseLocationStatType, 'count'>
      & { entryName: EnterpriseLocationStatType['country'] }
    )>>, meta: (
      { __typename?: 'PaginationMeta' }
      & PaginationFieldsFragment
    ) }
  ) }
);

export type GetHeadhunterStatByTopLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHeadhunterStatByTopLocationsQuery = (
  { __typename?: 'Query' }
  & { getHeadhunterStatByTopLocations: (
    { __typename?: 'HeadhunterTopLocationStatsType' }
    & { items: Array<(
      { __typename?: 'HeadhunterTopLocationStatType' }
      & Pick<HeadhunterTopLocationStatType, 'count'>
      & { entryName: HeadhunterTopLocationStatType['country'] }
    )> }
  ) }
);

export type GetEnterpriseStatByTopCompaniesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type GetEnterpriseStatByTopCompaniesQuery = (
  { __typename?: 'Query' }
  & { getEnterpriseStatByTopCompanies: (
    { __typename?: 'EnterpriseCompaniesStatsType' }
    & { items?: Maybe<Array<(
      { __typename?: 'EnterpriseCompaniesStatType' }
      & Pick<EnterpriseCompaniesStatType, 'count'>
      & { entryName: EnterpriseCompaniesStatType['employer'] }
    )>>, meta: (
      { __typename?: 'PaginationMeta' }
      & PaginationFieldsFragment
    ) }
  ) }
);

export type AdminProfileFragment = (
  { __typename?: 'AdminProfileType' }
  & Pick<AdminProfileType, 'id' | 'email' | 'fullName' | 'type'>
);

export type TalentBadgeStatFragment = (
  { __typename?: 'TalentBadgeStatType' }
  & Pick<TalentBadgeStatType, 'advanced' | 'competent' | 'elite' | 'rising' | 'total'>
);

export type EnterpriseAccountStatFragment = (
  { __typename?: 'EnterpriseAccountStatType' }
  & Pick<EnterpriseAccountStatType, 'inHouse' | 'recruitment' | 'total'>
);

export type CertificateRequestFragment = (
  { __typename?: 'CertificateType' }
  & Pick<CertificateType, 'id' | 'name' | 'description' | 'createdAt' | 'status' | 'updatedAt'>
  & { createdByProfile?: Maybe<(
    { __typename?: 'ProfileType' }
    & Pick<ProfileType, 'fullName'>
  )> }
);

export type ExpertiseRequestFragment = (
  { __typename?: 'ExpertiseType' }
  & Pick<ExpertiseType, 'id' | 'name' | 'description' | 'createdAt' | 'status' | 'updatedAt'>
  & { createdByProfile?: Maybe<(
    { __typename?: 'ProfileType' }
    & Pick<ProfileType, 'fullName'>
  )> }
);

export type ItSkillRequestFragment = (
  { __typename?: 'ItSkillType' }
  & Pick<ItSkillType, 'id' | 'name' | 'createdAt' | 'status' | 'updatedAt'>
  & { createdByProfile?: Maybe<(
    { __typename?: 'ProfileType' }
    & Pick<ProfileType, 'fullName'>
  )> }
);

export type GetJobPostsRequestsQueryVariables = Exact<{
  statuses?: Maybe<Array<JobStatusEnum> | JobStatusEnum>;
  orderBy?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type GetJobPostsRequestsQuery = (
  { __typename?: 'Query' }
  & { findJobPosts: (
    { __typename?: 'JobPostsType' }
    & { items: Array<(
      { __typename?: 'JobPostType' }
      & Pick<JobPostType, 'id' | 'title' | 'location' | 'description' | 'createdAt' | 'postedAt' | 'updatedAt' | 'status' | 'statusReason' | 'yearsOfExperience'>
      & { occupation?: Maybe<(
        { __typename?: 'OccupationType' }
        & OccupationFragmentFragment
      )>, jobType?: Maybe<(
        { __typename?: 'JobType' }
        & Pick<JobType, 'id' | 'value'>
      )>, attendance?: Maybe<(
        { __typename?: 'AttendanceType' }
        & Pick<AttendanceType, 'id' | 'value'>
      )>, enterprise?: Maybe<(
        { __typename?: 'EnterpriseType' }
        & Pick<EnterpriseType, 'id' | 'accountType'>
        & { details?: Maybe<(
          { __typename?: 'EnterpriseDetailsType' }
          & { employer: (
            { __typename?: 'EmployerType' }
            & Pick<EmployerType, 'name' | 'website'>
          ) }
        )>, profile?: Maybe<(
          { __typename?: 'ProfileType' }
          & Pick<ProfileType, 'id' | 'email' | 'fullName' | 'location'>
        )> }
      )>, certificates?: Maybe<Array<(
        { __typename?: 'CertificateType' }
        & Pick<CertificateType, 'id' | 'name'>
      )>>, expertises?: Maybe<Array<(
        { __typename?: 'ExpertiseType' }
        & Pick<ExpertiseType, 'id' | 'name'>
      )>>, industries?: Maybe<Array<(
        { __typename?: 'EmployerIndustryType' }
        & Pick<EmployerIndustryType, 'id' | 'name'>
      )>>, itSkills?: Maybe<Array<(
        { __typename?: 'ItSkillType' }
        & Pick<ItSkillType, 'id' | 'name'>
      )>> }
    )>, meta: (
      { __typename?: 'PaginationMeta' }
      & PaginationFieldsFragment
    ) }
  ) }
);

export type UpdateJobPostByAdminMutationVariables = Exact<{
  id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status: JobStatusEnum;
  statusReason?: Maybe<Scalars['String']>;
  occupationId?: Maybe<Scalars['Int']>;
}>;


export type UpdateJobPostByAdminMutation = (
  { __typename?: 'Mutation' }
  & { updateJobPostByAdmin: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type GetJobByMonthlyAppliedStatQueryVariables = Exact<{
  year?: Maybe<Scalars['Int']>;
}>;


export type GetJobByMonthlyAppliedStatQuery = (
  { __typename?: 'Query' }
  & { getJobByMonthlyAppliedStat: (
    { __typename?: 'JobStatsType' }
    & { items: Array<(
      { __typename?: 'JobStatType' }
      & Pick<JobStatType, 'count' | 'date'>
    )> }
  ) }
);

export type GetJobByMonthlyCreatedStatQueryVariables = Exact<{
  year?: Maybe<Scalars['Int']>;
}>;


export type GetJobByMonthlyCreatedStatQuery = (
  { __typename?: 'Query' }
  & { getJobByMonthlyCreatedStat: (
    { __typename?: 'JobStatsType' }
    & { items: Array<(
      { __typename?: 'JobStatType' }
      & Pick<JobStatType, 'count' | 'date'>
    )> }
  ) }
);

export type MergeItSkillMutationVariables = Exact<{
  itSkillId: Scalars['Int'];
  targetItSkillId: Scalars['Int'];
}>;


export type MergeItSkillMutation = (
  { __typename?: 'Mutation' }
  & { mergeItSkill: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type MergeExpertiseMutationVariables = Exact<{
  expertiseId: Scalars['Int'];
  targetExpertiseId: Scalars['Int'];
}>;


export type MergeExpertiseMutation = (
  { __typename?: 'Mutation' }
  & { mergeExpertise: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type MergeCertificateMutationVariables = Exact<{
  certificateId: Scalars['Int'];
  targetCertificateId: Scalars['Int'];
}>;


export type MergeCertificateMutation = (
  { __typename?: 'Mutation' }
  & { mergeCertificate: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type GetCountOfPendingRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountOfPendingRequestsQuery = (
  { __typename?: 'Query' }
  & { getCountOfPendingRequests: (
    { __typename?: 'PendingRequestsType' }
    & Pick<PendingRequestsType, 'certificate' | 'expertises' | 'itSkills'>
  ) }
);

export type GetCountOfJobPostsPendingRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountOfJobPostsPendingRequestsQuery = (
  { __typename?: 'Query' }
  & { getCountOfPendingRequests: (
    { __typename?: 'PendingRequestsType' }
    & Pick<PendingRequestsType, 'job'>
  ) }
);

export type GetCertificatesRequestsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  statuses?: Maybe<Array<CustomEntityStatusEnum> | CustomEntityStatusEnum>;
}>;


export type GetCertificatesRequestsQuery = (
  { __typename?: 'Query' }
  & { findCertificates: (
    { __typename?: 'CertificatesType' }
    & { items?: Maybe<Array<(
      { __typename?: 'CertificateType' }
      & CertificateRequestFragment
    )>>, meta: (
      { __typename?: 'PaginationMeta' }
      & PaginationFieldsFragment
    ) }
  ) }
);

export type UpdateCertificateRequestsMutationVariables = Exact<{
  status?: Maybe<CustomEntityStatusEnum>;
  name?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  descriptions?: Maybe<Scalars['String']>;
}>;


export type UpdateCertificateRequestsMutation = (
  { __typename?: 'Mutation' }
  & { updateCertificate: (
    { __typename?: 'CertificateType' }
    & CertificateRequestFragment
  ) }
);

export type GetExpertisesRequestsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  statuses?: Maybe<Array<CustomEntityStatusEnum> | CustomEntityStatusEnum>;
}>;


export type GetExpertisesRequestsQuery = (
  { __typename?: 'Query' }
  & { findExpertises: (
    { __typename?: 'ExpertisesType' }
    & { items?: Maybe<Array<(
      { __typename?: 'ExpertiseType' }
      & ExpertiseRequestFragment
    )>>, meta: (
      { __typename?: 'PaginationMeta' }
      & PaginationFieldsFragment
    ) }
  ) }
);

export type UpdateExpertiseRequestsMutationVariables = Exact<{
  status?: Maybe<CustomEntityStatusEnum>;
  name?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  descriptions?: Maybe<Scalars['String']>;
}>;


export type UpdateExpertiseRequestsMutation = (
  { __typename?: 'Mutation' }
  & { updateExpertise: (
    { __typename?: 'ExpertiseType' }
    & ExpertiseRequestFragment
  ) }
);

export type GetItSkillsRequestsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  statuses?: Maybe<Array<CustomEntityStatusEnum> | CustomEntityStatusEnum>;
}>;


export type GetItSkillsRequestsQuery = (
  { __typename?: 'Query' }
  & { findItSkills: (
    { __typename?: 'ItSkillsType' }
    & { items?: Maybe<Array<(
      { __typename?: 'ItSkillType' }
      & ItSkillRequestFragment
    )>>, meta: (
      { __typename?: 'PaginationMeta' }
      & PaginationFieldsFragment
    ) }
  ) }
);

export type UpdateItSkillsRequestsMutationVariables = Exact<{
  status?: Maybe<CustomEntityStatusEnum>;
  name?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
}>;


export type UpdateItSkillsRequestsMutation = (
  { __typename?: 'Mutation' }
  & { updateItSkill: (
    { __typename?: 'ItSkillType' }
    & ItSkillRequestFragment
  ) }
);

export type GetTalentStatByBadgeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTalentStatByBadgeQuery = (
  { __typename?: 'Query' }
  & { getTalentStatByBadge: (
    { __typename?: 'TalentBadgeStatType' }
    & TalentBadgeStatFragment
  ) }
);

export type GetTalentStatByMonthlyRegisteredQueryVariables = Exact<{
  year?: Maybe<Scalars['Int']>;
}>;


export type GetTalentStatByMonthlyRegisteredQuery = (
  { __typename?: 'Query' }
  & { getTalentStatByMonthlyRegistered: (
    { __typename?: 'TalentMonthlyRegisteredStatsType' }
    & { items: Array<(
      { __typename?: 'TalentMonthlyRegisteredStatType' }
      & Pick<TalentMonthlyRegisteredStatType, 'count' | 'date'>
    )> }
  ) }
);

export type GetTalentStatByLocationQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type GetTalentStatByLocationQuery = (
  { __typename?: 'Query' }
  & { getTalentStatByLocation: (
    { __typename?: 'TalentLocationStatsType' }
    & { items?: Maybe<Array<(
      { __typename?: 'TalentLocationStatType' }
      & Pick<TalentLocationStatType, 'count'>
      & { entryName: TalentLocationStatType['country'] }
    )>>, meta: (
      { __typename?: 'PaginationMeta' }
      & PaginationFieldsFragment
    ) }
  ) }
);

export type GetTalentStatByOccupationQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type GetTalentStatByOccupationQuery = (
  { __typename?: 'Query' }
  & { getTalentStatByOccupation: (
    { __typename?: 'TalentOccupationStatsType' }
    & { items?: Maybe<Array<(
      { __typename?: 'TalentOccupationStatType' }
      & Pick<TalentOccupationStatType, 'count'>
      & { entryName: TalentOccupationStatType['occupation'] }
    )>>, meta: (
      { __typename?: 'PaginationMeta' }
      & PaginationFieldsFragment
    ) }
  ) }
);

export type NotificationFragment = (
  { __typename?: 'NotificationType' }
  & Pick<NotificationType, 'id' | 'additionalValue' | 'notificationValue' | 'type' | 'createdAt' | 'profileId'>
);

export type GetNotificationsQueryVariables = Exact<{
  profileId?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type GetNotificationsQuery = (
  { __typename?: 'Query' }
  & { getNotifications: (
    { __typename?: 'NotificationsType' }
    & { items?: Maybe<Array<(
      { __typename?: 'NotificationType' }
      & NotificationFragment
    )>>, meta: (
      { __typename?: 'PaginationMeta' }
      & PaginationFieldsFragment
    ) }
  ) }
);

export type GetCountOfUnreadNotificationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountOfUnreadNotificationQuery = (
  { __typename?: 'Query' }
  & { getCountOfUnreadNotification: (
    { __typename?: 'UnreadNotificationsType' }
    & Pick<UnreadNotificationsType, 'countOfUnreadNotifications'>
  ) }
);

export type MarkNotificationAsReadMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type MarkNotificationAsReadMutation = (
  { __typename?: 'Mutation' }
  & { markNotificationAsRead: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type NotificationUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NotificationUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { notificationUpdated: (
    { __typename?: 'NotificationType' }
    & NotificationFragment
  ) }
);

export type NotificationCounterUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NotificationCounterUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { notificationCounterUpdated: (
    { __typename?: 'UnreadNotificationsType' }
    & Pick<UnreadNotificationsType, 'countOfUnreadNotifications'>
  ) }
);

export type SendNotificationMutationVariables = Exact<{
  additionalValue?: Maybe<Scalars['Int']>;
  enterpriseProfileId?: Maybe<Scalars['Int']>;
  notificationType?: Maybe<NotificationTypeEnum>;
  notificationValue?: Maybe<Scalars['Int']>;
  talentProfileId?: Maybe<Scalars['Int']>;
}>;


export type SendNotificationMutation = (
  { __typename?: 'Mutation' }
  & { sendNotification: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type GetProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProfileQuery = (
  { __typename?: 'Query' }
  & { getProfile: (
    { __typename?: 'ProfileType' }
    & ProfileFieldsFragment
  ) }
);

export type UpdateFullNameMutationVariables = Exact<{
  fullName: Scalars['String'];
}>;


export type UpdateFullNameMutation = (
  { __typename?: 'Mutation' }
  & { updateFullName: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type DeleteProfileMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteProfileMutation = (
  { __typename?: 'Mutation' }
  & { deleteProfile: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type TalentScoreFieldsFragment = (
  { __typename?: 'TalentScore' }
  & Pick<TalentScore, 'educations' | 'experiences' | 'expertises' | 'skills' | 'competencies'>
);

export type GetTalentComparisonScoreQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTalentComparisonScoreQuery = (
  { __typename?: 'Query' }
  & { getTalentComparisonScore: (
    { __typename?: 'ComparisonScoreType' }
    & TalentComparisonFieldsFragment
  ) }
);

export type GetTalentTopVisitorsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTalentTopVisitorsQuery = (
  { __typename?: 'Query' }
  & { getTopVisitors?: Maybe<Array<(
    { __typename?: 'EmployerType' }
    & Pick<EmployerType, 'name' | 'logo'>
  )>> }
);

export type GetTalentVisibilityStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTalentVisibilityStatsQuery = (
  { __typename?: 'Query' }
  & { getVisibilityStats: (
    { __typename?: 'VisibilityStatsType' }
    & { items: Array<(
      { __typename?: 'VisibilityStatType' }
      & Pick<VisibilityStatType, 'type' | 'value' | 'statChange'>
    )> }
  ) }
);

export type TalentCvUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type TalentCvUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { talentCvUpdated: (
    { __typename?: 'TalentCV' }
    & { entity?: Maybe<(
      { __typename?: 'TalentCvType' }
      & Pick<TalentCvType, 'link' | 'originName' | 'summary'>
      & { raw?: Maybe<(
        { __typename?: 'RawTalentCV' }
        & RawTalentCvFieldsFragment
      )> }
    )> }
  ) }
);

export type GetTalentCvWithRawQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTalentCvWithRawQuery = (
  { __typename?: 'Query' }
  & { getTalentCV: (
    { __typename?: 'TalentCvType' }
    & Pick<TalentCvType, 'link' | 'originName' | 'summary'>
    & { raw?: Maybe<(
      { __typename?: 'RawTalentCV' }
      & RawTalentCvFieldsFragment
    )> }
  ) }
);

export type GetTrendsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTrendsQuery = (
  { __typename?: 'Query' }
  & { getTrends: (
    { __typename?: 'TrendsType' }
    & { items?: Maybe<Array<(
      { __typename?: 'TrendType' }
      & TrendFieldsFragment
    )>> }
  ) }
);

export type UpdateTalentSummaryMutationVariables = Exact<{
  summary: Scalars['String'];
}>;


export type UpdateTalentSummaryMutation = (
  { __typename?: 'Mutation' }
  & { updateTalentSummary: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type GetOwnTalentProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOwnTalentProfileQuery = (
  { __typename?: 'Query' }
  & { getOwnTalentProfile: (
    { __typename?: 'TalentType' }
    & Pick<TalentType, 'id'>
    & { jobPreference: (
      { __typename?: 'TalentJobPreferenceType' }
      & { availability?: Maybe<(
        { __typename?: 'AvailabilityType' }
        & Pick<AvailabilityType, 'id' | 'value'>
      )>, relocation?: Maybe<(
        { __typename?: 'RelocationType' }
        & Pick<RelocationType, 'id' | 'value' | 'countries'>
      )>, attendances?: Maybe<Array<(
        { __typename?: 'AttendanceType' }
        & Pick<AttendanceType, 'id' | 'value'>
      )>>, jobTypes?: Maybe<Array<(
        { __typename?: 'JobType' }
        & Pick<JobType, 'id' | 'value'>
      )>> }
    ), biography?: Maybe<(
      { __typename?: 'TalentBiographyType' }
      & { expertises?: Maybe<Array<(
        { __typename?: 'ExpertiseType' }
        & ExpertiseFullFieldsFragment
      )>>, certificates?: Maybe<Array<(
        { __typename?: 'TalentCertificateType' }
        & CertificatesFullFieldsFragment
      )>>, educations?: Maybe<Array<(
        { __typename?: 'TalentEducationType' }
        & EducationFieldsFragment
      )>>, experiences?: Maybe<Array<(
        { __typename?: 'ExperienceType' }
        & ExperienceFieldsFragment
      )>>, itSkills?: Maybe<Array<(
        { __typename?: 'TalentItSkillType' }
        & ItSkillsFullFieldsFragment
      )>>, skills?: Maybe<Array<(
        { __typename?: 'SkillType' }
        & SkillsFullFieldsFragment
      )>>, languages?: Maybe<Array<(
        { __typename?: 'TalentLanguageType' }
        & LanguageFullFieldsFragment
      )>> }
    )>, competencies?: Maybe<(
      { __typename?: 'AssessmentResultsType' }
      & Pick<AssessmentResultsType, 'attempts' | 'duration'>
      & { items?: Maybe<Array<(
        { __typename?: 'AssessmentResultType' }
        & AssessmentResultFieldsFragment
      )>> }
    )>, cv?: Maybe<(
      { __typename?: 'TalentCvType' }
      & TalentCvFieldsFragment
    )>, comparisonScore?: Maybe<(
      { __typename?: 'ComparisonScoreType' }
      & { average: (
        { __typename?: 'TalentScore' }
        & Pick<TalentScore, 'educations' | 'experiences' | 'expertises' | 'skills' | 'competencies'>
      ), talent: (
        { __typename?: 'TalentScore' }
        & Pick<TalentScore, 'badge' | 'competencies' | 'educations' | 'experiences' | 'expertises'>
        & { topQualities: Array<(
          { __typename?: 'TopQuality' }
          & Pick<TopQuality, 'questionName' | 'questionType'>
        )> }
      ) }
      & TalentComparisonFieldsFragment
    )> }
  ) }
);

export type UploadTalentFileMutationVariables = Exact<{
  file: Scalars['Upload'];
  type: FileTypeEnum;
}>;


export type UploadTalentFileMutation = (
  { __typename?: 'Mutation' }
  & { uploadTalentFile: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type RemoveTalentFileMutationVariables = Exact<{
  type: FileTypeEnum;
}>;


export type RemoveTalentFileMutation = (
  { __typename?: 'Mutation' }
  & { removeTalentFile: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type GetAssessmentQuestionnaireQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAssessmentQuestionnaireQuery = (
  { __typename?: 'Query' }
  & { getAssessmentQuestionnaire: (
    { __typename?: 'StartQuestionnaireType' }
    & Pick<StartQuestionnaireType, 'talentAssessmentId' | 'startDate'>
    & { assessmentQuestions: (
      { __typename?: 'AssessmentQuestionsType' }
      & { items?: Maybe<Array<(
        { __typename?: 'AssessmentQuestionType' }
        & Pick<AssessmentQuestionType, 'id' | 'name' | 'text' | 'title' | 'multipleChoice'>
        & { answers: Array<(
          { __typename?: 'AssessmentAnswerType' }
          & Pick<AssessmentAnswerType, 'id' | 'text'>
        )> }
      )>> }
    ) }
  ) }
);

export type SaveAssessmentAnswersMutationVariables = Exact<{
  talentAssessmentId: Scalars['Int'];
  answers: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type SaveAssessmentAnswersMutation = (
  { __typename?: 'Mutation' }
  & { saveAssessmentAnswers: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type GetAssessmentResultsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAssessmentResultsQuery = (
  { __typename?: 'Query' }
  & { getAssessmentResults: (
    { __typename?: 'AssessmentResultsType' }
    & Pick<AssessmentResultsType, 'attempts' | 'duration'>
    & { items?: Maybe<Array<(
      { __typename?: 'AssessmentResultType' }
      & AssessmentResultFieldsFragment
    )>> }
  ) }
);

export type AssessmentProgressPingMutationVariables = Exact<{
  talentAssessmentId: Scalars['Int'];
}>;


export type AssessmentProgressPingMutation = (
  { __typename?: 'Mutation' }
  & { assessmentProgressPing: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type FindCertificatesQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  statuses?: Maybe<Array<CustomEntityStatusEnum> | CustomEntityStatusEnum>;
  receiveDescription?: Maybe<Scalars['Boolean']>;
}>;


export type FindCertificatesQuery = (
  { __typename?: 'Query' }
  & { findCertificates: (
    { __typename?: 'CertificatesType' }
    & { items?: Maybe<Array<(
      { __typename?: 'CertificateType' }
      & MakeOptional<Pick<CertificateType, 'id' | 'name' | 'description' | 'label' | 'value'>, 'description'>
    )>> }
  ) }
);

export type GetTalentCertificatesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type GetTalentCertificatesQuery = (
  { __typename?: 'Query' }
  & { getTalentCertificates: (
    { __typename?: 'TalentCertificatesType' }
    & { items?: Maybe<Array<(
      { __typename?: 'TalentCertificateType' }
      & CertificatesFullFieldsFragment
    )>>, meta: (
      { __typename?: 'PaginationMeta' }
      & PaginationFieldsFragment
    ) }
  ) }
);

export type CreateCertificateMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateCertificateMutation = (
  { __typename?: 'Mutation' }
  & { createCertificate: (
    { __typename?: 'CertificateType' }
    & Pick<CertificateType, 'id' | 'name'>
  ) }
);

export type CreateTalentCertificateMutationVariables = Exact<{
  certificateId: Scalars['Int'];
  certifiedIn?: Maybe<Scalars['DateTime']>;
  issuedBy?: Maybe<Scalars['String']>;
}>;


export type CreateTalentCertificateMutation = (
  { __typename?: 'Mutation' }
  & { createTalentCertificate: (
    { __typename?: 'TalentCertificateType' }
    & Pick<TalentCertificateType, 'id' | 'certifiedIn' | 'issuedBy'>
    & { certificate: (
      { __typename?: 'CertificateType' }
      & Pick<CertificateType, 'id' | 'name' | 'description' | 'label' | 'value'>
    ) }
  ) }
);

export type UpdateTalentCertificateMutationVariables = Exact<{
  talentCertificateId: Scalars['Int'];
  certificateId?: Maybe<Scalars['Int']>;
  certifiedIn?: Maybe<Scalars['DateTime']>;
  issuedBy?: Maybe<Scalars['String']>;
}>;


export type UpdateTalentCertificateMutation = (
  { __typename?: 'Mutation' }
  & { updateTalentCertificate: (
    { __typename?: 'TalentCertificateType' }
    & Pick<TalentCertificateType, 'id' | 'certifiedIn' | 'issuedBy'>
    & { certificate: (
      { __typename?: 'CertificateType' }
      & Pick<CertificateType, 'id' | 'name' | 'description' | 'label' | 'value'>
    ) }
  ) }
);

export type DeleteTalentCertificateMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTalentCertificateMutation = (
  { __typename?: 'Mutation' }
  & { deleteTalentCertificate: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type GetTalentCvQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTalentCvQuery = (
  { __typename?: 'Query' }
  & { getTalent: (
    { __typename?: 'TalentType' }
    & { cv?: Maybe<(
      { __typename?: 'TalentCvType' }
      & TalentCvFieldsFragment
    )> }
  ) }
);

export type CampusFragment = (
  { __typename?: 'UniversityCampusType' }
  & { campus: (
    { __typename?: 'CampusType' }
    & Pick<CampusType, 'id' | 'name'>
  ) }
);

export type UniversityFieldsFragment = (
  { __typename?: 'UniversityType' }
  & { campuses: Array<(
    { __typename?: 'UniversityCampusType' }
    & Pick<UniversityCampusType, 'custom'>
    & CampusFragment
  )> }
  & UniversityBaseFieldsFragment
);

export type CreateUniversityMutationVariables = Exact<{
  name: Scalars['String'];
  campus: Scalars['String'];
}>;


export type CreateUniversityMutation = (
  { __typename?: 'Mutation' }
  & { createUniversity: (
    { __typename?: 'UniversityType' }
    & UniversityFieldsFragment
  ) }
);

export type UpdateUniversityMutationVariables = Exact<{
  universityId: Scalars['Int'];
  campus: Scalars['String'];
}>;


export type UpdateUniversityMutation = (
  { __typename?: 'Mutation' }
  & { updateUniversity: (
    { __typename?: 'UniversityType' }
    & UniversityFieldsFragment
  ) }
);

export type CreateUniversityCourseMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateUniversityCourseMutation = (
  { __typename?: 'Mutation' }
  & { createUniversityCourse: (
    { __typename?: 'UniversityCourseType' }
    & Pick<UniversityCourseType, 'id' | 'name'>
  ) }
);

export type CreateTalentEducationMutationVariables = Exact<{
  universityId: Scalars['Int'];
  courseId: Scalars['Int'];
  degreeId: Scalars['Int'];
  campusId: Scalars['Int'];
  graduatedIn: Scalars['DateTime'];
}>;


export type CreateTalentEducationMutation = (
  { __typename?: 'Mutation' }
  & { createTalentEducation: (
    { __typename?: 'TalentEducationType' }
    & { course?: Maybe<(
      { __typename?: 'UniversityCourseType' }
      & Pick<UniversityCourseType, 'name'>
    )> }
  ) }
);

export type DeleteEducationMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteEducationMutation = (
  { __typename?: 'Mutation' }
  & { deleteTalentEducation: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type UpdateTalentEducationMutationVariables = Exact<{
  courseId?: Maybe<Scalars['Int']>;
  degreeId?: Maybe<Scalars['Int']>;
  educationId: Scalars['Int'];
  graduatedIn?: Maybe<Scalars['DateTime']>;
  universityId?: Maybe<Scalars['Int']>;
  campusId: Scalars['Int'];
}>;


export type UpdateTalentEducationMutation = (
  { __typename?: 'Mutation' }
  & { updateTalentEducation: (
    { __typename?: 'TalentEducationType' }
    & Pick<TalentEducationType, 'id'>
    & { course?: Maybe<(
      { __typename?: 'UniversityCourseType' }
      & Pick<UniversityCourseType, 'id' | 'name'>
    )> }
  ) }
);

export type FindUniversityCourseQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type FindUniversityCourseQuery = (
  { __typename?: 'Query' }
  & { findUniversityCourses: (
    { __typename?: 'UniversityCoursesType' }
    & { items?: Maybe<Array<(
      { __typename?: 'UniversityCourseType' }
      & Pick<UniversityCourseType, 'id' | 'name' | 'label' | 'value'>
    )>>, meta: (
      { __typename?: 'PaginationMeta' }
      & Pick<PaginationMeta, 'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'>
    ) }
  ) }
);

export type FindUniversityDegreeQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type FindUniversityDegreeQuery = (
  { __typename?: 'Query' }
  & { findUniversityDegrees: (
    { __typename?: 'UniversityDegreesType' }
    & { items?: Maybe<Array<(
      { __typename?: 'UniversityDegreeType' }
      & Pick<UniversityDegreeType, 'id' | 'name' | 'label' | 'value'>
    )>>, meta: (
      { __typename?: 'PaginationMeta' }
      & Pick<PaginationMeta, 'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'>
    ) }
  ) }
);

export type FindUniversitiesQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type FindUniversitiesQuery = (
  { __typename?: 'Query' }
  & { findUniversities: (
    { __typename?: 'UniversitiesType' }
    & { items?: Maybe<Array<(
      { __typename?: 'UniversityType' }
      & UniversityFieldsFragment
    )>>, meta: (
      { __typename?: 'PaginationMeta' }
      & Pick<PaginationMeta, 'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'>
    ) }
  ) }
);

export type FindCampusesQueryVariables = Exact<{
  universityIds: Array<Scalars['Float']> | Scalars['Float'];
}>;


export type FindCampusesQuery = (
  { __typename?: 'Query' }
  & { findCampuses: (
    { __typename?: 'CampusesType' }
    & { items: Array<(
      { __typename?: 'CampusType' }
      & Pick<CampusType, 'id' | 'name' | 'label' | 'value'>
    )> }
  ) }
);

export type GetTalentEducationsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type GetTalentEducationsQuery = (
  { __typename?: 'Query' }
  & { getTalentEducations: (
    { __typename?: 'TalentEducationsType' }
    & { meta: (
      { __typename?: 'PaginationMeta' }
      & PaginationFieldsFragment
    ), items?: Maybe<Array<(
      { __typename?: 'TalentEducationType' }
      & EducationFieldsFragment
    )>> }
  ) }
);

export type EmployerFragment = (
  { __typename?: 'EmployerType' }
  & Pick<EmployerType, 'id' | 'name' | 'label' | 'value' | 'website' | 'headquarter' | 'profileId' | 'logo' | 'createdAt' | 'updatedAt'>
  & { industry?: Maybe<(
    { __typename?: 'EmployerIndustryType' }
    & Pick<EmployerIndustryType, 'id' | 'name' | 'label' | 'value'>
  )>, employerLabel?: Maybe<(
    { __typename?: 'EmployerLabelType' }
    & Pick<EmployerLabelType, 'id' | 'name' | 'label' | 'value'>
  )>, size?: Maybe<(
    { __typename?: 'EmployerSizeType' }
    & Pick<EmployerSizeType, 'id' | 'name' | 'label' | 'value'>
  )>, type?: Maybe<(
    { __typename?: 'EmployerTypeType' }
    & Pick<EmployerTypeType, 'id' | 'name' | 'label' | 'value'>
  )> }
);

export type FindEmployersQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  includeMeta?: Maybe<Scalars['Boolean']>;
}>;


export type FindEmployersQuery = (
  { __typename?: 'Query' }
  & { findEmployers: (
    { __typename?: 'EmployersType' }
    & { items?: Maybe<Array<(
      { __typename?: 'EmployerType' }
      & EmployerFragment
    )>>, meta?: Maybe<(
      { __typename?: 'PaginationMeta' }
      & PaginationFieldsFragment
    )> }
  ) }
);

export type FindOccupationsQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type FindOccupationsQuery = (
  { __typename?: 'Query' }
  & { findOccupations: (
    { __typename?: 'OccupationsType' }
    & { items?: Maybe<Array<(
      { __typename?: 'OccupationType' }
      & Pick<OccupationType, 'id' | 'name' | 'label' | 'value'>
    )>>, meta: (
      { __typename?: 'PaginationMeta' }
      & PaginationFieldsFragment
    ) }
  ) }
);

export type FindEmployerSizesQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type FindEmployerSizesQuery = (
  { __typename?: 'Query' }
  & { findEmployerSizes: (
    { __typename?: 'EmployerSizesType' }
    & { items?: Maybe<Array<(
      { __typename?: 'EmployerSizeType' }
      & Pick<EmployerSizeType, 'id' | 'name' | 'label' | 'value'>
    )>> }
  ) }
);

export type FindIndustriesQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type FindIndustriesQuery = (
  { __typename?: 'Query' }
  & { findEmployerIndustries: (
    { __typename?: 'EmployerIndustriesType' }
    & { items?: Maybe<Array<(
      { __typename?: 'EmployerIndustryType' }
      & Pick<EmployerIndustryType, 'id' | 'name' | 'label' | 'value'>
    )>>, meta: (
      { __typename?: 'PaginationMeta' }
      & PaginationFieldsFragment
    ) }
  ) }
);

export type FindEmployerTypesQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type FindEmployerTypesQuery = (
  { __typename?: 'Query' }
  & { findEmployerTypes: (
    { __typename?: 'EmployerTypesType' }
    & { items?: Maybe<Array<(
      { __typename?: 'EmployerTypeType' }
      & Pick<EmployerTypeType, 'id' | 'name' | 'label' | 'value'>
    )>> }
  ) }
);

export type OccupationFragmentFragment = (
  { __typename?: 'OccupationType' }
  & Pick<OccupationType, 'id' | 'name' | 'label' | 'value'>
);

export type SimpleExperienceFieldsFragment = (
  { __typename?: 'SimpleExperienceType' }
  & Pick<SimpleExperienceType, 'id'>
  & { occupation: (
    { __typename?: 'OccupationType' }
    & OccupationFragmentFragment
  ) }
);

export type ExperienceFieldsFragment = (
  { __typename?: 'ExperienceType' }
  & Pick<ExperienceType, 'id' | 'keyActivities' | 'from' | 'to' | 'jobTitle' | 'location'>
  & { employer?: Maybe<(
    { __typename?: 'EmployerType' }
    & EmployerFragment
  )>, jobType?: Maybe<(
    { __typename?: 'JobType' }
    & Pick<JobType, 'id' | 'value'>
  )>, occupation?: Maybe<(
    { __typename?: 'OccupationType' }
    & OccupationFragmentFragment
  )> }
);

export type GetExperiencesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type GetExperiencesQuery = (
  { __typename?: 'Query' }
  & { getExperiences: (
    { __typename?: 'ExperiencesType' }
    & { items?: Maybe<Array<(
      { __typename?: 'ExperienceType' }
      & ExperienceFieldsFragment
    )>>, meta: (
      { __typename?: 'PaginationMeta' }
      & PaginationFieldsFragment
    ) }
  ) | (
    { __typename?: 'SimpleExperiencesType' }
    & { simpleExperiences?: Maybe<Array<(
      { __typename?: 'SimpleExperienceType' }
      & SimpleExperienceFieldsFragment
    )>>, meta: (
      { __typename?: 'PaginationMeta' }
      & PaginationFieldsFragment
    ) }
  ) }
);

export type CreateEmployerMutationVariables = Exact<{
  industryId: Scalars['Int'];
  name: Scalars['String'];
  sizeId: Scalars['Int'];
  website?: Maybe<Scalars['String']>;
}>;


export type CreateEmployerMutation = (
  { __typename?: 'Mutation' }
  & { createEmployer: (
    { __typename?: 'EmployerType' }
    & EmployerFragment
  ) }
);

export type UpdateEmployerMutationVariables = Exact<{
  employerId: Scalars['Int'];
  industryId: Scalars['Int'];
  name: Scalars['String'];
  sizeId: Scalars['Int'];
  website?: Maybe<Scalars['String']>;
  headquarter?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['Int']>;
  logo?: Maybe<Scalars['String']>;
}>;


export type UpdateEmployerMutation = (
  { __typename?: 'Mutation' }
  & { updateEmployer: (
    { __typename?: 'EmployerType' }
    & EmployerFragment
  ) }
);

export type CreateExperienceMutationVariables = Exact<{
  employerId: Scalars['Int'];
  from: Scalars['DateTime'];
  to?: Maybe<Scalars['DateTime']>;
  jobTitle: Scalars['String'];
  jobTypeValue?: Maybe<JobTypeValueEnum>;
  keyActivities?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  occupationId: Scalars['Int'];
}>;


export type CreateExperienceMutation = (
  { __typename?: 'Mutation' }
  & { createExperience: (
    { __typename?: 'ExperienceType' }
    & ExperienceFieldsFragment
  ) }
);

export type UpdateExperienceMutationVariables = Exact<{
  employerId?: Maybe<Scalars['Int']>;
  experienceId: Scalars['Int'];
  from?: Maybe<Scalars['DateTime']>;
  jobTitle?: Maybe<Scalars['String']>;
  jobTypeValue?: Maybe<JobTypeValueEnum>;
  keyActivities?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  occupationId?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['DateTime']>;
}>;


export type UpdateExperienceMutation = (
  { __typename?: 'Mutation' }
  & { updateExperience: (
    { __typename?: 'ExperienceType' }
    & ExperienceFieldsFragment
  ) }
);

export type DeleteExperienceMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteExperienceMutation = (
  { __typename?: 'Mutation' }
  & { deleteExperience: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type SaveSimpleExperienceMutationVariables = Exact<{
  occupationId: Scalars['Int'];
}>;


export type SaveSimpleExperienceMutation = (
  { __typename?: 'Mutation' }
  & { saveSimpleExperience: (
    { __typename?: 'SimpleExperienceType' }
    & Pick<SimpleExperienceType, 'id'>
    & { occupation: (
      { __typename?: 'OccupationType' }
      & OccupationFragmentFragment
    ) }
  ) }
);

export type HasCurrentlyWorkingExperienceQueryVariables = Exact<{ [key: string]: never; }>;


export type HasCurrentlyWorkingExperienceQuery = (
  { __typename?: 'Query' }
  & { hasCurrentlyWorkingExperience: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type FindExpertisesQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  statuses?: Maybe<Array<CustomEntityStatusEnum> | CustomEntityStatusEnum>;
  receiveDescription?: Maybe<Scalars['Boolean']>;
}>;


export type FindExpertisesQuery = (
  { __typename?: 'Query' }
  & { findExpertises: (
    { __typename?: 'ExpertisesType' }
    & { items?: Maybe<Array<(
      { __typename?: 'ExpertiseType' }
      & MakeOptional<Pick<ExpertiseType, 'id' | 'name' | 'description' | 'label' | 'value'>, 'description'>
    )>> }
  ) }
);

export type GetTalentExpertisesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type GetTalentExpertisesQuery = (
  { __typename?: 'Query' }
  & { findExpertises: (
    { __typename?: 'ExpertisesType' }
    & { items?: Maybe<Array<(
      { __typename?: 'ExpertiseType' }
      & ExpertiseFullFieldsFragment
    )>> }
  ) }
);

export type CreateExpertiseMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateExpertiseMutation = (
  { __typename?: 'Mutation' }
  & { createExpertise: (
    { __typename?: 'ExpertiseType' }
    & Pick<ExpertiseType, 'id' | 'name' | 'description'>
  ) }
);

export type AddExpertisesToTalentMutationVariables = Exact<{
  expertises: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type AddExpertisesToTalentMutation = (
  { __typename?: 'Mutation' }
  & { addExpertisesToTalent: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type RemoveExpertisesFromTalentMutationVariables = Exact<{
  expertises: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type RemoveExpertisesFromTalentMutation = (
  { __typename?: 'Mutation' }
  & { removeExpertisesFromTalent: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type ReplaceTalentExpertisesMutationVariables = Exact<{
  expertises: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type ReplaceTalentExpertisesMutation = (
  { __typename?: 'Mutation' }
  & { replaceTalentExpertises: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type FindItSkillsQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  statuses?: Maybe<Array<CustomEntityStatusEnum> | CustomEntityStatusEnum>;
}>;


export type FindItSkillsQuery = (
  { __typename?: 'Query' }
  & { findItSkills: (
    { __typename?: 'ItSkillsType' }
    & { items?: Maybe<Array<(
      { __typename?: 'ItSkillType' }
      & Pick<ItSkillType, 'id' | 'name' | 'label' | 'value'>
    )>> }
  ) }
);

export type FindItSkillLevelsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
}>;


export type FindItSkillLevelsQuery = (
  { __typename?: 'Query' }
  & { findItSkillLevels: (
    { __typename?: 'ItSkillLevelsType' }
    & { items?: Maybe<Array<(
      { __typename?: 'ItSkillLevelType' }
      & Pick<ItSkillLevelType, 'id' | 'name' | 'label' | 'value'>
    )>> }
  ) }
);

export type GetTalentItSkillsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type GetTalentItSkillsQuery = (
  { __typename?: 'Query' }
  & { getTalentItSkills: (
    { __typename?: 'TalentItSkillsType' }
    & { items?: Maybe<Array<(
      { __typename?: 'TalentItSkillType' }
      & ItSkillsFullFieldsFragment
    )>>, meta: (
      { __typename?: 'PaginationMeta' }
      & PaginationFieldsFragment
    ) }
  ) }
);

export type CreateItSkillMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateItSkillMutation = (
  { __typename?: 'Mutation' }
  & { createItSkill: (
    { __typename?: 'ItSkillType' }
    & Pick<ItSkillType, 'id' | 'name'>
  ) }
);

export type CreateTalentItSkillMutationVariables = Exact<{
  itSkillId: Scalars['Int'];
  levelId: Scalars['Int'];
  yearsOfExperience: Scalars['Float'];
}>;


export type CreateTalentItSkillMutation = (
  { __typename?: 'Mutation' }
  & { createTalentItSkill: (
    { __typename?: 'TalentItSkillType' }
    & Pick<TalentItSkillType, 'id' | 'biographyId' | 'yearsOfExperience'>
    & { itSkill: (
      { __typename?: 'ItSkillType' }
      & Pick<ItSkillType, 'id' | 'name' | 'label' | 'value'>
    ), level: (
      { __typename?: 'ItSkillLevelType' }
      & Pick<ItSkillLevelType, 'id' | 'name' | 'label' | 'value'>
    ) }
  ) }
);

export type UpdateTalentItSkillMutationVariables = Exact<{
  talentItSkillId: Scalars['Int'];
  itSkillId?: Maybe<Scalars['Int']>;
  levelId?: Maybe<Scalars['Int']>;
  yearsOfExperience?: Maybe<Scalars['Float']>;
}>;


export type UpdateTalentItSkillMutation = (
  { __typename?: 'Mutation' }
  & { updateTalentItSkill: (
    { __typename?: 'TalentItSkillType' }
    & Pick<TalentItSkillType, 'id' | 'yearsOfExperience'>
    & { itSkill: (
      { __typename?: 'ItSkillType' }
      & Pick<ItSkillType, 'id' | 'name' | 'label' | 'value'>
    ), level: (
      { __typename?: 'ItSkillLevelType' }
      & Pick<ItSkillLevelType, 'id' | 'name' | 'label' | 'value'>
    ) }
  ) }
);

export type DeleteTalentItSkillMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTalentItSkillMutation = (
  { __typename?: 'Mutation' }
  & { deleteTalentItSkill: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type FindLanguagesQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type FindLanguagesQuery = (
  { __typename?: 'Query' }
  & { findLanguages: (
    { __typename?: 'LanguagesType' }
    & { items?: Maybe<Array<(
      { __typename?: 'LanguageType' }
      & Pick<LanguageType, 'id' | 'name' | 'label' | 'value'>
    )>> }
  ) }
);

export type FindLanguageLevelsQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type FindLanguageLevelsQuery = (
  { __typename?: 'Query' }
  & { findLanguageLevels: (
    { __typename?: 'LanguageLevelsType' }
    & { items?: Maybe<Array<(
      { __typename?: 'LanguageLevelType' }
      & Pick<LanguageLevelType, 'id' | 'name' | 'label' | 'value'>
    )>> }
  ) }
);

export type GetTalentLanguagesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type GetTalentLanguagesQuery = (
  { __typename?: 'Query' }
  & { getTalentLanguages: (
    { __typename?: 'TalentLanguagesType' }
    & { items?: Maybe<Array<(
      { __typename?: 'TalentLanguageType' }
      & Pick<TalentLanguageType, 'id'>
      & { language: (
        { __typename?: 'LanguageType' }
        & Pick<LanguageType, 'id' | 'name' | 'label' | 'value'>
      ), level: (
        { __typename?: 'LanguageLevelType' }
        & Pick<LanguageLevelType, 'id' | 'name' | 'label' | 'value'>
      ) }
    )>>, meta: (
      { __typename?: 'PaginationMeta' }
      & PaginationFieldsFragment
    ) }
  ) }
);

export type CreateTalentLanguageMutationVariables = Exact<{
  languageId: Scalars['Int'];
  levelId: Scalars['Int'];
}>;


export type CreateTalentLanguageMutation = (
  { __typename?: 'Mutation' }
  & { createTalentLanguage: (
    { __typename?: 'TalentLanguageType' }
    & Pick<TalentLanguageType, 'id'>
    & { language: (
      { __typename?: 'LanguageType' }
      & Pick<LanguageType, 'id' | 'name' | 'label' | 'value'>
    ), level: (
      { __typename?: 'LanguageLevelType' }
      & Pick<LanguageLevelType, 'id' | 'name' | 'label' | 'value'>
    ) }
  ) }
);

export type UpdateTalentLanguageMutationVariables = Exact<{
  talentLanguageId: Scalars['Int'];
  languageId?: Maybe<Scalars['Int']>;
  levelId?: Maybe<Scalars['Int']>;
}>;


export type UpdateTalentLanguageMutation = (
  { __typename?: 'Mutation' }
  & { updateTalentLanguage: (
    { __typename?: 'TalentLanguageType' }
    & Pick<TalentLanguageType, 'id'>
    & { language: (
      { __typename?: 'LanguageType' }
      & Pick<LanguageType, 'id' | 'name' | 'label' | 'value'>
    ), level: (
      { __typename?: 'LanguageLevelType' }
      & Pick<LanguageLevelType, 'id' | 'name' | 'label' | 'value'>
    ) }
  ) }
);

export type DeleteTalentLanguageMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTalentLanguageMutation = (
  { __typename?: 'Mutation' }
  & { deleteTalentLanguage: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type FindSkillsQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type FindSkillsQuery = (
  { __typename?: 'Query' }
  & { findSkills: (
    { __typename?: 'SkillsType' }
    & { items?: Maybe<Array<(
      { __typename?: 'SkillType' }
      & SkillsFullFieldsFragment
    )>>, meta: (
      { __typename?: 'PaginationMeta' }
      & PaginationFieldsFragment
    ) }
  ) }
);

export type GetTalentSkillsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type GetTalentSkillsQuery = (
  { __typename?: 'Query' }
  & { findSkills: (
    { __typename?: 'SkillsType' }
    & { items?: Maybe<Array<(
      { __typename?: 'SkillType' }
      & SkillsFullFieldsFragment
    )>> }
  ) }
);

export type AddSkillsToTalentMutationVariables = Exact<{
  skills: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type AddSkillsToTalentMutation = (
  { __typename?: 'Mutation' }
  & { addSkillsToTalent: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type RemoveSkillsFromTalentMutationVariables = Exact<{
  skills: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type RemoveSkillsFromTalentMutation = (
  { __typename?: 'Mutation' }
  & { removeSkillsFromTalent: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type ReplaceTalentSkillsMutationVariables = Exact<{
  skills: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type ReplaceTalentSkillsMutation = (
  { __typename?: 'Mutation' }
  & { replaceTalentSkills: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type GetTalentTopCharacteristicsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTalentTopCharacteristicsQuery = (
  { __typename?: 'Query' }
  & { getTalentQualities: (
    { __typename?: 'TalentQualitiesType' }
    & Pick<TalentQualitiesType, 'badge'>
    & { topQualities: Array<(
      { __typename?: 'TopQuality' }
      & Pick<TopQuality, 'questionName' | 'questionType'>
    )> }
  ) }
);

export type GetStepsStatusQueryVariables = Exact<{
  wizard?: Maybe<WizardEnum>;
}>;


export type GetStepsStatusQuery = (
  { __typename?: 'Query' }
  & { getStepsStatus: (
    { __typename?: 'StepsStatusType' }
    & { items: Array<(
      { __typename?: 'StepStatusType' }
      & Pick<StepStatusType, 'status' | 'stepKey' | 'parentStepKey'>
    )> }
  ) }
);

export type SkipStepMutationVariables = Exact<{
  step: SkippableStepEnum;
}>;


export type SkipStepMutation = (
  { __typename?: 'Mutation' }
  & { skipStep: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type UpdateTalentAttendanceMutationVariables = Exact<{
  values: Array<AttendanceValueEnum> | AttendanceValueEnum;
}>;


export type UpdateTalentAttendanceMutation = (
  { __typename?: 'Mutation' }
  & { updateTalentAttendances: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type GetTalentAttendanceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTalentAttendanceQuery = (
  { __typename?: 'Query' }
  & { getTalentAttendances: (
    { __typename?: 'AttendancesType' }
    & { items: Array<(
      { __typename?: 'AttendanceType' }
      & Pick<AttendanceType, 'value'>
    )> }
  ) }
);

export type UpdateTalentAvailabilityMutationVariables = Exact<{
  value: AvailabilityValueEnum;
}>;


export type UpdateTalentAvailabilityMutation = (
  { __typename?: 'Mutation' }
  & { updateTalentAvailability: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type GetTalentAvailabilityQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTalentAvailabilityQuery = (
  { __typename?: 'Query' }
  & { getTalentAvailability?: Maybe<(
    { __typename?: 'AvailabilityType' }
    & Pick<AvailabilityType, 'id' | 'value'>
  )> }
);

export type UpdateTalentContactsMutationVariables = Exact<{
  contactMethodValue: ContactMethodEnum;
  location: Scalars['String'];
  countryCode?: Maybe<Scalars['String']>;
}>;


export type UpdateTalentContactsMutation = (
  { __typename?: 'Mutation' }
  & { updateContacts: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type SendTelephoneVerificationSmsMutationVariables = Exact<{
  telephone: Scalars['String'];
}>;


export type SendTelephoneVerificationSmsMutation = (
  { __typename?: 'Mutation' }
  & { sendTelephoneVerificationSms: (
    { __typename?: 'SendTelephoneVerificationSmsResponse' }
    & Pick<SendTelephoneVerificationSmsResponse, 'success' | 'verificationCode'>
  ) }
);

export type SaveTelephoneMutationVariables = Exact<{
  verificationCode?: Maybe<Scalars['Float']>;
}>;


export type SaveTelephoneMutation = (
  { __typename?: 'Mutation' }
  & { saveTelephone: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type GetTalentContactsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTalentContactsQuery = (
  { __typename?: 'Query' }
  & { getTalent: (
    { __typename?: 'TalentType' }
    & { contactMethod?: Maybe<(
      { __typename?: 'ContactMethodType' }
      & Pick<ContactMethodType, 'id' | 'value'>
    )>, profile?: Maybe<(
      { __typename?: 'ProfileType' }
      & Pick<ProfileType, 'id' | 'type' | 'picture' | 'email' | 'fullName' | 'location' | 'telephone'>
    )> }
  ) }
);

export type UpdateTalentJobTypesMutationVariables = Exact<{
  values: Array<JobTypeValueEnum> | JobTypeValueEnum;
}>;


export type UpdateTalentJobTypesMutation = (
  { __typename?: 'Mutation' }
  & { updateTalentJobTypes: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type GetTalentJobTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTalentJobTypeQuery = (
  { __typename?: 'Query' }
  & { getTalentJobTypes: (
    { __typename?: 'JobTypes' }
    & { items: Array<(
      { __typename?: 'JobType' }
      & Pick<JobType, 'value'>
    )> }
  ) }
);

export type UpdateTalentObjectiveMutationVariables = Exact<{
  value: ObjectiveValueEnum;
}>;


export type UpdateTalentObjectiveMutation = (
  { __typename?: 'Mutation' }
  & { updateTalentObjective: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type GetTalentObjectiveQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTalentObjectiveQuery = (
  { __typename?: 'Query' }
  & { getTalentObjective?: Maybe<(
    { __typename?: 'ObjectiveType' }
    & Pick<ObjectiveType, 'id' | 'value'>
  )> }
);

export type UpdateTalentPrivacyMutationVariables = Exact<{
  value: PrivacyValueEnum;
}>;


export type UpdateTalentPrivacyMutation = (
  { __typename?: 'Mutation' }
  & { updateTalentPrivacy: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type GetTalentPrivacyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTalentPrivacyQuery = (
  { __typename?: 'Query' }
  & { getTalentPrivacy?: Maybe<(
    { __typename?: 'PrivacyType' }
    & Pick<PrivacyType, 'id' | 'value'>
  )> }
);

export type UpdateTalentRelocationMutationVariables = Exact<{
  countries?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  value: RelocationValueEnum;
}>;


export type UpdateTalentRelocationMutation = (
  { __typename?: 'Mutation' }
  & { updateTalentRelocation: (
    { __typename?: 'SuccessResponse' }
    & Pick<SuccessResponse, 'success'>
  ) }
);

export type GetTalentRelocationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTalentRelocationQuery = (
  { __typename?: 'Query' }
  & { getTalentRelocation?: Maybe<(
    { __typename?: 'RelocationType' }
    & Pick<RelocationType, 'id' | 'countries' | 'value'>
  )> }
);

export const ProfileFieldsFragmentDoc = gql`
    fragment ProfileFields on ProfileType {
  id
  email
  location
  telephone
  picture
  fullName
  type
  onboarded
}
    `;
export const TokenFieldsFragmentDoc = gql`
    fragment TokenFields on JwtType {
  token
  expiresIn
}
    `;
export const SubscriptionFieldsFragmentDoc = gql`
    fragment SubscriptionFields on SubscriptionType {
  availableCredits
  initialCredits
  status
  expireAt
  cancelAtPeriodEnd
  createdAt
  product {
    id
    name
    displayedName
    hasTrial
    price {
      currency
      interval
      unitAmount
    }
  }
}
    `;
export const LoginResponseFragmentDoc = gql`
    fragment LoginResponse on LoginResponse {
  profile {
    ...ProfileFields
  }
  jwt {
    ...TokenFields
  }
  subscription {
    ...SubscriptionFields
  }
}
    ${ProfileFieldsFragmentDoc}
${TokenFieldsFragmentDoc}
${SubscriptionFieldsFragmentDoc}`;
export const ChatProfileFragmentDoc = gql`
    fragment ChatProfile on ChatProfileType {
  fullName
  id
  picture
  employment
  isOnline
  jobTitle
  lastActivity
  type
}
    `;
export const ChatFragmentDoc = gql`
    fragment Chat on ChatType {
  hasUnreadMessages
  id
  profiles {
    ...ChatProfile
  }
}
    ${ChatProfileFragmentDoc}`;
export const ChatMessageFragmentDoc = gql`
    fragment ChatMessage on MessageType {
  chatId
  createdAt
  from {
    ...ChatProfile
  }
  fromId
  id
  text
  to {
    ...ChatProfile
  }
  toId
  unread
}
    ${ChatProfileFragmentDoc}`;
export const EnterpriseTalentNoteFieldsFragmentDoc = gql`
    fragment EnterpriseTalentNoteFields on EnterpriseNoteType {
  id
  createdAt
  text
}
    `;
export const FindTalentsFavoriteFieldFragmentDoc = gql`
    fragment FindTalentsFavoriteField on TalentType {
  favorite
}
    `;
export const PoolDataFavoriteFieldFragmentDoc = gql`
    fragment PoolDataFavoriteField on TalentWithMatchingScoreType {
  favorite
}
    `;
export const HhPoolCardFieldsFragmentDoc = gql`
    fragment HhPoolCardFields on HeadhunterPoolType {
  id
  name
  totalTalentsCount
  newTalentsCount
  label @client
  value @client
  status
}
    `;
export const PaymentMethodFieldFragmentDoc = gql`
    fragment PaymentMethodField on PaymentMethodType {
  id
  externalId
  profileId
  last4
  cardBrand
  expYear
  expMonth
  name
  phone
  email
  city
  country
  addressLine1
  addressLine2
  postalCode
  state
  isDefault
  createdAt
}
    `;
export const SubscriptionProductsFieldFragmentDoc = gql`
    fragment SubscriptionProductsField on SubscriptionProductType {
  id
  price {
    unitAmount
    currency
    interval
    intervalCount
  }
  discount
  externalId
  talentCredits
  displayedName
  externalPriceId
  hasTrial
  name
}
    `;
export const EmployerFragmentDoc = gql`
    fragment Employer on EmployerType {
  id
  name
  label @client
  value @client
  website
  headquarter
  profileId
  logo
  createdAt
  updatedAt
  industry {
    id
    name
    label @client
    value @client
  }
  employerLabel {
    id
    name
    label @client
    value @client
  }
  size {
    id
    name
    label @client
    value @client
  }
  type {
    id
    name
    label @client
    value @client
  }
}
    `;
export const EnterpriseDetailsFragmentDoc = gql`
    fragment EnterpriseDetails on EnterpriseDetailsType {
  employer {
    ...Employer
  }
  headquarter
  id
  jobTitle
  employerType {
    id
    name
    label @client
    value @client
  }
  logo
}
    ${EmployerFragmentDoc}`;
export const AssessmentResultQuestionFragmentDoc = gql`
    fragment AssessmentResultQuestion on AssessmentQuestionType {
  id
  name
  questionType
  toolTip
}
    `;
export const AssessmentResultFieldsFragmentDoc = gql`
    fragment AssessmentResultFields on AssessmentResultType {
  question {
    ...AssessmentResultQuestion
  }
  score
}
    ${AssessmentResultQuestionFragmentDoc}`;
export const TalentScoreFieldsFragmentDoc = gql`
    fragment TalentScoreFields on TalentScore {
  educations
  experiences
  expertises
  skills
  competencies
}
    `;
export const TalentComparisonFieldsFragmentDoc = gql`
    fragment TalentComparisonFields on ComparisonScoreType {
  average {
    ...TalentScoreFields
  }
  talent {
    ...TalentScoreFields
    badge
  }
}
    ${TalentScoreFieldsFragmentDoc}`;
export const CvEntityFieldsFragmentDoc = gql`
    fragment CvEntityFields on CVEntity {
  id
  name
  value @client
  label @client
}
    `;
export const CvEmployerFieldsFragmentDoc = gql`
    fragment CvEmployerFields on CVEmployer {
  id
  name
  website
  headquarter
  rank
  industry {
    ...CvEntityFields
  }
  size {
    ...CvEntityFields
  }
  type {
    ...CvEntityFields
  }
}
    ${CvEntityFieldsFragmentDoc}`;
export const RawTalentCvFieldsFragmentDoc = gql`
    fragment RawTalentCVFields on RawTalentCV {
  certificates {
    certificate {
      ...CvEntityFields
    }
    certifiedIn
    issuedBy
  }
  educations {
    campus {
      ...CvEntityFields
    }
    course {
      ...CvEntityFields
    }
    degree {
      ...CvEntityFields
    }
    graduatedIn
    university {
      ...CvEntityFields
    }
  }
  experiences {
    employer {
      ...CvEmployerFields
    }
    from
    jobTitle
    jobType
    keyActivities
    location
    occupation {
      ...CvEntityFields
    }
    to
  }
  expertises {
    ...CvEntityFields
  }
  itSkills {
    ...CvEntityFields
  }
  languages {
    language {
      ...CvEntityFields
    }
    level {
      ...CvEntityFields
    }
  }
  personalSkills {
    ...CvEntityFields
  }
  summary
}
    ${CvEntityFieldsFragmentDoc}
${CvEmployerFieldsFragmentDoc}`;
export const UniversityBaseFieldsFragmentDoc = gql`
    fragment UniversityBaseFields on UniversityType {
  id
  name
  label @client
  value @client
}
    `;
export const CampusFragmentDoc = gql`
    fragment Campus on UniversityCampusType {
  campus {
    id
    name
  }
}
    `;
export const UniversityFieldsFragmentDoc = gql`
    fragment UniversityFields on UniversityType {
  ...UniversityBaseFields
  campuses {
    ...Campus
    custom
  }
}
    ${UniversityBaseFieldsFragmentDoc}
${CampusFragmentDoc}`;
export const CourseFieldsFragmentDoc = gql`
    fragment CourseFields on UniversityCourseType {
  id
  name
  label @client
  value @client
}
    `;
export const DegreeFieldsFragmentDoc = gql`
    fragment DegreeFields on UniversityDegreeType {
  id
  name
  label @client
  value @client
}
    `;
export const EducationFieldsFragmentDoc = gql`
    fragment EducationFields on TalentEducationType {
  id
  university {
    ...UniversityFields
    rank
  }
  course {
    ...CourseFields
  }
  degree {
    ...DegreeFields
  }
  graduatedIn
}
    ${UniversityFieldsFragmentDoc}
${CourseFieldsFragmentDoc}
${DegreeFieldsFragmentDoc}`;
export const IndustryFieldsFragmentDoc = gql`
    fragment IndustryFields on EmployerIndustryType {
  id
  name
  label @client
  value @client
}
    `;
export const EmployerFieldsFragmentDoc = gql`
    fragment EmployerFields on EmployerType {
  id
  name
  label @client
  value @client
}
    `;
export const ExpertiseFieldsFragmentDoc = gql`
    fragment ExpertiseFields on ExpertiseType {
  id
  name
  label @client
  value @client
}
    `;
export const ExpertiseFullFieldsFragmentDoc = gql`
    fragment ExpertiseFullFields on ExpertiseType {
  ...ExpertiseFields
  description
}
    ${ExpertiseFieldsFragmentDoc}`;
export const PaginationFieldsFragmentDoc = gql`
    fragment PaginationFields on PaginationMeta {
  currentPage
  itemCount
  itemsPerPage
  totalItems
  totalPages
}
    `;
export const NameWithIdFragmentDoc = gql`
    fragment NameWithId on NameWithIdResponse {
  id
  name
  label @client
  value @client
}
    `;
export const PoolFiltersFieldsFragmentDoc = gql`
    fragment PoolFiltersFields on HeadhunterPoolExtendedFilterType {
  badges
  certificates {
    ...NameWithId
  }
  educations {
    ...NameWithId
  }
  employers {
    ...NameWithId
  }
  expertises {
    ...NameWithId
  }
  itSkills {
    ...NameWithId
  }
  languages {
    ...NameWithId
  }
  locations
  matchPercentage: matchRange
  occupations {
    ...NameWithId
  }
  yearOfExperienceRange
}
    ${NameWithIdFragmentDoc}`;
export const AssessmentQuestionFieldFragmentDoc = gql`
    fragment AssessmentQuestionField on AssessmentQuestionType {
  id
  name
  questionType
  label @client
  value @client
}
    `;
export const SkillsFieldsFragmentDoc = gql`
    fragment SkillsFields on SkillType {
  id
  name
  label @client
  value @client
}
    `;
export const SkillsFullFieldsFragmentDoc = gql`
    fragment SkillsFullFields on SkillType {
  ...SkillsFields
  description
}
    ${SkillsFieldsFragmentDoc}`;
export const ItSkillsFieldsFragmentDoc = gql`
    fragment ItSkillsFields on ItSkillType {
  id
  name
  label @client
  value @client
}
    `;
export const ItSkillsFullFieldsFragmentDoc = gql`
    fragment ItSkillsFullFields on TalentItSkillType {
  id
  biographyId
  itSkill {
    ...ItSkillsFields
  }
  level {
    id
    name
    label @client
    value @client
  }
  yearsOfExperience
}
    ${ItSkillsFieldsFragmentDoc}`;
export const CertificatesFieldsFragmentDoc = gql`
    fragment CertificatesFields on CertificateType {
  id
  name
  label @client
  value @client
}
    `;
export const CertificatesFullFieldsFragmentDoc = gql`
    fragment CertificatesFullFields on TalentCertificateType {
  id
  certificate {
    ...CertificatesFields
    description
  }
  certifiedIn
  issuedBy
}
    ${CertificatesFieldsFragmentDoc}`;
export const LanguageFieldsFragmentDoc = gql`
    fragment LanguageFields on LanguageType {
  id
  name
  label @client
  value @client
}
    `;
export const LanguageFullFieldsFragmentDoc = gql`
    fragment LanguageFullFields on TalentLanguageType {
  id
  language {
    ...LanguageFields
  }
  level {
    id
    name
    label @client
    value @client
  }
}
    ${LanguageFieldsFragmentDoc}`;
export const SubscriptionNotificationFieldsFragmentDoc = gql`
    fragment SubscriptionNotificationFields on SubscriptionNotification {
  availableCredits
  initialCredits
  status
  expireAt
  cancelAtPeriodEnd
  createdAt
  product {
    id
    name
    displayedName
    hasTrial
    price {
      currency
      interval
      unitAmount
    }
  }
}
    `;
export const TalentSummaryProfileFieldsFragmentDoc = gql`
    fragment TalentSummaryProfileFields on ProfileType {
  id
  fullName
  picture
  location
}
    `;
export const TalentSummaryExperiencesFieldsFragmentDoc = gql`
    fragment TalentSummaryExperiencesFields on ExperienceType {
  jobTitle
  occupation {
    name
  }
  employer {
    name
  }
}
    `;
export const TalentSummaryEducationsFieldsFragmentDoc = gql`
    fragment TalentSummaryEducationsFields on TalentEducationType {
  degree {
    name
  }
}
    `;
export const TalentCvFieldsFragmentDoc = gql`
    fragment TalentCvFields on TalentCvType {
  link
  originName
  summary
}
    `;
export const TrendFieldsFragmentDoc = gql`
    fragment TrendFields on TrendType {
  areaKey
  hasSuggestion
  talentSettings
  trends
}
    `;
export const AdminProfileFragmentDoc = gql`
    fragment AdminProfile on AdminProfileType {
  id
  email
  fullName
  type
}
    `;
export const TalentBadgeStatFragmentDoc = gql`
    fragment TalentBadgeStat on TalentBadgeStatType {
  advanced
  competent
  elite
  rising
  total
}
    `;
export const EnterpriseAccountStatFragmentDoc = gql`
    fragment EnterpriseAccountStat on EnterpriseAccountStatType {
  inHouse
  recruitment
  total
}
    `;
export const CertificateRequestFragmentDoc = gql`
    fragment CertificateRequest on CertificateType {
  id
  name
  description
  createdAt
  createdByProfile {
    fullName
  }
  status
  updatedAt
}
    `;
export const ExpertiseRequestFragmentDoc = gql`
    fragment ExpertiseRequest on ExpertiseType {
  id
  name
  description
  createdAt
  createdByProfile {
    fullName
  }
  status
  updatedAt
}
    `;
export const ItSkillRequestFragmentDoc = gql`
    fragment ItSkillRequest on ItSkillType {
  id
  name
  createdAt
  createdByProfile {
    fullName
  }
  status
  updatedAt
}
    `;
export const NotificationFragmentDoc = gql`
    fragment Notification on NotificationType {
  id
  additionalValue
  notificationValue
  type
  createdAt
  profileId
}
    `;
export const OccupationFragmentFragmentDoc = gql`
    fragment OccupationFragment on OccupationType {
  id
  name
  label @client
  value @client
}
    `;
export const SimpleExperienceFieldsFragmentDoc = gql`
    fragment SimpleExperienceFields on SimpleExperienceType {
  id
  occupation {
    ...OccupationFragment
  }
}
    ${OccupationFragmentFragmentDoc}`;
export const ExperienceFieldsFragmentDoc = gql`
    fragment ExperienceFields on ExperienceType {
  id
  employer {
    ...Employer
  }
  jobType {
    id
    value
  }
  keyActivities
  occupation {
    ...OccupationFragment
  }
  from
  to
  jobTitle
  location
}
    ${EmployerFragmentDoc}
${OccupationFragmentFragmentDoc}`;
export const RegisterUserDocument = gql`
    mutation registerUser($email: String!, $fullName: String!, $password: String!, $profileType: profileTypeEnum!, $enterpriseAccountType: EnterpriseAccountEnum) {
  registration(
    email: $email
    fullName: $fullName
    password: $password
    profileType: $profileType
    enterpriseAccountType: $enterpriseAccountType
  ) {
    success
  }
}
    `;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      fullName: // value for 'fullName'
 *      password: // value for 'password'
 *      profileType: // value for 'profileType'
 *      enterpriseAccountType: // value for 'enterpriseAccountType'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, options);
}
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;
export const LoginUserDocument = gql`
    mutation loginUser($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    ...LoginResponse
  }
}
    ${LoginResponseFragmentDoc}`;
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, options);
}
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;
export const LinkedinLoginDocument = gql`
    mutation linkedinLogin($token: String!, $callback: String!) {
  loginBySocialToken(provider: LINKEDIN, token: $token, callback: $callback) {
    ...LoginResponse
  }
}
    ${LoginResponseFragmentDoc}`;
export type LinkedinLoginMutationFn = Apollo.MutationFunction<LinkedinLoginMutation, LinkedinLoginMutationVariables>;

/**
 * __useLinkedinLoginMutation__
 *
 * To run a mutation, you first call `useLinkedinLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkedinLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkedinLoginMutation, { data, loading, error }] = useLinkedinLoginMutation({
 *   variables: {
 *      token: // value for 'token'
 *      callback: // value for 'callback'
 *   },
 * });
 */
export function useLinkedinLoginMutation(baseOptions?: Apollo.MutationHookOptions<LinkedinLoginMutation, LinkedinLoginMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<LinkedinLoginMutation, LinkedinLoginMutationVariables>(LinkedinLoginDocument, options);
}
export type LinkedinLoginMutationHookResult = ReturnType<typeof useLinkedinLoginMutation>;
export type LinkedinLoginMutationResult = Apollo.MutationResult<LinkedinLoginMutation>;
export type LinkedinLoginMutationOptions = Apollo.BaseMutationOptions<LinkedinLoginMutation, LinkedinLoginMutationVariables>;
export const GoogleLoginDocument = gql`
    mutation googleLogin($token: String!, $callback: String!) {
  loginBySocialToken(provider: GOOGLE, token: $token, callback: $callback) {
    ...LoginResponse
  }
}
    ${LoginResponseFragmentDoc}`;
export type GoogleLoginMutationFn = Apollo.MutationFunction<GoogleLoginMutation, GoogleLoginMutationVariables>;

/**
 * __useGoogleLoginMutation__
 *
 * To run a mutation, you first call `useGoogleLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleLoginMutation, { data, loading, error }] = useGoogleLoginMutation({
 *   variables: {
 *      token: // value for 'token'
 *      callback: // value for 'callback'
 *   },
 * });
 */
export function useGoogleLoginMutation(baseOptions?: Apollo.MutationHookOptions<GoogleLoginMutation, GoogleLoginMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<GoogleLoginMutation, GoogleLoginMutationVariables>(GoogleLoginDocument, options);
}
export type GoogleLoginMutationHookResult = ReturnType<typeof useGoogleLoginMutation>;
export type GoogleLoginMutationResult = Apollo.MutationResult<GoogleLoginMutation>;
export type GoogleLoginMutationOptions = Apollo.BaseMutationOptions<GoogleLoginMutation, GoogleLoginMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout {
    success
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const EmailValidationDocument = gql`
    query emailValidation($email: String!) {
  emailValidation(email: $email) {
    success
  }
}
    `;

/**
 * __useEmailValidationQuery__
 *
 * To run a query within a React component, call `useEmailValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailValidationQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useEmailValidationQuery(baseOptions: Apollo.QueryHookOptions<EmailValidationQuery, EmailValidationQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<EmailValidationQuery, EmailValidationQueryVariables>(EmailValidationDocument, options);
}
export function useEmailValidationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailValidationQuery, EmailValidationQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<EmailValidationQuery, EmailValidationQueryVariables>(EmailValidationDocument, options);
}
export type EmailValidationQueryHookResult = ReturnType<typeof useEmailValidationQuery>;
export type EmailValidationLazyQueryHookResult = ReturnType<typeof useEmailValidationLazyQuery>;
export type EmailValidationQueryResult = Apollo.QueryResult<EmailValidationQuery, EmailValidationQueryVariables>;
export const EmailVerificationDocument = gql`
    query emailVerification($token: String!) {
  emailVerification(token: $token) {
    success
  }
}
    `;

/**
 * __useEmailVerificationQuery__
 *
 * To run a query within a React component, call `useEmailVerificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailVerificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailVerificationQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useEmailVerificationQuery(baseOptions: Apollo.QueryHookOptions<EmailVerificationQuery, EmailVerificationQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<EmailVerificationQuery, EmailVerificationQueryVariables>(EmailVerificationDocument, options);
}
export function useEmailVerificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailVerificationQuery, EmailVerificationQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<EmailVerificationQuery, EmailVerificationQueryVariables>(EmailVerificationDocument, options);
}
export type EmailVerificationQueryHookResult = ReturnType<typeof useEmailVerificationQuery>;
export type EmailVerificationLazyQueryHookResult = ReturnType<typeof useEmailVerificationLazyQuery>;
export type EmailVerificationQueryResult = Apollo.QueryResult<EmailVerificationQuery, EmailVerificationQueryVariables>;
export const ConfirmEmailAndLoginDocument = gql`
    mutation confirmEmailAndLogin($token: String!) {
  confirmEmailAndLogin(token: $token) {
    ...LoginResponse
  }
}
    ${LoginResponseFragmentDoc}`;
export type ConfirmEmailAndLoginMutationFn = Apollo.MutationFunction<ConfirmEmailAndLoginMutation, ConfirmEmailAndLoginMutationVariables>;

/**
 * __useConfirmEmailAndLoginMutation__
 *
 * To run a mutation, you first call `useConfirmEmailAndLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailAndLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailAndLoginMutation, { data, loading, error }] = useConfirmEmailAndLoginMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmEmailAndLoginMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmEmailAndLoginMutation, ConfirmEmailAndLoginMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<ConfirmEmailAndLoginMutation, ConfirmEmailAndLoginMutationVariables>(ConfirmEmailAndLoginDocument, options);
}
export type ConfirmEmailAndLoginMutationHookResult = ReturnType<typeof useConfirmEmailAndLoginMutation>;
export type ConfirmEmailAndLoginMutationResult = Apollo.MutationResult<ConfirmEmailAndLoginMutation>;
export type ConfirmEmailAndLoginMutationOptions = Apollo.BaseMutationOptions<ConfirmEmailAndLoginMutation, ConfirmEmailAndLoginMutationVariables>;
export const SendResetPasswordLinkDocument = gql`
    query sendResetPasswordLink($email: String!) {
  sendResetPasswordLink(email: $email) {
    success
  }
}
    `;

/**
 * __useSendResetPasswordLinkQuery__
 *
 * To run a query within a React component, call `useSendResetPasswordLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendResetPasswordLinkQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetPasswordLinkQuery(baseOptions: Apollo.QueryHookOptions<SendResetPasswordLinkQuery, SendResetPasswordLinkQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<SendResetPasswordLinkQuery, SendResetPasswordLinkQueryVariables>(SendResetPasswordLinkDocument, options);
}
export function useSendResetPasswordLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SendResetPasswordLinkQuery, SendResetPasswordLinkQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<SendResetPasswordLinkQuery, SendResetPasswordLinkQueryVariables>(SendResetPasswordLinkDocument, options);
}
export type SendResetPasswordLinkQueryHookResult = ReturnType<typeof useSendResetPasswordLinkQuery>;
export type SendResetPasswordLinkLazyQueryHookResult = ReturnType<typeof useSendResetPasswordLinkLazyQuery>;
export type SendResetPasswordLinkQueryResult = Apollo.QueryResult<SendResetPasswordLinkQuery, SendResetPasswordLinkQueryVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($password: String!, $token: String!) {
  resetPassword(password: $password, token: $token) {
    success
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const UpfrontEmailValidationDocument = gql`
    query upfrontEmailValidation($email: String!, $profileType: profileTypeEnum = ENTERPRISE, $validateFor: EmailValidationForEnum = SIGN_UP) {
  upfrontEmailValidation(
    email: $email
    profileType: $profileType
    validateFor: $validateFor
  ) {
    success
  }
}
    `;

/**
 * __useUpfrontEmailValidationQuery__
 *
 * To run a query within a React component, call `useUpfrontEmailValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpfrontEmailValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpfrontEmailValidationQuery({
 *   variables: {
 *      email: // value for 'email'
 *      profileType: // value for 'profileType'
 *      validateFor: // value for 'validateFor'
 *   },
 * });
 */
export function useUpfrontEmailValidationQuery(baseOptions: Apollo.QueryHookOptions<UpfrontEmailValidationQuery, UpfrontEmailValidationQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<UpfrontEmailValidationQuery, UpfrontEmailValidationQueryVariables>(UpfrontEmailValidationDocument, options);
}
export function useUpfrontEmailValidationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpfrontEmailValidationQuery, UpfrontEmailValidationQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<UpfrontEmailValidationQuery, UpfrontEmailValidationQueryVariables>(UpfrontEmailValidationDocument, options);
}
export type UpfrontEmailValidationQueryHookResult = ReturnType<typeof useUpfrontEmailValidationQuery>;
export type UpfrontEmailValidationLazyQueryHookResult = ReturnType<typeof useUpfrontEmailValidationLazyQuery>;
export type UpfrontEmailValidationQueryResult = Apollo.QueryResult<UpfrontEmailValidationQuery, UpfrontEmailValidationQueryVariables>;
export const GetChatsDocument = gql`
    query getChats($limit: Int, $page: Int) {
  getListOfChats(pagination: {limit: $limit, page: $page}) {
    items {
      ...Chat
    }
    meta {
      ...PaginationFields
    }
  }
}
    ${ChatFragmentDoc}
${PaginationFieldsFragmentDoc}`;

/**
 * __useGetChatsQuery__
 *
 * To run a query within a React component, call `useGetChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetChatsQuery(baseOptions?: Apollo.QueryHookOptions<GetChatsQuery, GetChatsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetChatsQuery, GetChatsQueryVariables>(GetChatsDocument, options);
}
export function useGetChatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChatsQuery, GetChatsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetChatsQuery, GetChatsQueryVariables>(GetChatsDocument, options);
}
export type GetChatsQueryHookResult = ReturnType<typeof useGetChatsQuery>;
export type GetChatsLazyQueryHookResult = ReturnType<typeof useGetChatsLazyQuery>;
export type GetChatsQueryResult = Apollo.QueryResult<GetChatsQuery, GetChatsQueryVariables>;
export const GetChatMessagesDocument = gql`
    query getChatMessages($chatId: Int!, $limit: Int, $page: Int) {
  getListOfMessages(chatId: $chatId, pagination: {limit: $limit, page: $page}) {
    items {
      ...ChatMessage
    }
    meta {
      ...PaginationFields
    }
  }
}
    ${ChatMessageFragmentDoc}
${PaginationFieldsFragmentDoc}`;

/**
 * __useGetChatMessagesQuery__
 *
 * To run a query within a React component, call `useGetChatMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatMessagesQuery({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetChatMessagesQuery(baseOptions: Apollo.QueryHookOptions<GetChatMessagesQuery, GetChatMessagesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetChatMessagesQuery, GetChatMessagesQueryVariables>(GetChatMessagesDocument, options);
}
export function useGetChatMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChatMessagesQuery, GetChatMessagesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetChatMessagesQuery, GetChatMessagesQueryVariables>(GetChatMessagesDocument, options);
}
export type GetChatMessagesQueryHookResult = ReturnType<typeof useGetChatMessagesQuery>;
export type GetChatMessagesLazyQueryHookResult = ReturnType<typeof useGetChatMessagesLazyQuery>;
export type GetChatMessagesQueryResult = Apollo.QueryResult<GetChatMessagesQuery, GetChatMessagesQueryVariables>;
export const HasUnreadChatsDocument = gql`
    query hasUnreadChats {
  hasUnreadChats {
    countOfUnreadChats
  }
}
    `;

/**
 * __useHasUnreadChatsQuery__
 *
 * To run a query within a React component, call `useHasUnreadChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasUnreadChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasUnreadChatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasUnreadChatsQuery(baseOptions?: Apollo.QueryHookOptions<HasUnreadChatsQuery, HasUnreadChatsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<HasUnreadChatsQuery, HasUnreadChatsQueryVariables>(HasUnreadChatsDocument, options);
}
export function useHasUnreadChatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasUnreadChatsQuery, HasUnreadChatsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<HasUnreadChatsQuery, HasUnreadChatsQueryVariables>(HasUnreadChatsDocument, options);
}
export type HasUnreadChatsQueryHookResult = ReturnType<typeof useHasUnreadChatsQuery>;
export type HasUnreadChatsLazyQueryHookResult = ReturnType<typeof useHasUnreadChatsLazyQuery>;
export type HasUnreadChatsQueryResult = Apollo.QueryResult<HasUnreadChatsQuery, HasUnreadChatsQueryVariables>;
export const GetChatByMembersDocument = gql`
    query getChatByMembers($members: [Int!]!) {
  getChatByMembers(members: $members) {
    ...Chat
  }
}
    ${ChatFragmentDoc}`;

/**
 * __useGetChatByMembersQuery__
 *
 * To run a query within a React component, call `useGetChatByMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatByMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatByMembersQuery({
 *   variables: {
 *      members: // value for 'members'
 *   },
 * });
 */
export function useGetChatByMembersQuery(baseOptions: Apollo.QueryHookOptions<GetChatByMembersQuery, GetChatByMembersQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetChatByMembersQuery, GetChatByMembersQueryVariables>(GetChatByMembersDocument, options);
}
export function useGetChatByMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChatByMembersQuery, GetChatByMembersQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetChatByMembersQuery, GetChatByMembersQueryVariables>(GetChatByMembersDocument, options);
}
export type GetChatByMembersQueryHookResult = ReturnType<typeof useGetChatByMembersQuery>;
export type GetChatByMembersLazyQueryHookResult = ReturnType<typeof useGetChatByMembersLazyQuery>;
export type GetChatByMembersQueryResult = Apollo.QueryResult<GetChatByMembersQuery, GetChatByMembersQueryVariables>;
export const CreateChatDocument = gql`
    mutation createChat($members: [Int!]!) {
  createChat(members: $members) {
    ...Chat
  }
}
    ${ChatFragmentDoc}`;
export type CreateChatMutationFn = Apollo.MutationFunction<CreateChatMutation, CreateChatMutationVariables>;

/**
 * __useCreateChatMutation__
 *
 * To run a mutation, you first call `useCreateChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatMutation, { data, loading, error }] = useCreateChatMutation({
 *   variables: {
 *      members: // value for 'members'
 *   },
 * });
 */
export function useCreateChatMutation(baseOptions?: Apollo.MutationHookOptions<CreateChatMutation, CreateChatMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<CreateChatMutation, CreateChatMutationVariables>(CreateChatDocument, options);
}
export type CreateChatMutationHookResult = ReturnType<typeof useCreateChatMutation>;
export type CreateChatMutationResult = Apollo.MutationResult<CreateChatMutation>;
export type CreateChatMutationOptions = Apollo.BaseMutationOptions<CreateChatMutation, CreateChatMutationVariables>;
export const CreateMessageDocument = gql`
    mutation createMessage($chatId: Int!, $text: String!) {
  createMessage(chatId: $chatId, text: $text) {
    ...ChatMessage
  }
}
    ${ChatMessageFragmentDoc}`;
export type CreateMessageMutationFn = Apollo.MutationFunction<CreateMessageMutation, CreateMessageMutationVariables>;

/**
 * __useCreateMessageMutation__
 *
 * To run a mutation, you first call `useCreateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageMutation, { data, loading, error }] = useCreateMessageMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useCreateMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateMessageMutation, CreateMessageMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<CreateMessageMutation, CreateMessageMutationVariables>(CreateMessageDocument, options);
}
export type CreateMessageMutationHookResult = ReturnType<typeof useCreateMessageMutation>;
export type CreateMessageMutationResult = Apollo.MutationResult<CreateMessageMutation>;
export type CreateMessageMutationOptions = Apollo.BaseMutationOptions<CreateMessageMutation, CreateMessageMutationVariables>;
export const MarkMessageAsReadDocument = gql`
    mutation markMessageAsRead($id: Int!) {
  markMessageAsRead(id: $id) {
    success
  }
}
    `;
export type MarkMessageAsReadMutationFn = Apollo.MutationFunction<MarkMessageAsReadMutation, MarkMessageAsReadMutationVariables>;

/**
 * __useMarkMessageAsReadMutation__
 *
 * To run a mutation, you first call `useMarkMessageAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkMessageAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markMessageAsReadMutation, { data, loading, error }] = useMarkMessageAsReadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkMessageAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkMessageAsReadMutation, MarkMessageAsReadMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<MarkMessageAsReadMutation, MarkMessageAsReadMutationVariables>(MarkMessageAsReadDocument, options);
}
export type MarkMessageAsReadMutationHookResult = ReturnType<typeof useMarkMessageAsReadMutation>;
export type MarkMessageAsReadMutationResult = Apollo.MutationResult<MarkMessageAsReadMutation>;
export type MarkMessageAsReadMutationOptions = Apollo.BaseMutationOptions<MarkMessageAsReadMutation, MarkMessageAsReadMutationVariables>;
export const ChatNotificationDocument = gql`
    subscription chatNotification {
  chatNotificationUpdated {
    countOfUnreadChats
  }
}
    `;

/**
 * __useChatNotificationSubscription__
 *
 * To run a query within a React component, call `useChatNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatNotificationSubscription({
 *   variables: {
 *   },
 * });
 */
export function useChatNotificationSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ChatNotificationSubscription, ChatNotificationSubscriptionVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useSubscription<ChatNotificationSubscription, ChatNotificationSubscriptionVariables>(ChatNotificationDocument, options);
}
export type ChatNotificationSubscriptionHookResult = ReturnType<typeof useChatNotificationSubscription>;
export type ChatNotificationSubscriptionResult = Apollo.SubscriptionResult<ChatNotificationSubscription>;
export const ChatUpdatedDocument = gql`
    subscription chatUpdated {
  chatUpdated {
    ...Chat
  }
}
    ${ChatFragmentDoc}`;

/**
 * __useChatUpdatedSubscription__
 *
 * To run a query within a React component, call `useChatUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useChatUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ChatUpdatedSubscription, ChatUpdatedSubscriptionVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useSubscription<ChatUpdatedSubscription, ChatUpdatedSubscriptionVariables>(ChatUpdatedDocument, options);
}
export type ChatUpdatedSubscriptionHookResult = ReturnType<typeof useChatUpdatedSubscription>;
export type ChatUpdatedSubscriptionResult = Apollo.SubscriptionResult<ChatUpdatedSubscription>;
export const ChatMessageSentDocument = gql`
    subscription chatMessageSent($chatId: Int!) {
  messageSent(chatId: $chatId) {
    ...ChatMessage
  }
}
    ${ChatMessageFragmentDoc}`;

/**
 * __useChatMessageSentSubscription__
 *
 * To run a query within a React component, call `useChatMessageSentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatMessageSentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatMessageSentSubscription({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useChatMessageSentSubscription(baseOptions: Apollo.SubscriptionHookOptions<ChatMessageSentSubscription, ChatMessageSentSubscriptionVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useSubscription<ChatMessageSentSubscription, ChatMessageSentSubscriptionVariables>(ChatMessageSentDocument, options);
}
export type ChatMessageSentSubscriptionHookResult = ReturnType<typeof useChatMessageSentSubscription>;
export type ChatMessageSentSubscriptionResult = Apollo.SubscriptionResult<ChatMessageSentSubscription>;
export const ProfileStatusChangedDocument = gql`
    subscription profileStatusChanged {
  profileStatusChanged {
    isOnline
    profileId
  }
}
    `;

/**
 * __useProfileStatusChangedSubscription__
 *
 * To run a query within a React component, call `useProfileStatusChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProfileStatusChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileStatusChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useProfileStatusChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ProfileStatusChangedSubscription, ProfileStatusChangedSubscriptionVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useSubscription<ProfileStatusChangedSubscription, ProfileStatusChangedSubscriptionVariables>(ProfileStatusChangedDocument, options);
}
export type ProfileStatusChangedSubscriptionHookResult = ReturnType<typeof useProfileStatusChangedSubscription>;
export type ProfileStatusChangedSubscriptionResult = Apollo.SubscriptionResult<ProfileStatusChangedSubscription>;
export const FindTalentDocument = gql`
    query findTalent($talentId: Float!, $poolId: Float, $jobId: Int, $hhPoolId: Int, $withMatchingScore: Boolean! = false) {
  findTalent(talentId: $talentId, headhunterPoolId: $hhPoolId, jobId: $jobId) {
    id
    cv {
      link
      originName
      summary
    }
    ...FindTalentsFavoriteField
    contactMethod {
      id
      value
    }
    profile {
      id
      fullName
      location
      picture
      email
      telephone
    }
    jobPreference {
      attendances {
        id
        value
      }
      availability {
        id
        value
      }
      jobTypes {
        id
        value
      }
      relocation {
        id
        value
        countries
      }
    }
    matchingScore(talentId: $talentId, headhunterPoolId: $poolId) @include(if: $withMatchingScore)
    comparisonScore {
      ...TalentComparisonFields
      average {
        educations
        experiences
        expertises
        skills
        competencies
      }
      talent {
        badge
        competencies
        educations
        experiences
        expertises
        topQualities {
          questionName
          questionType
        }
      }
    }
    competencies {
      attempts
      duration
      items {
        ...AssessmentResultFields
      }
    }
    biography {
      educations {
        ...EducationFields
      }
      experiences {
        ...ExperienceFields
      }
      certificates {
        ...CertificatesFullFields
      }
      expertises {
        ...ExpertiseFullFields
      }
      itSkills {
        ...ItSkillsFullFields
      }
      skills {
        ...SkillsFullFields
      }
      languages {
        ...LanguageFullFields
      }
    }
  }
}
    ${FindTalentsFavoriteFieldFragmentDoc}
${TalentComparisonFieldsFragmentDoc}
${AssessmentResultFieldsFragmentDoc}
${EducationFieldsFragmentDoc}
${ExperienceFieldsFragmentDoc}
${CertificatesFullFieldsFragmentDoc}
${ExpertiseFullFieldsFragmentDoc}
${ItSkillsFullFieldsFragmentDoc}
${SkillsFullFieldsFragmentDoc}
${LanguageFullFieldsFragmentDoc}`;

/**
 * __useFindTalentQuery__
 *
 * To run a query within a React component, call `useFindTalentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindTalentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindTalentQuery({
 *   variables: {
 *      talentId: // value for 'talentId'
 *      poolId: // value for 'poolId'
 *      jobId: // value for 'jobId'
 *      hhPoolId: // value for 'hhPoolId'
 *      withMatchingScore: // value for 'withMatchingScore'
 *   },
 * });
 */
export function useFindTalentQuery(baseOptions: Apollo.QueryHookOptions<FindTalentQuery, FindTalentQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindTalentQuery, FindTalentQueryVariables>(FindTalentDocument, options);
}
export function useFindTalentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindTalentQuery, FindTalentQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindTalentQuery, FindTalentQueryVariables>(FindTalentDocument, options);
}
export type FindTalentQueryHookResult = ReturnType<typeof useFindTalentQuery>;
export type FindTalentLazyQueryHookResult = ReturnType<typeof useFindTalentLazyQuery>;
export type FindTalentQueryResult = Apollo.QueryResult<FindTalentQuery, FindTalentQueryVariables>;
export const GetTalentPercentileDocument = gql`
    query getTalentPercentile($talentId: Float!, $hhPoolId: Int) {
  getTalentPercentile(talentId: $talentId, headhunterPoolId: $hhPoolId) {
    numberOfTalents
    percentile
    occupation {
      id
      name
    }
  }
}
    `;

/**
 * __useGetTalentPercentileQuery__
 *
 * To run a query within a React component, call `useGetTalentPercentileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentPercentileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentPercentileQuery({
 *   variables: {
 *      talentId: // value for 'talentId'
 *      hhPoolId: // value for 'hhPoolId'
 *   },
 * });
 */
export function useGetTalentPercentileQuery(baseOptions: Apollo.QueryHookOptions<GetTalentPercentileQuery, GetTalentPercentileQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetTalentPercentileQuery, GetTalentPercentileQueryVariables>(GetTalentPercentileDocument, options);
}
export function useGetTalentPercentileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTalentPercentileQuery, GetTalentPercentileQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetTalentPercentileQuery, GetTalentPercentileQueryVariables>(GetTalentPercentileDocument, options);
}
export type GetTalentPercentileQueryHookResult = ReturnType<typeof useGetTalentPercentileQuery>;
export type GetTalentPercentileLazyQueryHookResult = ReturnType<typeof useGetTalentPercentileLazyQuery>;
export type GetTalentPercentileQueryResult = Apollo.QueryResult<GetTalentPercentileQuery, GetTalentPercentileQueryVariables>;
export const GetSimilarTalentsDocument = gql`
    query getSimilarTalents($talentId: Int!, $poolId: Int!) {
  getBestTalents(talentId: $talentId, headhunterPoolId: $poolId) {
    headhunterPool {
      name
    }
    items {
      profile {
        picture
        fullName
      }
      score
      badge
      talentId
    }
  }
}
    `;

/**
 * __useGetSimilarTalentsQuery__
 *
 * To run a query within a React component, call `useGetSimilarTalentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSimilarTalentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimilarTalentsQuery({
 *   variables: {
 *      talentId: // value for 'talentId'
 *      poolId: // value for 'poolId'
 *   },
 * });
 */
export function useGetSimilarTalentsQuery(baseOptions: Apollo.QueryHookOptions<GetSimilarTalentsQuery, GetSimilarTalentsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetSimilarTalentsQuery, GetSimilarTalentsQueryVariables>(GetSimilarTalentsDocument, options);
}
export function useGetSimilarTalentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSimilarTalentsQuery, GetSimilarTalentsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetSimilarTalentsQuery, GetSimilarTalentsQueryVariables>(GetSimilarTalentsDocument, options);
}
export type GetSimilarTalentsQueryHookResult = ReturnType<typeof useGetSimilarTalentsQuery>;
export type GetSimilarTalentsLazyQueryHookResult = ReturnType<typeof useGetSimilarTalentsLazyQuery>;
export type GetSimilarTalentsQueryResult = Apollo.QueryResult<GetSimilarTalentsQuery, GetSimilarTalentsQueryVariables>;
export const GetTalentNotesDocument = gql`
    query getTalentNotes($talentId: Int!, $limit: Int, $page: Int) {
  getEnterpriseNoteByTalent(
    pagination: {limit: $limit, page: $page}
    talentId: $talentId
  ) {
    meta {
      ...PaginationFields
    }
    items {
      ...EnterpriseTalentNoteFields
    }
  }
}
    ${PaginationFieldsFragmentDoc}
${EnterpriseTalentNoteFieldsFragmentDoc}`;

/**
 * __useGetTalentNotesQuery__
 *
 * To run a query within a React component, call `useGetTalentNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentNotesQuery({
 *   variables: {
 *      talentId: // value for 'talentId'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetTalentNotesQuery(baseOptions: Apollo.QueryHookOptions<GetTalentNotesQuery, GetTalentNotesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetTalentNotesQuery, GetTalentNotesQueryVariables>(GetTalentNotesDocument, options);
}
export function useGetTalentNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTalentNotesQuery, GetTalentNotesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetTalentNotesQuery, GetTalentNotesQueryVariables>(GetTalentNotesDocument, options);
}
export type GetTalentNotesQueryHookResult = ReturnType<typeof useGetTalentNotesQuery>;
export type GetTalentNotesLazyQueryHookResult = ReturnType<typeof useGetTalentNotesLazyQuery>;
export type GetTalentNotesQueryResult = Apollo.QueryResult<GetTalentNotesQuery, GetTalentNotesQueryVariables>;
export const SaveTalentNoteDocument = gql`
    mutation saveTalentNote($talentId: Int!, $text: String!) {
  addEnterpriseNoteToTalent(text: $text, talentId: $talentId) {
    success
  }
}
    `;
export type SaveTalentNoteMutationFn = Apollo.MutationFunction<SaveTalentNoteMutation, SaveTalentNoteMutationVariables>;

/**
 * __useSaveTalentNoteMutation__
 *
 * To run a mutation, you first call `useSaveTalentNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTalentNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTalentNoteMutation, { data, loading, error }] = useSaveTalentNoteMutation({
 *   variables: {
 *      talentId: // value for 'talentId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useSaveTalentNoteMutation(baseOptions?: Apollo.MutationHookOptions<SaveTalentNoteMutation, SaveTalentNoteMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<SaveTalentNoteMutation, SaveTalentNoteMutationVariables>(SaveTalentNoteDocument, options);
}
export type SaveTalentNoteMutationHookResult = ReturnType<typeof useSaveTalentNoteMutation>;
export type SaveTalentNoteMutationResult = Apollo.MutationResult<SaveTalentNoteMutation>;
export type SaveTalentNoteMutationOptions = Apollo.BaseMutationOptions<SaveTalentNoteMutation, SaveTalentNoteMutationVariables>;
export const AddTalentToFavoritesDocument = gql`
    mutation AddTalentToFavorites($talentId: Int!) {
  addTalentToFavorites(talentId: $talentId) {
    success
  }
}
    `;
export type AddTalentToFavoritesMutationFn = Apollo.MutationFunction<AddTalentToFavoritesMutation, AddTalentToFavoritesMutationVariables>;

/**
 * __useAddTalentToFavoritesMutation__
 *
 * To run a mutation, you first call `useAddTalentToFavoritesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTalentToFavoritesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTalentToFavoritesMutation, { data, loading, error }] = useAddTalentToFavoritesMutation({
 *   variables: {
 *      talentId: // value for 'talentId'
 *   },
 * });
 */
export function useAddTalentToFavoritesMutation(baseOptions?: Apollo.MutationHookOptions<AddTalentToFavoritesMutation, AddTalentToFavoritesMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<AddTalentToFavoritesMutation, AddTalentToFavoritesMutationVariables>(AddTalentToFavoritesDocument, options);
}
export type AddTalentToFavoritesMutationHookResult = ReturnType<typeof useAddTalentToFavoritesMutation>;
export type AddTalentToFavoritesMutationResult = Apollo.MutationResult<AddTalentToFavoritesMutation>;
export type AddTalentToFavoritesMutationOptions = Apollo.BaseMutationOptions<AddTalentToFavoritesMutation, AddTalentToFavoritesMutationVariables>;
export const RemoveTalentFromFavoritesDocument = gql`
    mutation RemoveTalentFromFavorites($talentId: Int!) {
  deleteTalentFromFavorites(talentId: $talentId) {
    success
  }
}
    `;
export type RemoveTalentFromFavoritesMutationFn = Apollo.MutationFunction<RemoveTalentFromFavoritesMutation, RemoveTalentFromFavoritesMutationVariables>;

/**
 * __useRemoveTalentFromFavoritesMutation__
 *
 * To run a mutation, you first call `useRemoveTalentFromFavoritesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTalentFromFavoritesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTalentFromFavoritesMutation, { data, loading, error }] = useRemoveTalentFromFavoritesMutation({
 *   variables: {
 *      talentId: // value for 'talentId'
 *   },
 * });
 */
export function useRemoveTalentFromFavoritesMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTalentFromFavoritesMutation, RemoveTalentFromFavoritesMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<RemoveTalentFromFavoritesMutation, RemoveTalentFromFavoritesMutationVariables>(RemoveTalentFromFavoritesDocument, options);
}
export type RemoveTalentFromFavoritesMutationHookResult = ReturnType<typeof useRemoveTalentFromFavoritesMutation>;
export type RemoveTalentFromFavoritesMutationResult = Apollo.MutationResult<RemoveTalentFromFavoritesMutation>;
export type RemoveTalentFromFavoritesMutationOptions = Apollo.BaseMutationOptions<RemoveTalentFromFavoritesMutation, RemoveTalentFromFavoritesMutationVariables>;
export const FindTalentsDocument = gql`
    query findTalents($name: String, $ids: [Float!], $profileIds: [Float!], $keywords: [SearchKeywordsEnum!], $pagination: PaginationRequest = {}, $byEnterpriseRegion: Boolean, $byEnterpriseCareers: Boolean, $randomOrder: Boolean, $careerId: Float) {
  findTalents(
    name: $name
    keywords: $keywords
    ids: $ids
    join: [EDUCATION, EXPERIENCE, PROFILE, SCORE, TOTAL_YEARS_OF_EXPERIENCE, FAVORITE]
    profileIds: $profileIds
    pagination: $pagination
    onlyOnboarded: true
    byEnterpriseRegion: $byEnterpriseRegion
    byEnterpriseCareers: $byEnterpriseCareers
    randomOrder: $randomOrder
    careerId: $careerId
  ) {
    items {
      id
      yearsOfExperience
      ...FindTalentsFavoriteField
      profile {
        ...TalentSummaryProfileFields
      }
      score {
        badge
      }
      biography {
        experiences {
          ...TalentSummaryExperiencesFields
        }
        educations {
          ...TalentSummaryEducationsFields
        }
      }
    }
    keywords {
      count
      keyword
    }
    meta {
      ...PaginationFields
    }
  }
}
    ${FindTalentsFavoriteFieldFragmentDoc}
${TalentSummaryProfileFieldsFragmentDoc}
${TalentSummaryExperiencesFieldsFragmentDoc}
${TalentSummaryEducationsFieldsFragmentDoc}
${PaginationFieldsFragmentDoc}`;

/**
 * __useFindTalentsQuery__
 *
 * To run a query within a React component, call `useFindTalentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindTalentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindTalentsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      ids: // value for 'ids'
 *      profileIds: // value for 'profileIds'
 *      keywords: // value for 'keywords'
 *      pagination: // value for 'pagination'
 *      byEnterpriseRegion: // value for 'byEnterpriseRegion'
 *      byEnterpriseCareers: // value for 'byEnterpriseCareers'
 *      randomOrder: // value for 'randomOrder'
 *      careerId: // value for 'careerId'
 *   },
 * });
 */
export function useFindTalentsQuery(baseOptions?: Apollo.QueryHookOptions<FindTalentsQuery, FindTalentsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindTalentsQuery, FindTalentsQueryVariables>(FindTalentsDocument, options);
}
export function useFindTalentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindTalentsQuery, FindTalentsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindTalentsQuery, FindTalentsQueryVariables>(FindTalentsDocument, options);
}
export type FindTalentsQueryHookResult = ReturnType<typeof useFindTalentsQuery>;
export type FindTalentsLazyQueryHookResult = ReturnType<typeof useFindTalentsLazyQuery>;
export type FindTalentsQueryResult = Apollo.QueryResult<FindTalentsQuery, FindTalentsQueryVariables>;
export const FindTalentForCompareDocument = gql`
    query findTalentForCompare($talentId: Float!, $poolId: Float!, $hhPoolId: Int) {
  findTalent(talentId: $talentId, headhunterPoolId: $hhPoolId) {
    id
    ...FindTalentsFavoriteField
    profile {
      ...TalentSummaryProfileFields
    }
    matchingScore(talentId: $talentId, headhunterPoolId: $poolId)
    comparisonScore {
      ...TalentComparisonFields
    }
    jobPreference {
      attendances {
        id
        value
      }
      availability {
        id
        value
      }
      jobTypes {
        id
        value
      }
      relocation {
        id
        value
        countries
      }
    }
  }
}
    ${FindTalentsFavoriteFieldFragmentDoc}
${TalentSummaryProfileFieldsFragmentDoc}
${TalentComparisonFieldsFragmentDoc}`;

/**
 * __useFindTalentForCompareQuery__
 *
 * To run a query within a React component, call `useFindTalentForCompareQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindTalentForCompareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindTalentForCompareQuery({
 *   variables: {
 *      talentId: // value for 'talentId'
 *      poolId: // value for 'poolId'
 *      hhPoolId: // value for 'hhPoolId'
 *   },
 * });
 */
export function useFindTalentForCompareQuery(baseOptions: Apollo.QueryHookOptions<FindTalentForCompareQuery, FindTalentForCompareQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindTalentForCompareQuery, FindTalentForCompareQueryVariables>(FindTalentForCompareDocument, options);
}
export function useFindTalentForCompareLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindTalentForCompareQuery, FindTalentForCompareQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindTalentForCompareQuery, FindTalentForCompareQueryVariables>(FindTalentForCompareDocument, options);
}
export type FindTalentForCompareQueryHookResult = ReturnType<typeof useFindTalentForCompareQuery>;
export type FindTalentForCompareLazyQueryHookResult = ReturnType<typeof useFindTalentForCompareLazyQuery>;
export type FindTalentForCompareQueryResult = Apollo.QueryResult<FindTalentForCompareQuery, FindTalentForCompareQueryVariables>;
export const GetHhPoolsDocument = gql`
    query getHHPools {
  getHeadhunterPools {
    items {
      ...HhPoolCardFields
    }
  }
}
    ${HhPoolCardFieldsFragmentDoc}`;

/**
 * __useGetHhPoolsQuery__
 *
 * To run a query within a React component, call `useGetHhPoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHhPoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHhPoolsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHhPoolsQuery(baseOptions?: Apollo.QueryHookOptions<GetHhPoolsQuery, GetHhPoolsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetHhPoolsQuery, GetHhPoolsQueryVariables>(GetHhPoolsDocument, options);
}
export function useGetHhPoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHhPoolsQuery, GetHhPoolsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetHhPoolsQuery, GetHhPoolsQueryVariables>(GetHhPoolsDocument, options);
}
export type GetHhPoolsQueryHookResult = ReturnType<typeof useGetHhPoolsQuery>;
export type GetHhPoolsLazyQueryHookResult = ReturnType<typeof useGetHhPoolsLazyQuery>;
export type GetHhPoolsQueryResult = Apollo.QueryResult<GetHhPoolsQuery, GetHhPoolsQueryVariables>;
export const GetHhPoolBasicDocument = gql`
    query getHHPoolBasic($hhPoolId: Int!) {
  getHeadhunterPool(headhunterPoolId: $hhPoolId) {
    id
    name
    totalTalentsCount
  }
}
    `;

/**
 * __useGetHhPoolBasicQuery__
 *
 * To run a query within a React component, call `useGetHhPoolBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHhPoolBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHhPoolBasicQuery({
 *   variables: {
 *      hhPoolId: // value for 'hhPoolId'
 *   },
 * });
 */
export function useGetHhPoolBasicQuery(baseOptions: Apollo.QueryHookOptions<GetHhPoolBasicQuery, GetHhPoolBasicQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetHhPoolBasicQuery, GetHhPoolBasicQueryVariables>(GetHhPoolBasicDocument, options);
}
export function useGetHhPoolBasicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHhPoolBasicQuery, GetHhPoolBasicQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetHhPoolBasicQuery, GetHhPoolBasicQueryVariables>(GetHhPoolBasicDocument, options);
}
export type GetHhPoolBasicQueryHookResult = ReturnType<typeof useGetHhPoolBasicQuery>;
export type GetHhPoolBasicLazyQueryHookResult = ReturnType<typeof useGetHhPoolBasicLazyQuery>;
export type GetHhPoolBasicQueryResult = Apollo.QueryResult<GetHhPoolBasicQuery, GetHhPoolBasicQueryVariables>;
export const GetFavoriteTalentCountDocument = gql`
    query getFavoriteTalentCount($onlyFavorite: Boolean = true, $pagination: PaginationRequest = {}) {
  findTalents(onlyFavorite: $onlyFavorite, pagination: $pagination) {
    meta {
      totalItems
    }
  }
}
    `;

/**
 * __useGetFavoriteTalentCountQuery__
 *
 * To run a query within a React component, call `useGetFavoriteTalentCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoriteTalentCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoriteTalentCountQuery({
 *   variables: {
 *      onlyFavorite: // value for 'onlyFavorite'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetFavoriteTalentCountQuery(baseOptions?: Apollo.QueryHookOptions<GetFavoriteTalentCountQuery, GetFavoriteTalentCountQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetFavoriteTalentCountQuery, GetFavoriteTalentCountQueryVariables>(GetFavoriteTalentCountDocument, options);
}
export function useGetFavoriteTalentCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFavoriteTalentCountQuery, GetFavoriteTalentCountQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetFavoriteTalentCountQuery, GetFavoriteTalentCountQueryVariables>(GetFavoriteTalentCountDocument, options);
}
export type GetFavoriteTalentCountQueryHookResult = ReturnType<typeof useGetFavoriteTalentCountQuery>;
export type GetFavoriteTalentCountLazyQueryHookResult = ReturnType<typeof useGetFavoriteTalentCountLazyQuery>;
export type GetFavoriteTalentCountQueryResult = Apollo.QueryResult<GetFavoriteTalentCountQuery, GetFavoriteTalentCountQueryVariables>;
export const GetHhPoolFullDocument = gql`
    query getHHPoolFull($hhPoolId: Int!) {
  getHeadhunterPool(headhunterPoolId: $hhPoolId) {
    id
    name
    universities {
      ...UniversityBaseFields
    }
    courses {
      ...CourseFields
    }
    degrees {
      ...DegreeFields
    }
    campuses {
      id
      name
      label @client
      value @client
    }
    currentWork
    mostRecentJobTitle
    jobTitles
    yearsOfExperience
    expertises {
      ...ExpertiseFields
    }
    industries {
      ...IndustryFields
    }
    employers {
      ...EmployerFields
    }
    locations
    relocation
    attendances {
      id
      value
    }
    jobTypes {
      id
      value
    }
    certificates {
      ...CertificatesFields
    }
    itSkills {
      ...ItSkillsFields
    }
    languages {
      ...LanguageFields
    }
    traitsAndCompetencies {
      ...AssessmentQuestionField
    }
    valuesAndMotivations {
      ...AssessmentQuestionField
    }
    skills {
      ...SkillsFields
    }
  }
}
    ${UniversityBaseFieldsFragmentDoc}
${CourseFieldsFragmentDoc}
${DegreeFieldsFragmentDoc}
${ExpertiseFieldsFragmentDoc}
${IndustryFieldsFragmentDoc}
${EmployerFieldsFragmentDoc}
${CertificatesFieldsFragmentDoc}
${ItSkillsFieldsFragmentDoc}
${LanguageFieldsFragmentDoc}
${AssessmentQuestionFieldFragmentDoc}
${SkillsFieldsFragmentDoc}`;

/**
 * __useGetHhPoolFullQuery__
 *
 * To run a query within a React component, call `useGetHhPoolFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHhPoolFullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHhPoolFullQuery({
 *   variables: {
 *      hhPoolId: // value for 'hhPoolId'
 *   },
 * });
 */
export function useGetHhPoolFullQuery(baseOptions: Apollo.QueryHookOptions<GetHhPoolFullQuery, GetHhPoolFullQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetHhPoolFullQuery, GetHhPoolFullQueryVariables>(GetHhPoolFullDocument, options);
}
export function useGetHhPoolFullLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHhPoolFullQuery, GetHhPoolFullQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetHhPoolFullQuery, GetHhPoolFullQueryVariables>(GetHhPoolFullDocument, options);
}
export type GetHhPoolFullQueryHookResult = ReturnType<typeof useGetHhPoolFullQuery>;
export type GetHhPoolFullLazyQueryHookResult = ReturnType<typeof useGetHhPoolFullLazyQuery>;
export type GetHhPoolFullQueryResult = Apollo.QueryResult<GetHhPoolFullQuery, GetHhPoolFullQueryVariables>;
export const GetHhPoolDataDocument = gql`
    query getHHPoolData($hhPoolId: Int!, $filters: HHPoolFilterType! = {}, $pagination: PaginationRequest! = {}, $sort: HhPoolSortType! = {}) {
  getHeadhunterPoolData(
    headhunterPoolId: $hhPoolId
    filters: $filters
    pagination: $pagination
    sort: $sort
  ) {
    talents {
      items {
        id
        yearsOfExperience
        matchingScore
        ...PoolDataFavoriteField
        profile {
          ...TalentSummaryProfileFields
        }
        score {
          ...TalentScoreFields
          badge
        }
        biography {
          experiences {
            ...TalentSummaryExperiencesFields
          }
          educations {
            ...TalentSummaryEducationsFields
            university {
              name
            }
          }
        }
      }
      meta {
        ...PaginationFields
      }
    }
    filters {
      ...PoolFiltersFields
    }
  }
}
    ${PoolDataFavoriteFieldFragmentDoc}
${TalentSummaryProfileFieldsFragmentDoc}
${TalentScoreFieldsFragmentDoc}
${TalentSummaryExperiencesFieldsFragmentDoc}
${TalentSummaryEducationsFieldsFragmentDoc}
${PaginationFieldsFragmentDoc}
${PoolFiltersFieldsFragmentDoc}`;

/**
 * __useGetHhPoolDataQuery__
 *
 * To run a query within a React component, call `useGetHhPoolDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHhPoolDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHhPoolDataQuery({
 *   variables: {
 *      hhPoolId: // value for 'hhPoolId'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetHhPoolDataQuery(baseOptions: Apollo.QueryHookOptions<GetHhPoolDataQuery, GetHhPoolDataQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetHhPoolDataQuery, GetHhPoolDataQueryVariables>(GetHhPoolDataDocument, options);
}
export function useGetHhPoolDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHhPoolDataQuery, GetHhPoolDataQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetHhPoolDataQuery, GetHhPoolDataQueryVariables>(GetHhPoolDataDocument, options);
}
export type GetHhPoolDataQueryHookResult = ReturnType<typeof useGetHhPoolDataQuery>;
export type GetHhPoolDataLazyQueryHookResult = ReturnType<typeof useGetHhPoolDataLazyQuery>;
export type GetHhPoolDataQueryResult = Apollo.QueryResult<GetHhPoolDataQuery, GetHhPoolDataQueryVariables>;
export const GetTalentPoolDataDocument = gql`
    query getTalentPoolData($filters: HHPoolFilterType! = {}, $pagination: PaginationRequest! = {}, $sort: HhPoolSortType! = {}) {
  getTalentPoolData(filters: $filters, pagination: $pagination, sort: $sort) {
    talents {
      items {
        id
        yearsOfExperience
        ...PoolDataFavoriteField
        profile {
          ...TalentSummaryProfileFields
        }
        score {
          badge
        }
        biography {
          experiences {
            ...TalentSummaryExperiencesFields
          }
          educations {
            ...TalentSummaryEducationsFields
            university {
              name
            }
          }
        }
      }
      meta {
        ...PaginationFields
      }
    }
    filters {
      ...PoolFiltersFields
    }
  }
}
    ${PoolDataFavoriteFieldFragmentDoc}
${TalentSummaryProfileFieldsFragmentDoc}
${TalentSummaryExperiencesFieldsFragmentDoc}
${TalentSummaryEducationsFieldsFragmentDoc}
${PaginationFieldsFragmentDoc}
${PoolFiltersFieldsFragmentDoc}`;

/**
 * __useGetTalentPoolDataQuery__
 *
 * To run a query within a React component, call `useGetTalentPoolDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentPoolDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentPoolDataQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetTalentPoolDataQuery(baseOptions?: Apollo.QueryHookOptions<GetTalentPoolDataQuery, GetTalentPoolDataQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetTalentPoolDataQuery, GetTalentPoolDataQueryVariables>(GetTalentPoolDataDocument, options);
}
export function useGetTalentPoolDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTalentPoolDataQuery, GetTalentPoolDataQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetTalentPoolDataQuery, GetTalentPoolDataQueryVariables>(GetTalentPoolDataDocument, options);
}
export type GetTalentPoolDataQueryHookResult = ReturnType<typeof useGetTalentPoolDataQuery>;
export type GetTalentPoolDataLazyQueryHookResult = ReturnType<typeof useGetTalentPoolDataLazyQuery>;
export type GetTalentPoolDataQueryResult = Apollo.QueryResult<GetTalentPoolDataQuery, GetTalentPoolDataQueryVariables>;
export const CreateHhPoolDocument = gql`
    mutation createHHPool($attendances: [AttendanceValueEnum!]!, $campusIds: [Float!], $certificateIds: [Float!], $courseIds: [Float!], $currentWork: Boolean, $degreeIds: [Float!], $employerIds: [Float!], $expertiseIds: [Float!], $industryIds: [Float!], $itSkillIds: [Float!], $jobTitles: [String!], $jobTypes: [JobTypeValueEnum!]!, $languageIds: [Float!], $locations: [String!]!, $mostRecentJobTitle: Boolean, $name: String!, $relocation: Boolean, $skillIds: [Float!], $traitsAndCompetencyIds: [Float!], $universityIds: [Float!], $valuesAndMotivationIds: [Float!], $yearsOfExperience: [ExperienceRangeEnum!]) {
  createHeadhunterPool(
    attendances: $attendances
    campusIds: $campusIds
    certificateIds: $certificateIds
    courseIds: $courseIds
    currentWork: $currentWork
    degreeIds: $degreeIds
    employerIds: $employerIds
    expertiseIds: $expertiseIds
    industryIds: $industryIds
    itSkillIds: $itSkillIds
    jobTitles: $jobTitles
    jobTypes: $jobTypes
    languageIds: $languageIds
    locations: $locations
    mostRecentJobTitle: $mostRecentJobTitle
    name: $name
    relocation: $relocation
    skillIds: $skillIds
    traitsAndCompetencyIds: $traitsAndCompetencyIds
    universityIds: $universityIds
    valuesAndMotivationIds: $valuesAndMotivationIds
    yearsOfExperience: $yearsOfExperience
  ) {
    success
  }
}
    `;
export type CreateHhPoolMutationFn = Apollo.MutationFunction<CreateHhPoolMutation, CreateHhPoolMutationVariables>;

/**
 * __useCreateHhPoolMutation__
 *
 * To run a mutation, you first call `useCreateHhPoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHhPoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHhPoolMutation, { data, loading, error }] = useCreateHhPoolMutation({
 *   variables: {
 *      attendances: // value for 'attendances'
 *      campusIds: // value for 'campusIds'
 *      certificateIds: // value for 'certificateIds'
 *      courseIds: // value for 'courseIds'
 *      currentWork: // value for 'currentWork'
 *      degreeIds: // value for 'degreeIds'
 *      employerIds: // value for 'employerIds'
 *      expertiseIds: // value for 'expertiseIds'
 *      industryIds: // value for 'industryIds'
 *      itSkillIds: // value for 'itSkillIds'
 *      jobTitles: // value for 'jobTitles'
 *      jobTypes: // value for 'jobTypes'
 *      languageIds: // value for 'languageIds'
 *      locations: // value for 'locations'
 *      mostRecentJobTitle: // value for 'mostRecentJobTitle'
 *      name: // value for 'name'
 *      relocation: // value for 'relocation'
 *      skillIds: // value for 'skillIds'
 *      traitsAndCompetencyIds: // value for 'traitsAndCompetencyIds'
 *      universityIds: // value for 'universityIds'
 *      valuesAndMotivationIds: // value for 'valuesAndMotivationIds'
 *      yearsOfExperience: // value for 'yearsOfExperience'
 *   },
 * });
 */
export function useCreateHhPoolMutation(baseOptions?: Apollo.MutationHookOptions<CreateHhPoolMutation, CreateHhPoolMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<CreateHhPoolMutation, CreateHhPoolMutationVariables>(CreateHhPoolDocument, options);
}
export type CreateHhPoolMutationHookResult = ReturnType<typeof useCreateHhPoolMutation>;
export type CreateHhPoolMutationResult = Apollo.MutationResult<CreateHhPoolMutation>;
export type CreateHhPoolMutationOptions = Apollo.BaseMutationOptions<CreateHhPoolMutation, CreateHhPoolMutationVariables>;
export const EditHhPoolDocument = gql`
    mutation editHHPool($id: Float!, $attendances: [AttendanceValueEnum!]!, $campusIds: [Float!], $certificateIds: [Float!], $courseIds: [Float!], $currentWork: Boolean, $degreeIds: [Float!], $employerIds: [Float!], $expertiseIds: [Float!], $industryIds: [Float!], $itSkillIds: [Float!], $jobTitles: [String!], $jobTypes: [JobTypeValueEnum!]!, $languageIds: [Float!], $locations: [String!]!, $mostRecentJobTitle: Boolean, $name: String!, $relocation: Boolean, $skillIds: [Float!], $traitsAndCompetencyIds: [Float!], $universityIds: [Float!], $valuesAndMotivationIds: [Float!], $yearsOfExperience: [ExperienceRangeEnum!]) {
  updateHeadhunterPool(
    id: $id
    attendances: $attendances
    campusIds: $campusIds
    certificateIds: $certificateIds
    courseIds: $courseIds
    currentWork: $currentWork
    degreeIds: $degreeIds
    employerIds: $employerIds
    expertiseIds: $expertiseIds
    industryIds: $industryIds
    itSkillIds: $itSkillIds
    jobTitles: $jobTitles
    jobTypes: $jobTypes
    languageIds: $languageIds
    locations: $locations
    mostRecentJobTitle: $mostRecentJobTitle
    name: $name
    relocation: $relocation
    skillIds: $skillIds
    traitsAndCompetencyIds: $traitsAndCompetencyIds
    universityIds: $universityIds
    valuesAndMotivationIds: $valuesAndMotivationIds
    yearsOfExperience: $yearsOfExperience
  ) {
    success
  }
}
    `;
export type EditHhPoolMutationFn = Apollo.MutationFunction<EditHhPoolMutation, EditHhPoolMutationVariables>;

/**
 * __useEditHhPoolMutation__
 *
 * To run a mutation, you first call `useEditHhPoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditHhPoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editHhPoolMutation, { data, loading, error }] = useEditHhPoolMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attendances: // value for 'attendances'
 *      campusIds: // value for 'campusIds'
 *      certificateIds: // value for 'certificateIds'
 *      courseIds: // value for 'courseIds'
 *      currentWork: // value for 'currentWork'
 *      degreeIds: // value for 'degreeIds'
 *      employerIds: // value for 'employerIds'
 *      expertiseIds: // value for 'expertiseIds'
 *      industryIds: // value for 'industryIds'
 *      itSkillIds: // value for 'itSkillIds'
 *      jobTitles: // value for 'jobTitles'
 *      jobTypes: // value for 'jobTypes'
 *      languageIds: // value for 'languageIds'
 *      locations: // value for 'locations'
 *      mostRecentJobTitle: // value for 'mostRecentJobTitle'
 *      name: // value for 'name'
 *      relocation: // value for 'relocation'
 *      skillIds: // value for 'skillIds'
 *      traitsAndCompetencyIds: // value for 'traitsAndCompetencyIds'
 *      universityIds: // value for 'universityIds'
 *      valuesAndMotivationIds: // value for 'valuesAndMotivationIds'
 *      yearsOfExperience: // value for 'yearsOfExperience'
 *   },
 * });
 */
export function useEditHhPoolMutation(baseOptions?: Apollo.MutationHookOptions<EditHhPoolMutation, EditHhPoolMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<EditHhPoolMutation, EditHhPoolMutationVariables>(EditHhPoolDocument, options);
}
export type EditHhPoolMutationHookResult = ReturnType<typeof useEditHhPoolMutation>;
export type EditHhPoolMutationResult = Apollo.MutationResult<EditHhPoolMutation>;
export type EditHhPoolMutationOptions = Apollo.BaseMutationOptions<EditHhPoolMutation, EditHhPoolMutationVariables>;
export const DeleteHhPoolDocument = gql`
    mutation deleteHHPool($id: Int!) {
  deleteHeadhunterPool(headhunterPoolId: $id) {
    success
  }
}
    `;
export type DeleteHhPoolMutationFn = Apollo.MutationFunction<DeleteHhPoolMutation, DeleteHhPoolMutationVariables>;

/**
 * __useDeleteHhPoolMutation__
 *
 * To run a mutation, you first call `useDeleteHhPoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHhPoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHhPoolMutation, { data, loading, error }] = useDeleteHhPoolMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteHhPoolMutation(baseOptions?: Apollo.MutationHookOptions<DeleteHhPoolMutation, DeleteHhPoolMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<DeleteHhPoolMutation, DeleteHhPoolMutationVariables>(DeleteHhPoolDocument, options);
}
export type DeleteHhPoolMutationHookResult = ReturnType<typeof useDeleteHhPoolMutation>;
export type DeleteHhPoolMutationResult = Apollo.MutationResult<DeleteHhPoolMutation>;
export type DeleteHhPoolMutationOptions = Apollo.BaseMutationOptions<DeleteHhPoolMutation, DeleteHhPoolMutationVariables>;
export const GetAssessmentQuestionsDocument = gql`
    query getAssessmentQuestions {
  getAssessmentQuestions {
    items {
      id
      name
      questionType
    }
  }
}
    `;

/**
 * __useGetAssessmentQuestionsQuery__
 *
 * To run a query within a React component, call `useGetAssessmentQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentQuestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAssessmentQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAssessmentQuestionsQuery, GetAssessmentQuestionsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetAssessmentQuestionsQuery, GetAssessmentQuestionsQueryVariables>(GetAssessmentQuestionsDocument, options);
}
export function useGetAssessmentQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssessmentQuestionsQuery, GetAssessmentQuestionsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetAssessmentQuestionsQuery, GetAssessmentQuestionsQueryVariables>(GetAssessmentQuestionsDocument, options);
}
export type GetAssessmentQuestionsQueryHookResult = ReturnType<typeof useGetAssessmentQuestionsQuery>;
export type GetAssessmentQuestionsLazyQueryHookResult = ReturnType<typeof useGetAssessmentQuestionsLazyQuery>;
export type GetAssessmentQuestionsQueryResult = Apollo.QueryResult<GetAssessmentQuestionsQuery, GetAssessmentQuestionsQueryVariables>;
export const HeadhunterUpdatedDocument = gql`
    subscription headhunterUpdated {
  headhunterUpdated {
    id
    totalTalentsCount
    newTalentsCount
    status
  }
}
    `;

/**
 * __useHeadhunterUpdatedSubscription__
 *
 * To run a query within a React component, call `useHeadhunterUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useHeadhunterUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeadhunterUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useHeadhunterUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<HeadhunterUpdatedSubscription, HeadhunterUpdatedSubscriptionVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useSubscription<HeadhunterUpdatedSubscription, HeadhunterUpdatedSubscriptionVariables>(HeadhunterUpdatedDocument, options);
}
export type HeadhunterUpdatedSubscriptionHookResult = ReturnType<typeof useHeadhunterUpdatedSubscription>;
export type HeadhunterUpdatedSubscriptionResult = Apollo.SubscriptionResult<HeadhunterUpdatedSubscription>;
export const CreateJobPostDocument = gql`
    mutation createJobPost($title: String!, $description: String!, $attendanceValue: AttendanceValueEnum!, $certificateIds: [Int!], $expertiseIds: [Int!], $industryIds: [Int!], $itSkillIds: [Int!], $jobTypeValue: JobTypeValueEnum!, $location: String!, $occupationId: Int!, $relocation: Boolean!, $yearsOfExperience: [ExperienceRangeEnum!]) {
  createJobPost(
    title: $title
    description: $description
    attendanceValue: $attendanceValue
    certificateIds: $certificateIds
    expertiseIds: $expertiseIds
    industryIds: $industryIds
    itSkillIds: $itSkillIds
    jobTypeValue: $jobTypeValue
    location: $location
    occupationId: $occupationId
    relocation: $relocation
    yearsOfExperience: $yearsOfExperience
  ) {
    success
  }
}
    `;
export type CreateJobPostMutationFn = Apollo.MutationFunction<CreateJobPostMutation, CreateJobPostMutationVariables>;

/**
 * __useCreateJobPostMutation__
 *
 * To run a mutation, you first call `useCreateJobPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobPostMutation, { data, loading, error }] = useCreateJobPostMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      attendanceValue: // value for 'attendanceValue'
 *      certificateIds: // value for 'certificateIds'
 *      expertiseIds: // value for 'expertiseIds'
 *      industryIds: // value for 'industryIds'
 *      itSkillIds: // value for 'itSkillIds'
 *      jobTypeValue: // value for 'jobTypeValue'
 *      location: // value for 'location'
 *      occupationId: // value for 'occupationId'
 *      relocation: // value for 'relocation'
 *      yearsOfExperience: // value for 'yearsOfExperience'
 *   },
 * });
 */
export function useCreateJobPostMutation(baseOptions?: Apollo.MutationHookOptions<CreateJobPostMutation, CreateJobPostMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<CreateJobPostMutation, CreateJobPostMutationVariables>(CreateJobPostDocument, options);
}
export type CreateJobPostMutationHookResult = ReturnType<typeof useCreateJobPostMutation>;
export type CreateJobPostMutationResult = Apollo.MutationResult<CreateJobPostMutation>;
export type CreateJobPostMutationOptions = Apollo.BaseMutationOptions<CreateJobPostMutation, CreateJobPostMutationVariables>;
export const EditJobPostDocument = gql`
    mutation editJobPost($id: Int!, $title: String!, $description: String!, $attendanceValue: AttendanceValueEnum!, $certificateIds: [Int!], $expertiseIds: [Int!], $industryIds: [Int!], $itSkillIds: [Int!], $jobTypeValue: JobTypeValueEnum!, $location: String!, $occupationId: Int!, $relocation: Boolean!, $yearsOfExperience: [ExperienceRangeEnum!]) {
  updateJobPost(
    id: $id
    title: $title
    description: $description
    attendanceValue: $attendanceValue
    certificateIds: $certificateIds
    expertiseIds: $expertiseIds
    industryIds: $industryIds
    itSkillIds: $itSkillIds
    jobTypeValue: $jobTypeValue
    location: $location
    occupationId: $occupationId
    relocation: $relocation
    yearsOfExperience: $yearsOfExperience
  ) {
    success
  }
}
    `;
export type EditJobPostMutationFn = Apollo.MutationFunction<EditJobPostMutation, EditJobPostMutationVariables>;

/**
 * __useEditJobPostMutation__
 *
 * To run a mutation, you first call `useEditJobPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditJobPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editJobPostMutation, { data, loading, error }] = useEditJobPostMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      attendanceValue: // value for 'attendanceValue'
 *      certificateIds: // value for 'certificateIds'
 *      expertiseIds: // value for 'expertiseIds'
 *      industryIds: // value for 'industryIds'
 *      itSkillIds: // value for 'itSkillIds'
 *      jobTypeValue: // value for 'jobTypeValue'
 *      location: // value for 'location'
 *      occupationId: // value for 'occupationId'
 *      relocation: // value for 'relocation'
 *      yearsOfExperience: // value for 'yearsOfExperience'
 *   },
 * });
 */
export function useEditJobPostMutation(baseOptions?: Apollo.MutationHookOptions<EditJobPostMutation, EditJobPostMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<EditJobPostMutation, EditJobPostMutationVariables>(EditJobPostDocument, options);
}
export type EditJobPostMutationHookResult = ReturnType<typeof useEditJobPostMutation>;
export type EditJobPostMutationResult = Apollo.MutationResult<EditJobPostMutation>;
export type EditJobPostMutationOptions = Apollo.BaseMutationOptions<EditJobPostMutation, EditJobPostMutationVariables>;
export const SwitchJobPostStateDocument = gql`
    mutation switchJobPostState($id: Int!) {
  switchJobPostState(id: $id) {
    success
  }
}
    `;
export type SwitchJobPostStateMutationFn = Apollo.MutationFunction<SwitchJobPostStateMutation, SwitchJobPostStateMutationVariables>;

/**
 * __useSwitchJobPostStateMutation__
 *
 * To run a mutation, you first call `useSwitchJobPostStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchJobPostStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchJobPostStateMutation, { data, loading, error }] = useSwitchJobPostStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSwitchJobPostStateMutation(baseOptions?: Apollo.MutationHookOptions<SwitchJobPostStateMutation, SwitchJobPostStateMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<SwitchJobPostStateMutation, SwitchJobPostStateMutationVariables>(SwitchJobPostStateDocument, options);
}
export type SwitchJobPostStateMutationHookResult = ReturnType<typeof useSwitchJobPostStateMutation>;
export type SwitchJobPostStateMutationResult = Apollo.MutationResult<SwitchJobPostStateMutation>;
export type SwitchJobPostStateMutationOptions = Apollo.BaseMutationOptions<SwitchJobPostStateMutation, SwitchJobPostStateMutationVariables>;
export const ApplyJobPostDocument = gql`
    mutation applyJobPost($jobId: Int!) {
  applyJobPost(jobId: $jobId) {
    success
  }
}
    `;
export type ApplyJobPostMutationFn = Apollo.MutationFunction<ApplyJobPostMutation, ApplyJobPostMutationVariables>;

/**
 * __useApplyJobPostMutation__
 *
 * To run a mutation, you first call `useApplyJobPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyJobPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyJobPostMutation, { data, loading, error }] = useApplyJobPostMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useApplyJobPostMutation(baseOptions?: Apollo.MutationHookOptions<ApplyJobPostMutation, ApplyJobPostMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<ApplyJobPostMutation, ApplyJobPostMutationVariables>(ApplyJobPostDocument, options);
}
export type ApplyJobPostMutationHookResult = ReturnType<typeof useApplyJobPostMutation>;
export type ApplyJobPostMutationResult = Apollo.MutationResult<ApplyJobPostMutation>;
export type ApplyJobPostMutationOptions = Apollo.BaseMutationOptions<ApplyJobPostMutation, ApplyJobPostMutationVariables>;
export const FindJobPostsDocument = gql`
    query findJobPosts($join: [JoinJobFieldEnum!] = [], $orderBy: [String!], $statuses: [JobStatusEnum!], $limit: Int, $page: Int, $onlyApplied: Boolean = false) {
  findJobPosts(
    join: $join
    orderBy: $orderBy
    pagination: {limit: $limit, page: $page}
    statuses: $statuses
    onlyApplied: $onlyApplied
  ) {
    items {
      id
      title
      applicants
      status
      postedAt
    }
    meta {
      ...PaginationFields
    }
  }
}
    ${PaginationFieldsFragmentDoc}`;

/**
 * __useFindJobPostsQuery__
 *
 * To run a query within a React component, call `useFindJobPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindJobPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindJobPostsQuery({
 *   variables: {
 *      join: // value for 'join'
 *      orderBy: // value for 'orderBy'
 *      statuses: // value for 'statuses'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      onlyApplied: // value for 'onlyApplied'
 *   },
 * });
 */
export function useFindJobPostsQuery(baseOptions?: Apollo.QueryHookOptions<FindJobPostsQuery, FindJobPostsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindJobPostsQuery, FindJobPostsQueryVariables>(FindJobPostsDocument, options);
}
export function useFindJobPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindJobPostsQuery, FindJobPostsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindJobPostsQuery, FindJobPostsQueryVariables>(FindJobPostsDocument, options);
}
export type FindJobPostsQueryHookResult = ReturnType<typeof useFindJobPostsQuery>;
export type FindJobPostsLazyQueryHookResult = ReturnType<typeof useFindJobPostsLazyQuery>;
export type FindJobPostsQueryResult = Apollo.QueryResult<FindJobPostsQuery, FindJobPostsQueryVariables>;
export const FindJobPostForEditPageDocument = gql`
    query findJobPostForEditPage($jobPostId: Int) {
  findJobPost(id: $jobPostId) {
    id
    title
    description
    attendance {
      id
      value
    }
    certificates {
      ...CertificatesFields
    }
    expertises {
      ...ExpertiseFields
    }
    industries {
      ...IndustryFields
    }
    itSkills {
      ...ItSkillsFields
    }
    jobType {
      id
      value
    }
    location
    occupation {
      ...OccupationFragment
    }
    relocation
    status
    statusReason
    yearsOfExperience
  }
}
    ${CertificatesFieldsFragmentDoc}
${ExpertiseFieldsFragmentDoc}
${IndustryFieldsFragmentDoc}
${ItSkillsFieldsFragmentDoc}
${OccupationFragmentFragmentDoc}`;

/**
 * __useFindJobPostForEditPageQuery__
 *
 * To run a query within a React component, call `useFindJobPostForEditPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindJobPostForEditPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindJobPostForEditPageQuery({
 *   variables: {
 *      jobPostId: // value for 'jobPostId'
 *   },
 * });
 */
export function useFindJobPostForEditPageQuery(baseOptions?: Apollo.QueryHookOptions<FindJobPostForEditPageQuery, FindJobPostForEditPageQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindJobPostForEditPageQuery, FindJobPostForEditPageQueryVariables>(FindJobPostForEditPageDocument, options);
}
export function useFindJobPostForEditPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindJobPostForEditPageQuery, FindJobPostForEditPageQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindJobPostForEditPageQuery, FindJobPostForEditPageQueryVariables>(FindJobPostForEditPageDocument, options);
}
export type FindJobPostForEditPageQueryHookResult = ReturnType<typeof useFindJobPostForEditPageQuery>;
export type FindJobPostForEditPageLazyQueryHookResult = ReturnType<typeof useFindJobPostForEditPageLazyQuery>;
export type FindJobPostForEditPageQueryResult = Apollo.QueryResult<FindJobPostForEditPageQuery, FindJobPostForEditPageQueryVariables>;
export const FindJobPostDetailsDocument = gql`
    query findJobPostDetails($jobPostId: Int) {
  findJobPost(id: $jobPostId) {
    id
    title
    description
    applicants
    createdAt
    attendance {
      id
      value
    }
    certificates {
      ...CertificatesFields
    }
    expertises {
      ...ExpertiseFields
    }
    industries {
      ...IndustryFields
    }
    itSkills {
      ...ItSkillsFields
    }
    jobType {
      id
      value
    }
    location
    occupation {
      ...OccupationFragment
    }
    relocation
    status
    statusReason
    yearsOfExperience
  }
}
    ${CertificatesFieldsFragmentDoc}
${ExpertiseFieldsFragmentDoc}
${IndustryFieldsFragmentDoc}
${ItSkillsFieldsFragmentDoc}
${OccupationFragmentFragmentDoc}`;

/**
 * __useFindJobPostDetailsQuery__
 *
 * To run a query within a React component, call `useFindJobPostDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindJobPostDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindJobPostDetailsQuery({
 *   variables: {
 *      jobPostId: // value for 'jobPostId'
 *   },
 * });
 */
export function useFindJobPostDetailsQuery(baseOptions?: Apollo.QueryHookOptions<FindJobPostDetailsQuery, FindJobPostDetailsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindJobPostDetailsQuery, FindJobPostDetailsQueryVariables>(FindJobPostDetailsDocument, options);
}
export function useFindJobPostDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindJobPostDetailsQuery, FindJobPostDetailsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindJobPostDetailsQuery, FindJobPostDetailsQueryVariables>(FindJobPostDetailsDocument, options);
}
export type FindJobPostDetailsQueryHookResult = ReturnType<typeof useFindJobPostDetailsQuery>;
export type FindJobPostDetailsLazyQueryHookResult = ReturnType<typeof useFindJobPostDetailsLazyQuery>;
export type FindJobPostDetailsQueryResult = Apollo.QueryResult<FindJobPostDetailsQuery, FindJobPostDetailsQueryVariables>;
export const FindJobPostTalentsDocument = gql`
    query findJobPostTalents($jobPostId: Int!, $onlyApplied: Boolean = false, $pagination: PaginationRequest = {}, $sort: TalentSortType! = {}) {
  findJobTalents(
    jobId: $jobPostId
    onlyApplied: $onlyApplied
    pagination: $pagination
    sort: $sort
  ) {
    talents {
      items {
        id
        yearsOfExperience
        favorite
        applied
        profile {
          ...TalentSummaryProfileFields
        }
        score {
          badge
        }
        biography {
          experiences {
            ...TalentSummaryExperiencesFields
          }
          educations {
            ...TalentSummaryEducationsFields
            university {
              name
            }
          }
        }
      }
      meta {
        ...PaginationFields
      }
    }
  }
}
    ${TalentSummaryProfileFieldsFragmentDoc}
${TalentSummaryExperiencesFieldsFragmentDoc}
${TalentSummaryEducationsFieldsFragmentDoc}
${PaginationFieldsFragmentDoc}`;

/**
 * __useFindJobPostTalentsQuery__
 *
 * To run a query within a React component, call `useFindJobPostTalentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindJobPostTalentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindJobPostTalentsQuery({
 *   variables: {
 *      jobPostId: // value for 'jobPostId'
 *      onlyApplied: // value for 'onlyApplied'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useFindJobPostTalentsQuery(baseOptions: Apollo.QueryHookOptions<FindJobPostTalentsQuery, FindJobPostTalentsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindJobPostTalentsQuery, FindJobPostTalentsQueryVariables>(FindJobPostTalentsDocument, options);
}
export function useFindJobPostTalentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindJobPostTalentsQuery, FindJobPostTalentsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindJobPostTalentsQuery, FindJobPostTalentsQueryVariables>(FindJobPostTalentsDocument, options);
}
export type FindJobPostTalentsQueryHookResult = ReturnType<typeof useFindJobPostTalentsQuery>;
export type FindJobPostTalentsLazyQueryHookResult = ReturnType<typeof useFindJobPostTalentsLazyQuery>;
export type FindJobPostTalentsQueryResult = Apollo.QueryResult<FindJobPostTalentsQuery, FindJobPostTalentsQueryVariables>;
export const FindJobPostDetailsForTalentsDocument = gql`
    query findJobPostDetailsForTalents($jobPostId: Int) {
  findJobPost(id: $jobPostId) {
    id
    title
    description
    applied
    createdAt
    attendance {
      id
      value
    }
    certificates {
      ...CertificatesFields
    }
    expertises {
      ...ExpertiseFields
    }
    industries {
      ...IndustryFields
    }
    itSkills {
      ...ItSkillsFields
    }
    jobType {
      id
      value
    }
    location
    yearsOfExperience
    enterprise {
      id
      accountType
      details {
        logo
        employer {
          id
          name
          logo
        }
      }
    }
  }
}
    ${CertificatesFieldsFragmentDoc}
${ExpertiseFieldsFragmentDoc}
${IndustryFieldsFragmentDoc}
${ItSkillsFieldsFragmentDoc}`;

/**
 * __useFindJobPostDetailsForTalentsQuery__
 *
 * To run a query within a React component, call `useFindJobPostDetailsForTalentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindJobPostDetailsForTalentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindJobPostDetailsForTalentsQuery({
 *   variables: {
 *      jobPostId: // value for 'jobPostId'
 *   },
 * });
 */
export function useFindJobPostDetailsForTalentsQuery(baseOptions?: Apollo.QueryHookOptions<FindJobPostDetailsForTalentsQuery, FindJobPostDetailsForTalentsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindJobPostDetailsForTalentsQuery, FindJobPostDetailsForTalentsQueryVariables>(FindJobPostDetailsForTalentsDocument, options);
}
export function useFindJobPostDetailsForTalentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindJobPostDetailsForTalentsQuery, FindJobPostDetailsForTalentsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindJobPostDetailsForTalentsQuery, FindJobPostDetailsForTalentsQueryVariables>(FindJobPostDetailsForTalentsDocument, options);
}
export type FindJobPostDetailsForTalentsQueryHookResult = ReturnType<typeof useFindJobPostDetailsForTalentsQuery>;
export type FindJobPostDetailsForTalentsLazyQueryHookResult = ReturnType<typeof useFindJobPostDetailsForTalentsLazyQuery>;
export type FindJobPostDetailsForTalentsQueryResult = Apollo.QueryResult<FindJobPostDetailsForTalentsQuery, FindJobPostDetailsForTalentsQueryVariables>;
export const FindMyJobDetailsForTalentDocument = gql`
    query findMyJobDetailsForTalent($jobPostId: Int) {
  findJobPost(id: $jobPostId) {
    id
    title
    description
    applicants
    viewed
    createdAt
    appliedAt
    attendance {
      id
      value
    }
    certificates {
      ...CertificatesFields
    }
    expertises {
      ...ExpertiseFields
    }
    industries {
      ...IndustryFields
    }
    itSkills {
      ...ItSkillsFields
    }
    jobType {
      id
      value
    }
    location
    occupation {
      ...OccupationFragment
    }
    relocation
    status
    statusReason
    yearsOfExperience
  }
}
    ${CertificatesFieldsFragmentDoc}
${ExpertiseFieldsFragmentDoc}
${IndustryFieldsFragmentDoc}
${ItSkillsFieldsFragmentDoc}
${OccupationFragmentFragmentDoc}`;

/**
 * __useFindMyJobDetailsForTalentQuery__
 *
 * To run a query within a React component, call `useFindMyJobDetailsForTalentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMyJobDetailsForTalentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMyJobDetailsForTalentQuery({
 *   variables: {
 *      jobPostId: // value for 'jobPostId'
 *   },
 * });
 */
export function useFindMyJobDetailsForTalentQuery(baseOptions?: Apollo.QueryHookOptions<FindMyJobDetailsForTalentQuery, FindMyJobDetailsForTalentQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindMyJobDetailsForTalentQuery, FindMyJobDetailsForTalentQueryVariables>(FindMyJobDetailsForTalentDocument, options);
}
export function useFindMyJobDetailsForTalentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindMyJobDetailsForTalentQuery, FindMyJobDetailsForTalentQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindMyJobDetailsForTalentQuery, FindMyJobDetailsForTalentQueryVariables>(FindMyJobDetailsForTalentDocument, options);
}
export type FindMyJobDetailsForTalentQueryHookResult = ReturnType<typeof useFindMyJobDetailsForTalentQuery>;
export type FindMyJobDetailsForTalentLazyQueryHookResult = ReturnType<typeof useFindMyJobDetailsForTalentLazyQuery>;
export type FindMyJobDetailsForTalentQueryResult = Apollo.QueryResult<FindMyJobDetailsForTalentQuery, FindMyJobDetailsForTalentQueryVariables>;
export const SavePaymentMethosDocument = gql`
    mutation savePaymentMethos($addressLine1: String!, $addressLine2: String, $cardBrand: String!, $city: String!, $country: String!, $email: String, $expMonth: Int!, $expYear: Int!, $externalId: String!, $last4: String!, $name: String!, $phone: String, $postalCode: String, $state: String) {
  savePaymentMethod(
    addressLine1: $addressLine1
    addressLine2: $addressLine2
    cardBrand: $cardBrand
    city: $city
    country: $country
    email: $email
    expMonth: $expMonth
    expYear: $expYear
    externalId: $externalId
    last4: $last4
    name: $name
    phone: $phone
    postalCode: $postalCode
    state: $state
  ) {
    ...PaymentMethodField
  }
}
    ${PaymentMethodFieldFragmentDoc}`;
export type SavePaymentMethosMutationFn = Apollo.MutationFunction<SavePaymentMethosMutation, SavePaymentMethosMutationVariables>;

/**
 * __useSavePaymentMethosMutation__
 *
 * To run a mutation, you first call `useSavePaymentMethosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePaymentMethosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePaymentMethosMutation, { data, loading, error }] = useSavePaymentMethosMutation({
 *   variables: {
 *      addressLine1: // value for 'addressLine1'
 *      addressLine2: // value for 'addressLine2'
 *      cardBrand: // value for 'cardBrand'
 *      city: // value for 'city'
 *      country: // value for 'country'
 *      email: // value for 'email'
 *      expMonth: // value for 'expMonth'
 *      expYear: // value for 'expYear'
 *      externalId: // value for 'externalId'
 *      last4: // value for 'last4'
 *      name: // value for 'name'
 *      phone: // value for 'phone'
 *      postalCode: // value for 'postalCode'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useSavePaymentMethosMutation(baseOptions?: Apollo.MutationHookOptions<SavePaymentMethosMutation, SavePaymentMethosMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<SavePaymentMethosMutation, SavePaymentMethosMutationVariables>(SavePaymentMethosDocument, options);
}
export type SavePaymentMethosMutationHookResult = ReturnType<typeof useSavePaymentMethosMutation>;
export type SavePaymentMethosMutationResult = Apollo.MutationResult<SavePaymentMethosMutation>;
export type SavePaymentMethosMutationOptions = Apollo.BaseMutationOptions<SavePaymentMethosMutation, SavePaymentMethosMutationVariables>;
export const RemovePaymentMethodDocument = gql`
    mutation removePaymentMethod($paymentMethodId: Int!) {
  removePaymentMethod(paymentMethodId: $paymentMethodId) {
    success
  }
}
    `;
export type RemovePaymentMethodMutationFn = Apollo.MutationFunction<RemovePaymentMethodMutation, RemovePaymentMethodMutationVariables>;

/**
 * __useRemovePaymentMethodMutation__
 *
 * To run a mutation, you first call `useRemovePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePaymentMethodMutation, { data, loading, error }] = useRemovePaymentMethodMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useRemovePaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<RemovePaymentMethodMutation, RemovePaymentMethodMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<RemovePaymentMethodMutation, RemovePaymentMethodMutationVariables>(RemovePaymentMethodDocument, options);
}
export type RemovePaymentMethodMutationHookResult = ReturnType<typeof useRemovePaymentMethodMutation>;
export type RemovePaymentMethodMutationResult = Apollo.MutationResult<RemovePaymentMethodMutation>;
export type RemovePaymentMethodMutationOptions = Apollo.BaseMutationOptions<RemovePaymentMethodMutation, RemovePaymentMethodMutationVariables>;
export const UpdateDefaultPaymentMethodDocument = gql`
    mutation updateDefaultPaymentMethod($paymentMethodId: Int!) {
  updateDefaultPaymentMethod(paymentMethodId: $paymentMethodId) {
    ...PaymentMethodField
  }
}
    ${PaymentMethodFieldFragmentDoc}`;
export type UpdateDefaultPaymentMethodMutationFn = Apollo.MutationFunction<UpdateDefaultPaymentMethodMutation, UpdateDefaultPaymentMethodMutationVariables>;

/**
 * __useUpdateDefaultPaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultPaymentMethodMutation, { data, loading, error }] = useUpdateDefaultPaymentMethodMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useUpdateDefaultPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDefaultPaymentMethodMutation, UpdateDefaultPaymentMethodMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UpdateDefaultPaymentMethodMutation, UpdateDefaultPaymentMethodMutationVariables>(UpdateDefaultPaymentMethodDocument, options);
}
export type UpdateDefaultPaymentMethodMutationHookResult = ReturnType<typeof useUpdateDefaultPaymentMethodMutation>;
export type UpdateDefaultPaymentMethodMutationResult = Apollo.MutationResult<UpdateDefaultPaymentMethodMutation>;
export type UpdateDefaultPaymentMethodMutationOptions = Apollo.BaseMutationOptions<UpdateDefaultPaymentMethodMutation, UpdateDefaultPaymentMethodMutationVariables>;
export const FindPaymentMethodsDocument = gql`
    query findPaymentMethods($limit: Int, $page: Int) {
  findPaymentMethods(pagination: {limit: $limit, page: $page}) {
    items {
      ...PaymentMethodField
    }
    meta {
      ...PaginationFields
    }
  }
}
    ${PaymentMethodFieldFragmentDoc}
${PaginationFieldsFragmentDoc}`;

/**
 * __useFindPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useFindPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPaymentMethodsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFindPaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<FindPaymentMethodsQuery, FindPaymentMethodsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindPaymentMethodsQuery, FindPaymentMethodsQueryVariables>(FindPaymentMethodsDocument, options);
}
export function useFindPaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindPaymentMethodsQuery, FindPaymentMethodsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindPaymentMethodsQuery, FindPaymentMethodsQueryVariables>(FindPaymentMethodsDocument, options);
}
export type FindPaymentMethodsQueryHookResult = ReturnType<typeof useFindPaymentMethodsQuery>;
export type FindPaymentMethodsLazyQueryHookResult = ReturnType<typeof useFindPaymentMethodsLazyQuery>;
export type FindPaymentMethodsQueryResult = Apollo.QueryResult<FindPaymentMethodsQuery, FindPaymentMethodsQueryVariables>;
export const CreateSubscriptionDocument = gql`
    mutation createSubscription($paymentMethodId: Int, $productId: Int!) {
  createSubscription(paymentMethodId: $paymentMethodId, productId: $productId) {
    id
    availableCredits
    initialCredits
    status
    expireAt
    createdAt
  }
}
    `;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(CreateSubscriptionDocument, options);
}
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>;
export type CreateSubscriptionMutationResult = Apollo.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;
export const SwitchAutoChargeSubscriptionOptionDocument = gql`
    mutation switchAutoChargeSubscriptionOption {
  switchAutoChargeSubscriptionOption {
    ...SubscriptionFields
  }
}
    ${SubscriptionFieldsFragmentDoc}`;
export type SwitchAutoChargeSubscriptionOptionMutationFn = Apollo.MutationFunction<SwitchAutoChargeSubscriptionOptionMutation, SwitchAutoChargeSubscriptionOptionMutationVariables>;

/**
 * __useSwitchAutoChargeSubscriptionOptionMutation__
 *
 * To run a mutation, you first call `useSwitchAutoChargeSubscriptionOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchAutoChargeSubscriptionOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchAutoChargeSubscriptionOptionMutation, { data, loading, error }] = useSwitchAutoChargeSubscriptionOptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useSwitchAutoChargeSubscriptionOptionMutation(baseOptions?: Apollo.MutationHookOptions<SwitchAutoChargeSubscriptionOptionMutation, SwitchAutoChargeSubscriptionOptionMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<SwitchAutoChargeSubscriptionOptionMutation, SwitchAutoChargeSubscriptionOptionMutationVariables>(SwitchAutoChargeSubscriptionOptionDocument, options);
}
export type SwitchAutoChargeSubscriptionOptionMutationHookResult = ReturnType<typeof useSwitchAutoChargeSubscriptionOptionMutation>;
export type SwitchAutoChargeSubscriptionOptionMutationResult = Apollo.MutationResult<SwitchAutoChargeSubscriptionOptionMutation>;
export type SwitchAutoChargeSubscriptionOptionMutationOptions = Apollo.BaseMutationOptions<SwitchAutoChargeSubscriptionOptionMutation, SwitchAutoChargeSubscriptionOptionMutationVariables>;
export const FindSubscriptionProductsDocument = gql`
    query findSubscriptionProducts {
  findSubscriptionProducts {
    items {
      ...SubscriptionProductsField
    }
  }
}
    ${SubscriptionProductsFieldFragmentDoc}`;

/**
 * __useFindSubscriptionProductsQuery__
 *
 * To run a query within a React component, call `useFindSubscriptionProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSubscriptionProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSubscriptionProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindSubscriptionProductsQuery(baseOptions?: Apollo.QueryHookOptions<FindSubscriptionProductsQuery, FindSubscriptionProductsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindSubscriptionProductsQuery, FindSubscriptionProductsQueryVariables>(FindSubscriptionProductsDocument, options);
}
export function useFindSubscriptionProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindSubscriptionProductsQuery, FindSubscriptionProductsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindSubscriptionProductsQuery, FindSubscriptionProductsQueryVariables>(FindSubscriptionProductsDocument, options);
}
export type FindSubscriptionProductsQueryHookResult = ReturnType<typeof useFindSubscriptionProductsQuery>;
export type FindSubscriptionProductsLazyQueryHookResult = ReturnType<typeof useFindSubscriptionProductsLazyQuery>;
export type FindSubscriptionProductsQueryResult = Apollo.QueryResult<FindSubscriptionProductsQuery, FindSubscriptionProductsQueryVariables>;
export const FindSubscriptionDocument = gql`
    query findSubscription($onlyActive: Boolean = true) {
  findSubscription(onlyActive: $onlyActive) {
    ...SubscriptionFields
  }
}
    ${SubscriptionFieldsFragmentDoc}`;

/**
 * __useFindSubscriptionQuery__
 *
 * To run a query within a React component, call `useFindSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSubscriptionQuery({
 *   variables: {
 *      onlyActive: // value for 'onlyActive'
 *   },
 * });
 */
export function useFindSubscriptionQuery(baseOptions?: Apollo.QueryHookOptions<FindSubscriptionQuery, FindSubscriptionQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindSubscriptionQuery, FindSubscriptionQueryVariables>(FindSubscriptionDocument, options);
}
export function useFindSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindSubscriptionQuery, FindSubscriptionQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindSubscriptionQuery, FindSubscriptionQueryVariables>(FindSubscriptionDocument, options);
}
export type FindSubscriptionQueryHookResult = ReturnType<typeof useFindSubscriptionQuery>;
export type FindSubscriptionLazyQueryHookResult = ReturnType<typeof useFindSubscriptionLazyQuery>;
export type FindSubscriptionQueryResult = Apollo.QueryResult<FindSubscriptionQuery, FindSubscriptionQueryVariables>;
export const FindSubscriptionsDocument = gql`
    query findSubscriptions($limit: Int = 1, $page: Int = 1, $orderBy: String = "createdAt DESC", $status: SubscriptionStatusEnum) {
  findSubscriptions(
    pagination: {limit: $limit, page: $page}
    status: $status
    orderBy: $orderBy
  ) {
    items {
      ...SubscriptionFields
    }
    meta {
      ...PaginationFields
    }
  }
}
    ${SubscriptionFieldsFragmentDoc}
${PaginationFieldsFragmentDoc}`;

/**
 * __useFindSubscriptionsQuery__
 *
 * To run a query within a React component, call `useFindSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSubscriptionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      orderBy: // value for 'orderBy'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useFindSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<FindSubscriptionsQuery, FindSubscriptionsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindSubscriptionsQuery, FindSubscriptionsQueryVariables>(FindSubscriptionsDocument, options);
}
export function useFindSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindSubscriptionsQuery, FindSubscriptionsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindSubscriptionsQuery, FindSubscriptionsQueryVariables>(FindSubscriptionsDocument, options);
}
export type FindSubscriptionsQueryHookResult = ReturnType<typeof useFindSubscriptionsQuery>;
export type FindSubscriptionsLazyQueryHookResult = ReturnType<typeof useFindSubscriptionsLazyQuery>;
export type FindSubscriptionsQueryResult = Apollo.QueryResult<FindSubscriptionsQuery, FindSubscriptionsQueryVariables>;
export const FindCreditUsageStatDocument = gql`
    query findCreditUsageStat($limit: Int, $page: Int, $dateRange: [String!]) {
  findCreditUsageStat(
    pagination: {limit: $limit, page: $page}
    dateRange: $dateRange
  ) {
    items {
      id
      createdAt
      creditsBefore
      creditsAfter
      creditsUsed
    }
    meta {
      ...PaginationFields
    }
  }
}
    ${PaginationFieldsFragmentDoc}`;

/**
 * __useFindCreditUsageStatQuery__
 *
 * To run a query within a React component, call `useFindCreditUsageStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCreditUsageStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCreditUsageStatQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      dateRange: // value for 'dateRange'
 *   },
 * });
 */
export function useFindCreditUsageStatQuery(baseOptions?: Apollo.QueryHookOptions<FindCreditUsageStatQuery, FindCreditUsageStatQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindCreditUsageStatQuery, FindCreditUsageStatQueryVariables>(FindCreditUsageStatDocument, options);
}
export function useFindCreditUsageStatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindCreditUsageStatQuery, FindCreditUsageStatQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindCreditUsageStatQuery, FindCreditUsageStatQueryVariables>(FindCreditUsageStatDocument, options);
}
export type FindCreditUsageStatQueryHookResult = ReturnType<typeof useFindCreditUsageStatQuery>;
export type FindCreditUsageStatLazyQueryHookResult = ReturnType<typeof useFindCreditUsageStatLazyQuery>;
export type FindCreditUsageStatQueryResult = Apollo.QueryResult<FindCreditUsageStatQuery, FindCreditUsageStatQueryVariables>;
export const FindBillingHistoryDocument = gql`
    query findBillingHistory($limit: Int = 10, $bookmark: String = null) {
  findBillingHistory(pagination: {limit: $limit, bookmark: $bookmark}) {
    items {
      invoiceId
      invoiceNumber
      product
      status
      date
      amount
      pdf
    }
    meta {
      bookmark
      hasMore
      itemCount
    }
  }
}
    `;

/**
 * __useFindBillingHistoryQuery__
 *
 * To run a query within a React component, call `useFindBillingHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindBillingHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindBillingHistoryQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      bookmark: // value for 'bookmark'
 *   },
 * });
 */
export function useFindBillingHistoryQuery(baseOptions?: Apollo.QueryHookOptions<FindBillingHistoryQuery, FindBillingHistoryQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindBillingHistoryQuery, FindBillingHistoryQueryVariables>(FindBillingHistoryDocument, options);
}
export function useFindBillingHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindBillingHistoryQuery, FindBillingHistoryQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindBillingHistoryQuery, FindBillingHistoryQueryVariables>(FindBillingHistoryDocument, options);
}
export type FindBillingHistoryQueryHookResult = ReturnType<typeof useFindBillingHistoryQuery>;
export type FindBillingHistoryLazyQueryHookResult = ReturnType<typeof useFindBillingHistoryLazyQuery>;
export type FindBillingHistoryQueryResult = Apollo.QueryResult<FindBillingHistoryQuery, FindBillingHistoryQueryVariables>;
export const SubscriptionUpdatedDocument = gql`
    subscription subscriptionUpdated {
  subscriptionUpdated {
    ...SubscriptionNotificationFields
  }
}
    ${SubscriptionNotificationFieldsFragmentDoc}`;

/**
 * __useSubscriptionUpdatedSubscription__
 *
 * To run a query within a React component, call `useSubscriptionUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SubscriptionUpdatedSubscription, SubscriptionUpdatedSubscriptionVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useSubscription<SubscriptionUpdatedSubscription, SubscriptionUpdatedSubscriptionVariables>(SubscriptionUpdatedDocument, options);
}
export type SubscriptionUpdatedSubscriptionHookResult = ReturnType<typeof useSubscriptionUpdatedSubscription>;
export type SubscriptionUpdatedSubscriptionResult = Apollo.SubscriptionResult<SubscriptionUpdatedSubscription>;
export const UploadEnterpriseFileDocument = gql`
    mutation uploadEnterpriseFile($file: Upload!, $type: EnterpriseFileTypeEnum!) {
  uploadEnterpriseFile(file: $file, type: $type) {
    success
  }
}
    `;
export type UploadEnterpriseFileMutationFn = Apollo.MutationFunction<UploadEnterpriseFileMutation, UploadEnterpriseFileMutationVariables>;

/**
 * __useUploadEnterpriseFileMutation__
 *
 * To run a mutation, you first call `useUploadEnterpriseFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadEnterpriseFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadEnterpriseFileMutation, { data, loading, error }] = useUploadEnterpriseFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUploadEnterpriseFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadEnterpriseFileMutation, UploadEnterpriseFileMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UploadEnterpriseFileMutation, UploadEnterpriseFileMutationVariables>(UploadEnterpriseFileDocument, options);
}
export type UploadEnterpriseFileMutationHookResult = ReturnType<typeof useUploadEnterpriseFileMutation>;
export type UploadEnterpriseFileMutationResult = Apollo.MutationResult<UploadEnterpriseFileMutation>;
export type UploadEnterpriseFileMutationOptions = Apollo.BaseMutationOptions<UploadEnterpriseFileMutation, UploadEnterpriseFileMutationVariables>;
export const RemoveEnterpriseFileDocument = gql`
    mutation removeEnterpriseFile($type: EnterpriseFileTypeEnum!) {
  removeEnterpriseFile(type: $type) {
    success
  }
}
    `;
export type RemoveEnterpriseFileMutationFn = Apollo.MutationFunction<RemoveEnterpriseFileMutation, RemoveEnterpriseFileMutationVariables>;

/**
 * __useRemoveEnterpriseFileMutation__
 *
 * To run a mutation, you first call `useRemoveEnterpriseFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEnterpriseFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEnterpriseFileMutation, { data, loading, error }] = useRemoveEnterpriseFileMutation({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useRemoveEnterpriseFileMutation(baseOptions?: Apollo.MutationHookOptions<RemoveEnterpriseFileMutation, RemoveEnterpriseFileMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<RemoveEnterpriseFileMutation, RemoveEnterpriseFileMutationVariables>(RemoveEnterpriseFileDocument, options);
}
export type RemoveEnterpriseFileMutationHookResult = ReturnType<typeof useRemoveEnterpriseFileMutation>;
export type RemoveEnterpriseFileMutationResult = Apollo.MutationResult<RemoveEnterpriseFileMutation>;
export type RemoveEnterpriseFileMutationOptions = Apollo.BaseMutationOptions<RemoveEnterpriseFileMutation, RemoveEnterpriseFileMutationVariables>;
export const FindCareersDocument = gql`
    query findCareers($limit: Int = 50, $page: Int = 1) {
  findCareers(pagination: {limit: $limit, page: $page}) {
    items {
      id
      name
    }
    meta {
      totalItems
      itemCount
    }
  }
}
    `;

/**
 * __useFindCareersQuery__
 *
 * To run a query within a React component, call `useFindCareersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCareersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCareersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFindCareersQuery(baseOptions?: Apollo.QueryHookOptions<FindCareersQuery, FindCareersQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindCareersQuery, FindCareersQueryVariables>(FindCareersDocument, options);
}
export function useFindCareersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindCareersQuery, FindCareersQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindCareersQuery, FindCareersQueryVariables>(FindCareersDocument, options);
}
export type FindCareersQueryHookResult = ReturnType<typeof useFindCareersQuery>;
export type FindCareersLazyQueryHookResult = ReturnType<typeof useFindCareersLazyQuery>;
export type FindCareersQueryResult = Apollo.QueryResult<FindCareersQuery, FindCareersQueryVariables>;
export const FindEnterpriseCareersDocument = gql`
    query findEnterpriseCareers($limit: Int = 50, $page: Int = 1) {
  findEnterpriseCareers(pagination: {limit: $limit, page: $page}) {
    items {
      id
      name
    }
    meta {
      totalItems
      itemCount
    }
  }
}
    `;

/**
 * __useFindEnterpriseCareersQuery__
 *
 * To run a query within a React component, call `useFindEnterpriseCareersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEnterpriseCareersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEnterpriseCareersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFindEnterpriseCareersQuery(baseOptions?: Apollo.QueryHookOptions<FindEnterpriseCareersQuery, FindEnterpriseCareersQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindEnterpriseCareersQuery, FindEnterpriseCareersQueryVariables>(FindEnterpriseCareersDocument, options);
}
export function useFindEnterpriseCareersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindEnterpriseCareersQuery, FindEnterpriseCareersQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindEnterpriseCareersQuery, FindEnterpriseCareersQueryVariables>(FindEnterpriseCareersDocument, options);
}
export type FindEnterpriseCareersQueryHookResult = ReturnType<typeof useFindEnterpriseCareersQuery>;
export type FindEnterpriseCareersLazyQueryHookResult = ReturnType<typeof useFindEnterpriseCareersLazyQuery>;
export type FindEnterpriseCareersQueryResult = Apollo.QueryResult<FindEnterpriseCareersQuery, FindEnterpriseCareersQueryVariables>;
export const UpdateEnterpriseCareersDocument = gql`
    mutation updateEnterpriseCareers($careerIds: [Int!]!) {
  updateEnterpriseCareers(careerIds: $careerIds) {
    success
  }
}
    `;
export type UpdateEnterpriseCareersMutationFn = Apollo.MutationFunction<UpdateEnterpriseCareersMutation, UpdateEnterpriseCareersMutationVariables>;

/**
 * __useUpdateEnterpriseCareersMutation__
 *
 * To run a mutation, you first call `useUpdateEnterpriseCareersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnterpriseCareersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnterpriseCareersMutation, { data, loading, error }] = useUpdateEnterpriseCareersMutation({
 *   variables: {
 *      careerIds: // value for 'careerIds'
 *   },
 * });
 */
export function useUpdateEnterpriseCareersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEnterpriseCareersMutation, UpdateEnterpriseCareersMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UpdateEnterpriseCareersMutation, UpdateEnterpriseCareersMutationVariables>(UpdateEnterpriseCareersDocument, options);
}
export type UpdateEnterpriseCareersMutationHookResult = ReturnType<typeof useUpdateEnterpriseCareersMutation>;
export type UpdateEnterpriseCareersMutationResult = Apollo.MutationResult<UpdateEnterpriseCareersMutation>;
export type UpdateEnterpriseCareersMutationOptions = Apollo.BaseMutationOptions<UpdateEnterpriseCareersMutation, UpdateEnterpriseCareersMutationVariables>;
export const UpdateEnterpriseContactsDocument = gql`
    mutation updateEnterpriseContacts($location: String!, $countryCode: String, $telephone: String) {
  updateEnterpriseContacts(
    location: $location
    countryCode: $countryCode
    telephone: $telephone
  ) {
    success
  }
}
    `;
export type UpdateEnterpriseContactsMutationFn = Apollo.MutationFunction<UpdateEnterpriseContactsMutation, UpdateEnterpriseContactsMutationVariables>;

/**
 * __useUpdateEnterpriseContactsMutation__
 *
 * To run a mutation, you first call `useUpdateEnterpriseContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnterpriseContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnterpriseContactsMutation, { data, loading, error }] = useUpdateEnterpriseContactsMutation({
 *   variables: {
 *      location: // value for 'location'
 *      countryCode: // value for 'countryCode'
 *      telephone: // value for 'telephone'
 *   },
 * });
 */
export function useUpdateEnterpriseContactsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEnterpriseContactsMutation, UpdateEnterpriseContactsMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UpdateEnterpriseContactsMutation, UpdateEnterpriseContactsMutationVariables>(UpdateEnterpriseContactsDocument, options);
}
export type UpdateEnterpriseContactsMutationHookResult = ReturnType<typeof useUpdateEnterpriseContactsMutation>;
export type UpdateEnterpriseContactsMutationResult = Apollo.MutationResult<UpdateEnterpriseContactsMutation>;
export type UpdateEnterpriseContactsMutationOptions = Apollo.BaseMutationOptions<UpdateEnterpriseContactsMutation, UpdateEnterpriseContactsMutationVariables>;
export const GetEnterpriseDetailsDocument = gql`
    query getEnterpriseDetails {
  getEnterpriseDetails {
    ...EnterpriseDetails
  }
}
    ${EnterpriseDetailsFragmentDoc}`;

/**
 * __useGetEnterpriseDetailsQuery__
 *
 * To run a query within a React component, call `useGetEnterpriseDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnterpriseDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnterpriseDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEnterpriseDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetEnterpriseDetailsQuery, GetEnterpriseDetailsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetEnterpriseDetailsQuery, GetEnterpriseDetailsQueryVariables>(GetEnterpriseDetailsDocument, options);
}
export function useGetEnterpriseDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnterpriseDetailsQuery, GetEnterpriseDetailsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetEnterpriseDetailsQuery, GetEnterpriseDetailsQueryVariables>(GetEnterpriseDetailsDocument, options);
}
export type GetEnterpriseDetailsQueryHookResult = ReturnType<typeof useGetEnterpriseDetailsQuery>;
export type GetEnterpriseDetailsLazyQueryHookResult = ReturnType<typeof useGetEnterpriseDetailsLazyQuery>;
export type GetEnterpriseDetailsQueryResult = Apollo.QueryResult<GetEnterpriseDetailsQuery, GetEnterpriseDetailsQueryVariables>;
export const CreateEnterpriseDetailsDocument = gql`
    mutation createEnterpriseDetails($employerId: Int!, $headquarter: String, $jobTitle: String!, $employerTypeId: Int, $logo: String) {
  createEnterpriseDetails(
    employerId: $employerId
    headquarter: $headquarter
    jobTitle: $jobTitle
    employerTypeId: $employerTypeId
    logo: $logo
  ) {
    ...EnterpriseDetails
  }
}
    ${EnterpriseDetailsFragmentDoc}`;
export type CreateEnterpriseDetailsMutationFn = Apollo.MutationFunction<CreateEnterpriseDetailsMutation, CreateEnterpriseDetailsMutationVariables>;

/**
 * __useCreateEnterpriseDetailsMutation__
 *
 * To run a mutation, you first call `useCreateEnterpriseDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEnterpriseDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEnterpriseDetailsMutation, { data, loading, error }] = useCreateEnterpriseDetailsMutation({
 *   variables: {
 *      employerId: // value for 'employerId'
 *      headquarter: // value for 'headquarter'
 *      jobTitle: // value for 'jobTitle'
 *      employerTypeId: // value for 'employerTypeId'
 *      logo: // value for 'logo'
 *   },
 * });
 */
export function useCreateEnterpriseDetailsMutation(baseOptions?: Apollo.MutationHookOptions<CreateEnterpriseDetailsMutation, CreateEnterpriseDetailsMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<CreateEnterpriseDetailsMutation, CreateEnterpriseDetailsMutationVariables>(CreateEnterpriseDetailsDocument, options);
}
export type CreateEnterpriseDetailsMutationHookResult = ReturnType<typeof useCreateEnterpriseDetailsMutation>;
export type CreateEnterpriseDetailsMutationResult = Apollo.MutationResult<CreateEnterpriseDetailsMutation>;
export type CreateEnterpriseDetailsMutationOptions = Apollo.BaseMutationOptions<CreateEnterpriseDetailsMutation, CreateEnterpriseDetailsMutationVariables>;
export const UpdateEnterpriseDetailsDocument = gql`
    mutation updateEnterpriseDetails($enterpriseDetailsId: Int!, $employerId: Int!, $employerTypeId: Int, $headquarter: String, $jobTitle: String!, $logo: String) {
  updateEnterpriseDetails(
    enterpriseDetailsId: $enterpriseDetailsId
    employerId: $employerId
    employerTypeId: $employerTypeId
    headquarter: $headquarter
    jobTitle: $jobTitle
    logo: $logo
  ) {
    ...EnterpriseDetails
  }
}
    ${EnterpriseDetailsFragmentDoc}`;
export type UpdateEnterpriseDetailsMutationFn = Apollo.MutationFunction<UpdateEnterpriseDetailsMutation, UpdateEnterpriseDetailsMutationVariables>;

/**
 * __useUpdateEnterpriseDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateEnterpriseDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnterpriseDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnterpriseDetailsMutation, { data, loading, error }] = useUpdateEnterpriseDetailsMutation({
 *   variables: {
 *      enterpriseDetailsId: // value for 'enterpriseDetailsId'
 *      employerId: // value for 'employerId'
 *      employerTypeId: // value for 'employerTypeId'
 *      headquarter: // value for 'headquarter'
 *      jobTitle: // value for 'jobTitle'
 *      logo: // value for 'logo'
 *   },
 * });
 */
export function useUpdateEnterpriseDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEnterpriseDetailsMutation, UpdateEnterpriseDetailsMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UpdateEnterpriseDetailsMutation, UpdateEnterpriseDetailsMutationVariables>(UpdateEnterpriseDetailsDocument, options);
}
export type UpdateEnterpriseDetailsMutationHookResult = ReturnType<typeof useUpdateEnterpriseDetailsMutation>;
export type UpdateEnterpriseDetailsMutationResult = Apollo.MutationResult<UpdateEnterpriseDetailsMutation>;
export type UpdateEnterpriseDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateEnterpriseDetailsMutation, UpdateEnterpriseDetailsMutationVariables>;
export const UploadEnterpriseLogoDocument = gql`
    mutation uploadEnterpriseLogo($file: Upload!) {
  uploadEnterpriseLogo(file: $file) {
    link
  }
}
    `;
export type UploadEnterpriseLogoMutationFn = Apollo.MutationFunction<UploadEnterpriseLogoMutation, UploadEnterpriseLogoMutationVariables>;

/**
 * __useUploadEnterpriseLogoMutation__
 *
 * To run a mutation, you first call `useUploadEnterpriseLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadEnterpriseLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadEnterpriseLogoMutation, { data, loading, error }] = useUploadEnterpriseLogoMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadEnterpriseLogoMutation(baseOptions?: Apollo.MutationHookOptions<UploadEnterpriseLogoMutation, UploadEnterpriseLogoMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UploadEnterpriseLogoMutation, UploadEnterpriseLogoMutationVariables>(UploadEnterpriseLogoDocument, options);
}
export type UploadEnterpriseLogoMutationHookResult = ReturnType<typeof useUploadEnterpriseLogoMutation>;
export type UploadEnterpriseLogoMutationResult = Apollo.MutationResult<UploadEnterpriseLogoMutation>;
export type UploadEnterpriseLogoMutationOptions = Apollo.BaseMutationOptions<UploadEnterpriseLogoMutation, UploadEnterpriseLogoMutationVariables>;
export const GetEnterpriseStepsStatusDocument = gql`
    query getEnterpriseStepsStatus {
  getEnterpriseStepsStatus {
    items {
      enterpriseId
      status
      stepKey
    }
  }
}
    `;

/**
 * __useGetEnterpriseStepsStatusQuery__
 *
 * To run a query within a React component, call `useGetEnterpriseStepsStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnterpriseStepsStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnterpriseStepsStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEnterpriseStepsStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetEnterpriseStepsStatusQuery, GetEnterpriseStepsStatusQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetEnterpriseStepsStatusQuery, GetEnterpriseStepsStatusQueryVariables>(GetEnterpriseStepsStatusDocument, options);
}
export function useGetEnterpriseStepsStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnterpriseStepsStatusQuery, GetEnterpriseStepsStatusQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetEnterpriseStepsStatusQuery, GetEnterpriseStepsStatusQueryVariables>(GetEnterpriseStepsStatusDocument, options);
}
export type GetEnterpriseStepsStatusQueryHookResult = ReturnType<typeof useGetEnterpriseStepsStatusQuery>;
export type GetEnterpriseStepsStatusLazyQueryHookResult = ReturnType<typeof useGetEnterpriseStepsStatusLazyQuery>;
export type GetEnterpriseStepsStatusQueryResult = Apollo.QueryResult<GetEnterpriseStepsStatusQuery, GetEnterpriseStepsStatusQueryVariables>;
export const SkipEnterpriseStepDocument = gql`
    mutation skipEnterpriseStep($step: EnterpriseSkippableStepEnum!) {
  skipEnterpriseStep(step: $step) {
    success
  }
}
    `;
export type SkipEnterpriseStepMutationFn = Apollo.MutationFunction<SkipEnterpriseStepMutation, SkipEnterpriseStepMutationVariables>;

/**
 * __useSkipEnterpriseStepMutation__
 *
 * To run a mutation, you first call `useSkipEnterpriseStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSkipEnterpriseStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [skipEnterpriseStepMutation, { data, loading, error }] = useSkipEnterpriseStepMutation({
 *   variables: {
 *      step: // value for 'step'
 *   },
 * });
 */
export function useSkipEnterpriseStepMutation(baseOptions?: Apollo.MutationHookOptions<SkipEnterpriseStepMutation, SkipEnterpriseStepMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<SkipEnterpriseStepMutation, SkipEnterpriseStepMutationVariables>(SkipEnterpriseStepDocument, options);
}
export type SkipEnterpriseStepMutationHookResult = ReturnType<typeof useSkipEnterpriseStepMutation>;
export type SkipEnterpriseStepMutationResult = Apollo.MutationResult<SkipEnterpriseStepMutation>;
export type SkipEnterpriseStepMutationOptions = Apollo.BaseMutationOptions<SkipEnterpriseStepMutation, SkipEnterpriseStepMutationVariables>;
export const UpdateEnterprisePrivacyDocument = gql`
    mutation updateEnterprisePrivacy($value: EnterprisePrivacyEnum!) {
  updateEnterprisePrivacy(value: $value) {
    success
  }
}
    `;
export type UpdateEnterprisePrivacyMutationFn = Apollo.MutationFunction<UpdateEnterprisePrivacyMutation, UpdateEnterprisePrivacyMutationVariables>;

/**
 * __useUpdateEnterprisePrivacyMutation__
 *
 * To run a mutation, you first call `useUpdateEnterprisePrivacyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnterprisePrivacyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnterprisePrivacyMutation, { data, loading, error }] = useUpdateEnterprisePrivacyMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateEnterprisePrivacyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEnterprisePrivacyMutation, UpdateEnterprisePrivacyMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UpdateEnterprisePrivacyMutation, UpdateEnterprisePrivacyMutationVariables>(UpdateEnterprisePrivacyDocument, options);
}
export type UpdateEnterprisePrivacyMutationHookResult = ReturnType<typeof useUpdateEnterprisePrivacyMutation>;
export type UpdateEnterprisePrivacyMutationResult = Apollo.MutationResult<UpdateEnterprisePrivacyMutation>;
export type UpdateEnterprisePrivacyMutationOptions = Apollo.BaseMutationOptions<UpdateEnterprisePrivacyMutation, UpdateEnterprisePrivacyMutationVariables>;
export const GetEnterprisePrivacyDocument = gql`
    query getEnterprisePrivacy {
  getEnterprisePrivacy {
    id
    value
  }
}
    `;

/**
 * __useGetEnterprisePrivacyQuery__
 *
 * To run a query within a React component, call `useGetEnterprisePrivacyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnterprisePrivacyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnterprisePrivacyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEnterprisePrivacyQuery(baseOptions?: Apollo.QueryHookOptions<GetEnterprisePrivacyQuery, GetEnterprisePrivacyQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetEnterprisePrivacyQuery, GetEnterprisePrivacyQueryVariables>(GetEnterprisePrivacyDocument, options);
}
export function useGetEnterprisePrivacyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnterprisePrivacyQuery, GetEnterprisePrivacyQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetEnterprisePrivacyQuery, GetEnterprisePrivacyQueryVariables>(GetEnterprisePrivacyDocument, options);
}
export type GetEnterprisePrivacyQueryHookResult = ReturnType<typeof useGetEnterprisePrivacyQuery>;
export type GetEnterprisePrivacyLazyQueryHookResult = ReturnType<typeof useGetEnterprisePrivacyLazyQuery>;
export type GetEnterprisePrivacyQueryResult = Apollo.QueryResult<GetEnterprisePrivacyQuery, GetEnterprisePrivacyQueryVariables>;
export const AdminLoginDocument = gql`
    mutation adminLogin($email: String!, $password: String!) {
  adminLogin(email: $email, password: $password) {
    profile {
      ...AdminProfile
    }
    jwt {
      ...TokenFields
    }
  }
}
    ${AdminProfileFragmentDoc}
${TokenFieldsFragmentDoc}`;
export type AdminLoginMutationFn = Apollo.MutationFunction<AdminLoginMutation, AdminLoginMutationVariables>;

/**
 * __useAdminLoginMutation__
 *
 * To run a mutation, you first call `useAdminLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminLoginMutation, { data, loading, error }] = useAdminLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAdminLoginMutation(baseOptions?: Apollo.MutationHookOptions<AdminLoginMutation, AdminLoginMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<AdminLoginMutation, AdminLoginMutationVariables>(AdminLoginDocument, options);
}
export type AdminLoginMutationHookResult = ReturnType<typeof useAdminLoginMutation>;
export type AdminLoginMutationResult = Apollo.MutationResult<AdminLoginMutation>;
export type AdminLoginMutationOptions = Apollo.BaseMutationOptions<AdminLoginMutation, AdminLoginMutationVariables>;
export const GetAdminProfileDocument = gql`
    query getAdminProfile {
  getAdminProfile {
    ...AdminProfile
  }
}
    ${AdminProfileFragmentDoc}`;

/**
 * __useGetAdminProfileQuery__
 *
 * To run a query within a React component, call `useGetAdminProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetAdminProfileQuery, GetAdminProfileQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetAdminProfileQuery, GetAdminProfileQueryVariables>(GetAdminProfileDocument, options);
}
export function useGetAdminProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminProfileQuery, GetAdminProfileQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetAdminProfileQuery, GetAdminProfileQueryVariables>(GetAdminProfileDocument, options);
}
export type GetAdminProfileQueryHookResult = ReturnType<typeof useGetAdminProfileQuery>;
export type GetAdminProfileLazyQueryHookResult = ReturnType<typeof useGetAdminProfileLazyQuery>;
export type GetAdminProfileQueryResult = Apollo.QueryResult<GetAdminProfileQuery, GetAdminProfileQueryVariables>;
export const UploadEmployerLogoDocument = gql`
    mutation uploadEmployerLogo($file: Upload!) {
  uploadEmployerLogo(file: $file)
}
    `;
export type UploadEmployerLogoMutationFn = Apollo.MutationFunction<UploadEmployerLogoMutation, UploadEmployerLogoMutationVariables>;

/**
 * __useUploadEmployerLogoMutation__
 *
 * To run a mutation, you first call `useUploadEmployerLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadEmployerLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadEmployerLogoMutation, { data, loading, error }] = useUploadEmployerLogoMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadEmployerLogoMutation(baseOptions?: Apollo.MutationHookOptions<UploadEmployerLogoMutation, UploadEmployerLogoMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UploadEmployerLogoMutation, UploadEmployerLogoMutationVariables>(UploadEmployerLogoDocument, options);
}
export type UploadEmployerLogoMutationHookResult = ReturnType<typeof useUploadEmployerLogoMutation>;
export type UploadEmployerLogoMutationResult = Apollo.MutationResult<UploadEmployerLogoMutation>;
export type UploadEmployerLogoMutationOptions = Apollo.BaseMutationOptions<UploadEmployerLogoMutation, UploadEmployerLogoMutationVariables>;
export const MergeEmployerDocument = gql`
    mutation mergeEmployer($employerId: Int!, $targetEmployerId: Int!) {
  mergeEmployer(employerId: $employerId, targetEmployerId: $targetEmployerId) {
    success
  }
}
    `;
export type MergeEmployerMutationFn = Apollo.MutationFunction<MergeEmployerMutation, MergeEmployerMutationVariables>;

/**
 * __useMergeEmployerMutation__
 *
 * To run a mutation, you first call `useMergeEmployerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeEmployerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeEmployerMutation, { data, loading, error }] = useMergeEmployerMutation({
 *   variables: {
 *      employerId: // value for 'employerId'
 *      targetEmployerId: // value for 'targetEmployerId'
 *   },
 * });
 */
export function useMergeEmployerMutation(baseOptions?: Apollo.MutationHookOptions<MergeEmployerMutation, MergeEmployerMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<MergeEmployerMutation, MergeEmployerMutationVariables>(MergeEmployerDocument, options);
}
export type MergeEmployerMutationHookResult = ReturnType<typeof useMergeEmployerMutation>;
export type MergeEmployerMutationResult = Apollo.MutationResult<MergeEmployerMutation>;
export type MergeEmployerMutationOptions = Apollo.BaseMutationOptions<MergeEmployerMutation, MergeEmployerMutationVariables>;
export const GetEnterpriseStatByAccountTypeDocument = gql`
    query getEnterpriseStatByAccountType {
  getEnterpriseStatByAccountType {
    ...EnterpriseAccountStat
  }
}
    ${EnterpriseAccountStatFragmentDoc}`;

/**
 * __useGetEnterpriseStatByAccountTypeQuery__
 *
 * To run a query within a React component, call `useGetEnterpriseStatByAccountTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnterpriseStatByAccountTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnterpriseStatByAccountTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEnterpriseStatByAccountTypeQuery(baseOptions?: Apollo.QueryHookOptions<GetEnterpriseStatByAccountTypeQuery, GetEnterpriseStatByAccountTypeQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetEnterpriseStatByAccountTypeQuery, GetEnterpriseStatByAccountTypeQueryVariables>(GetEnterpriseStatByAccountTypeDocument, options);
}
export function useGetEnterpriseStatByAccountTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnterpriseStatByAccountTypeQuery, GetEnterpriseStatByAccountTypeQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetEnterpriseStatByAccountTypeQuery, GetEnterpriseStatByAccountTypeQueryVariables>(GetEnterpriseStatByAccountTypeDocument, options);
}
export type GetEnterpriseStatByAccountTypeQueryHookResult = ReturnType<typeof useGetEnterpriseStatByAccountTypeQuery>;
export type GetEnterpriseStatByAccountTypeLazyQueryHookResult = ReturnType<typeof useGetEnterpriseStatByAccountTypeLazyQuery>;
export type GetEnterpriseStatByAccountTypeQueryResult = Apollo.QueryResult<GetEnterpriseStatByAccountTypeQuery, GetEnterpriseStatByAccountTypeQueryVariables>;
export const GetEnterpriseStatByMonthlyRegisteredDocument = gql`
    query getEnterpriseStatByMonthlyRegistered($year: Int) {
  getEnterpriseStatByMonthlyRegistered(year: $year) {
    items {
      count
      date
    }
  }
}
    `;

/**
 * __useGetEnterpriseStatByMonthlyRegisteredQuery__
 *
 * To run a query within a React component, call `useGetEnterpriseStatByMonthlyRegisteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnterpriseStatByMonthlyRegisteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnterpriseStatByMonthlyRegisteredQuery({
 *   variables: {
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetEnterpriseStatByMonthlyRegisteredQuery(baseOptions?: Apollo.QueryHookOptions<GetEnterpriseStatByMonthlyRegisteredQuery, GetEnterpriseStatByMonthlyRegisteredQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetEnterpriseStatByMonthlyRegisteredQuery, GetEnterpriseStatByMonthlyRegisteredQueryVariables>(GetEnterpriseStatByMonthlyRegisteredDocument, options);
}
export function useGetEnterpriseStatByMonthlyRegisteredLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnterpriseStatByMonthlyRegisteredQuery, GetEnterpriseStatByMonthlyRegisteredQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetEnterpriseStatByMonthlyRegisteredQuery, GetEnterpriseStatByMonthlyRegisteredQueryVariables>(GetEnterpriseStatByMonthlyRegisteredDocument, options);
}
export type GetEnterpriseStatByMonthlyRegisteredQueryHookResult = ReturnType<typeof useGetEnterpriseStatByMonthlyRegisteredQuery>;
export type GetEnterpriseStatByMonthlyRegisteredLazyQueryHookResult = ReturnType<typeof useGetEnterpriseStatByMonthlyRegisteredLazyQuery>;
export type GetEnterpriseStatByMonthlyRegisteredQueryResult = Apollo.QueryResult<GetEnterpriseStatByMonthlyRegisteredQuery, GetEnterpriseStatByMonthlyRegisteredQueryVariables>;
export const GetEnterpriseStatByLocationDocument = gql`
    query getEnterpriseStatByLocation($limit: Int, $page: Int) {
  getEnterpriseStatByLocation(limit: $limit, page: $page) {
    items {
      count
      entryName: country
    }
    meta {
      ...PaginationFields
    }
  }
}
    ${PaginationFieldsFragmentDoc}`;

/**
 * __useGetEnterpriseStatByLocationQuery__
 *
 * To run a query within a React component, call `useGetEnterpriseStatByLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnterpriseStatByLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnterpriseStatByLocationQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetEnterpriseStatByLocationQuery(baseOptions?: Apollo.QueryHookOptions<GetEnterpriseStatByLocationQuery, GetEnterpriseStatByLocationQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetEnterpriseStatByLocationQuery, GetEnterpriseStatByLocationQueryVariables>(GetEnterpriseStatByLocationDocument, options);
}
export function useGetEnterpriseStatByLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnterpriseStatByLocationQuery, GetEnterpriseStatByLocationQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetEnterpriseStatByLocationQuery, GetEnterpriseStatByLocationQueryVariables>(GetEnterpriseStatByLocationDocument, options);
}
export type GetEnterpriseStatByLocationQueryHookResult = ReturnType<typeof useGetEnterpriseStatByLocationQuery>;
export type GetEnterpriseStatByLocationLazyQueryHookResult = ReturnType<typeof useGetEnterpriseStatByLocationLazyQuery>;
export type GetEnterpriseStatByLocationQueryResult = Apollo.QueryResult<GetEnterpriseStatByLocationQuery, GetEnterpriseStatByLocationQueryVariables>;
export const GetHeadhunterStatByTopLocationsDocument = gql`
    query getHeadhunterStatByTopLocations {
  getHeadhunterStatByTopLocations {
    items {
      count
      entryName: country
    }
  }
}
    `;

/**
 * __useGetHeadhunterStatByTopLocationsQuery__
 *
 * To run a query within a React component, call `useGetHeadhunterStatByTopLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHeadhunterStatByTopLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHeadhunterStatByTopLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHeadhunterStatByTopLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetHeadhunterStatByTopLocationsQuery, GetHeadhunterStatByTopLocationsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetHeadhunterStatByTopLocationsQuery, GetHeadhunterStatByTopLocationsQueryVariables>(GetHeadhunterStatByTopLocationsDocument, options);
}
export function useGetHeadhunterStatByTopLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHeadhunterStatByTopLocationsQuery, GetHeadhunterStatByTopLocationsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetHeadhunterStatByTopLocationsQuery, GetHeadhunterStatByTopLocationsQueryVariables>(GetHeadhunterStatByTopLocationsDocument, options);
}
export type GetHeadhunterStatByTopLocationsQueryHookResult = ReturnType<typeof useGetHeadhunterStatByTopLocationsQuery>;
export type GetHeadhunterStatByTopLocationsLazyQueryHookResult = ReturnType<typeof useGetHeadhunterStatByTopLocationsLazyQuery>;
export type GetHeadhunterStatByTopLocationsQueryResult = Apollo.QueryResult<GetHeadhunterStatByTopLocationsQuery, GetHeadhunterStatByTopLocationsQueryVariables>;
export const GetEnterpriseStatByTopCompaniesDocument = gql`
    query getEnterpriseStatByTopCompanies($limit: Int, $page: Int) {
  getEnterpriseStatByTopCompanies(limit: $limit, page: $page) {
    items {
      count
      entryName: employer
    }
    meta {
      ...PaginationFields
    }
  }
}
    ${PaginationFieldsFragmentDoc}`;

/**
 * __useGetEnterpriseStatByTopCompaniesQuery__
 *
 * To run a query within a React component, call `useGetEnterpriseStatByTopCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnterpriseStatByTopCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnterpriseStatByTopCompaniesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetEnterpriseStatByTopCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<GetEnterpriseStatByTopCompaniesQuery, GetEnterpriseStatByTopCompaniesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetEnterpriseStatByTopCompaniesQuery, GetEnterpriseStatByTopCompaniesQueryVariables>(GetEnterpriseStatByTopCompaniesDocument, options);
}
export function useGetEnterpriseStatByTopCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnterpriseStatByTopCompaniesQuery, GetEnterpriseStatByTopCompaniesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetEnterpriseStatByTopCompaniesQuery, GetEnterpriseStatByTopCompaniesQueryVariables>(GetEnterpriseStatByTopCompaniesDocument, options);
}
export type GetEnterpriseStatByTopCompaniesQueryHookResult = ReturnType<typeof useGetEnterpriseStatByTopCompaniesQuery>;
export type GetEnterpriseStatByTopCompaniesLazyQueryHookResult = ReturnType<typeof useGetEnterpriseStatByTopCompaniesLazyQuery>;
export type GetEnterpriseStatByTopCompaniesQueryResult = Apollo.QueryResult<GetEnterpriseStatByTopCompaniesQuery, GetEnterpriseStatByTopCompaniesQueryVariables>;
export const GetJobPostsRequestsDocument = gql`
    query getJobPostsRequests($statuses: [JobStatusEnum!], $orderBy: [String!], $limit: Int, $page: Int) {
  findJobPosts(
    join: [IT_SKILLS, INDUSTRIES, EXPERTISES, CERTIFICATES, OCCUPATION, JOB_TYPE, ATTENDANCE, ENTERPRISE]
    orderBy: $orderBy
    pagination: {limit: $limit, page: $page}
    statuses: $statuses
  ) {
    items {
      id
      title
      occupation {
        ...OccupationFragment
      }
      jobType {
        id
        value
      }
      attendance {
        id
        value
      }
      location
      description
      createdAt
      postedAt
      updatedAt
      status
      statusReason
      enterprise {
        id
        accountType
        details {
          employer {
            name
            website
          }
        }
        profile {
          id
          email
          fullName
          location
        }
      }
      yearsOfExperience
      certificates {
        id
        name
      }
      expertises {
        id
        name
      }
      industries {
        id
        name
      }
      itSkills {
        id
        name
      }
    }
    meta {
      ...PaginationFields
    }
  }
}
    ${OccupationFragmentFragmentDoc}
${PaginationFieldsFragmentDoc}`;

/**
 * __useGetJobPostsRequestsQuery__
 *
 * To run a query within a React component, call `useGetJobPostsRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobPostsRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobPostsRequestsQuery({
 *   variables: {
 *      statuses: // value for 'statuses'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetJobPostsRequestsQuery(baseOptions?: Apollo.QueryHookOptions<GetJobPostsRequestsQuery, GetJobPostsRequestsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetJobPostsRequestsQuery, GetJobPostsRequestsQueryVariables>(GetJobPostsRequestsDocument, options);
}
export function useGetJobPostsRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobPostsRequestsQuery, GetJobPostsRequestsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetJobPostsRequestsQuery, GetJobPostsRequestsQueryVariables>(GetJobPostsRequestsDocument, options);
}
export type GetJobPostsRequestsQueryHookResult = ReturnType<typeof useGetJobPostsRequestsQuery>;
export type GetJobPostsRequestsLazyQueryHookResult = ReturnType<typeof useGetJobPostsRequestsLazyQuery>;
export type GetJobPostsRequestsQueryResult = Apollo.QueryResult<GetJobPostsRequestsQuery, GetJobPostsRequestsQueryVariables>;
export const UpdateJobPostByAdminDocument = gql`
    mutation updateJobPostByAdmin($id: Int!, $title: String, $description: String, $status: JobStatusEnum!, $statusReason: String, $occupationId: Int) {
  updateJobPostByAdmin(
    id: $id
    title: $title
    description: $description
    status: $status
    statusReason: $statusReason
    occupationId: $occupationId
  ) {
    success
  }
}
    `;
export type UpdateJobPostByAdminMutationFn = Apollo.MutationFunction<UpdateJobPostByAdminMutation, UpdateJobPostByAdminMutationVariables>;

/**
 * __useUpdateJobPostByAdminMutation__
 *
 * To run a mutation, you first call `useUpdateJobPostByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobPostByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobPostByAdminMutation, { data, loading, error }] = useUpdateJobPostByAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      status: // value for 'status'
 *      statusReason: // value for 'statusReason'
 *      occupationId: // value for 'occupationId'
 *   },
 * });
 */
export function useUpdateJobPostByAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJobPostByAdminMutation, UpdateJobPostByAdminMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UpdateJobPostByAdminMutation, UpdateJobPostByAdminMutationVariables>(UpdateJobPostByAdminDocument, options);
}
export type UpdateJobPostByAdminMutationHookResult = ReturnType<typeof useUpdateJobPostByAdminMutation>;
export type UpdateJobPostByAdminMutationResult = Apollo.MutationResult<UpdateJobPostByAdminMutation>;
export type UpdateJobPostByAdminMutationOptions = Apollo.BaseMutationOptions<UpdateJobPostByAdminMutation, UpdateJobPostByAdminMutationVariables>;
export const GetJobByMonthlyAppliedStatDocument = gql`
    query getJobByMonthlyAppliedStat($year: Int) {
  getJobByMonthlyAppliedStat(year: $year) {
    items {
      count
      date
    }
  }
}
    `;

/**
 * __useGetJobByMonthlyAppliedStatQuery__
 *
 * To run a query within a React component, call `useGetJobByMonthlyAppliedStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobByMonthlyAppliedStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobByMonthlyAppliedStatQuery({
 *   variables: {
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetJobByMonthlyAppliedStatQuery(baseOptions?: Apollo.QueryHookOptions<GetJobByMonthlyAppliedStatQuery, GetJobByMonthlyAppliedStatQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetJobByMonthlyAppliedStatQuery, GetJobByMonthlyAppliedStatQueryVariables>(GetJobByMonthlyAppliedStatDocument, options);
}
export function useGetJobByMonthlyAppliedStatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobByMonthlyAppliedStatQuery, GetJobByMonthlyAppliedStatQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetJobByMonthlyAppliedStatQuery, GetJobByMonthlyAppliedStatQueryVariables>(GetJobByMonthlyAppliedStatDocument, options);
}
export type GetJobByMonthlyAppliedStatQueryHookResult = ReturnType<typeof useGetJobByMonthlyAppliedStatQuery>;
export type GetJobByMonthlyAppliedStatLazyQueryHookResult = ReturnType<typeof useGetJobByMonthlyAppliedStatLazyQuery>;
export type GetJobByMonthlyAppliedStatQueryResult = Apollo.QueryResult<GetJobByMonthlyAppliedStatQuery, GetJobByMonthlyAppliedStatQueryVariables>;
export const GetJobByMonthlyCreatedStatDocument = gql`
    query getJobByMonthlyCreatedStat($year: Int) {
  getJobByMonthlyCreatedStat(year: $year) {
    items {
      count
      date
    }
  }
}
    `;

/**
 * __useGetJobByMonthlyCreatedStatQuery__
 *
 * To run a query within a React component, call `useGetJobByMonthlyCreatedStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobByMonthlyCreatedStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobByMonthlyCreatedStatQuery({
 *   variables: {
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetJobByMonthlyCreatedStatQuery(baseOptions?: Apollo.QueryHookOptions<GetJobByMonthlyCreatedStatQuery, GetJobByMonthlyCreatedStatQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetJobByMonthlyCreatedStatQuery, GetJobByMonthlyCreatedStatQueryVariables>(GetJobByMonthlyCreatedStatDocument, options);
}
export function useGetJobByMonthlyCreatedStatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobByMonthlyCreatedStatQuery, GetJobByMonthlyCreatedStatQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetJobByMonthlyCreatedStatQuery, GetJobByMonthlyCreatedStatQueryVariables>(GetJobByMonthlyCreatedStatDocument, options);
}
export type GetJobByMonthlyCreatedStatQueryHookResult = ReturnType<typeof useGetJobByMonthlyCreatedStatQuery>;
export type GetJobByMonthlyCreatedStatLazyQueryHookResult = ReturnType<typeof useGetJobByMonthlyCreatedStatLazyQuery>;
export type GetJobByMonthlyCreatedStatQueryResult = Apollo.QueryResult<GetJobByMonthlyCreatedStatQuery, GetJobByMonthlyCreatedStatQueryVariables>;
export const MergeItSkillDocument = gql`
    mutation mergeItSkill($itSkillId: Int!, $targetItSkillId: Int!) {
  mergeItSkill(itSkillId: $itSkillId, targetItSkillId: $targetItSkillId) {
    success
  }
}
    `;
export type MergeItSkillMutationFn = Apollo.MutationFunction<MergeItSkillMutation, MergeItSkillMutationVariables>;

/**
 * __useMergeItSkillMutation__
 *
 * To run a mutation, you first call `useMergeItSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeItSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeItSkillMutation, { data, loading, error }] = useMergeItSkillMutation({
 *   variables: {
 *      itSkillId: // value for 'itSkillId'
 *      targetItSkillId: // value for 'targetItSkillId'
 *   },
 * });
 */
export function useMergeItSkillMutation(baseOptions?: Apollo.MutationHookOptions<MergeItSkillMutation, MergeItSkillMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<MergeItSkillMutation, MergeItSkillMutationVariables>(MergeItSkillDocument, options);
}
export type MergeItSkillMutationHookResult = ReturnType<typeof useMergeItSkillMutation>;
export type MergeItSkillMutationResult = Apollo.MutationResult<MergeItSkillMutation>;
export type MergeItSkillMutationOptions = Apollo.BaseMutationOptions<MergeItSkillMutation, MergeItSkillMutationVariables>;
export const MergeExpertiseDocument = gql`
    mutation mergeExpertise($expertiseId: Int!, $targetExpertiseId: Int!) {
  mergeExpertise(expertiseId: $expertiseId, targetExpertiseId: $targetExpertiseId) {
    success
  }
}
    `;
export type MergeExpertiseMutationFn = Apollo.MutationFunction<MergeExpertiseMutation, MergeExpertiseMutationVariables>;

/**
 * __useMergeExpertiseMutation__
 *
 * To run a mutation, you first call `useMergeExpertiseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeExpertiseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeExpertiseMutation, { data, loading, error }] = useMergeExpertiseMutation({
 *   variables: {
 *      expertiseId: // value for 'expertiseId'
 *      targetExpertiseId: // value for 'targetExpertiseId'
 *   },
 * });
 */
export function useMergeExpertiseMutation(baseOptions?: Apollo.MutationHookOptions<MergeExpertiseMutation, MergeExpertiseMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<MergeExpertiseMutation, MergeExpertiseMutationVariables>(MergeExpertiseDocument, options);
}
export type MergeExpertiseMutationHookResult = ReturnType<typeof useMergeExpertiseMutation>;
export type MergeExpertiseMutationResult = Apollo.MutationResult<MergeExpertiseMutation>;
export type MergeExpertiseMutationOptions = Apollo.BaseMutationOptions<MergeExpertiseMutation, MergeExpertiseMutationVariables>;
export const MergeCertificateDocument = gql`
    mutation mergeCertificate($certificateId: Int!, $targetCertificateId: Int!) {
  mergeCertificate(
    certificateId: $certificateId
    targetCertificateId: $targetCertificateId
  ) {
    success
  }
}
    `;
export type MergeCertificateMutationFn = Apollo.MutationFunction<MergeCertificateMutation, MergeCertificateMutationVariables>;

/**
 * __useMergeCertificateMutation__
 *
 * To run a mutation, you first call `useMergeCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeCertificateMutation, { data, loading, error }] = useMergeCertificateMutation({
 *   variables: {
 *      certificateId: // value for 'certificateId'
 *      targetCertificateId: // value for 'targetCertificateId'
 *   },
 * });
 */
export function useMergeCertificateMutation(baseOptions?: Apollo.MutationHookOptions<MergeCertificateMutation, MergeCertificateMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<MergeCertificateMutation, MergeCertificateMutationVariables>(MergeCertificateDocument, options);
}
export type MergeCertificateMutationHookResult = ReturnType<typeof useMergeCertificateMutation>;
export type MergeCertificateMutationResult = Apollo.MutationResult<MergeCertificateMutation>;
export type MergeCertificateMutationOptions = Apollo.BaseMutationOptions<MergeCertificateMutation, MergeCertificateMutationVariables>;
export const GetCountOfPendingRequestsDocument = gql`
    query getCountOfPendingRequests {
  getCountOfPendingRequests {
    certificate
    expertises
    itSkills
  }
}
    `;

/**
 * __useGetCountOfPendingRequestsQuery__
 *
 * To run a query within a React component, call `useGetCountOfPendingRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountOfPendingRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountOfPendingRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountOfPendingRequestsQuery(baseOptions?: Apollo.QueryHookOptions<GetCountOfPendingRequestsQuery, GetCountOfPendingRequestsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetCountOfPendingRequestsQuery, GetCountOfPendingRequestsQueryVariables>(GetCountOfPendingRequestsDocument, options);
}
export function useGetCountOfPendingRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountOfPendingRequestsQuery, GetCountOfPendingRequestsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetCountOfPendingRequestsQuery, GetCountOfPendingRequestsQueryVariables>(GetCountOfPendingRequestsDocument, options);
}
export type GetCountOfPendingRequestsQueryHookResult = ReturnType<typeof useGetCountOfPendingRequestsQuery>;
export type GetCountOfPendingRequestsLazyQueryHookResult = ReturnType<typeof useGetCountOfPendingRequestsLazyQuery>;
export type GetCountOfPendingRequestsQueryResult = Apollo.QueryResult<GetCountOfPendingRequestsQuery, GetCountOfPendingRequestsQueryVariables>;
export const GetCountOfJobPostsPendingRequestsDocument = gql`
    query getCountOfJobPostsPendingRequests {
  getCountOfPendingRequests {
    job
  }
}
    `;

/**
 * __useGetCountOfJobPostsPendingRequestsQuery__
 *
 * To run a query within a React component, call `useGetCountOfJobPostsPendingRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountOfJobPostsPendingRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountOfJobPostsPendingRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountOfJobPostsPendingRequestsQuery(baseOptions?: Apollo.QueryHookOptions<GetCountOfJobPostsPendingRequestsQuery, GetCountOfJobPostsPendingRequestsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetCountOfJobPostsPendingRequestsQuery, GetCountOfJobPostsPendingRequestsQueryVariables>(GetCountOfJobPostsPendingRequestsDocument, options);
}
export function useGetCountOfJobPostsPendingRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountOfJobPostsPendingRequestsQuery, GetCountOfJobPostsPendingRequestsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetCountOfJobPostsPendingRequestsQuery, GetCountOfJobPostsPendingRequestsQueryVariables>(GetCountOfJobPostsPendingRequestsDocument, options);
}
export type GetCountOfJobPostsPendingRequestsQueryHookResult = ReturnType<typeof useGetCountOfJobPostsPendingRequestsQuery>;
export type GetCountOfJobPostsPendingRequestsLazyQueryHookResult = ReturnType<typeof useGetCountOfJobPostsPendingRequestsLazyQuery>;
export type GetCountOfJobPostsPendingRequestsQueryResult = Apollo.QueryResult<GetCountOfJobPostsPendingRequestsQuery, GetCountOfJobPostsPendingRequestsQueryVariables>;
export const GetCertificatesRequestsDocument = gql`
    query getCertificatesRequests($limit: Int, $page: Int, $statuses: [CustomEntityStatusEnum!]) {
  findCertificates(pagination: {limit: $limit, page: $page}, statuses: $statuses) {
    items {
      ...CertificateRequest
    }
    meta {
      ...PaginationFields
    }
  }
}
    ${CertificateRequestFragmentDoc}
${PaginationFieldsFragmentDoc}`;

/**
 * __useGetCertificatesRequestsQuery__
 *
 * To run a query within a React component, call `useGetCertificatesRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertificatesRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertificatesRequestsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useGetCertificatesRequestsQuery(baseOptions?: Apollo.QueryHookOptions<GetCertificatesRequestsQuery, GetCertificatesRequestsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetCertificatesRequestsQuery, GetCertificatesRequestsQueryVariables>(GetCertificatesRequestsDocument, options);
}
export function useGetCertificatesRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCertificatesRequestsQuery, GetCertificatesRequestsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetCertificatesRequestsQuery, GetCertificatesRequestsQueryVariables>(GetCertificatesRequestsDocument, options);
}
export type GetCertificatesRequestsQueryHookResult = ReturnType<typeof useGetCertificatesRequestsQuery>;
export type GetCertificatesRequestsLazyQueryHookResult = ReturnType<typeof useGetCertificatesRequestsLazyQuery>;
export type GetCertificatesRequestsQueryResult = Apollo.QueryResult<GetCertificatesRequestsQuery, GetCertificatesRequestsQueryVariables>;
export const UpdateCertificateRequestsDocument = gql`
    mutation updateCertificateRequests($status: CustomEntityStatusEnum, $name: String, $id: Float!, $descriptions: String) {
  updateCertificate(
    status: $status
    name: $name
    id: $id
    description: $descriptions
  ) {
    ...CertificateRequest
  }
}
    ${CertificateRequestFragmentDoc}`;
export type UpdateCertificateRequestsMutationFn = Apollo.MutationFunction<UpdateCertificateRequestsMutation, UpdateCertificateRequestsMutationVariables>;

/**
 * __useUpdateCertificateRequestsMutation__
 *
 * To run a mutation, you first call `useUpdateCertificateRequestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCertificateRequestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCertificateRequestsMutation, { data, loading, error }] = useUpdateCertificateRequestsMutation({
 *   variables: {
 *      status: // value for 'status'
 *      name: // value for 'name'
 *      id: // value for 'id'
 *      descriptions: // value for 'descriptions'
 *   },
 * });
 */
export function useUpdateCertificateRequestsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCertificateRequestsMutation, UpdateCertificateRequestsMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UpdateCertificateRequestsMutation, UpdateCertificateRequestsMutationVariables>(UpdateCertificateRequestsDocument, options);
}
export type UpdateCertificateRequestsMutationHookResult = ReturnType<typeof useUpdateCertificateRequestsMutation>;
export type UpdateCertificateRequestsMutationResult = Apollo.MutationResult<UpdateCertificateRequestsMutation>;
export type UpdateCertificateRequestsMutationOptions = Apollo.BaseMutationOptions<UpdateCertificateRequestsMutation, UpdateCertificateRequestsMutationVariables>;
export const GetExpertisesRequestsDocument = gql`
    query getExpertisesRequests($limit: Int, $page: Int, $statuses: [CustomEntityStatusEnum!]) {
  findExpertises(pagination: {limit: $limit, page: $page}, statuses: $statuses) {
    items {
      ...ExpertiseRequest
    }
    meta {
      ...PaginationFields
    }
  }
}
    ${ExpertiseRequestFragmentDoc}
${PaginationFieldsFragmentDoc}`;

/**
 * __useGetExpertisesRequestsQuery__
 *
 * To run a query within a React component, call `useGetExpertisesRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpertisesRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpertisesRequestsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useGetExpertisesRequestsQuery(baseOptions?: Apollo.QueryHookOptions<GetExpertisesRequestsQuery, GetExpertisesRequestsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetExpertisesRequestsQuery, GetExpertisesRequestsQueryVariables>(GetExpertisesRequestsDocument, options);
}
export function useGetExpertisesRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExpertisesRequestsQuery, GetExpertisesRequestsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetExpertisesRequestsQuery, GetExpertisesRequestsQueryVariables>(GetExpertisesRequestsDocument, options);
}
export type GetExpertisesRequestsQueryHookResult = ReturnType<typeof useGetExpertisesRequestsQuery>;
export type GetExpertisesRequestsLazyQueryHookResult = ReturnType<typeof useGetExpertisesRequestsLazyQuery>;
export type GetExpertisesRequestsQueryResult = Apollo.QueryResult<GetExpertisesRequestsQuery, GetExpertisesRequestsQueryVariables>;
export const UpdateExpertiseRequestsDocument = gql`
    mutation updateExpertiseRequests($status: CustomEntityStatusEnum, $name: String, $id: Float!, $descriptions: String) {
  updateExpertise(
    status: $status
    name: $name
    id: $id
    description: $descriptions
  ) {
    ...ExpertiseRequest
  }
}
    ${ExpertiseRequestFragmentDoc}`;
export type UpdateExpertiseRequestsMutationFn = Apollo.MutationFunction<UpdateExpertiseRequestsMutation, UpdateExpertiseRequestsMutationVariables>;

/**
 * __useUpdateExpertiseRequestsMutation__
 *
 * To run a mutation, you first call `useUpdateExpertiseRequestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExpertiseRequestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExpertiseRequestsMutation, { data, loading, error }] = useUpdateExpertiseRequestsMutation({
 *   variables: {
 *      status: // value for 'status'
 *      name: // value for 'name'
 *      id: // value for 'id'
 *      descriptions: // value for 'descriptions'
 *   },
 * });
 */
export function useUpdateExpertiseRequestsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExpertiseRequestsMutation, UpdateExpertiseRequestsMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UpdateExpertiseRequestsMutation, UpdateExpertiseRequestsMutationVariables>(UpdateExpertiseRequestsDocument, options);
}
export type UpdateExpertiseRequestsMutationHookResult = ReturnType<typeof useUpdateExpertiseRequestsMutation>;
export type UpdateExpertiseRequestsMutationResult = Apollo.MutationResult<UpdateExpertiseRequestsMutation>;
export type UpdateExpertiseRequestsMutationOptions = Apollo.BaseMutationOptions<UpdateExpertiseRequestsMutation, UpdateExpertiseRequestsMutationVariables>;
export const GetItSkillsRequestsDocument = gql`
    query getItSkillsRequests($limit: Int, $page: Int, $statuses: [CustomEntityStatusEnum!]) {
  findItSkills(pagination: {limit: $limit, page: $page}, statuses: $statuses) {
    items {
      ...ItSkillRequest
    }
    meta {
      ...PaginationFields
    }
  }
}
    ${ItSkillRequestFragmentDoc}
${PaginationFieldsFragmentDoc}`;

/**
 * __useGetItSkillsRequestsQuery__
 *
 * To run a query within a React component, call `useGetItSkillsRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItSkillsRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItSkillsRequestsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useGetItSkillsRequestsQuery(baseOptions?: Apollo.QueryHookOptions<GetItSkillsRequestsQuery, GetItSkillsRequestsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetItSkillsRequestsQuery, GetItSkillsRequestsQueryVariables>(GetItSkillsRequestsDocument, options);
}
export function useGetItSkillsRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetItSkillsRequestsQuery, GetItSkillsRequestsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetItSkillsRequestsQuery, GetItSkillsRequestsQueryVariables>(GetItSkillsRequestsDocument, options);
}
export type GetItSkillsRequestsQueryHookResult = ReturnType<typeof useGetItSkillsRequestsQuery>;
export type GetItSkillsRequestsLazyQueryHookResult = ReturnType<typeof useGetItSkillsRequestsLazyQuery>;
export type GetItSkillsRequestsQueryResult = Apollo.QueryResult<GetItSkillsRequestsQuery, GetItSkillsRequestsQueryVariables>;
export const UpdateItSkillsRequestsDocument = gql`
    mutation updateItSkillsRequests($status: CustomEntityStatusEnum, $name: String, $id: Float!) {
  updateItSkill(status: $status, name: $name, id: $id) {
    ...ItSkillRequest
  }
}
    ${ItSkillRequestFragmentDoc}`;
export type UpdateItSkillsRequestsMutationFn = Apollo.MutationFunction<UpdateItSkillsRequestsMutation, UpdateItSkillsRequestsMutationVariables>;

/**
 * __useUpdateItSkillsRequestsMutation__
 *
 * To run a mutation, you first call `useUpdateItSkillsRequestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItSkillsRequestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItSkillsRequestsMutation, { data, loading, error }] = useUpdateItSkillsRequestsMutation({
 *   variables: {
 *      status: // value for 'status'
 *      name: // value for 'name'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateItSkillsRequestsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateItSkillsRequestsMutation, UpdateItSkillsRequestsMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UpdateItSkillsRequestsMutation, UpdateItSkillsRequestsMutationVariables>(UpdateItSkillsRequestsDocument, options);
}
export type UpdateItSkillsRequestsMutationHookResult = ReturnType<typeof useUpdateItSkillsRequestsMutation>;
export type UpdateItSkillsRequestsMutationResult = Apollo.MutationResult<UpdateItSkillsRequestsMutation>;
export type UpdateItSkillsRequestsMutationOptions = Apollo.BaseMutationOptions<UpdateItSkillsRequestsMutation, UpdateItSkillsRequestsMutationVariables>;
export const GetTalentStatByBadgeDocument = gql`
    query getTalentStatByBadge {
  getTalentStatByBadge {
    ...TalentBadgeStat
  }
}
    ${TalentBadgeStatFragmentDoc}`;

/**
 * __useGetTalentStatByBadgeQuery__
 *
 * To run a query within a React component, call `useGetTalentStatByBadgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentStatByBadgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentStatByBadgeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTalentStatByBadgeQuery(baseOptions?: Apollo.QueryHookOptions<GetTalentStatByBadgeQuery, GetTalentStatByBadgeQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetTalentStatByBadgeQuery, GetTalentStatByBadgeQueryVariables>(GetTalentStatByBadgeDocument, options);
}
export function useGetTalentStatByBadgeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTalentStatByBadgeQuery, GetTalentStatByBadgeQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetTalentStatByBadgeQuery, GetTalentStatByBadgeQueryVariables>(GetTalentStatByBadgeDocument, options);
}
export type GetTalentStatByBadgeQueryHookResult = ReturnType<typeof useGetTalentStatByBadgeQuery>;
export type GetTalentStatByBadgeLazyQueryHookResult = ReturnType<typeof useGetTalentStatByBadgeLazyQuery>;
export type GetTalentStatByBadgeQueryResult = Apollo.QueryResult<GetTalentStatByBadgeQuery, GetTalentStatByBadgeQueryVariables>;
export const GetTalentStatByMonthlyRegisteredDocument = gql`
    query getTalentStatByMonthlyRegistered($year: Int) {
  getTalentStatByMonthlyRegistered(year: $year) {
    items {
      count
      date
    }
  }
}
    `;

/**
 * __useGetTalentStatByMonthlyRegisteredQuery__
 *
 * To run a query within a React component, call `useGetTalentStatByMonthlyRegisteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentStatByMonthlyRegisteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentStatByMonthlyRegisteredQuery({
 *   variables: {
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetTalentStatByMonthlyRegisteredQuery(baseOptions?: Apollo.QueryHookOptions<GetTalentStatByMonthlyRegisteredQuery, GetTalentStatByMonthlyRegisteredQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetTalentStatByMonthlyRegisteredQuery, GetTalentStatByMonthlyRegisteredQueryVariables>(GetTalentStatByMonthlyRegisteredDocument, options);
}
export function useGetTalentStatByMonthlyRegisteredLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTalentStatByMonthlyRegisteredQuery, GetTalentStatByMonthlyRegisteredQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetTalentStatByMonthlyRegisteredQuery, GetTalentStatByMonthlyRegisteredQueryVariables>(GetTalentStatByMonthlyRegisteredDocument, options);
}
export type GetTalentStatByMonthlyRegisteredQueryHookResult = ReturnType<typeof useGetTalentStatByMonthlyRegisteredQuery>;
export type GetTalentStatByMonthlyRegisteredLazyQueryHookResult = ReturnType<typeof useGetTalentStatByMonthlyRegisteredLazyQuery>;
export type GetTalentStatByMonthlyRegisteredQueryResult = Apollo.QueryResult<GetTalentStatByMonthlyRegisteredQuery, GetTalentStatByMonthlyRegisteredQueryVariables>;
export const GetTalentStatByLocationDocument = gql`
    query getTalentStatByLocation($limit: Int, $page: Int) {
  getTalentStatByLocation(limit: $limit, page: $page) {
    items {
      count
      entryName: country
    }
    meta {
      ...PaginationFields
    }
  }
}
    ${PaginationFieldsFragmentDoc}`;

/**
 * __useGetTalentStatByLocationQuery__
 *
 * To run a query within a React component, call `useGetTalentStatByLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentStatByLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentStatByLocationQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetTalentStatByLocationQuery(baseOptions?: Apollo.QueryHookOptions<GetTalentStatByLocationQuery, GetTalentStatByLocationQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetTalentStatByLocationQuery, GetTalentStatByLocationQueryVariables>(GetTalentStatByLocationDocument, options);
}
export function useGetTalentStatByLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTalentStatByLocationQuery, GetTalentStatByLocationQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetTalentStatByLocationQuery, GetTalentStatByLocationQueryVariables>(GetTalentStatByLocationDocument, options);
}
export type GetTalentStatByLocationQueryHookResult = ReturnType<typeof useGetTalentStatByLocationQuery>;
export type GetTalentStatByLocationLazyQueryHookResult = ReturnType<typeof useGetTalentStatByLocationLazyQuery>;
export type GetTalentStatByLocationQueryResult = Apollo.QueryResult<GetTalentStatByLocationQuery, GetTalentStatByLocationQueryVariables>;
export const GetTalentStatByOccupationDocument = gql`
    query getTalentStatByOccupation($limit: Int, $page: Int) {
  getTalentStatByOccupation(limit: $limit, page: $page) {
    items {
      count
      entryName: occupation
    }
    meta {
      ...PaginationFields
    }
  }
}
    ${PaginationFieldsFragmentDoc}`;

/**
 * __useGetTalentStatByOccupationQuery__
 *
 * To run a query within a React component, call `useGetTalentStatByOccupationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentStatByOccupationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentStatByOccupationQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetTalentStatByOccupationQuery(baseOptions?: Apollo.QueryHookOptions<GetTalentStatByOccupationQuery, GetTalentStatByOccupationQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetTalentStatByOccupationQuery, GetTalentStatByOccupationQueryVariables>(GetTalentStatByOccupationDocument, options);
}
export function useGetTalentStatByOccupationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTalentStatByOccupationQuery, GetTalentStatByOccupationQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetTalentStatByOccupationQuery, GetTalentStatByOccupationQueryVariables>(GetTalentStatByOccupationDocument, options);
}
export type GetTalentStatByOccupationQueryHookResult = ReturnType<typeof useGetTalentStatByOccupationQuery>;
export type GetTalentStatByOccupationLazyQueryHookResult = ReturnType<typeof useGetTalentStatByOccupationLazyQuery>;
export type GetTalentStatByOccupationQueryResult = Apollo.QueryResult<GetTalentStatByOccupationQuery, GetTalentStatByOccupationQueryVariables>;
export const GetNotificationsDocument = gql`
    query getNotifications($profileId: Int, $limit: Int, $page: Int) {
  getNotifications(
    profileId: $profileId
    pagination: {limit: $limit, page: $page}
  ) {
    items {
      ...Notification
    }
    meta {
      ...PaginationFields
    }
  }
}
    ${NotificationFragmentDoc}
${PaginationFieldsFragmentDoc}`;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
}
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
}
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const GetCountOfUnreadNotificationDocument = gql`
    query getCountOfUnreadNotification {
  getCountOfUnreadNotification {
    countOfUnreadNotifications
  }
}
    `;

/**
 * __useGetCountOfUnreadNotificationQuery__
 *
 * To run a query within a React component, call `useGetCountOfUnreadNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountOfUnreadNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountOfUnreadNotificationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountOfUnreadNotificationQuery(baseOptions?: Apollo.QueryHookOptions<GetCountOfUnreadNotificationQuery, GetCountOfUnreadNotificationQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetCountOfUnreadNotificationQuery, GetCountOfUnreadNotificationQueryVariables>(GetCountOfUnreadNotificationDocument, options);
}
export function useGetCountOfUnreadNotificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountOfUnreadNotificationQuery, GetCountOfUnreadNotificationQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetCountOfUnreadNotificationQuery, GetCountOfUnreadNotificationQueryVariables>(GetCountOfUnreadNotificationDocument, options);
}
export type GetCountOfUnreadNotificationQueryHookResult = ReturnType<typeof useGetCountOfUnreadNotificationQuery>;
export type GetCountOfUnreadNotificationLazyQueryHookResult = ReturnType<typeof useGetCountOfUnreadNotificationLazyQuery>;
export type GetCountOfUnreadNotificationQueryResult = Apollo.QueryResult<GetCountOfUnreadNotificationQuery, GetCountOfUnreadNotificationQueryVariables>;
export const MarkNotificationAsReadDocument = gql`
    mutation markNotificationAsRead($id: Int!) {
  markNotificationAsRead(id: $id) {
    success
  }
}
    `;
export type MarkNotificationAsReadMutationFn = Apollo.MutationFunction<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>;

/**
 * __useMarkNotificationAsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationAsReadMutation, { data, loading, error }] = useMarkNotificationAsReadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkNotificationAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>(MarkNotificationAsReadDocument, options);
}
export type MarkNotificationAsReadMutationHookResult = ReturnType<typeof useMarkNotificationAsReadMutation>;
export type MarkNotificationAsReadMutationResult = Apollo.MutationResult<MarkNotificationAsReadMutation>;
export type MarkNotificationAsReadMutationOptions = Apollo.BaseMutationOptions<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>;
export const NotificationUpdatedDocument = gql`
    subscription notificationUpdated {
  notificationUpdated {
    ...Notification
  }
}
    ${NotificationFragmentDoc}`;

/**
 * __useNotificationUpdatedSubscription__
 *
 * To run a query within a React component, call `useNotificationUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNotificationUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<NotificationUpdatedSubscription, NotificationUpdatedSubscriptionVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useSubscription<NotificationUpdatedSubscription, NotificationUpdatedSubscriptionVariables>(NotificationUpdatedDocument, options);
}
export type NotificationUpdatedSubscriptionHookResult = ReturnType<typeof useNotificationUpdatedSubscription>;
export type NotificationUpdatedSubscriptionResult = Apollo.SubscriptionResult<NotificationUpdatedSubscription>;
export const NotificationCounterUpdatedDocument = gql`
    subscription notificationCounterUpdated {
  notificationCounterUpdated {
    countOfUnreadNotifications
  }
}
    `;

/**
 * __useNotificationCounterUpdatedSubscription__
 *
 * To run a query within a React component, call `useNotificationCounterUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationCounterUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationCounterUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNotificationCounterUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<NotificationCounterUpdatedSubscription, NotificationCounterUpdatedSubscriptionVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useSubscription<NotificationCounterUpdatedSubscription, NotificationCounterUpdatedSubscriptionVariables>(NotificationCounterUpdatedDocument, options);
}
export type NotificationCounterUpdatedSubscriptionHookResult = ReturnType<typeof useNotificationCounterUpdatedSubscription>;
export type NotificationCounterUpdatedSubscriptionResult = Apollo.SubscriptionResult<NotificationCounterUpdatedSubscription>;
export const SendNotificationDocument = gql`
    mutation sendNotification($additionalValue: Int, $enterpriseProfileId: Int, $notificationType: NotificationTypeEnum, $notificationValue: Int, $talentProfileId: Int) {
  sendNotification(
    additionalValue: $additionalValue
    enterpriseProfileId: $enterpriseProfileId
    notificationType: $notificationType
    notificationValue: $notificationValue
    talentProfileId: $talentProfileId
  ) {
    success
  }
}
    `;
export type SendNotificationMutationFn = Apollo.MutationFunction<SendNotificationMutation, SendNotificationMutationVariables>;

/**
 * __useSendNotificationMutation__
 *
 * To run a mutation, you first call `useSendNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendNotificationMutation, { data, loading, error }] = useSendNotificationMutation({
 *   variables: {
 *      additionalValue: // value for 'additionalValue'
 *      enterpriseProfileId: // value for 'enterpriseProfileId'
 *      notificationType: // value for 'notificationType'
 *      notificationValue: // value for 'notificationValue'
 *      talentProfileId: // value for 'talentProfileId'
 *   },
 * });
 */
export function useSendNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SendNotificationMutation, SendNotificationMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<SendNotificationMutation, SendNotificationMutationVariables>(SendNotificationDocument, options);
}
export type SendNotificationMutationHookResult = ReturnType<typeof useSendNotificationMutation>;
export type SendNotificationMutationResult = Apollo.MutationResult<SendNotificationMutation>;
export type SendNotificationMutationOptions = Apollo.BaseMutationOptions<SendNotificationMutation, SendNotificationMutationVariables>;
export const GetProfileDocument = gql`
    query getProfile {
  getProfile {
    ...ProfileFields
  }
}
    ${ProfileFieldsFragmentDoc}`;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
}
export function useGetProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
}
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>;
export type GetProfileQueryResult = Apollo.QueryResult<GetProfileQuery, GetProfileQueryVariables>;
export const UpdateFullNameDocument = gql`
    mutation updateFullName($fullName: String!) {
  updateFullName(fullName: $fullName) {
    success
  }
}
    `;
export type UpdateFullNameMutationFn = Apollo.MutationFunction<UpdateFullNameMutation, UpdateFullNameMutationVariables>;

/**
 * __useUpdateFullNameMutation__
 *
 * To run a mutation, you first call `useUpdateFullNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFullNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFullNameMutation, { data, loading, error }] = useUpdateFullNameMutation({
 *   variables: {
 *      fullName: // value for 'fullName'
 *   },
 * });
 */
export function useUpdateFullNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFullNameMutation, UpdateFullNameMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UpdateFullNameMutation, UpdateFullNameMutationVariables>(UpdateFullNameDocument, options);
}
export type UpdateFullNameMutationHookResult = ReturnType<typeof useUpdateFullNameMutation>;
export type UpdateFullNameMutationResult = Apollo.MutationResult<UpdateFullNameMutation>;
export type UpdateFullNameMutationOptions = Apollo.BaseMutationOptions<UpdateFullNameMutation, UpdateFullNameMutationVariables>;
export const DeleteProfileDocument = gql`
    mutation deleteProfile {
  deleteProfile {
    success
  }
}
    `;
export type DeleteProfileMutationFn = Apollo.MutationFunction<DeleteProfileMutation, DeleteProfileMutationVariables>;

/**
 * __useDeleteProfileMutation__
 *
 * To run a mutation, you first call `useDeleteProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProfileMutation, { data, loading, error }] = useDeleteProfileMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteProfileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProfileMutation, DeleteProfileMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<DeleteProfileMutation, DeleteProfileMutationVariables>(DeleteProfileDocument, options);
}
export type DeleteProfileMutationHookResult = ReturnType<typeof useDeleteProfileMutation>;
export type DeleteProfileMutationResult = Apollo.MutationResult<DeleteProfileMutation>;
export type DeleteProfileMutationOptions = Apollo.BaseMutationOptions<DeleteProfileMutation, DeleteProfileMutationVariables>;
export const GetTalentComparisonScoreDocument = gql`
    query getTalentComparisonScore {
  getTalentComparisonScore {
    ...TalentComparisonFields
  }
}
    ${TalentComparisonFieldsFragmentDoc}`;

/**
 * __useGetTalentComparisonScoreQuery__
 *
 * To run a query within a React component, call `useGetTalentComparisonScoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentComparisonScoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentComparisonScoreQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTalentComparisonScoreQuery(baseOptions?: Apollo.QueryHookOptions<GetTalentComparisonScoreQuery, GetTalentComparisonScoreQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetTalentComparisonScoreQuery, GetTalentComparisonScoreQueryVariables>(GetTalentComparisonScoreDocument, options);
}
export function useGetTalentComparisonScoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTalentComparisonScoreQuery, GetTalentComparisonScoreQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetTalentComparisonScoreQuery, GetTalentComparisonScoreQueryVariables>(GetTalentComparisonScoreDocument, options);
}
export type GetTalentComparisonScoreQueryHookResult = ReturnType<typeof useGetTalentComparisonScoreQuery>;
export type GetTalentComparisonScoreLazyQueryHookResult = ReturnType<typeof useGetTalentComparisonScoreLazyQuery>;
export type GetTalentComparisonScoreQueryResult = Apollo.QueryResult<GetTalentComparisonScoreQuery, GetTalentComparisonScoreQueryVariables>;
export const GetTalentTopVisitorsDocument = gql`
    query getTalentTopVisitors {
  getTopVisitors {
    name
    logo
  }
}
    `;

/**
 * __useGetTalentTopVisitorsQuery__
 *
 * To run a query within a React component, call `useGetTalentTopVisitorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentTopVisitorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentTopVisitorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTalentTopVisitorsQuery(baseOptions?: Apollo.QueryHookOptions<GetTalentTopVisitorsQuery, GetTalentTopVisitorsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetTalentTopVisitorsQuery, GetTalentTopVisitorsQueryVariables>(GetTalentTopVisitorsDocument, options);
}
export function useGetTalentTopVisitorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTalentTopVisitorsQuery, GetTalentTopVisitorsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetTalentTopVisitorsQuery, GetTalentTopVisitorsQueryVariables>(GetTalentTopVisitorsDocument, options);
}
export type GetTalentTopVisitorsQueryHookResult = ReturnType<typeof useGetTalentTopVisitorsQuery>;
export type GetTalentTopVisitorsLazyQueryHookResult = ReturnType<typeof useGetTalentTopVisitorsLazyQuery>;
export type GetTalentTopVisitorsQueryResult = Apollo.QueryResult<GetTalentTopVisitorsQuery, GetTalentTopVisitorsQueryVariables>;
export const GetTalentVisibilityStatsDocument = gql`
    query getTalentVisibilityStats {
  getVisibilityStats {
    items {
      type
      value
      statChange
    }
  }
}
    `;

/**
 * __useGetTalentVisibilityStatsQuery__
 *
 * To run a query within a React component, call `useGetTalentVisibilityStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentVisibilityStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentVisibilityStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTalentVisibilityStatsQuery(baseOptions?: Apollo.QueryHookOptions<GetTalentVisibilityStatsQuery, GetTalentVisibilityStatsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetTalentVisibilityStatsQuery, GetTalentVisibilityStatsQueryVariables>(GetTalentVisibilityStatsDocument, options);
}
export function useGetTalentVisibilityStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTalentVisibilityStatsQuery, GetTalentVisibilityStatsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetTalentVisibilityStatsQuery, GetTalentVisibilityStatsQueryVariables>(GetTalentVisibilityStatsDocument, options);
}
export type GetTalentVisibilityStatsQueryHookResult = ReturnType<typeof useGetTalentVisibilityStatsQuery>;
export type GetTalentVisibilityStatsLazyQueryHookResult = ReturnType<typeof useGetTalentVisibilityStatsLazyQuery>;
export type GetTalentVisibilityStatsQueryResult = Apollo.QueryResult<GetTalentVisibilityStatsQuery, GetTalentVisibilityStatsQueryVariables>;
export const TalentCvUpdatedDocument = gql`
    subscription talentCVUpdated {
  talentCvUpdated {
    entity {
      link
      originName
      raw {
        ...RawTalentCVFields
      }
      summary
    }
  }
}
    ${RawTalentCvFieldsFragmentDoc}`;

/**
 * __useTalentCvUpdatedSubscription__
 *
 * To run a query within a React component, call `useTalentCvUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTalentCvUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentCvUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useTalentCvUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<TalentCvUpdatedSubscription, TalentCvUpdatedSubscriptionVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useSubscription<TalentCvUpdatedSubscription, TalentCvUpdatedSubscriptionVariables>(TalentCvUpdatedDocument, options);
}
export type TalentCvUpdatedSubscriptionHookResult = ReturnType<typeof useTalentCvUpdatedSubscription>;
export type TalentCvUpdatedSubscriptionResult = Apollo.SubscriptionResult<TalentCvUpdatedSubscription>;
export const GetTalentCvWithRawDocument = gql`
    query getTalentCVWithRaw {
  getTalentCV {
    link
    originName
    raw {
      ...RawTalentCVFields
    }
    summary
  }
}
    ${RawTalentCvFieldsFragmentDoc}`;

/**
 * __useGetTalentCvWithRawQuery__
 *
 * To run a query within a React component, call `useGetTalentCvWithRawQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentCvWithRawQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentCvWithRawQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTalentCvWithRawQuery(baseOptions?: Apollo.QueryHookOptions<GetTalentCvWithRawQuery, GetTalentCvWithRawQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetTalentCvWithRawQuery, GetTalentCvWithRawQueryVariables>(GetTalentCvWithRawDocument, options);
}
export function useGetTalentCvWithRawLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTalentCvWithRawQuery, GetTalentCvWithRawQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetTalentCvWithRawQuery, GetTalentCvWithRawQueryVariables>(GetTalentCvWithRawDocument, options);
}
export type GetTalentCvWithRawQueryHookResult = ReturnType<typeof useGetTalentCvWithRawQuery>;
export type GetTalentCvWithRawLazyQueryHookResult = ReturnType<typeof useGetTalentCvWithRawLazyQuery>;
export type GetTalentCvWithRawQueryResult = Apollo.QueryResult<GetTalentCvWithRawQuery, GetTalentCvWithRawQueryVariables>;
export const GetTrendsDocument = gql`
    query getTrends {
  getTrends {
    items {
      ...TrendFields
    }
  }
}
    ${TrendFieldsFragmentDoc}`;

/**
 * __useGetTrendsQuery__
 *
 * To run a query within a React component, call `useGetTrendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrendsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTrendsQuery(baseOptions?: Apollo.QueryHookOptions<GetTrendsQuery, GetTrendsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetTrendsQuery, GetTrendsQueryVariables>(GetTrendsDocument, options);
}
export function useGetTrendsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrendsQuery, GetTrendsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetTrendsQuery, GetTrendsQueryVariables>(GetTrendsDocument, options);
}
export type GetTrendsQueryHookResult = ReturnType<typeof useGetTrendsQuery>;
export type GetTrendsLazyQueryHookResult = ReturnType<typeof useGetTrendsLazyQuery>;
export type GetTrendsQueryResult = Apollo.QueryResult<GetTrendsQuery, GetTrendsQueryVariables>;
export const UpdateTalentSummaryDocument = gql`
    mutation updateTalentSummary($summary: String!) {
  updateTalentSummary(summary: $summary) {
    success
  }
}
    `;
export type UpdateTalentSummaryMutationFn = Apollo.MutationFunction<UpdateTalentSummaryMutation, UpdateTalentSummaryMutationVariables>;

/**
 * __useUpdateTalentSummaryMutation__
 *
 * To run a mutation, you first call `useUpdateTalentSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTalentSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTalentSummaryMutation, { data, loading, error }] = useUpdateTalentSummaryMutation({
 *   variables: {
 *      summary: // value for 'summary'
 *   },
 * });
 */
export function useUpdateTalentSummaryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTalentSummaryMutation, UpdateTalentSummaryMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UpdateTalentSummaryMutation, UpdateTalentSummaryMutationVariables>(UpdateTalentSummaryDocument, options);
}
export type UpdateTalentSummaryMutationHookResult = ReturnType<typeof useUpdateTalentSummaryMutation>;
export type UpdateTalentSummaryMutationResult = Apollo.MutationResult<UpdateTalentSummaryMutation>;
export type UpdateTalentSummaryMutationOptions = Apollo.BaseMutationOptions<UpdateTalentSummaryMutation, UpdateTalentSummaryMutationVariables>;
export const GetOwnTalentProfileDocument = gql`
    query getOwnTalentProfile {
  getOwnTalentProfile {
    id
    jobPreference {
      availability {
        id
        value
      }
      relocation {
        id
        value
        countries
      }
      attendances {
        id
        value
      }
      jobTypes {
        id
        value
      }
    }
    biography {
      expertises {
        ...ExpertiseFullFields
      }
      certificates {
        ...CertificatesFullFields
      }
      educations {
        ...EducationFields
      }
      experiences {
        ...ExperienceFields
      }
      itSkills {
        ...ItSkillsFullFields
      }
      skills {
        ...SkillsFullFields
      }
      languages {
        ...LanguageFullFields
      }
    }
    competencies {
      attempts
      duration
      items {
        ...AssessmentResultFields
      }
    }
    cv {
      ...TalentCvFields
    }
    comparisonScore {
      ...TalentComparisonFields
      average {
        educations
        experiences
        expertises
        skills
        competencies
      }
      talent {
        badge
        competencies
        educations
        experiences
        expertises
        topQualities {
          questionName
          questionType
        }
      }
    }
  }
}
    ${ExpertiseFullFieldsFragmentDoc}
${CertificatesFullFieldsFragmentDoc}
${EducationFieldsFragmentDoc}
${ExperienceFieldsFragmentDoc}
${ItSkillsFullFieldsFragmentDoc}
${SkillsFullFieldsFragmentDoc}
${LanguageFullFieldsFragmentDoc}
${AssessmentResultFieldsFragmentDoc}
${TalentCvFieldsFragmentDoc}
${TalentComparisonFieldsFragmentDoc}`;

/**
 * __useGetOwnTalentProfileQuery__
 *
 * To run a query within a React component, call `useGetOwnTalentProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnTalentProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnTalentProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOwnTalentProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetOwnTalentProfileQuery, GetOwnTalentProfileQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetOwnTalentProfileQuery, GetOwnTalentProfileQueryVariables>(GetOwnTalentProfileDocument, options);
}
export function useGetOwnTalentProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOwnTalentProfileQuery, GetOwnTalentProfileQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetOwnTalentProfileQuery, GetOwnTalentProfileQueryVariables>(GetOwnTalentProfileDocument, options);
}
export type GetOwnTalentProfileQueryHookResult = ReturnType<typeof useGetOwnTalentProfileQuery>;
export type GetOwnTalentProfileLazyQueryHookResult = ReturnType<typeof useGetOwnTalentProfileLazyQuery>;
export type GetOwnTalentProfileQueryResult = Apollo.QueryResult<GetOwnTalentProfileQuery, GetOwnTalentProfileQueryVariables>;
export const UploadTalentFileDocument = gql`
    mutation uploadTalentFile($file: Upload!, $type: FileTypeEnum!) {
  uploadTalentFile(file: $file, type: $type) {
    success
  }
}
    `;
export type UploadTalentFileMutationFn = Apollo.MutationFunction<UploadTalentFileMutation, UploadTalentFileMutationVariables>;

/**
 * __useUploadTalentFileMutation__
 *
 * To run a mutation, you first call `useUploadTalentFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadTalentFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadTalentFileMutation, { data, loading, error }] = useUploadTalentFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUploadTalentFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadTalentFileMutation, UploadTalentFileMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UploadTalentFileMutation, UploadTalentFileMutationVariables>(UploadTalentFileDocument, options);
}
export type UploadTalentFileMutationHookResult = ReturnType<typeof useUploadTalentFileMutation>;
export type UploadTalentFileMutationResult = Apollo.MutationResult<UploadTalentFileMutation>;
export type UploadTalentFileMutationOptions = Apollo.BaseMutationOptions<UploadTalentFileMutation, UploadTalentFileMutationVariables>;
export const RemoveTalentFileDocument = gql`
    mutation removeTalentFile($type: FileTypeEnum!) {
  removeTalentFile(type: $type) {
    success
  }
}
    `;
export type RemoveTalentFileMutationFn = Apollo.MutationFunction<RemoveTalentFileMutation, RemoveTalentFileMutationVariables>;

/**
 * __useRemoveTalentFileMutation__
 *
 * To run a mutation, you first call `useRemoveTalentFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTalentFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTalentFileMutation, { data, loading, error }] = useRemoveTalentFileMutation({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useRemoveTalentFileMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTalentFileMutation, RemoveTalentFileMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<RemoveTalentFileMutation, RemoveTalentFileMutationVariables>(RemoveTalentFileDocument, options);
}
export type RemoveTalentFileMutationHookResult = ReturnType<typeof useRemoveTalentFileMutation>;
export type RemoveTalentFileMutationResult = Apollo.MutationResult<RemoveTalentFileMutation>;
export type RemoveTalentFileMutationOptions = Apollo.BaseMutationOptions<RemoveTalentFileMutation, RemoveTalentFileMutationVariables>;
export const GetAssessmentQuestionnaireDocument = gql`
    query getAssessmentQuestionnaire {
  getAssessmentQuestionnaire {
    talentAssessmentId
    startDate
    assessmentQuestions {
      items {
        id
        name
        text
        title
        answers {
          id
          text
        }
        multipleChoice
      }
    }
  }
}
    `;

/**
 * __useGetAssessmentQuestionnaireQuery__
 *
 * To run a query within a React component, call `useGetAssessmentQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentQuestionnaireQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAssessmentQuestionnaireQuery(baseOptions?: Apollo.QueryHookOptions<GetAssessmentQuestionnaireQuery, GetAssessmentQuestionnaireQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetAssessmentQuestionnaireQuery, GetAssessmentQuestionnaireQueryVariables>(GetAssessmentQuestionnaireDocument, options);
}
export function useGetAssessmentQuestionnaireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssessmentQuestionnaireQuery, GetAssessmentQuestionnaireQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetAssessmentQuestionnaireQuery, GetAssessmentQuestionnaireQueryVariables>(GetAssessmentQuestionnaireDocument, options);
}
export type GetAssessmentQuestionnaireQueryHookResult = ReturnType<typeof useGetAssessmentQuestionnaireQuery>;
export type GetAssessmentQuestionnaireLazyQueryHookResult = ReturnType<typeof useGetAssessmentQuestionnaireLazyQuery>;
export type GetAssessmentQuestionnaireQueryResult = Apollo.QueryResult<GetAssessmentQuestionnaireQuery, GetAssessmentQuestionnaireQueryVariables>;
export const SaveAssessmentAnswersDocument = gql`
    mutation saveAssessmentAnswers($talentAssessmentId: Int!, $answers: [Int!]!) {
  saveAssessmentAnswers(
    talentAssessmentId: $talentAssessmentId
    answers: $answers
  ) {
    success
  }
}
    `;
export type SaveAssessmentAnswersMutationFn = Apollo.MutationFunction<SaveAssessmentAnswersMutation, SaveAssessmentAnswersMutationVariables>;

/**
 * __useSaveAssessmentAnswersMutation__
 *
 * To run a mutation, you first call `useSaveAssessmentAnswersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAssessmentAnswersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAssessmentAnswersMutation, { data, loading, error }] = useSaveAssessmentAnswersMutation({
 *   variables: {
 *      talentAssessmentId: // value for 'talentAssessmentId'
 *      answers: // value for 'answers'
 *   },
 * });
 */
export function useSaveAssessmentAnswersMutation(baseOptions?: Apollo.MutationHookOptions<SaveAssessmentAnswersMutation, SaveAssessmentAnswersMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<SaveAssessmentAnswersMutation, SaveAssessmentAnswersMutationVariables>(SaveAssessmentAnswersDocument, options);
}
export type SaveAssessmentAnswersMutationHookResult = ReturnType<typeof useSaveAssessmentAnswersMutation>;
export type SaveAssessmentAnswersMutationResult = Apollo.MutationResult<SaveAssessmentAnswersMutation>;
export type SaveAssessmentAnswersMutationOptions = Apollo.BaseMutationOptions<SaveAssessmentAnswersMutation, SaveAssessmentAnswersMutationVariables>;
export const GetAssessmentResultsDocument = gql`
    query getAssessmentResults {
  getAssessmentResults {
    attempts
    duration
    items {
      ...AssessmentResultFields
    }
  }
}
    ${AssessmentResultFieldsFragmentDoc}`;

/**
 * __useGetAssessmentResultsQuery__
 *
 * To run a query within a React component, call `useGetAssessmentResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentResultsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAssessmentResultsQuery(baseOptions?: Apollo.QueryHookOptions<GetAssessmentResultsQuery, GetAssessmentResultsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetAssessmentResultsQuery, GetAssessmentResultsQueryVariables>(GetAssessmentResultsDocument, options);
}
export function useGetAssessmentResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssessmentResultsQuery, GetAssessmentResultsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetAssessmentResultsQuery, GetAssessmentResultsQueryVariables>(GetAssessmentResultsDocument, options);
}
export type GetAssessmentResultsQueryHookResult = ReturnType<typeof useGetAssessmentResultsQuery>;
export type GetAssessmentResultsLazyQueryHookResult = ReturnType<typeof useGetAssessmentResultsLazyQuery>;
export type GetAssessmentResultsQueryResult = Apollo.QueryResult<GetAssessmentResultsQuery, GetAssessmentResultsQueryVariables>;
export const AssessmentProgressPingDocument = gql`
    mutation assessmentProgressPing($talentAssessmentId: Int!) {
  assessmentProgressPing(talentAssessmentId: $talentAssessmentId) {
    success
  }
}
    `;
export type AssessmentProgressPingMutationFn = Apollo.MutationFunction<AssessmentProgressPingMutation, AssessmentProgressPingMutationVariables>;

/**
 * __useAssessmentProgressPingMutation__
 *
 * To run a mutation, you first call `useAssessmentProgressPingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssessmentProgressPingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assessmentProgressPingMutation, { data, loading, error }] = useAssessmentProgressPingMutation({
 *   variables: {
 *      talentAssessmentId: // value for 'talentAssessmentId'
 *   },
 * });
 */
export function useAssessmentProgressPingMutation(baseOptions?: Apollo.MutationHookOptions<AssessmentProgressPingMutation, AssessmentProgressPingMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<AssessmentProgressPingMutation, AssessmentProgressPingMutationVariables>(AssessmentProgressPingDocument, options);
}
export type AssessmentProgressPingMutationHookResult = ReturnType<typeof useAssessmentProgressPingMutation>;
export type AssessmentProgressPingMutationResult = Apollo.MutationResult<AssessmentProgressPingMutation>;
export type AssessmentProgressPingMutationOptions = Apollo.BaseMutationOptions<AssessmentProgressPingMutation, AssessmentProgressPingMutationVariables>;
export const FindCertificatesDocument = gql`
    query findCertificates($name: String, $limit: Int = 20, $statuses: [CustomEntityStatusEnum!], $receiveDescription: Boolean = false) {
  findCertificates(name: $name, pagination: {limit: $limit}, statuses: $statuses) {
    items {
      id
      name
      description @include(if: $receiveDescription)
      label @client
      value @client
    }
  }
}
    `;

/**
 * __useFindCertificatesQuery__
 *
 * To run a query within a React component, call `useFindCertificatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCertificatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCertificatesQuery({
 *   variables: {
 *      name: // value for 'name'
 *      limit: // value for 'limit'
 *      statuses: // value for 'statuses'
 *      receiveDescription: // value for 'receiveDescription'
 *   },
 * });
 */
export function useFindCertificatesQuery(baseOptions?: Apollo.QueryHookOptions<FindCertificatesQuery, FindCertificatesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindCertificatesQuery, FindCertificatesQueryVariables>(FindCertificatesDocument, options);
}
export function useFindCertificatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindCertificatesQuery, FindCertificatesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindCertificatesQuery, FindCertificatesQueryVariables>(FindCertificatesDocument, options);
}
export type FindCertificatesQueryHookResult = ReturnType<typeof useFindCertificatesQuery>;
export type FindCertificatesLazyQueryHookResult = ReturnType<typeof useFindCertificatesLazyQuery>;
export type FindCertificatesQueryResult = Apollo.QueryResult<FindCertificatesQuery, FindCertificatesQueryVariables>;
export const GetTalentCertificatesDocument = gql`
    query getTalentCertificates($limit: Int, $page: Int) {
  getTalentCertificates(pagination: {limit: $limit, page: $page}) {
    items {
      ...CertificatesFullFields
    }
    meta {
      ...PaginationFields
    }
  }
}
    ${CertificatesFullFieldsFragmentDoc}
${PaginationFieldsFragmentDoc}`;

/**
 * __useGetTalentCertificatesQuery__
 *
 * To run a query within a React component, call `useGetTalentCertificatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentCertificatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentCertificatesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetTalentCertificatesQuery(baseOptions?: Apollo.QueryHookOptions<GetTalentCertificatesQuery, GetTalentCertificatesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetTalentCertificatesQuery, GetTalentCertificatesQueryVariables>(GetTalentCertificatesDocument, options);
}
export function useGetTalentCertificatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTalentCertificatesQuery, GetTalentCertificatesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetTalentCertificatesQuery, GetTalentCertificatesQueryVariables>(GetTalentCertificatesDocument, options);
}
export type GetTalentCertificatesQueryHookResult = ReturnType<typeof useGetTalentCertificatesQuery>;
export type GetTalentCertificatesLazyQueryHookResult = ReturnType<typeof useGetTalentCertificatesLazyQuery>;
export type GetTalentCertificatesQueryResult = Apollo.QueryResult<GetTalentCertificatesQuery, GetTalentCertificatesQueryVariables>;
export const CreateCertificateDocument = gql`
    mutation createCertificate($name: String!) {
  createCertificate(name: $name, description: $name) {
    id
    name
  }
}
    `;
export type CreateCertificateMutationFn = Apollo.MutationFunction<CreateCertificateMutation, CreateCertificateMutationVariables>;

/**
 * __useCreateCertificateMutation__
 *
 * To run a mutation, you first call `useCreateCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCertificateMutation, { data, loading, error }] = useCreateCertificateMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateCertificateMutation(baseOptions?: Apollo.MutationHookOptions<CreateCertificateMutation, CreateCertificateMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<CreateCertificateMutation, CreateCertificateMutationVariables>(CreateCertificateDocument, options);
}
export type CreateCertificateMutationHookResult = ReturnType<typeof useCreateCertificateMutation>;
export type CreateCertificateMutationResult = Apollo.MutationResult<CreateCertificateMutation>;
export type CreateCertificateMutationOptions = Apollo.BaseMutationOptions<CreateCertificateMutation, CreateCertificateMutationVariables>;
export const CreateTalentCertificateDocument = gql`
    mutation createTalentCertificate($certificateId: Int!, $certifiedIn: DateTime, $issuedBy: String) {
  createTalentCertificate(
    certificateId: $certificateId
    certifiedIn: $certifiedIn
    issuedBy: $issuedBy
  ) {
    id
    certificate {
      id
      name
      description
      label @client
      value @client
    }
    certifiedIn
    issuedBy
  }
}
    `;
export type CreateTalentCertificateMutationFn = Apollo.MutationFunction<CreateTalentCertificateMutation, CreateTalentCertificateMutationVariables>;

/**
 * __useCreateTalentCertificateMutation__
 *
 * To run a mutation, you first call `useCreateTalentCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTalentCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTalentCertificateMutation, { data, loading, error }] = useCreateTalentCertificateMutation({
 *   variables: {
 *      certificateId: // value for 'certificateId'
 *      certifiedIn: // value for 'certifiedIn'
 *      issuedBy: // value for 'issuedBy'
 *   },
 * });
 */
export function useCreateTalentCertificateMutation(baseOptions?: Apollo.MutationHookOptions<CreateTalentCertificateMutation, CreateTalentCertificateMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<CreateTalentCertificateMutation, CreateTalentCertificateMutationVariables>(CreateTalentCertificateDocument, options);
}
export type CreateTalentCertificateMutationHookResult = ReturnType<typeof useCreateTalentCertificateMutation>;
export type CreateTalentCertificateMutationResult = Apollo.MutationResult<CreateTalentCertificateMutation>;
export type CreateTalentCertificateMutationOptions = Apollo.BaseMutationOptions<CreateTalentCertificateMutation, CreateTalentCertificateMutationVariables>;
export const UpdateTalentCertificateDocument = gql`
    mutation updateTalentCertificate($talentCertificateId: Int!, $certificateId: Int, $certifiedIn: DateTime, $issuedBy: String) {
  updateTalentCertificate(
    talentCertificateId: $talentCertificateId
    certificateId: $certificateId
    certifiedIn: $certifiedIn
    issuedBy: $issuedBy
  ) {
    id
    certificate {
      id
      name
      description
      label @client
      value @client
    }
    certifiedIn
    issuedBy
  }
}
    `;
export type UpdateTalentCertificateMutationFn = Apollo.MutationFunction<UpdateTalentCertificateMutation, UpdateTalentCertificateMutationVariables>;

/**
 * __useUpdateTalentCertificateMutation__
 *
 * To run a mutation, you first call `useUpdateTalentCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTalentCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTalentCertificateMutation, { data, loading, error }] = useUpdateTalentCertificateMutation({
 *   variables: {
 *      talentCertificateId: // value for 'talentCertificateId'
 *      certificateId: // value for 'certificateId'
 *      certifiedIn: // value for 'certifiedIn'
 *      issuedBy: // value for 'issuedBy'
 *   },
 * });
 */
export function useUpdateTalentCertificateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTalentCertificateMutation, UpdateTalentCertificateMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UpdateTalentCertificateMutation, UpdateTalentCertificateMutationVariables>(UpdateTalentCertificateDocument, options);
}
export type UpdateTalentCertificateMutationHookResult = ReturnType<typeof useUpdateTalentCertificateMutation>;
export type UpdateTalentCertificateMutationResult = Apollo.MutationResult<UpdateTalentCertificateMutation>;
export type UpdateTalentCertificateMutationOptions = Apollo.BaseMutationOptions<UpdateTalentCertificateMutation, UpdateTalentCertificateMutationVariables>;
export const DeleteTalentCertificateDocument = gql`
    mutation deleteTalentCertificate($id: Int!) {
  deleteTalentCertificate(id: $id) {
    success
  }
}
    `;
export type DeleteTalentCertificateMutationFn = Apollo.MutationFunction<DeleteTalentCertificateMutation, DeleteTalentCertificateMutationVariables>;

/**
 * __useDeleteTalentCertificateMutation__
 *
 * To run a mutation, you first call `useDeleteTalentCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTalentCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTalentCertificateMutation, { data, loading, error }] = useDeleteTalentCertificateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTalentCertificateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTalentCertificateMutation, DeleteTalentCertificateMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<DeleteTalentCertificateMutation, DeleteTalentCertificateMutationVariables>(DeleteTalentCertificateDocument, options);
}
export type DeleteTalentCertificateMutationHookResult = ReturnType<typeof useDeleteTalentCertificateMutation>;
export type DeleteTalentCertificateMutationResult = Apollo.MutationResult<DeleteTalentCertificateMutation>;
export type DeleteTalentCertificateMutationOptions = Apollo.BaseMutationOptions<DeleteTalentCertificateMutation, DeleteTalentCertificateMutationVariables>;
export const GetTalentCvDocument = gql`
    query getTalentCV {
  getTalent {
    cv {
      ...TalentCvFields
    }
  }
}
    ${TalentCvFieldsFragmentDoc}`;

/**
 * __useGetTalentCvQuery__
 *
 * To run a query within a React component, call `useGetTalentCvQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentCvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentCvQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTalentCvQuery(baseOptions?: Apollo.QueryHookOptions<GetTalentCvQuery, GetTalentCvQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetTalentCvQuery, GetTalentCvQueryVariables>(GetTalentCvDocument, options);
}
export function useGetTalentCvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTalentCvQuery, GetTalentCvQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetTalentCvQuery, GetTalentCvQueryVariables>(GetTalentCvDocument, options);
}
export type GetTalentCvQueryHookResult = ReturnType<typeof useGetTalentCvQuery>;
export type GetTalentCvLazyQueryHookResult = ReturnType<typeof useGetTalentCvLazyQuery>;
export type GetTalentCvQueryResult = Apollo.QueryResult<GetTalentCvQuery, GetTalentCvQueryVariables>;
export const CreateUniversityDocument = gql`
    mutation createUniversity($name: String!, $campus: String!) {
  createUniversity(campus: $campus, name: $name) {
    ...UniversityFields
  }
}
    ${UniversityFieldsFragmentDoc}`;
export type CreateUniversityMutationFn = Apollo.MutationFunction<CreateUniversityMutation, CreateUniversityMutationVariables>;

/**
 * __useCreateUniversityMutation__
 *
 * To run a mutation, you first call `useCreateUniversityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUniversityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUniversityMutation, { data, loading, error }] = useCreateUniversityMutation({
 *   variables: {
 *      name: // value for 'name'
 *      campus: // value for 'campus'
 *   },
 * });
 */
export function useCreateUniversityMutation(baseOptions?: Apollo.MutationHookOptions<CreateUniversityMutation, CreateUniversityMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<CreateUniversityMutation, CreateUniversityMutationVariables>(CreateUniversityDocument, options);
}
export type CreateUniversityMutationHookResult = ReturnType<typeof useCreateUniversityMutation>;
export type CreateUniversityMutationResult = Apollo.MutationResult<CreateUniversityMutation>;
export type CreateUniversityMutationOptions = Apollo.BaseMutationOptions<CreateUniversityMutation, CreateUniversityMutationVariables>;
export const UpdateUniversityDocument = gql`
    mutation updateUniversity($universityId: Int!, $campus: String!) {
  updateUniversity(campus: $campus, universityId: $universityId) {
    ...UniversityFields
  }
}
    ${UniversityFieldsFragmentDoc}`;
export type UpdateUniversityMutationFn = Apollo.MutationFunction<UpdateUniversityMutation, UpdateUniversityMutationVariables>;

/**
 * __useUpdateUniversityMutation__
 *
 * To run a mutation, you first call `useUpdateUniversityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUniversityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUniversityMutation, { data, loading, error }] = useUpdateUniversityMutation({
 *   variables: {
 *      universityId: // value for 'universityId'
 *      campus: // value for 'campus'
 *   },
 * });
 */
export function useUpdateUniversityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUniversityMutation, UpdateUniversityMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UpdateUniversityMutation, UpdateUniversityMutationVariables>(UpdateUniversityDocument, options);
}
export type UpdateUniversityMutationHookResult = ReturnType<typeof useUpdateUniversityMutation>;
export type UpdateUniversityMutationResult = Apollo.MutationResult<UpdateUniversityMutation>;
export type UpdateUniversityMutationOptions = Apollo.BaseMutationOptions<UpdateUniversityMutation, UpdateUniversityMutationVariables>;
export const CreateUniversityCourseDocument = gql`
    mutation createUniversityCourse($name: String!) {
  createUniversityCourse(name: $name) {
    id
    name
  }
}
    `;
export type CreateUniversityCourseMutationFn = Apollo.MutationFunction<CreateUniversityCourseMutation, CreateUniversityCourseMutationVariables>;

/**
 * __useCreateUniversityCourseMutation__
 *
 * To run a mutation, you first call `useCreateUniversityCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUniversityCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUniversityCourseMutation, { data, loading, error }] = useCreateUniversityCourseMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateUniversityCourseMutation(baseOptions?: Apollo.MutationHookOptions<CreateUniversityCourseMutation, CreateUniversityCourseMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<CreateUniversityCourseMutation, CreateUniversityCourseMutationVariables>(CreateUniversityCourseDocument, options);
}
export type CreateUniversityCourseMutationHookResult = ReturnType<typeof useCreateUniversityCourseMutation>;
export type CreateUniversityCourseMutationResult = Apollo.MutationResult<CreateUniversityCourseMutation>;
export type CreateUniversityCourseMutationOptions = Apollo.BaseMutationOptions<CreateUniversityCourseMutation, CreateUniversityCourseMutationVariables>;
export const CreateTalentEducationDocument = gql`
    mutation createTalentEducation($universityId: Int!, $courseId: Int!, $degreeId: Int!, $campusId: Int!, $graduatedIn: DateTime!) {
  createTalentEducation(
    universityId: $universityId
    courseId: $courseId
    degreeId: $degreeId
    campusId: $campusId
    graduatedIn: $graduatedIn
  ) {
    course {
      name
    }
  }
}
    `;
export type CreateTalentEducationMutationFn = Apollo.MutationFunction<CreateTalentEducationMutation, CreateTalentEducationMutationVariables>;

/**
 * __useCreateTalentEducationMutation__
 *
 * To run a mutation, you first call `useCreateTalentEducationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTalentEducationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTalentEducationMutation, { data, loading, error }] = useCreateTalentEducationMutation({
 *   variables: {
 *      universityId: // value for 'universityId'
 *      courseId: // value for 'courseId'
 *      degreeId: // value for 'degreeId'
 *      campusId: // value for 'campusId'
 *      graduatedIn: // value for 'graduatedIn'
 *   },
 * });
 */
export function useCreateTalentEducationMutation(baseOptions?: Apollo.MutationHookOptions<CreateTalentEducationMutation, CreateTalentEducationMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<CreateTalentEducationMutation, CreateTalentEducationMutationVariables>(CreateTalentEducationDocument, options);
}
export type CreateTalentEducationMutationHookResult = ReturnType<typeof useCreateTalentEducationMutation>;
export type CreateTalentEducationMutationResult = Apollo.MutationResult<CreateTalentEducationMutation>;
export type CreateTalentEducationMutationOptions = Apollo.BaseMutationOptions<CreateTalentEducationMutation, CreateTalentEducationMutationVariables>;
export const DeleteEducationDocument = gql`
    mutation deleteEducation($id: Int!) {
  deleteTalentEducation(id: $id) {
    success
  }
}
    `;
export type DeleteEducationMutationFn = Apollo.MutationFunction<DeleteEducationMutation, DeleteEducationMutationVariables>;

/**
 * __useDeleteEducationMutation__
 *
 * To run a mutation, you first call `useDeleteEducationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEducationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEducationMutation, { data, loading, error }] = useDeleteEducationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEducationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEducationMutation, DeleteEducationMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<DeleteEducationMutation, DeleteEducationMutationVariables>(DeleteEducationDocument, options);
}
export type DeleteEducationMutationHookResult = ReturnType<typeof useDeleteEducationMutation>;
export type DeleteEducationMutationResult = Apollo.MutationResult<DeleteEducationMutation>;
export type DeleteEducationMutationOptions = Apollo.BaseMutationOptions<DeleteEducationMutation, DeleteEducationMutationVariables>;
export const UpdateTalentEducationDocument = gql`
    mutation updateTalentEducation($courseId: Int, $degreeId: Int, $educationId: Int!, $graduatedIn: DateTime, $universityId: Int, $campusId: Int!) {
  updateTalentEducation(
    courseId: $courseId
    degreeId: $degreeId
    educationId: $educationId
    graduatedIn: $graduatedIn
    universityId: $universityId
    campusId: $campusId
  ) {
    id
    course {
      id
      name
    }
  }
}
    `;
export type UpdateTalentEducationMutationFn = Apollo.MutationFunction<UpdateTalentEducationMutation, UpdateTalentEducationMutationVariables>;

/**
 * __useUpdateTalentEducationMutation__
 *
 * To run a mutation, you first call `useUpdateTalentEducationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTalentEducationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTalentEducationMutation, { data, loading, error }] = useUpdateTalentEducationMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      degreeId: // value for 'degreeId'
 *      educationId: // value for 'educationId'
 *      graduatedIn: // value for 'graduatedIn'
 *      universityId: // value for 'universityId'
 *      campusId: // value for 'campusId'
 *   },
 * });
 */
export function useUpdateTalentEducationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTalentEducationMutation, UpdateTalentEducationMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UpdateTalentEducationMutation, UpdateTalentEducationMutationVariables>(UpdateTalentEducationDocument, options);
}
export type UpdateTalentEducationMutationHookResult = ReturnType<typeof useUpdateTalentEducationMutation>;
export type UpdateTalentEducationMutationResult = Apollo.MutationResult<UpdateTalentEducationMutation>;
export type UpdateTalentEducationMutationOptions = Apollo.BaseMutationOptions<UpdateTalentEducationMutation, UpdateTalentEducationMutationVariables>;
export const FindUniversityCourseDocument = gql`
    query findUniversityCourse($name: String, $limit: Int = 20) {
  findUniversityCourses(name: $name, pagination: {limit: $limit}) {
    items {
      id
      name
      label @client
      value @client
    }
    meta {
      currentPage
      itemCount
      itemsPerPage
      totalItems
      totalPages
    }
  }
}
    `;

/**
 * __useFindUniversityCourseQuery__
 *
 * To run a query within a React component, call `useFindUniversityCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUniversityCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUniversityCourseQuery({
 *   variables: {
 *      name: // value for 'name'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useFindUniversityCourseQuery(baseOptions?: Apollo.QueryHookOptions<FindUniversityCourseQuery, FindUniversityCourseQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindUniversityCourseQuery, FindUniversityCourseQueryVariables>(FindUniversityCourseDocument, options);
}
export function useFindUniversityCourseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUniversityCourseQuery, FindUniversityCourseQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindUniversityCourseQuery, FindUniversityCourseQueryVariables>(FindUniversityCourseDocument, options);
}
export type FindUniversityCourseQueryHookResult = ReturnType<typeof useFindUniversityCourseQuery>;
export type FindUniversityCourseLazyQueryHookResult = ReturnType<typeof useFindUniversityCourseLazyQuery>;
export type FindUniversityCourseQueryResult = Apollo.QueryResult<FindUniversityCourseQuery, FindUniversityCourseQueryVariables>;
export const FindUniversityDegreeDocument = gql`
    query findUniversityDegree($name: String, $limit: Int = 20) {
  findUniversityDegrees(name: $name, pagination: {limit: $limit}) {
    items {
      id
      name
      label @client
      value @client
    }
    meta {
      currentPage
      itemCount
      itemsPerPage
      totalItems
      totalPages
    }
  }
}
    `;

/**
 * __useFindUniversityDegreeQuery__
 *
 * To run a query within a React component, call `useFindUniversityDegreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUniversityDegreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUniversityDegreeQuery({
 *   variables: {
 *      name: // value for 'name'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useFindUniversityDegreeQuery(baseOptions?: Apollo.QueryHookOptions<FindUniversityDegreeQuery, FindUniversityDegreeQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindUniversityDegreeQuery, FindUniversityDegreeQueryVariables>(FindUniversityDegreeDocument, options);
}
export function useFindUniversityDegreeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUniversityDegreeQuery, FindUniversityDegreeQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindUniversityDegreeQuery, FindUniversityDegreeQueryVariables>(FindUniversityDegreeDocument, options);
}
export type FindUniversityDegreeQueryHookResult = ReturnType<typeof useFindUniversityDegreeQuery>;
export type FindUniversityDegreeLazyQueryHookResult = ReturnType<typeof useFindUniversityDegreeLazyQuery>;
export type FindUniversityDegreeQueryResult = Apollo.QueryResult<FindUniversityDegreeQuery, FindUniversityDegreeQueryVariables>;
export const FindUniversitiesDocument = gql`
    query findUniversities($name: String, $limit: Int = 20) {
  findUniversities(name: $name, pagination: {limit: $limit}) {
    items {
      ...UniversityFields
    }
    meta {
      currentPage
      itemCount
      itemsPerPage
      totalItems
      totalPages
    }
  }
}
    ${UniversityFieldsFragmentDoc}`;

/**
 * __useFindUniversitiesQuery__
 *
 * To run a query within a React component, call `useFindUniversitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUniversitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUniversitiesQuery({
 *   variables: {
 *      name: // value for 'name'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useFindUniversitiesQuery(baseOptions?: Apollo.QueryHookOptions<FindUniversitiesQuery, FindUniversitiesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindUniversitiesQuery, FindUniversitiesQueryVariables>(FindUniversitiesDocument, options);
}
export function useFindUniversitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUniversitiesQuery, FindUniversitiesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindUniversitiesQuery, FindUniversitiesQueryVariables>(FindUniversitiesDocument, options);
}
export type FindUniversitiesQueryHookResult = ReturnType<typeof useFindUniversitiesQuery>;
export type FindUniversitiesLazyQueryHookResult = ReturnType<typeof useFindUniversitiesLazyQuery>;
export type FindUniversitiesQueryResult = Apollo.QueryResult<FindUniversitiesQuery, FindUniversitiesQueryVariables>;
export const FindCampusesDocument = gql`
    query findCampuses($universityIds: [Float!]!) {
  findCampuses(universityIds: $universityIds) {
    items {
      id
      name
      label @client
      value @client
    }
  }
}
    `;

/**
 * __useFindCampusesQuery__
 *
 * To run a query within a React component, call `useFindCampusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCampusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCampusesQuery({
 *   variables: {
 *      universityIds: // value for 'universityIds'
 *   },
 * });
 */
export function useFindCampusesQuery(baseOptions: Apollo.QueryHookOptions<FindCampusesQuery, FindCampusesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindCampusesQuery, FindCampusesQueryVariables>(FindCampusesDocument, options);
}
export function useFindCampusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindCampusesQuery, FindCampusesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindCampusesQuery, FindCampusesQueryVariables>(FindCampusesDocument, options);
}
export type FindCampusesQueryHookResult = ReturnType<typeof useFindCampusesQuery>;
export type FindCampusesLazyQueryHookResult = ReturnType<typeof useFindCampusesLazyQuery>;
export type FindCampusesQueryResult = Apollo.QueryResult<FindCampusesQuery, FindCampusesQueryVariables>;
export const GetTalentEducationsDocument = gql`
    query getTalentEducations($limit: Int, $page: Int) {
  getTalentEducations(limit: $limit, page: $page) {
    meta {
      ...PaginationFields
    }
    items {
      ...EducationFields
    }
  }
}
    ${PaginationFieldsFragmentDoc}
${EducationFieldsFragmentDoc}`;

/**
 * __useGetTalentEducationsQuery__
 *
 * To run a query within a React component, call `useGetTalentEducationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentEducationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentEducationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetTalentEducationsQuery(baseOptions?: Apollo.QueryHookOptions<GetTalentEducationsQuery, GetTalentEducationsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetTalentEducationsQuery, GetTalentEducationsQueryVariables>(GetTalentEducationsDocument, options);
}
export function useGetTalentEducationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTalentEducationsQuery, GetTalentEducationsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetTalentEducationsQuery, GetTalentEducationsQueryVariables>(GetTalentEducationsDocument, options);
}
export type GetTalentEducationsQueryHookResult = ReturnType<typeof useGetTalentEducationsQuery>;
export type GetTalentEducationsLazyQueryHookResult = ReturnType<typeof useGetTalentEducationsLazyQuery>;
export type GetTalentEducationsQueryResult = Apollo.QueryResult<GetTalentEducationsQuery, GetTalentEducationsQueryVariables>;
export const FindEmployersDocument = gql`
    query findEmployers($name: String, $limit: Int = 20, $page: Int, $order: [String!], $includeMeta: Boolean = false) {
  findEmployers(
    name: $name
    pagination: {limit: $limit, page: $page}
    order: $order
  ) {
    items {
      ...Employer
    }
    meta @include(if: $includeMeta) {
      ...PaginationFields
    }
  }
}
    ${EmployerFragmentDoc}
${PaginationFieldsFragmentDoc}`;

/**
 * __useFindEmployersQuery__
 *
 * To run a query within a React component, call `useFindEmployersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEmployersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEmployersQuery({
 *   variables: {
 *      name: // value for 'name'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      order: // value for 'order'
 *      includeMeta: // value for 'includeMeta'
 *   },
 * });
 */
export function useFindEmployersQuery(baseOptions?: Apollo.QueryHookOptions<FindEmployersQuery, FindEmployersQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindEmployersQuery, FindEmployersQueryVariables>(FindEmployersDocument, options);
}
export function useFindEmployersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindEmployersQuery, FindEmployersQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindEmployersQuery, FindEmployersQueryVariables>(FindEmployersDocument, options);
}
export type FindEmployersQueryHookResult = ReturnType<typeof useFindEmployersQuery>;
export type FindEmployersLazyQueryHookResult = ReturnType<typeof useFindEmployersLazyQuery>;
export type FindEmployersQueryResult = Apollo.QueryResult<FindEmployersQuery, FindEmployersQueryVariables>;
export const FindOccupationsDocument = gql`
    query findOccupations($name: String, $limit: Int = 20, $page: Int) {
  findOccupations(name: $name, pagination: {limit: $limit, page: $page}) {
    items {
      id
      name
      label @client
      value @client
    }
    meta {
      ...PaginationFields
    }
  }
}
    ${PaginationFieldsFragmentDoc}`;

/**
 * __useFindOccupationsQuery__
 *
 * To run a query within a React component, call `useFindOccupationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOccupationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOccupationsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFindOccupationsQuery(baseOptions?: Apollo.QueryHookOptions<FindOccupationsQuery, FindOccupationsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindOccupationsQuery, FindOccupationsQueryVariables>(FindOccupationsDocument, options);
}
export function useFindOccupationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindOccupationsQuery, FindOccupationsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindOccupationsQuery, FindOccupationsQueryVariables>(FindOccupationsDocument, options);
}
export type FindOccupationsQueryHookResult = ReturnType<typeof useFindOccupationsQuery>;
export type FindOccupationsLazyQueryHookResult = ReturnType<typeof useFindOccupationsLazyQuery>;
export type FindOccupationsQueryResult = Apollo.QueryResult<FindOccupationsQuery, FindOccupationsQueryVariables>;
export const FindEmployerSizesDocument = gql`
    query findEmployerSizes($name: String, $limit: Int = 20) {
  findEmployerSizes(name: $name, pagination: {limit: $limit}) {
    items {
      id
      name
      label @client
      value @client
    }
  }
}
    `;

/**
 * __useFindEmployerSizesQuery__
 *
 * To run a query within a React component, call `useFindEmployerSizesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEmployerSizesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEmployerSizesQuery({
 *   variables: {
 *      name: // value for 'name'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useFindEmployerSizesQuery(baseOptions?: Apollo.QueryHookOptions<FindEmployerSizesQuery, FindEmployerSizesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindEmployerSizesQuery, FindEmployerSizesQueryVariables>(FindEmployerSizesDocument, options);
}
export function useFindEmployerSizesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindEmployerSizesQuery, FindEmployerSizesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindEmployerSizesQuery, FindEmployerSizesQueryVariables>(FindEmployerSizesDocument, options);
}
export type FindEmployerSizesQueryHookResult = ReturnType<typeof useFindEmployerSizesQuery>;
export type FindEmployerSizesLazyQueryHookResult = ReturnType<typeof useFindEmployerSizesLazyQuery>;
export type FindEmployerSizesQueryResult = Apollo.QueryResult<FindEmployerSizesQuery, FindEmployerSizesQueryVariables>;
export const FindIndustriesDocument = gql`
    query findIndustries($name: String, $limit: Int = 20, $page: Int) {
  findEmployerIndustries(name: $name, pagination: {limit: $limit, page: $page}) {
    items {
      id
      name
      label @client
      value @client
    }
    meta {
      ...PaginationFields
    }
  }
}
    ${PaginationFieldsFragmentDoc}`;

/**
 * __useFindIndustriesQuery__
 *
 * To run a query within a React component, call `useFindIndustriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindIndustriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindIndustriesQuery({
 *   variables: {
 *      name: // value for 'name'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFindIndustriesQuery(baseOptions?: Apollo.QueryHookOptions<FindIndustriesQuery, FindIndustriesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindIndustriesQuery, FindIndustriesQueryVariables>(FindIndustriesDocument, options);
}
export function useFindIndustriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindIndustriesQuery, FindIndustriesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindIndustriesQuery, FindIndustriesQueryVariables>(FindIndustriesDocument, options);
}
export type FindIndustriesQueryHookResult = ReturnType<typeof useFindIndustriesQuery>;
export type FindIndustriesLazyQueryHookResult = ReturnType<typeof useFindIndustriesLazyQuery>;
export type FindIndustriesQueryResult = Apollo.QueryResult<FindIndustriesQuery, FindIndustriesQueryVariables>;
export const FindEmployerTypesDocument = gql`
    query findEmployerTypes($name: String, $limit: Int = 20) {
  findEmployerTypes(name: $name, pagination: {limit: $limit}) {
    items {
      id
      name
      label @client
      value @client
    }
  }
}
    `;

/**
 * __useFindEmployerTypesQuery__
 *
 * To run a query within a React component, call `useFindEmployerTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEmployerTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEmployerTypesQuery({
 *   variables: {
 *      name: // value for 'name'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useFindEmployerTypesQuery(baseOptions?: Apollo.QueryHookOptions<FindEmployerTypesQuery, FindEmployerTypesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindEmployerTypesQuery, FindEmployerTypesQueryVariables>(FindEmployerTypesDocument, options);
}
export function useFindEmployerTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindEmployerTypesQuery, FindEmployerTypesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindEmployerTypesQuery, FindEmployerTypesQueryVariables>(FindEmployerTypesDocument, options);
}
export type FindEmployerTypesQueryHookResult = ReturnType<typeof useFindEmployerTypesQuery>;
export type FindEmployerTypesLazyQueryHookResult = ReturnType<typeof useFindEmployerTypesLazyQuery>;
export type FindEmployerTypesQueryResult = Apollo.QueryResult<FindEmployerTypesQuery, FindEmployerTypesQueryVariables>;
export const GetExperiencesDocument = gql`
    query getExperiences($limit: Int, $page: Int) {
  getExperiences(pagination: {limit: $limit, page: $page}) {
    ... on SimpleExperiencesType {
      simpleExperiences: items {
        ...SimpleExperienceFields
      }
      meta {
        ...PaginationFields
      }
    }
    ... on ExperiencesType {
      items {
        ...ExperienceFields
      }
      meta {
        ...PaginationFields
      }
    }
  }
}
    ${SimpleExperienceFieldsFragmentDoc}
${PaginationFieldsFragmentDoc}
${ExperienceFieldsFragmentDoc}`;

/**
 * __useGetExperiencesQuery__
 *
 * To run a query within a React component, call `useGetExperiencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExperiencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExperiencesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetExperiencesQuery(baseOptions?: Apollo.QueryHookOptions<GetExperiencesQuery, GetExperiencesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetExperiencesQuery, GetExperiencesQueryVariables>(GetExperiencesDocument, options);
}
export function useGetExperiencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExperiencesQuery, GetExperiencesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetExperiencesQuery, GetExperiencesQueryVariables>(GetExperiencesDocument, options);
}
export type GetExperiencesQueryHookResult = ReturnType<typeof useGetExperiencesQuery>;
export type GetExperiencesLazyQueryHookResult = ReturnType<typeof useGetExperiencesLazyQuery>;
export type GetExperiencesQueryResult = Apollo.QueryResult<GetExperiencesQuery, GetExperiencesQueryVariables>;
export const CreateEmployerDocument = gql`
    mutation createEmployer($industryId: Int!, $name: String!, $sizeId: Int!, $website: String) {
  createEmployer(
    industryId: $industryId
    name: $name
    sizeId: $sizeId
    website: $website
  ) {
    ...Employer
  }
}
    ${EmployerFragmentDoc}`;
export type CreateEmployerMutationFn = Apollo.MutationFunction<CreateEmployerMutation, CreateEmployerMutationVariables>;

/**
 * __useCreateEmployerMutation__
 *
 * To run a mutation, you first call `useCreateEmployerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployerMutation, { data, loading, error }] = useCreateEmployerMutation({
 *   variables: {
 *      industryId: // value for 'industryId'
 *      name: // value for 'name'
 *      sizeId: // value for 'sizeId'
 *      website: // value for 'website'
 *   },
 * });
 */
export function useCreateEmployerMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmployerMutation, CreateEmployerMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<CreateEmployerMutation, CreateEmployerMutationVariables>(CreateEmployerDocument, options);
}
export type CreateEmployerMutationHookResult = ReturnType<typeof useCreateEmployerMutation>;
export type CreateEmployerMutationResult = Apollo.MutationResult<CreateEmployerMutation>;
export type CreateEmployerMutationOptions = Apollo.BaseMutationOptions<CreateEmployerMutation, CreateEmployerMutationVariables>;
export const UpdateEmployerDocument = gql`
    mutation updateEmployer($employerId: Int!, $industryId: Int!, $name: String!, $sizeId: Int!, $website: String, $headquarter: String, $typeId: Int, $logo: String) {
  updateEmployer(
    employerId: $employerId
    industryId: $industryId
    name: $name
    sizeId: $sizeId
    website: $website
    headquarter: $headquarter
    typeId: $typeId
    logo: $logo
  ) {
    ...Employer
  }
}
    ${EmployerFragmentDoc}`;
export type UpdateEmployerMutationFn = Apollo.MutationFunction<UpdateEmployerMutation, UpdateEmployerMutationVariables>;

/**
 * __useUpdateEmployerMutation__
 *
 * To run a mutation, you first call `useUpdateEmployerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployerMutation, { data, loading, error }] = useUpdateEmployerMutation({
 *   variables: {
 *      employerId: // value for 'employerId'
 *      industryId: // value for 'industryId'
 *      name: // value for 'name'
 *      sizeId: // value for 'sizeId'
 *      website: // value for 'website'
 *      headquarter: // value for 'headquarter'
 *      typeId: // value for 'typeId'
 *      logo: // value for 'logo'
 *   },
 * });
 */
export function useUpdateEmployerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployerMutation, UpdateEmployerMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UpdateEmployerMutation, UpdateEmployerMutationVariables>(UpdateEmployerDocument, options);
}
export type UpdateEmployerMutationHookResult = ReturnType<typeof useUpdateEmployerMutation>;
export type UpdateEmployerMutationResult = Apollo.MutationResult<UpdateEmployerMutation>;
export type UpdateEmployerMutationOptions = Apollo.BaseMutationOptions<UpdateEmployerMutation, UpdateEmployerMutationVariables>;
export const CreateExperienceDocument = gql`
    mutation createExperience($employerId: Int!, $from: DateTime!, $to: DateTime, $jobTitle: String!, $jobTypeValue: JobTypeValueEnum, $keyActivities: String, $location: String, $occupationId: Int!) {
  createExperience(
    employerId: $employerId
    from: $from
    to: $to
    jobTitle: $jobTitle
    jobTypeValue: $jobTypeValue
    keyActivities: $keyActivities
    location: $location
    occupationId: $occupationId
  ) {
    ...ExperienceFields
  }
}
    ${ExperienceFieldsFragmentDoc}`;
export type CreateExperienceMutationFn = Apollo.MutationFunction<CreateExperienceMutation, CreateExperienceMutationVariables>;

/**
 * __useCreateExperienceMutation__
 *
 * To run a mutation, you first call `useCreateExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExperienceMutation, { data, loading, error }] = useCreateExperienceMutation({
 *   variables: {
 *      employerId: // value for 'employerId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      jobTitle: // value for 'jobTitle'
 *      jobTypeValue: // value for 'jobTypeValue'
 *      keyActivities: // value for 'keyActivities'
 *      location: // value for 'location'
 *      occupationId: // value for 'occupationId'
 *   },
 * });
 */
export function useCreateExperienceMutation(baseOptions?: Apollo.MutationHookOptions<CreateExperienceMutation, CreateExperienceMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<CreateExperienceMutation, CreateExperienceMutationVariables>(CreateExperienceDocument, options);
}
export type CreateExperienceMutationHookResult = ReturnType<typeof useCreateExperienceMutation>;
export type CreateExperienceMutationResult = Apollo.MutationResult<CreateExperienceMutation>;
export type CreateExperienceMutationOptions = Apollo.BaseMutationOptions<CreateExperienceMutation, CreateExperienceMutationVariables>;
export const UpdateExperienceDocument = gql`
    mutation updateExperience($employerId: Int, $experienceId: Int!, $from: DateTime, $jobTitle: String, $jobTypeValue: JobTypeValueEnum, $keyActivities: String, $location: String, $occupationId: Int, $to: DateTime) {
  updateExperience(
    employerId: $employerId
    experienceId: $experienceId
    from: $from
    jobTitle: $jobTitle
    jobTypeValue: $jobTypeValue
    keyActivities: $keyActivities
    location: $location
    occupationId: $occupationId
    to: $to
  ) {
    ...ExperienceFields
  }
}
    ${ExperienceFieldsFragmentDoc}`;
export type UpdateExperienceMutationFn = Apollo.MutationFunction<UpdateExperienceMutation, UpdateExperienceMutationVariables>;

/**
 * __useUpdateExperienceMutation__
 *
 * To run a mutation, you first call `useUpdateExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExperienceMutation, { data, loading, error }] = useUpdateExperienceMutation({
 *   variables: {
 *      employerId: // value for 'employerId'
 *      experienceId: // value for 'experienceId'
 *      from: // value for 'from'
 *      jobTitle: // value for 'jobTitle'
 *      jobTypeValue: // value for 'jobTypeValue'
 *      keyActivities: // value for 'keyActivities'
 *      location: // value for 'location'
 *      occupationId: // value for 'occupationId'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useUpdateExperienceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExperienceMutation, UpdateExperienceMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UpdateExperienceMutation, UpdateExperienceMutationVariables>(UpdateExperienceDocument, options);
}
export type UpdateExperienceMutationHookResult = ReturnType<typeof useUpdateExperienceMutation>;
export type UpdateExperienceMutationResult = Apollo.MutationResult<UpdateExperienceMutation>;
export type UpdateExperienceMutationOptions = Apollo.BaseMutationOptions<UpdateExperienceMutation, UpdateExperienceMutationVariables>;
export const DeleteExperienceDocument = gql`
    mutation deleteExperience($id: Int!) {
  deleteExperience(id: $id) {
    success
  }
}
    `;
export type DeleteExperienceMutationFn = Apollo.MutationFunction<DeleteExperienceMutation, DeleteExperienceMutationVariables>;

/**
 * __useDeleteExperienceMutation__
 *
 * To run a mutation, you first call `useDeleteExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExperienceMutation, { data, loading, error }] = useDeleteExperienceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteExperienceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteExperienceMutation, DeleteExperienceMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<DeleteExperienceMutation, DeleteExperienceMutationVariables>(DeleteExperienceDocument, options);
}
export type DeleteExperienceMutationHookResult = ReturnType<typeof useDeleteExperienceMutation>;
export type DeleteExperienceMutationResult = Apollo.MutationResult<DeleteExperienceMutation>;
export type DeleteExperienceMutationOptions = Apollo.BaseMutationOptions<DeleteExperienceMutation, DeleteExperienceMutationVariables>;
export const SaveSimpleExperienceDocument = gql`
    mutation saveSimpleExperience($occupationId: Int!) {
  saveSimpleExperience(occupationId: $occupationId) {
    id
    occupation {
      ...OccupationFragment
    }
  }
}
    ${OccupationFragmentFragmentDoc}`;
export type SaveSimpleExperienceMutationFn = Apollo.MutationFunction<SaveSimpleExperienceMutation, SaveSimpleExperienceMutationVariables>;

/**
 * __useSaveSimpleExperienceMutation__
 *
 * To run a mutation, you first call `useSaveSimpleExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSimpleExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSimpleExperienceMutation, { data, loading, error }] = useSaveSimpleExperienceMutation({
 *   variables: {
 *      occupationId: // value for 'occupationId'
 *   },
 * });
 */
export function useSaveSimpleExperienceMutation(baseOptions?: Apollo.MutationHookOptions<SaveSimpleExperienceMutation, SaveSimpleExperienceMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<SaveSimpleExperienceMutation, SaveSimpleExperienceMutationVariables>(SaveSimpleExperienceDocument, options);
}
export type SaveSimpleExperienceMutationHookResult = ReturnType<typeof useSaveSimpleExperienceMutation>;
export type SaveSimpleExperienceMutationResult = Apollo.MutationResult<SaveSimpleExperienceMutation>;
export type SaveSimpleExperienceMutationOptions = Apollo.BaseMutationOptions<SaveSimpleExperienceMutation, SaveSimpleExperienceMutationVariables>;
export const HasCurrentlyWorkingExperienceDocument = gql`
    query hasCurrentlyWorkingExperience {
  hasCurrentlyWorkingExperience {
    success
  }
}
    `;

/**
 * __useHasCurrentlyWorkingExperienceQuery__
 *
 * To run a query within a React component, call `useHasCurrentlyWorkingExperienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasCurrentlyWorkingExperienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasCurrentlyWorkingExperienceQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasCurrentlyWorkingExperienceQuery(baseOptions?: Apollo.QueryHookOptions<HasCurrentlyWorkingExperienceQuery, HasCurrentlyWorkingExperienceQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<HasCurrentlyWorkingExperienceQuery, HasCurrentlyWorkingExperienceQueryVariables>(HasCurrentlyWorkingExperienceDocument, options);
}
export function useHasCurrentlyWorkingExperienceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasCurrentlyWorkingExperienceQuery, HasCurrentlyWorkingExperienceQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<HasCurrentlyWorkingExperienceQuery, HasCurrentlyWorkingExperienceQueryVariables>(HasCurrentlyWorkingExperienceDocument, options);
}
export type HasCurrentlyWorkingExperienceQueryHookResult = ReturnType<typeof useHasCurrentlyWorkingExperienceQuery>;
export type HasCurrentlyWorkingExperienceLazyQueryHookResult = ReturnType<typeof useHasCurrentlyWorkingExperienceLazyQuery>;
export type HasCurrentlyWorkingExperienceQueryResult = Apollo.QueryResult<HasCurrentlyWorkingExperienceQuery, HasCurrentlyWorkingExperienceQueryVariables>;
export const FindExpertisesDocument = gql`
    query findExpertises($name: String, $limit: Int = 20, $statuses: [CustomEntityStatusEnum!], $receiveDescription: Boolean = false) {
  findExpertises(name: $name, pagination: {limit: $limit}, statuses: $statuses) {
    items {
      id
      name
      description @include(if: $receiveDescription)
      label @client
      value @client
    }
  }
}
    `;

/**
 * __useFindExpertisesQuery__
 *
 * To run a query within a React component, call `useFindExpertisesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindExpertisesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindExpertisesQuery({
 *   variables: {
 *      name: // value for 'name'
 *      limit: // value for 'limit'
 *      statuses: // value for 'statuses'
 *      receiveDescription: // value for 'receiveDescription'
 *   },
 * });
 */
export function useFindExpertisesQuery(baseOptions?: Apollo.QueryHookOptions<FindExpertisesQuery, FindExpertisesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindExpertisesQuery, FindExpertisesQueryVariables>(FindExpertisesDocument, options);
}
export function useFindExpertisesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindExpertisesQuery, FindExpertisesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindExpertisesQuery, FindExpertisesQueryVariables>(FindExpertisesDocument, options);
}
export type FindExpertisesQueryHookResult = ReturnType<typeof useFindExpertisesQuery>;
export type FindExpertisesLazyQueryHookResult = ReturnType<typeof useFindExpertisesLazyQuery>;
export type FindExpertisesQueryResult = Apollo.QueryResult<FindExpertisesQuery, FindExpertisesQueryVariables>;
export const GetTalentExpertisesDocument = gql`
    query getTalentExpertises($limit: Int = 100, $page: Int) {
  findExpertises(
    pagination: {limit: $limit, page: $page}
    extractTalentIdFromAuthToken: true
  ) {
    items {
      ...ExpertiseFullFields
    }
  }
}
    ${ExpertiseFullFieldsFragmentDoc}`;

/**
 * __useGetTalentExpertisesQuery__
 *
 * To run a query within a React component, call `useGetTalentExpertisesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentExpertisesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentExpertisesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetTalentExpertisesQuery(baseOptions?: Apollo.QueryHookOptions<GetTalentExpertisesQuery, GetTalentExpertisesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetTalentExpertisesQuery, GetTalentExpertisesQueryVariables>(GetTalentExpertisesDocument, options);
}
export function useGetTalentExpertisesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTalentExpertisesQuery, GetTalentExpertisesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetTalentExpertisesQuery, GetTalentExpertisesQueryVariables>(GetTalentExpertisesDocument, options);
}
export type GetTalentExpertisesQueryHookResult = ReturnType<typeof useGetTalentExpertisesQuery>;
export type GetTalentExpertisesLazyQueryHookResult = ReturnType<typeof useGetTalentExpertisesLazyQuery>;
export type GetTalentExpertisesQueryResult = Apollo.QueryResult<GetTalentExpertisesQuery, GetTalentExpertisesQueryVariables>;
export const CreateExpertiseDocument = gql`
    mutation createExpertise($name: String!) {
  createExpertise(name: $name, description: $name) {
    id
    name
    description
  }
}
    `;
export type CreateExpertiseMutationFn = Apollo.MutationFunction<CreateExpertiseMutation, CreateExpertiseMutationVariables>;

/**
 * __useCreateExpertiseMutation__
 *
 * To run a mutation, you first call `useCreateExpertiseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExpertiseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExpertiseMutation, { data, loading, error }] = useCreateExpertiseMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateExpertiseMutation(baseOptions?: Apollo.MutationHookOptions<CreateExpertiseMutation, CreateExpertiseMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<CreateExpertiseMutation, CreateExpertiseMutationVariables>(CreateExpertiseDocument, options);
}
export type CreateExpertiseMutationHookResult = ReturnType<typeof useCreateExpertiseMutation>;
export type CreateExpertiseMutationResult = Apollo.MutationResult<CreateExpertiseMutation>;
export type CreateExpertiseMutationOptions = Apollo.BaseMutationOptions<CreateExpertiseMutation, CreateExpertiseMutationVariables>;
export const AddExpertisesToTalentDocument = gql`
    mutation addExpertisesToTalent($expertises: [Int!]!) {
  addExpertisesToTalent(expertises: $expertises) {
    success
  }
}
    `;
export type AddExpertisesToTalentMutationFn = Apollo.MutationFunction<AddExpertisesToTalentMutation, AddExpertisesToTalentMutationVariables>;

/**
 * __useAddExpertisesToTalentMutation__
 *
 * To run a mutation, you first call `useAddExpertisesToTalentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddExpertisesToTalentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addExpertisesToTalentMutation, { data, loading, error }] = useAddExpertisesToTalentMutation({
 *   variables: {
 *      expertises: // value for 'expertises'
 *   },
 * });
 */
export function useAddExpertisesToTalentMutation(baseOptions?: Apollo.MutationHookOptions<AddExpertisesToTalentMutation, AddExpertisesToTalentMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<AddExpertisesToTalentMutation, AddExpertisesToTalentMutationVariables>(AddExpertisesToTalentDocument, options);
}
export type AddExpertisesToTalentMutationHookResult = ReturnType<typeof useAddExpertisesToTalentMutation>;
export type AddExpertisesToTalentMutationResult = Apollo.MutationResult<AddExpertisesToTalentMutation>;
export type AddExpertisesToTalentMutationOptions = Apollo.BaseMutationOptions<AddExpertisesToTalentMutation, AddExpertisesToTalentMutationVariables>;
export const RemoveExpertisesFromTalentDocument = gql`
    mutation removeExpertisesFromTalent($expertises: [Int!]!) {
  removeExpertisesFromTalent(expertises: $expertises) {
    success
  }
}
    `;
export type RemoveExpertisesFromTalentMutationFn = Apollo.MutationFunction<RemoveExpertisesFromTalentMutation, RemoveExpertisesFromTalentMutationVariables>;

/**
 * __useRemoveExpertisesFromTalentMutation__
 *
 * To run a mutation, you first call `useRemoveExpertisesFromTalentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveExpertisesFromTalentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeExpertisesFromTalentMutation, { data, loading, error }] = useRemoveExpertisesFromTalentMutation({
 *   variables: {
 *      expertises: // value for 'expertises'
 *   },
 * });
 */
export function useRemoveExpertisesFromTalentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveExpertisesFromTalentMutation, RemoveExpertisesFromTalentMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<RemoveExpertisesFromTalentMutation, RemoveExpertisesFromTalentMutationVariables>(RemoveExpertisesFromTalentDocument, options);
}
export type RemoveExpertisesFromTalentMutationHookResult = ReturnType<typeof useRemoveExpertisesFromTalentMutation>;
export type RemoveExpertisesFromTalentMutationResult = Apollo.MutationResult<RemoveExpertisesFromTalentMutation>;
export type RemoveExpertisesFromTalentMutationOptions = Apollo.BaseMutationOptions<RemoveExpertisesFromTalentMutation, RemoveExpertisesFromTalentMutationVariables>;
export const ReplaceTalentExpertisesDocument = gql`
    mutation replaceTalentExpertises($expertises: [Int!]!) {
  replaceTalentExpertises(expertises: $expertises) {
    success
  }
}
    `;
export type ReplaceTalentExpertisesMutationFn = Apollo.MutationFunction<ReplaceTalentExpertisesMutation, ReplaceTalentExpertisesMutationVariables>;

/**
 * __useReplaceTalentExpertisesMutation__
 *
 * To run a mutation, you first call `useReplaceTalentExpertisesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceTalentExpertisesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceTalentExpertisesMutation, { data, loading, error }] = useReplaceTalentExpertisesMutation({
 *   variables: {
 *      expertises: // value for 'expertises'
 *   },
 * });
 */
export function useReplaceTalentExpertisesMutation(baseOptions?: Apollo.MutationHookOptions<ReplaceTalentExpertisesMutation, ReplaceTalentExpertisesMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<ReplaceTalentExpertisesMutation, ReplaceTalentExpertisesMutationVariables>(ReplaceTalentExpertisesDocument, options);
}
export type ReplaceTalentExpertisesMutationHookResult = ReturnType<typeof useReplaceTalentExpertisesMutation>;
export type ReplaceTalentExpertisesMutationResult = Apollo.MutationResult<ReplaceTalentExpertisesMutation>;
export type ReplaceTalentExpertisesMutationOptions = Apollo.BaseMutationOptions<ReplaceTalentExpertisesMutation, ReplaceTalentExpertisesMutationVariables>;
export const FindItSkillsDocument = gql`
    query findItSkills($name: String, $limit: Int = 20, $statuses: [CustomEntityStatusEnum!]) {
  findItSkills(name: $name, pagination: {limit: $limit}, statuses: $statuses) {
    items {
      id
      name
      label @client
      value @client
    }
  }
}
    `;

/**
 * __useFindItSkillsQuery__
 *
 * To run a query within a React component, call `useFindItSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindItSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindItSkillsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      limit: // value for 'limit'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useFindItSkillsQuery(baseOptions?: Apollo.QueryHookOptions<FindItSkillsQuery, FindItSkillsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindItSkillsQuery, FindItSkillsQueryVariables>(FindItSkillsDocument, options);
}
export function useFindItSkillsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindItSkillsQuery, FindItSkillsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindItSkillsQuery, FindItSkillsQueryVariables>(FindItSkillsDocument, options);
}
export type FindItSkillsQueryHookResult = ReturnType<typeof useFindItSkillsQuery>;
export type FindItSkillsLazyQueryHookResult = ReturnType<typeof useFindItSkillsLazyQuery>;
export type FindItSkillsQueryResult = Apollo.QueryResult<FindItSkillsQuery, FindItSkillsQueryVariables>;
export const FindItSkillLevelsDocument = gql`
    query findItSkillLevels($limit: Int = 20) {
  findItSkillLevels(pagination: {limit: $limit}) {
    items {
      id
      name
      label @client
      value @client
    }
  }
}
    `;

/**
 * __useFindItSkillLevelsQuery__
 *
 * To run a query within a React component, call `useFindItSkillLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindItSkillLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindItSkillLevelsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useFindItSkillLevelsQuery(baseOptions?: Apollo.QueryHookOptions<FindItSkillLevelsQuery, FindItSkillLevelsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindItSkillLevelsQuery, FindItSkillLevelsQueryVariables>(FindItSkillLevelsDocument, options);
}
export function useFindItSkillLevelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindItSkillLevelsQuery, FindItSkillLevelsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindItSkillLevelsQuery, FindItSkillLevelsQueryVariables>(FindItSkillLevelsDocument, options);
}
export type FindItSkillLevelsQueryHookResult = ReturnType<typeof useFindItSkillLevelsQuery>;
export type FindItSkillLevelsLazyQueryHookResult = ReturnType<typeof useFindItSkillLevelsLazyQuery>;
export type FindItSkillLevelsQueryResult = Apollo.QueryResult<FindItSkillLevelsQuery, FindItSkillLevelsQueryVariables>;
export const GetTalentItSkillsDocument = gql`
    query getTalentItSkills($limit: Int, $page: Int) {
  getTalentItSkills(pagination: {limit: $limit, page: $page}) {
    items {
      ...ItSkillsFullFields
    }
    meta {
      ...PaginationFields
    }
  }
}
    ${ItSkillsFullFieldsFragmentDoc}
${PaginationFieldsFragmentDoc}`;

/**
 * __useGetTalentItSkillsQuery__
 *
 * To run a query within a React component, call `useGetTalentItSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentItSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentItSkillsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetTalentItSkillsQuery(baseOptions?: Apollo.QueryHookOptions<GetTalentItSkillsQuery, GetTalentItSkillsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetTalentItSkillsQuery, GetTalentItSkillsQueryVariables>(GetTalentItSkillsDocument, options);
}
export function useGetTalentItSkillsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTalentItSkillsQuery, GetTalentItSkillsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetTalentItSkillsQuery, GetTalentItSkillsQueryVariables>(GetTalentItSkillsDocument, options);
}
export type GetTalentItSkillsQueryHookResult = ReturnType<typeof useGetTalentItSkillsQuery>;
export type GetTalentItSkillsLazyQueryHookResult = ReturnType<typeof useGetTalentItSkillsLazyQuery>;
export type GetTalentItSkillsQueryResult = Apollo.QueryResult<GetTalentItSkillsQuery, GetTalentItSkillsQueryVariables>;
export const CreateItSkillDocument = gql`
    mutation createItSkill($name: String!) {
  createItSkill(name: $name) {
    id
    name
  }
}
    `;
export type CreateItSkillMutationFn = Apollo.MutationFunction<CreateItSkillMutation, CreateItSkillMutationVariables>;

/**
 * __useCreateItSkillMutation__
 *
 * To run a mutation, you first call `useCreateItSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItSkillMutation, { data, loading, error }] = useCreateItSkillMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateItSkillMutation(baseOptions?: Apollo.MutationHookOptions<CreateItSkillMutation, CreateItSkillMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<CreateItSkillMutation, CreateItSkillMutationVariables>(CreateItSkillDocument, options);
}
export type CreateItSkillMutationHookResult = ReturnType<typeof useCreateItSkillMutation>;
export type CreateItSkillMutationResult = Apollo.MutationResult<CreateItSkillMutation>;
export type CreateItSkillMutationOptions = Apollo.BaseMutationOptions<CreateItSkillMutation, CreateItSkillMutationVariables>;
export const CreateTalentItSkillDocument = gql`
    mutation createTalentItSkill($itSkillId: Int!, $levelId: Int!, $yearsOfExperience: Float!) {
  createTalentItSkill(
    itSkillId: $itSkillId
    levelId: $levelId
    yearsOfExperience: $yearsOfExperience
  ) {
    id
    biographyId
    itSkill {
      id
      name
      label @client
      value @client
    }
    level {
      id
      name
      label @client
      value @client
    }
    yearsOfExperience
  }
}
    `;
export type CreateTalentItSkillMutationFn = Apollo.MutationFunction<CreateTalentItSkillMutation, CreateTalentItSkillMutationVariables>;

/**
 * __useCreateTalentItSkillMutation__
 *
 * To run a mutation, you first call `useCreateTalentItSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTalentItSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTalentItSkillMutation, { data, loading, error }] = useCreateTalentItSkillMutation({
 *   variables: {
 *      itSkillId: // value for 'itSkillId'
 *      levelId: // value for 'levelId'
 *      yearsOfExperience: // value for 'yearsOfExperience'
 *   },
 * });
 */
export function useCreateTalentItSkillMutation(baseOptions?: Apollo.MutationHookOptions<CreateTalentItSkillMutation, CreateTalentItSkillMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<CreateTalentItSkillMutation, CreateTalentItSkillMutationVariables>(CreateTalentItSkillDocument, options);
}
export type CreateTalentItSkillMutationHookResult = ReturnType<typeof useCreateTalentItSkillMutation>;
export type CreateTalentItSkillMutationResult = Apollo.MutationResult<CreateTalentItSkillMutation>;
export type CreateTalentItSkillMutationOptions = Apollo.BaseMutationOptions<CreateTalentItSkillMutation, CreateTalentItSkillMutationVariables>;
export const UpdateTalentItSkillDocument = gql`
    mutation updateTalentItSkill($talentItSkillId: Int!, $itSkillId: Int, $levelId: Int, $yearsOfExperience: Float) {
  updateTalentItSkill(
    talentItSkillId: $talentItSkillId
    itSkillId: $itSkillId
    levelId: $levelId
    yearsOfExperience: $yearsOfExperience
  ) {
    id
    itSkill {
      id
      name
      label @client
      value @client
    }
    level {
      id
      name
      label @client
      value @client
    }
    yearsOfExperience
  }
}
    `;
export type UpdateTalentItSkillMutationFn = Apollo.MutationFunction<UpdateTalentItSkillMutation, UpdateTalentItSkillMutationVariables>;

/**
 * __useUpdateTalentItSkillMutation__
 *
 * To run a mutation, you first call `useUpdateTalentItSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTalentItSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTalentItSkillMutation, { data, loading, error }] = useUpdateTalentItSkillMutation({
 *   variables: {
 *      talentItSkillId: // value for 'talentItSkillId'
 *      itSkillId: // value for 'itSkillId'
 *      levelId: // value for 'levelId'
 *      yearsOfExperience: // value for 'yearsOfExperience'
 *   },
 * });
 */
export function useUpdateTalentItSkillMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTalentItSkillMutation, UpdateTalentItSkillMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UpdateTalentItSkillMutation, UpdateTalentItSkillMutationVariables>(UpdateTalentItSkillDocument, options);
}
export type UpdateTalentItSkillMutationHookResult = ReturnType<typeof useUpdateTalentItSkillMutation>;
export type UpdateTalentItSkillMutationResult = Apollo.MutationResult<UpdateTalentItSkillMutation>;
export type UpdateTalentItSkillMutationOptions = Apollo.BaseMutationOptions<UpdateTalentItSkillMutation, UpdateTalentItSkillMutationVariables>;
export const DeleteTalentItSkillDocument = gql`
    mutation deleteTalentItSkill($id: Int!) {
  deleteTalentItSkill(id: $id) {
    success
  }
}
    `;
export type DeleteTalentItSkillMutationFn = Apollo.MutationFunction<DeleteTalentItSkillMutation, DeleteTalentItSkillMutationVariables>;

/**
 * __useDeleteTalentItSkillMutation__
 *
 * To run a mutation, you first call `useDeleteTalentItSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTalentItSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTalentItSkillMutation, { data, loading, error }] = useDeleteTalentItSkillMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTalentItSkillMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTalentItSkillMutation, DeleteTalentItSkillMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<DeleteTalentItSkillMutation, DeleteTalentItSkillMutationVariables>(DeleteTalentItSkillDocument, options);
}
export type DeleteTalentItSkillMutationHookResult = ReturnType<typeof useDeleteTalentItSkillMutation>;
export type DeleteTalentItSkillMutationResult = Apollo.MutationResult<DeleteTalentItSkillMutation>;
export type DeleteTalentItSkillMutationOptions = Apollo.BaseMutationOptions<DeleteTalentItSkillMutation, DeleteTalentItSkillMutationVariables>;
export const FindLanguagesDocument = gql`
    query findLanguages($name: String, $limit: Int = 20) {
  findLanguages(name: $name, pagination: {limit: $limit}) {
    items {
      id
      name
      label @client
      value @client
    }
  }
}
    `;

/**
 * __useFindLanguagesQuery__
 *
 * To run a query within a React component, call `useFindLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindLanguagesQuery({
 *   variables: {
 *      name: // value for 'name'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useFindLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<FindLanguagesQuery, FindLanguagesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindLanguagesQuery, FindLanguagesQueryVariables>(FindLanguagesDocument, options);
}
export function useFindLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindLanguagesQuery, FindLanguagesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindLanguagesQuery, FindLanguagesQueryVariables>(FindLanguagesDocument, options);
}
export type FindLanguagesQueryHookResult = ReturnType<typeof useFindLanguagesQuery>;
export type FindLanguagesLazyQueryHookResult = ReturnType<typeof useFindLanguagesLazyQuery>;
export type FindLanguagesQueryResult = Apollo.QueryResult<FindLanguagesQuery, FindLanguagesQueryVariables>;
export const FindLanguageLevelsDocument = gql`
    query findLanguageLevels($name: String, $limit: Int = 20) {
  findLanguageLevels(name: $name, pagination: {limit: $limit}) {
    items {
      id
      name
      label @client
      value @client
    }
  }
}
    `;

/**
 * __useFindLanguageLevelsQuery__
 *
 * To run a query within a React component, call `useFindLanguageLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindLanguageLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindLanguageLevelsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useFindLanguageLevelsQuery(baseOptions?: Apollo.QueryHookOptions<FindLanguageLevelsQuery, FindLanguageLevelsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindLanguageLevelsQuery, FindLanguageLevelsQueryVariables>(FindLanguageLevelsDocument, options);
}
export function useFindLanguageLevelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindLanguageLevelsQuery, FindLanguageLevelsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindLanguageLevelsQuery, FindLanguageLevelsQueryVariables>(FindLanguageLevelsDocument, options);
}
export type FindLanguageLevelsQueryHookResult = ReturnType<typeof useFindLanguageLevelsQuery>;
export type FindLanguageLevelsLazyQueryHookResult = ReturnType<typeof useFindLanguageLevelsLazyQuery>;
export type FindLanguageLevelsQueryResult = Apollo.QueryResult<FindLanguageLevelsQuery, FindLanguageLevelsQueryVariables>;
export const GetTalentLanguagesDocument = gql`
    query getTalentLanguages($limit: Int, $page: Int) {
  getTalentLanguages(pagination: {limit: $limit, page: $page}) {
    items {
      id
      language {
        id
        name
        label @client
        value @client
      }
      level {
        id
        name
        label @client
        value @client
      }
    }
    meta {
      ...PaginationFields
    }
  }
}
    ${PaginationFieldsFragmentDoc}`;

/**
 * __useGetTalentLanguagesQuery__
 *
 * To run a query within a React component, call `useGetTalentLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentLanguagesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetTalentLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<GetTalentLanguagesQuery, GetTalentLanguagesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetTalentLanguagesQuery, GetTalentLanguagesQueryVariables>(GetTalentLanguagesDocument, options);
}
export function useGetTalentLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTalentLanguagesQuery, GetTalentLanguagesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetTalentLanguagesQuery, GetTalentLanguagesQueryVariables>(GetTalentLanguagesDocument, options);
}
export type GetTalentLanguagesQueryHookResult = ReturnType<typeof useGetTalentLanguagesQuery>;
export type GetTalentLanguagesLazyQueryHookResult = ReturnType<typeof useGetTalentLanguagesLazyQuery>;
export type GetTalentLanguagesQueryResult = Apollo.QueryResult<GetTalentLanguagesQuery, GetTalentLanguagesQueryVariables>;
export const CreateTalentLanguageDocument = gql`
    mutation createTalentLanguage($languageId: Int!, $levelId: Int!) {
  createTalentLanguage(languageId: $languageId, levelId: $levelId) {
    id
    language {
      id
      name
      label @client
      value @client
    }
    level {
      id
      name
      label @client
      value @client
    }
  }
}
    `;
export type CreateTalentLanguageMutationFn = Apollo.MutationFunction<CreateTalentLanguageMutation, CreateTalentLanguageMutationVariables>;

/**
 * __useCreateTalentLanguageMutation__
 *
 * To run a mutation, you first call `useCreateTalentLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTalentLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTalentLanguageMutation, { data, loading, error }] = useCreateTalentLanguageMutation({
 *   variables: {
 *      languageId: // value for 'languageId'
 *      levelId: // value for 'levelId'
 *   },
 * });
 */
export function useCreateTalentLanguageMutation(baseOptions?: Apollo.MutationHookOptions<CreateTalentLanguageMutation, CreateTalentLanguageMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<CreateTalentLanguageMutation, CreateTalentLanguageMutationVariables>(CreateTalentLanguageDocument, options);
}
export type CreateTalentLanguageMutationHookResult = ReturnType<typeof useCreateTalentLanguageMutation>;
export type CreateTalentLanguageMutationResult = Apollo.MutationResult<CreateTalentLanguageMutation>;
export type CreateTalentLanguageMutationOptions = Apollo.BaseMutationOptions<CreateTalentLanguageMutation, CreateTalentLanguageMutationVariables>;
export const UpdateTalentLanguageDocument = gql`
    mutation updateTalentLanguage($talentLanguageId: Int!, $languageId: Int, $levelId: Int) {
  updateTalentLanguage(
    talentLanguageId: $talentLanguageId
    languageId: $languageId
    levelId: $levelId
  ) {
    id
    language {
      id
      name
      label @client
      value @client
    }
    level {
      id
      name
      label @client
      value @client
    }
  }
}
    `;
export type UpdateTalentLanguageMutationFn = Apollo.MutationFunction<UpdateTalentLanguageMutation, UpdateTalentLanguageMutationVariables>;

/**
 * __useUpdateTalentLanguageMutation__
 *
 * To run a mutation, you first call `useUpdateTalentLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTalentLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTalentLanguageMutation, { data, loading, error }] = useUpdateTalentLanguageMutation({
 *   variables: {
 *      talentLanguageId: // value for 'talentLanguageId'
 *      languageId: // value for 'languageId'
 *      levelId: // value for 'levelId'
 *   },
 * });
 */
export function useUpdateTalentLanguageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTalentLanguageMutation, UpdateTalentLanguageMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UpdateTalentLanguageMutation, UpdateTalentLanguageMutationVariables>(UpdateTalentLanguageDocument, options);
}
export type UpdateTalentLanguageMutationHookResult = ReturnType<typeof useUpdateTalentLanguageMutation>;
export type UpdateTalentLanguageMutationResult = Apollo.MutationResult<UpdateTalentLanguageMutation>;
export type UpdateTalentLanguageMutationOptions = Apollo.BaseMutationOptions<UpdateTalentLanguageMutation, UpdateTalentLanguageMutationVariables>;
export const DeleteTalentLanguageDocument = gql`
    mutation deleteTalentLanguage($id: Int!) {
  deleteTalentLanguage(id: $id) {
    success
  }
}
    `;
export type DeleteTalentLanguageMutationFn = Apollo.MutationFunction<DeleteTalentLanguageMutation, DeleteTalentLanguageMutationVariables>;

/**
 * __useDeleteTalentLanguageMutation__
 *
 * To run a mutation, you first call `useDeleteTalentLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTalentLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTalentLanguageMutation, { data, loading, error }] = useDeleteTalentLanguageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTalentLanguageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTalentLanguageMutation, DeleteTalentLanguageMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<DeleteTalentLanguageMutation, DeleteTalentLanguageMutationVariables>(DeleteTalentLanguageDocument, options);
}
export type DeleteTalentLanguageMutationHookResult = ReturnType<typeof useDeleteTalentLanguageMutation>;
export type DeleteTalentLanguageMutationResult = Apollo.MutationResult<DeleteTalentLanguageMutation>;
export type DeleteTalentLanguageMutationOptions = Apollo.BaseMutationOptions<DeleteTalentLanguageMutation, DeleteTalentLanguageMutationVariables>;
export const FindSkillsDocument = gql`
    query findSkills($name: String, $limit: Int = 20, $page: Int) {
  findSkills(name: $name, pagination: {limit: $limit, page: $page}) {
    items {
      ...SkillsFullFields
    }
    meta {
      ...PaginationFields
    }
  }
}
    ${SkillsFullFieldsFragmentDoc}
${PaginationFieldsFragmentDoc}`;

/**
 * __useFindSkillsQuery__
 *
 * To run a query within a React component, call `useFindSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSkillsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFindSkillsQuery(baseOptions?: Apollo.QueryHookOptions<FindSkillsQuery, FindSkillsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<FindSkillsQuery, FindSkillsQueryVariables>(FindSkillsDocument, options);
}
export function useFindSkillsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindSkillsQuery, FindSkillsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<FindSkillsQuery, FindSkillsQueryVariables>(FindSkillsDocument, options);
}
export type FindSkillsQueryHookResult = ReturnType<typeof useFindSkillsQuery>;
export type FindSkillsLazyQueryHookResult = ReturnType<typeof useFindSkillsLazyQuery>;
export type FindSkillsQueryResult = Apollo.QueryResult<FindSkillsQuery, FindSkillsQueryVariables>;
export const GetTalentSkillsDocument = gql`
    query getTalentSkills($limit: Int = 100, $page: Int) {
  findSkills(
    pagination: {limit: $limit, page: $page}
    extractTalentIdFromAuthToken: true
  ) {
    items {
      ...SkillsFullFields
    }
  }
}
    ${SkillsFullFieldsFragmentDoc}`;

/**
 * __useGetTalentSkillsQuery__
 *
 * To run a query within a React component, call `useGetTalentSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentSkillsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetTalentSkillsQuery(baseOptions?: Apollo.QueryHookOptions<GetTalentSkillsQuery, GetTalentSkillsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetTalentSkillsQuery, GetTalentSkillsQueryVariables>(GetTalentSkillsDocument, options);
}
export function useGetTalentSkillsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTalentSkillsQuery, GetTalentSkillsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetTalentSkillsQuery, GetTalentSkillsQueryVariables>(GetTalentSkillsDocument, options);
}
export type GetTalentSkillsQueryHookResult = ReturnType<typeof useGetTalentSkillsQuery>;
export type GetTalentSkillsLazyQueryHookResult = ReturnType<typeof useGetTalentSkillsLazyQuery>;
export type GetTalentSkillsQueryResult = Apollo.QueryResult<GetTalentSkillsQuery, GetTalentSkillsQueryVariables>;
export const AddSkillsToTalentDocument = gql`
    mutation addSkillsToTalent($skills: [Int!]!) {
  addSkillsToTalent(skills: $skills) {
    success
  }
}
    `;
export type AddSkillsToTalentMutationFn = Apollo.MutationFunction<AddSkillsToTalentMutation, AddSkillsToTalentMutationVariables>;

/**
 * __useAddSkillsToTalentMutation__
 *
 * To run a mutation, you first call `useAddSkillsToTalentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSkillsToTalentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSkillsToTalentMutation, { data, loading, error }] = useAddSkillsToTalentMutation({
 *   variables: {
 *      skills: // value for 'skills'
 *   },
 * });
 */
export function useAddSkillsToTalentMutation(baseOptions?: Apollo.MutationHookOptions<AddSkillsToTalentMutation, AddSkillsToTalentMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<AddSkillsToTalentMutation, AddSkillsToTalentMutationVariables>(AddSkillsToTalentDocument, options);
}
export type AddSkillsToTalentMutationHookResult = ReturnType<typeof useAddSkillsToTalentMutation>;
export type AddSkillsToTalentMutationResult = Apollo.MutationResult<AddSkillsToTalentMutation>;
export type AddSkillsToTalentMutationOptions = Apollo.BaseMutationOptions<AddSkillsToTalentMutation, AddSkillsToTalentMutationVariables>;
export const RemoveSkillsFromTalentDocument = gql`
    mutation removeSkillsFromTalent($skills: [Int!]!) {
  removeSkillsFromTalent(skills: $skills) {
    success
  }
}
    `;
export type RemoveSkillsFromTalentMutationFn = Apollo.MutationFunction<RemoveSkillsFromTalentMutation, RemoveSkillsFromTalentMutationVariables>;

/**
 * __useRemoveSkillsFromTalentMutation__
 *
 * To run a mutation, you first call `useRemoveSkillsFromTalentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSkillsFromTalentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSkillsFromTalentMutation, { data, loading, error }] = useRemoveSkillsFromTalentMutation({
 *   variables: {
 *      skills: // value for 'skills'
 *   },
 * });
 */
export function useRemoveSkillsFromTalentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSkillsFromTalentMutation, RemoveSkillsFromTalentMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<RemoveSkillsFromTalentMutation, RemoveSkillsFromTalentMutationVariables>(RemoveSkillsFromTalentDocument, options);
}
export type RemoveSkillsFromTalentMutationHookResult = ReturnType<typeof useRemoveSkillsFromTalentMutation>;
export type RemoveSkillsFromTalentMutationResult = Apollo.MutationResult<RemoveSkillsFromTalentMutation>;
export type RemoveSkillsFromTalentMutationOptions = Apollo.BaseMutationOptions<RemoveSkillsFromTalentMutation, RemoveSkillsFromTalentMutationVariables>;
export const ReplaceTalentSkillsDocument = gql`
    mutation replaceTalentSkills($skills: [Int!]!) {
  replaceTalentSkills(skills: $skills) {
    success
  }
}
    `;
export type ReplaceTalentSkillsMutationFn = Apollo.MutationFunction<ReplaceTalentSkillsMutation, ReplaceTalentSkillsMutationVariables>;

/**
 * __useReplaceTalentSkillsMutation__
 *
 * To run a mutation, you first call `useReplaceTalentSkillsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceTalentSkillsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceTalentSkillsMutation, { data, loading, error }] = useReplaceTalentSkillsMutation({
 *   variables: {
 *      skills: // value for 'skills'
 *   },
 * });
 */
export function useReplaceTalentSkillsMutation(baseOptions?: Apollo.MutationHookOptions<ReplaceTalentSkillsMutation, ReplaceTalentSkillsMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<ReplaceTalentSkillsMutation, ReplaceTalentSkillsMutationVariables>(ReplaceTalentSkillsDocument, options);
}
export type ReplaceTalentSkillsMutationHookResult = ReturnType<typeof useReplaceTalentSkillsMutation>;
export type ReplaceTalentSkillsMutationResult = Apollo.MutationResult<ReplaceTalentSkillsMutation>;
export type ReplaceTalentSkillsMutationOptions = Apollo.BaseMutationOptions<ReplaceTalentSkillsMutation, ReplaceTalentSkillsMutationVariables>;
export const GetTalentTopCharacteristicsDocument = gql`
    query getTalentTopCharacteristics {
  getTalentQualities {
    badge
    topQualities {
      questionName
      questionType
    }
  }
}
    `;

/**
 * __useGetTalentTopCharacteristicsQuery__
 *
 * To run a query within a React component, call `useGetTalentTopCharacteristicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentTopCharacteristicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentTopCharacteristicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTalentTopCharacteristicsQuery(baseOptions?: Apollo.QueryHookOptions<GetTalentTopCharacteristicsQuery, GetTalentTopCharacteristicsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetTalentTopCharacteristicsQuery, GetTalentTopCharacteristicsQueryVariables>(GetTalentTopCharacteristicsDocument, options);
}
export function useGetTalentTopCharacteristicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTalentTopCharacteristicsQuery, GetTalentTopCharacteristicsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetTalentTopCharacteristicsQuery, GetTalentTopCharacteristicsQueryVariables>(GetTalentTopCharacteristicsDocument, options);
}
export type GetTalentTopCharacteristicsQueryHookResult = ReturnType<typeof useGetTalentTopCharacteristicsQuery>;
export type GetTalentTopCharacteristicsLazyQueryHookResult = ReturnType<typeof useGetTalentTopCharacteristicsLazyQuery>;
export type GetTalentTopCharacteristicsQueryResult = Apollo.QueryResult<GetTalentTopCharacteristicsQuery, GetTalentTopCharacteristicsQueryVariables>;
export const GetStepsStatusDocument = gql`
    query getStepsStatus($wizard: WizardEnum) {
  getStepsStatus(wizard: $wizard) {
    items {
      status
      stepKey
      parentStepKey
    }
  }
}
    `;

/**
 * __useGetStepsStatusQuery__
 *
 * To run a query within a React component, call `useGetStepsStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStepsStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStepsStatusQuery({
 *   variables: {
 *      wizard: // value for 'wizard'
 *   },
 * });
 */
export function useGetStepsStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetStepsStatusQuery, GetStepsStatusQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetStepsStatusQuery, GetStepsStatusQueryVariables>(GetStepsStatusDocument, options);
}
export function useGetStepsStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStepsStatusQuery, GetStepsStatusQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetStepsStatusQuery, GetStepsStatusQueryVariables>(GetStepsStatusDocument, options);
}
export type GetStepsStatusQueryHookResult = ReturnType<typeof useGetStepsStatusQuery>;
export type GetStepsStatusLazyQueryHookResult = ReturnType<typeof useGetStepsStatusLazyQuery>;
export type GetStepsStatusQueryResult = Apollo.QueryResult<GetStepsStatusQuery, GetStepsStatusQueryVariables>;
export const SkipStepDocument = gql`
    mutation skipStep($step: SkippableStepEnum!) {
  skipStep(step: $step) {
    success
  }
}
    `;
export type SkipStepMutationFn = Apollo.MutationFunction<SkipStepMutation, SkipStepMutationVariables>;

/**
 * __useSkipStepMutation__
 *
 * To run a mutation, you first call `useSkipStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSkipStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [skipStepMutation, { data, loading, error }] = useSkipStepMutation({
 *   variables: {
 *      step: // value for 'step'
 *   },
 * });
 */
export function useSkipStepMutation(baseOptions?: Apollo.MutationHookOptions<SkipStepMutation, SkipStepMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<SkipStepMutation, SkipStepMutationVariables>(SkipStepDocument, options);
}
export type SkipStepMutationHookResult = ReturnType<typeof useSkipStepMutation>;
export type SkipStepMutationResult = Apollo.MutationResult<SkipStepMutation>;
export type SkipStepMutationOptions = Apollo.BaseMutationOptions<SkipStepMutation, SkipStepMutationVariables>;
export const UpdateTalentAttendanceDocument = gql`
    mutation updateTalentAttendance($values: [AttendanceValueEnum!]!) {
  updateTalentAttendances(values: $values) {
    success
  }
}
    `;
export type UpdateTalentAttendanceMutationFn = Apollo.MutationFunction<UpdateTalentAttendanceMutation, UpdateTalentAttendanceMutationVariables>;

/**
 * __useUpdateTalentAttendanceMutation__
 *
 * To run a mutation, you first call `useUpdateTalentAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTalentAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTalentAttendanceMutation, { data, loading, error }] = useUpdateTalentAttendanceMutation({
 *   variables: {
 *      values: // value for 'values'
 *   },
 * });
 */
export function useUpdateTalentAttendanceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTalentAttendanceMutation, UpdateTalentAttendanceMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UpdateTalentAttendanceMutation, UpdateTalentAttendanceMutationVariables>(UpdateTalentAttendanceDocument, options);
}
export type UpdateTalentAttendanceMutationHookResult = ReturnType<typeof useUpdateTalentAttendanceMutation>;
export type UpdateTalentAttendanceMutationResult = Apollo.MutationResult<UpdateTalentAttendanceMutation>;
export type UpdateTalentAttendanceMutationOptions = Apollo.BaseMutationOptions<UpdateTalentAttendanceMutation, UpdateTalentAttendanceMutationVariables>;
export const GetTalentAttendanceDocument = gql`
    query getTalentAttendance {
  getTalentAttendances {
    items {
      value
    }
  }
}
    `;

/**
 * __useGetTalentAttendanceQuery__
 *
 * To run a query within a React component, call `useGetTalentAttendanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentAttendanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentAttendanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTalentAttendanceQuery(baseOptions?: Apollo.QueryHookOptions<GetTalentAttendanceQuery, GetTalentAttendanceQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetTalentAttendanceQuery, GetTalentAttendanceQueryVariables>(GetTalentAttendanceDocument, options);
}
export function useGetTalentAttendanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTalentAttendanceQuery, GetTalentAttendanceQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetTalentAttendanceQuery, GetTalentAttendanceQueryVariables>(GetTalentAttendanceDocument, options);
}
export type GetTalentAttendanceQueryHookResult = ReturnType<typeof useGetTalentAttendanceQuery>;
export type GetTalentAttendanceLazyQueryHookResult = ReturnType<typeof useGetTalentAttendanceLazyQuery>;
export type GetTalentAttendanceQueryResult = Apollo.QueryResult<GetTalentAttendanceQuery, GetTalentAttendanceQueryVariables>;
export const UpdateTalentAvailabilityDocument = gql`
    mutation updateTalentAvailability($value: AvailabilityValueEnum!) {
  updateTalentAvailability(value: $value) {
    success
  }
}
    `;
export type UpdateTalentAvailabilityMutationFn = Apollo.MutationFunction<UpdateTalentAvailabilityMutation, UpdateTalentAvailabilityMutationVariables>;

/**
 * __useUpdateTalentAvailabilityMutation__
 *
 * To run a mutation, you first call `useUpdateTalentAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTalentAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTalentAvailabilityMutation, { data, loading, error }] = useUpdateTalentAvailabilityMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateTalentAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTalentAvailabilityMutation, UpdateTalentAvailabilityMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UpdateTalentAvailabilityMutation, UpdateTalentAvailabilityMutationVariables>(UpdateTalentAvailabilityDocument, options);
}
export type UpdateTalentAvailabilityMutationHookResult = ReturnType<typeof useUpdateTalentAvailabilityMutation>;
export type UpdateTalentAvailabilityMutationResult = Apollo.MutationResult<UpdateTalentAvailabilityMutation>;
export type UpdateTalentAvailabilityMutationOptions = Apollo.BaseMutationOptions<UpdateTalentAvailabilityMutation, UpdateTalentAvailabilityMutationVariables>;
export const GetTalentAvailabilityDocument = gql`
    query getTalentAvailability {
  getTalentAvailability {
    id
    value
  }
}
    `;

/**
 * __useGetTalentAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetTalentAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentAvailabilityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTalentAvailabilityQuery(baseOptions?: Apollo.QueryHookOptions<GetTalentAvailabilityQuery, GetTalentAvailabilityQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetTalentAvailabilityQuery, GetTalentAvailabilityQueryVariables>(GetTalentAvailabilityDocument, options);
}
export function useGetTalentAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTalentAvailabilityQuery, GetTalentAvailabilityQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetTalentAvailabilityQuery, GetTalentAvailabilityQueryVariables>(GetTalentAvailabilityDocument, options);
}
export type GetTalentAvailabilityQueryHookResult = ReturnType<typeof useGetTalentAvailabilityQuery>;
export type GetTalentAvailabilityLazyQueryHookResult = ReturnType<typeof useGetTalentAvailabilityLazyQuery>;
export type GetTalentAvailabilityQueryResult = Apollo.QueryResult<GetTalentAvailabilityQuery, GetTalentAvailabilityQueryVariables>;
export const UpdateTalentContactsDocument = gql`
    mutation updateTalentContacts($contactMethodValue: ContactMethodEnum!, $location: String!, $countryCode: String) {
  updateContacts(
    contactMethodValue: $contactMethodValue
    location: $location
    countryCode: $countryCode
  ) {
    success
  }
}
    `;
export type UpdateTalentContactsMutationFn = Apollo.MutationFunction<UpdateTalentContactsMutation, UpdateTalentContactsMutationVariables>;

/**
 * __useUpdateTalentContactsMutation__
 *
 * To run a mutation, you first call `useUpdateTalentContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTalentContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTalentContactsMutation, { data, loading, error }] = useUpdateTalentContactsMutation({
 *   variables: {
 *      contactMethodValue: // value for 'contactMethodValue'
 *      location: // value for 'location'
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useUpdateTalentContactsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTalentContactsMutation, UpdateTalentContactsMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UpdateTalentContactsMutation, UpdateTalentContactsMutationVariables>(UpdateTalentContactsDocument, options);
}
export type UpdateTalentContactsMutationHookResult = ReturnType<typeof useUpdateTalentContactsMutation>;
export type UpdateTalentContactsMutationResult = Apollo.MutationResult<UpdateTalentContactsMutation>;
export type UpdateTalentContactsMutationOptions = Apollo.BaseMutationOptions<UpdateTalentContactsMutation, UpdateTalentContactsMutationVariables>;
export const SendTelephoneVerificationSmsDocument = gql`
    mutation sendTelephoneVerificationSms($telephone: String!) {
  sendTelephoneVerificationSms(telephone: $telephone) {
    success
    verificationCode
  }
}
    `;
export type SendTelephoneVerificationSmsMutationFn = Apollo.MutationFunction<SendTelephoneVerificationSmsMutation, SendTelephoneVerificationSmsMutationVariables>;

/**
 * __useSendTelephoneVerificationSmsMutation__
 *
 * To run a mutation, you first call `useSendTelephoneVerificationSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTelephoneVerificationSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTelephoneVerificationSmsMutation, { data, loading, error }] = useSendTelephoneVerificationSmsMutation({
 *   variables: {
 *      telephone: // value for 'telephone'
 *   },
 * });
 */
export function useSendTelephoneVerificationSmsMutation(baseOptions?: Apollo.MutationHookOptions<SendTelephoneVerificationSmsMutation, SendTelephoneVerificationSmsMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<SendTelephoneVerificationSmsMutation, SendTelephoneVerificationSmsMutationVariables>(SendTelephoneVerificationSmsDocument, options);
}
export type SendTelephoneVerificationSmsMutationHookResult = ReturnType<typeof useSendTelephoneVerificationSmsMutation>;
export type SendTelephoneVerificationSmsMutationResult = Apollo.MutationResult<SendTelephoneVerificationSmsMutation>;
export type SendTelephoneVerificationSmsMutationOptions = Apollo.BaseMutationOptions<SendTelephoneVerificationSmsMutation, SendTelephoneVerificationSmsMutationVariables>;
export const SaveTelephoneDocument = gql`
    mutation saveTelephone($verificationCode: Float) {
  saveTelephone(verificationCode: $verificationCode) {
    success
  }
}
    `;
export type SaveTelephoneMutationFn = Apollo.MutationFunction<SaveTelephoneMutation, SaveTelephoneMutationVariables>;

/**
 * __useSaveTelephoneMutation__
 *
 * To run a mutation, you first call `useSaveTelephoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTelephoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTelephoneMutation, { data, loading, error }] = useSaveTelephoneMutation({
 *   variables: {
 *      verificationCode: // value for 'verificationCode'
 *   },
 * });
 */
export function useSaveTelephoneMutation(baseOptions?: Apollo.MutationHookOptions<SaveTelephoneMutation, SaveTelephoneMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<SaveTelephoneMutation, SaveTelephoneMutationVariables>(SaveTelephoneDocument, options);
}
export type SaveTelephoneMutationHookResult = ReturnType<typeof useSaveTelephoneMutation>;
export type SaveTelephoneMutationResult = Apollo.MutationResult<SaveTelephoneMutation>;
export type SaveTelephoneMutationOptions = Apollo.BaseMutationOptions<SaveTelephoneMutation, SaveTelephoneMutationVariables>;
export const GetTalentContactsDocument = gql`
    query getTalentContacts {
  getTalent {
    contactMethod {
      id
      value
    }
    profile {
      id
      type
      picture
      email
      fullName
      location
      telephone
    }
  }
}
    `;

/**
 * __useGetTalentContactsQuery__
 *
 * To run a query within a React component, call `useGetTalentContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentContactsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTalentContactsQuery(baseOptions?: Apollo.QueryHookOptions<GetTalentContactsQuery, GetTalentContactsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetTalentContactsQuery, GetTalentContactsQueryVariables>(GetTalentContactsDocument, options);
}
export function useGetTalentContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTalentContactsQuery, GetTalentContactsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetTalentContactsQuery, GetTalentContactsQueryVariables>(GetTalentContactsDocument, options);
}
export type GetTalentContactsQueryHookResult = ReturnType<typeof useGetTalentContactsQuery>;
export type GetTalentContactsLazyQueryHookResult = ReturnType<typeof useGetTalentContactsLazyQuery>;
export type GetTalentContactsQueryResult = Apollo.QueryResult<GetTalentContactsQuery, GetTalentContactsQueryVariables>;
export const UpdateTalentJobTypesDocument = gql`
    mutation updateTalentJobTypes($values: [JobTypeValueEnum!]!) {
  updateTalentJobTypes(values: $values) {
    success
  }
}
    `;
export type UpdateTalentJobTypesMutationFn = Apollo.MutationFunction<UpdateTalentJobTypesMutation, UpdateTalentJobTypesMutationVariables>;

/**
 * __useUpdateTalentJobTypesMutation__
 *
 * To run a mutation, you first call `useUpdateTalentJobTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTalentJobTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTalentJobTypesMutation, { data, loading, error }] = useUpdateTalentJobTypesMutation({
 *   variables: {
 *      values: // value for 'values'
 *   },
 * });
 */
export function useUpdateTalentJobTypesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTalentJobTypesMutation, UpdateTalentJobTypesMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UpdateTalentJobTypesMutation, UpdateTalentJobTypesMutationVariables>(UpdateTalentJobTypesDocument, options);
}
export type UpdateTalentJobTypesMutationHookResult = ReturnType<typeof useUpdateTalentJobTypesMutation>;
export type UpdateTalentJobTypesMutationResult = Apollo.MutationResult<UpdateTalentJobTypesMutation>;
export type UpdateTalentJobTypesMutationOptions = Apollo.BaseMutationOptions<UpdateTalentJobTypesMutation, UpdateTalentJobTypesMutationVariables>;
export const GetTalentJobTypeDocument = gql`
    query getTalentJobType {
  getTalentJobTypes {
    items {
      value
    }
  }
}
    `;

/**
 * __useGetTalentJobTypeQuery__
 *
 * To run a query within a React component, call `useGetTalentJobTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentJobTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentJobTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTalentJobTypeQuery(baseOptions?: Apollo.QueryHookOptions<GetTalentJobTypeQuery, GetTalentJobTypeQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetTalentJobTypeQuery, GetTalentJobTypeQueryVariables>(GetTalentJobTypeDocument, options);
}
export function useGetTalentJobTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTalentJobTypeQuery, GetTalentJobTypeQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetTalentJobTypeQuery, GetTalentJobTypeQueryVariables>(GetTalentJobTypeDocument, options);
}
export type GetTalentJobTypeQueryHookResult = ReturnType<typeof useGetTalentJobTypeQuery>;
export type GetTalentJobTypeLazyQueryHookResult = ReturnType<typeof useGetTalentJobTypeLazyQuery>;
export type GetTalentJobTypeQueryResult = Apollo.QueryResult<GetTalentJobTypeQuery, GetTalentJobTypeQueryVariables>;
export const UpdateTalentObjectiveDocument = gql`
    mutation updateTalentObjective($value: ObjectiveValueEnum!) {
  updateTalentObjective(value: $value) {
    success
  }
}
    `;
export type UpdateTalentObjectiveMutationFn = Apollo.MutationFunction<UpdateTalentObjectiveMutation, UpdateTalentObjectiveMutationVariables>;

/**
 * __useUpdateTalentObjectiveMutation__
 *
 * To run a mutation, you first call `useUpdateTalentObjectiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTalentObjectiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTalentObjectiveMutation, { data, loading, error }] = useUpdateTalentObjectiveMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateTalentObjectiveMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTalentObjectiveMutation, UpdateTalentObjectiveMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UpdateTalentObjectiveMutation, UpdateTalentObjectiveMutationVariables>(UpdateTalentObjectiveDocument, options);
}
export type UpdateTalentObjectiveMutationHookResult = ReturnType<typeof useUpdateTalentObjectiveMutation>;
export type UpdateTalentObjectiveMutationResult = Apollo.MutationResult<UpdateTalentObjectiveMutation>;
export type UpdateTalentObjectiveMutationOptions = Apollo.BaseMutationOptions<UpdateTalentObjectiveMutation, UpdateTalentObjectiveMutationVariables>;
export const GetTalentObjectiveDocument = gql`
    query getTalentObjective {
  getTalentObjective {
    id
    value
  }
}
    `;

/**
 * __useGetTalentObjectiveQuery__
 *
 * To run a query within a React component, call `useGetTalentObjectiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentObjectiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentObjectiveQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTalentObjectiveQuery(baseOptions?: Apollo.QueryHookOptions<GetTalentObjectiveQuery, GetTalentObjectiveQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetTalentObjectiveQuery, GetTalentObjectiveQueryVariables>(GetTalentObjectiveDocument, options);
}
export function useGetTalentObjectiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTalentObjectiveQuery, GetTalentObjectiveQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetTalentObjectiveQuery, GetTalentObjectiveQueryVariables>(GetTalentObjectiveDocument, options);
}
export type GetTalentObjectiveQueryHookResult = ReturnType<typeof useGetTalentObjectiveQuery>;
export type GetTalentObjectiveLazyQueryHookResult = ReturnType<typeof useGetTalentObjectiveLazyQuery>;
export type GetTalentObjectiveQueryResult = Apollo.QueryResult<GetTalentObjectiveQuery, GetTalentObjectiveQueryVariables>;
export const UpdateTalentPrivacyDocument = gql`
    mutation updateTalentPrivacy($value: PrivacyValueEnum!) {
  updateTalentPrivacy(value: $value) {
    success
  }
}
    `;
export type UpdateTalentPrivacyMutationFn = Apollo.MutationFunction<UpdateTalentPrivacyMutation, UpdateTalentPrivacyMutationVariables>;

/**
 * __useUpdateTalentPrivacyMutation__
 *
 * To run a mutation, you first call `useUpdateTalentPrivacyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTalentPrivacyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTalentPrivacyMutation, { data, loading, error }] = useUpdateTalentPrivacyMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateTalentPrivacyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTalentPrivacyMutation, UpdateTalentPrivacyMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UpdateTalentPrivacyMutation, UpdateTalentPrivacyMutationVariables>(UpdateTalentPrivacyDocument, options);
}
export type UpdateTalentPrivacyMutationHookResult = ReturnType<typeof useUpdateTalentPrivacyMutation>;
export type UpdateTalentPrivacyMutationResult = Apollo.MutationResult<UpdateTalentPrivacyMutation>;
export type UpdateTalentPrivacyMutationOptions = Apollo.BaseMutationOptions<UpdateTalentPrivacyMutation, UpdateTalentPrivacyMutationVariables>;
export const GetTalentPrivacyDocument = gql`
    query getTalentPrivacy {
  getTalentPrivacy {
    id
    value
  }
}
    `;

/**
 * __useGetTalentPrivacyQuery__
 *
 * To run a query within a React component, call `useGetTalentPrivacyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentPrivacyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentPrivacyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTalentPrivacyQuery(baseOptions?: Apollo.QueryHookOptions<GetTalentPrivacyQuery, GetTalentPrivacyQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetTalentPrivacyQuery, GetTalentPrivacyQueryVariables>(GetTalentPrivacyDocument, options);
}
export function useGetTalentPrivacyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTalentPrivacyQuery, GetTalentPrivacyQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetTalentPrivacyQuery, GetTalentPrivacyQueryVariables>(GetTalentPrivacyDocument, options);
}
export type GetTalentPrivacyQueryHookResult = ReturnType<typeof useGetTalentPrivacyQuery>;
export type GetTalentPrivacyLazyQueryHookResult = ReturnType<typeof useGetTalentPrivacyLazyQuery>;
export type GetTalentPrivacyQueryResult = Apollo.QueryResult<GetTalentPrivacyQuery, GetTalentPrivacyQueryVariables>;
export const UpdateTalentRelocationDocument = gql`
    mutation updateTalentRelocation($countries: [String!] = [], $value: RelocationValueEnum!) {
  updateTalentRelocation(countries: $countries, value: $value) {
    success
  }
}
    `;
export type UpdateTalentRelocationMutationFn = Apollo.MutationFunction<UpdateTalentRelocationMutation, UpdateTalentRelocationMutationVariables>;

/**
 * __useUpdateTalentRelocationMutation__
 *
 * To run a mutation, you first call `useUpdateTalentRelocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTalentRelocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTalentRelocationMutation, { data, loading, error }] = useUpdateTalentRelocationMutation({
 *   variables: {
 *      countries: // value for 'countries'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateTalentRelocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTalentRelocationMutation, UpdateTalentRelocationMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useMutation<UpdateTalentRelocationMutation, UpdateTalentRelocationMutationVariables>(UpdateTalentRelocationDocument, options);
}
export type UpdateTalentRelocationMutationHookResult = ReturnType<typeof useUpdateTalentRelocationMutation>;
export type UpdateTalentRelocationMutationResult = Apollo.MutationResult<UpdateTalentRelocationMutation>;
export type UpdateTalentRelocationMutationOptions = Apollo.BaseMutationOptions<UpdateTalentRelocationMutation, UpdateTalentRelocationMutationVariables>;
export const GetTalentRelocationDocument = gql`
    query getTalentRelocation {
  getTalentRelocation {
    id
    countries
    value
  }
}
    `;

/**
 * __useGetTalentRelocationQuery__
 *
 * To run a query within a React component, call `useGetTalentRelocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentRelocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentRelocationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTalentRelocationQuery(baseOptions?: Apollo.QueryHookOptions<GetTalentRelocationQuery, GetTalentRelocationQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useQuery<GetTalentRelocationQuery, GetTalentRelocationQueryVariables>(GetTalentRelocationDocument, options);
}
export function useGetTalentRelocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTalentRelocationQuery, GetTalentRelocationQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions};
    return Apollo.useLazyQuery<GetTalentRelocationQuery, GetTalentRelocationQueryVariables>(GetTalentRelocationDocument, options);
}
export type GetTalentRelocationQueryHookResult = ReturnType<typeof useGetTalentRelocationQuery>;
export type GetTalentRelocationLazyQueryHookResult = ReturnType<typeof useGetTalentRelocationLazyQuery>;
export type GetTalentRelocationQueryResult = Apollo.QueryResult<GetTalentRelocationQuery, GetTalentRelocationQueryVariables>;