import {Suspense, useEffect} from 'react';
import {ApolloProvider} from '@apollo/client';
import { GoogleOAuthProvider } from '@react-oauth/google';

//Pages
import Routes from '~pages';
//Components
import AppHeader from '~components/AppHeader';
import AppFooter from '~components/AppFooter';
//Apollo
import client from './apolloClient';
//Providers
import {AuthProvider} from '~context/auth';
import CommonModalsProvider from '~context/commonModals';
import LandingPageUserProvider from '~context/landingPageUser';
import UserFeedbackProvider, {
    useUserFeedbackControl
} from '~context/userFeedback';
//Marketing
import {GTM} from '~mktgTools';
//Utils
import {initRemoteReporter} from '~utils/remoteReportHelper';
import IdleLogout from '~components/IdleLogout';
// Hooks
import useLocalStorageNotAvailableAlert from '~hooks/useLocalStorageNotAvailableAlert';
// Config
import { GOOGLE_CLIENT_ID } from '~config/constants';
import LoaderProvider from '~context/loader';



export default function MainApp() {
    useEffect(() => {
        function bootstrap() {
            initRemoteReporter();
            GTM.init();
        }

        window.addEventListener('load', bootstrap);

        return () => {
            window.removeEventListener('load', bootstrap);
        };
    }, []);

    return (
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <ApolloProvider client={client}>
                <Suspense fallback="">
                    <UserFeedbackProvider>
                        <CommonModalsProvider>
                            <LoaderProvider>
                                <MainAppWithAuth/>
                            </LoaderProvider>
                        </CommonModalsProvider>
                    </UserFeedbackProvider>
                </Suspense>
            </ApolloProvider>
        </GoogleOAuthProvider>
    );
}

function MainAppWithAuth() {
    const {
        initFeedbackServiceWithProfile,
        resetFeedbackService
    } = useUserFeedbackControl();

    useLocalStorageNotAvailableAlert();

    return (
        <AuthProvider
            onAuthSet={initFeedbackServiceWithProfile}
            onCleanInit={initFeedbackServiceWithProfile}
            onAuthClear={resetFeedbackService}
        >
            <IdleLogout/>
            <LandingPageUserProvider>
                <AppHeader/>
                <AppFooter/>
                <main className="page-content">
                    <Routes/>
                </main>
            </LandingPageUserProvider>
        </AuthProvider>
    );
}
