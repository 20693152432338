import {ReactNode, useCallback, useState} from 'react';
import cn from 'classnames';

//Types
import type {IModalParams} from '~components/Modal/types';
//Components
import Modal, {closeModalById} from '~components/Modal';
//Config
import {DEFAULT_CONFIRM_MODAL_CANCEL_BTN_LABEL, DEFAULT_CONFIRM_MODAL_AGREE_BTN_LABEL} from '~config/constants';



export interface IConfirmModalParams {
    title: ReactNode
    className?: string
    modalBody?: ReactNode
    confirmBtnLabel?: ReactNode
    cancelBtnLabel?: ReactNode
    onConfirm(): void | Promise<void>
    onCancel?(): void | Promise<void>
}

type IConfirmModalProps = IModalParams & IConfirmModalParams;

export const DEFAULT_CONFIRM_MODAL_ID = Symbol('DEFAULT_CONFIRM_MODAL_ID');

export default function ConfirmModal({
    onClose,
    title,
    modalBody,
    className,
    onConfirm,
    confirmBtnLabel = DEFAULT_CONFIRM_MODAL_AGREE_BTN_LABEL,
    cancelBtnLabel = DEFAULT_CONFIRM_MODAL_CANCEL_BTN_LABEL,
    onCancel,
    id = DEFAULT_CONFIRM_MODAL_ID
}: IConfirmModalProps) {
    const [processing, setProcessing] = useState(false);

    const handleProcessing = useCallback(async (result: void | Promise<void>) => {
        if (result) {
            setProcessing(true);
            await result;
            setProcessing(false);
        }
    }, [setProcessing]);

    const handleConfirmBtnClick = useCallback(async () => {
        await handleProcessing(onConfirm?.());
        closeModalById({onClose, modalId: id});
    }, [onClose, onConfirm, id, handleProcessing]);

    const handleCancelBtnClick = useCallback(async () => {
        await handleProcessing(onCancel?.());
        closeModalById({onClose, modalId: id});
    }, [onClose, onCancel, id, handleProcessing]);

    const handleOnClose = useCallback(async () => {
        await handleProcessing(onCancel?.());
        onClose();
    }, [onClose, onCancel, handleProcessing]);

    return (
        <Modal
            id={id}
            onClose={handleOnClose}
            className={cn('common-modals confirm-modal', className, {'without-footer': !modalBody})}
        >
            <Modal.CloseButton/>

            <Modal.Body className="common-modals__body">
                <h2 className="common-modals__title">{title}</h2>
                {modalBody && <div className="common-modals__content">{modalBody}</div>}
                {!modalBody &&
                    <div className="common-modals__actions">
                        <button type="button" className="btn" onClick={handleConfirmBtnClick} disabled={processing}>{confirmBtnLabel}</button>
                        <button type="button" className="btn btn--secondary" onClick={handleCancelBtnClick} disabled={processing}>{cancelBtnLabel}</button>
                    </div>
                }
            </Modal.Body>

            {modalBody &&
                <Modal.Footer className="common-modals__footer">
                    <button type="button" className="btn" onClick={handleConfirmBtnClick} disabled={processing}>{confirmBtnLabel}</button>
                    <button type="button" className="btn btn--secondary" onClick={handleCancelBtnClick} disabled={processing}>{cancelBtnLabel}</button>
                </Modal.Footer>
            }
        </Modal>
    );
}
