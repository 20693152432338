import { AuthActionTypes, IAuthAction, IAuthState } from '~context/auth/types';



export const authReducer = (
    state: IAuthState,
    { type, payload }: IAuthAction
): IAuthState => {
    switch (type) {
        case AuthActionTypes.LOGIN: {
            return {
                ...state,
                token: payload?.token,
                profile: payload?.profile,
                loading: payload?.loading ? payload.loading : state.loading,
                subscription: payload?.subscription
            };
        }

        case AuthActionTypes.LOGOUT: {
            return {
                ...state,
                token: null,
                profile: null,
                loading: payload?.loading ? payload.loading : state.loading,
                subscription: null
            };
        }

        case AuthActionTypes.SET_LOADING_STATE: {
            return {
                ...state,
                loading: payload?.loading ? payload.loading : state.loading
            };
        }

        case AuthActionTypes.CHECK_AUTH: {
            return {
                ...state,
                token: payload?.token ? payload.token : state.token,
                profile: payload?.profile,
                loading: payload?.loading ? payload.loading : state.loading
            };
        }
        case AuthActionTypes.SET_SUBSCRIPTION: {
            return {
                ...state,
                loading: payload?.loading ? payload.loading : state.loading,
                subscription: payload?.subscription
            };
        }

        case AuthActionTypes.UPDATE_TOKEN: {
            return {
                ...state,
                token: payload?.token ?? null,
                loading: payload?.loading ? payload.loading : state.loading
            };
        }

        default:
            return state;
    }
};
