import LStorage from './LStorage';



const CHUNK_HANDLER_STORAGE_KEY = 'page-has-been-force-refreshed';

export function chunkErrorHandler(error: Error): any {
    let localStorageValue = 'false';

    tryLocalStorageMethod(
        () => localStorageValue = LStorage.getFromStorage(CHUNK_HANDLER_STORAGE_KEY) || 'false'
    );

    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
        localStorageValue
    );

    tryLocalStorageMethod(
        () => LStorage.removeFromStorage(CHUNK_HANDLER_STORAGE_KEY)
    );

    if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        tryLocalStorageMethod(
            () => LStorage.addToStorage(CHUNK_HANDLER_STORAGE_KEY, 'true')
        );
        window.location.reload();

        return {default: () => null as any};
    }

    // The page has already been reloaded
    // Assuming that user is already using the latest version of the application.
    // Let's let the application crash and raise the error.
    throw error;
}

export function cleanUp<T>(component: T): T {
    tryLocalStorageMethod(
        () => LStorage.removeFromStorage(CHUNK_HANDLER_STORAGE_KEY)
    );
    return component;
}

/**
 * Handler for browsers with disabled localStorage function - catches errors such "Security error"
 */
function tryLocalStorageMethod(func: () => void): void {
    try {
        func();
    } catch {
        // eslint-disable-next-line no-console
        console.warn('LocalStorage inactive');
    }
}
