import {ReactNode, useCallback} from 'react';
import cn from 'classnames';

//Types
import type {IModalParams} from '~components/Modal/types';
//Components
import Modal, {closeModalById} from '~components/Modal';
//Config
import {DEFAULT_INFO_MODAL_BTN_LABEL} from '~config/constants';



export interface IInfoModalParams {
    title: ReactNode
    className?: string
    modalBody?: ReactNode
    btnLabel?: ReactNode
    onConfirm?(): void | Promise<void>
}

type IInfoModalProps = IModalParams & IInfoModalParams;

export const DEFAULT_INFO_MODAL_ID = Symbol('DEFAULT_INFO_MODAL_ID');

export default function InfoModal({
    onClose, title, modalBody, className, btnLabel = DEFAULT_INFO_MODAL_BTN_LABEL, id = DEFAULT_INFO_MODAL_ID, onConfirm
}: IInfoModalProps) {

    const handleConfirmBtnClick = useCallback(() => {
        onConfirm?.();
        closeModalById({onClose, modalId: id});
    }, [onClose, onConfirm, id]);

    return (
        <Modal
            id={id}
            onClose={onClose}
            className={cn('common-modals info-modal', className, {'without-footer': !modalBody})}
        >
            <Modal.CloseButton/>

            <Modal.Body className="common-modals__body">
                <h2 className="common-modals__title">{title}</h2>
                {modalBody && <div className="common-modals__content">{modalBody}</div>}
                {!modalBody &&
                    <div className="common-modals__actions">
                        <button type="button" className="btn" onClick={handleConfirmBtnClick}>{btnLabel}</button>
                    </div>
                }
            </Modal.Body>

            {modalBody &&
                <Modal.Footer className="common-modals__footer">
                    <button type="button" className="btn" onClick={handleConfirmBtnClick}>{btnLabel}</button>
                </Modal.Footer>
            }
        </Modal>
    );
}
