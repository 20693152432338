import {useEffect} from 'react';
import {useIdleTimer} from 'react-idle-timer';
import {
    IDLE_LOGOUT_COOKIE_NAME,
    IDLE_LOGOUT_TIMEOUT
} from '~config/constants';
import {useAuthContext} from '~context/auth';
import useFirstRender from '~hooks/useFirstRender';


/**
 * Cookie handlers to store idle state
 * If cookie exists - user isn't idle
 */
const getIdleCookie = () => document.cookie.includes(IDLE_LOGOUT_COOKIE_NAME);
const setIdleCookie = (timeout: number = IDLE_LOGOUT_TIMEOUT) => {
    document.cookie = `${IDLE_LOGOUT_COOKIE_NAME}=true; path=/; expires=${new Date(
        Date.now() + IDLE_LOGOUT_TIMEOUT
    ).toUTCString()}; SameSite=None; Secure`;
};
const deleteCookie = () => setIdleCookie(-IDLE_LOGOUT_TIMEOUT);

export default function IdleLogout() {
    const {isAuthenticated, logout} = useAuthContext();

    const handleOnIdle = (event: Event) => {
        if (isLeader()) {
            logout();
            deleteCookie();
        }
    };

    const handleOnActive = () => {
        if (isAuthenticated) setIdleCookie();
    };

    // Configured for crossTab support - idle timeout resets if user interacts in one of many opened tabs
    const {start, isLeader} = useIdleTimer({
        timeout: IDLE_LOGOUT_TIMEOUT,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnActive,
        crossTab: true,
        debounce: 500,
        startManually: true
    });

    const isFirstRender = useFirstRender();

    useEffect(() => {
        if (isAuthenticated) {
            // Logout after opening or reloading tab
            if (!getIdleCookie() && isFirstRender) {
                return logout({onlyLocal: true});
            }

            setIdleCookie();
            return start();
        }
    }, [logout, isAuthenticated, isFirstRender, start]);

    return <></>;
}
