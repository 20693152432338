import {createContext, useCallback, useContext, useEffect, useRef, useState} from 'react';

//Types
import type {IContextProps, IInitFeedback, IUserFeedbackProviderProps} from './types';
import type {ProfileFieldsFragment} from '~graphqlResources';
//Config
import {USERSNAP_GLOBAL_API_KEY, USERSNAP_PROJECT_API_KEY, USERSNAP_BOOTSTRAP_TIMEOUT} from '~config/constants';
//Utils
import {captureException} from '~utils/remoteReportHelper';



const UserFeedbackContext = createContext<Partial<IContextProps>>({});

const sendErrorReport = (err: Error, method: string) => {
    captureException({
        error: err,
        errorScope: 'UserFeedback',
        extraData: {extra: {feedback_method: method}}
    });
};

export default function UserFeedbackProvider({children}: IUserFeedbackProviderProps) {
    const deferredInit = useRef<{params?: IInitFeedback}>(null);
    const UsersnapAPIRef = useRef<any>(null);
    const WidgetAPIRef = useRef<any>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [APIIsReady, setIsReady] = useState(false);
    const [widgetAPIIsReady, setWidgetAPIIsReady] = useState(false);
    const [buttonIsVisible, setButtonIsVisible] = useState(false);

    const showFeedbackButton = useCallback(
        async () => {
            try {
                if (UsersnapAPIRef.current) {
                    WidgetAPIRef.current = await UsersnapAPIRef.current.show(USERSNAP_PROJECT_API_KEY);
                    setWidgetAPIIsReady(true);
                    setButtonIsVisible(true);
                    return WidgetAPIRef.current;
                }
            } catch (err) {
                if (err instanceof Error) sendErrorReport(err, 'show');
            }
        },
        []
    );

    const initFeedbackService = useCallback(
        async (params?: IInitFeedback) => {
            try {
                if (UsersnapAPIRef.current) {
                    const event = await UsersnapAPIRef.current.init(params);
                    setIsReady(true);

                    await showFeedbackButton();

                    deferredInit.current = null;

                    return event;
                } else {
                    deferredInit.current = {params};
                }
            } catch (err) {
                if (err instanceof Error) sendErrorReport(err, 'init');
            }
        },
        [showFeedbackButton]
    );

    const destroyFeedbackService = useCallback(
        async () => {
            try {
                if (UsersnapAPIRef.current) {
                    return Promise.race([
                        UsersnapAPIRef.current.destroy(),
                        // XXX: Workaround UserSnap destroy() Promise infinite pending status in Firefox
                        new Promise((resolve) => {
                            setTimeout(resolve, 2000);
                        })
                    ]);
                }
            } catch (err) {
                if (err instanceof Error) sendErrorReport(err, 'destroy');
            }
        },
        []
    );

    //Internally also calls destroy method ¯\_(ツ)_/¯
    const hideFeedbackButton = useCallback(
        async () => {
            try {
                if (UsersnapAPIRef.current) {
                    const widgetApi = await UsersnapAPIRef.current.hide(USERSNAP_PROJECT_API_KEY);
                    setButtonIsVisible(false);
                    return widgetApi;
                }
            } catch (err) {
                if (err instanceof Error) sendErrorReport(err, 'hide');
            }
        },
        []
    );

    const openFeedbackDialog = useCallback(
        async () => {
            try {
                if (WidgetAPIRef.current) {
                    return await WidgetAPIRef.current.open();
                }
            } catch (err) {
                if (err instanceof Error) sendErrorReport(err, 'open');
            }
        },
        []
    );

    /*
    * Available event listeners:
    * open, load, destroy, closing, close, submit
    * */
    const addListener = useCallback(
        (event: string, cb: (event?: any) => void) => {
            if (UsersnapAPIRef.current) {
                UsersnapAPIRef.current.on(event, cb);
            }
        },
        []
    );

    /*const removeListener = useCallback(
        (event: string) => {
            if (Usersnap.current) {
                const eventresult = Usersnap.current.off(event);
                console.log('eventresult', eventresult);
            }
        },
        []
    );*/


    useEffect(() => {
        function bootstrap() {
            (window as any).onUsersnapCXLoad = function(api: any) {
                UsersnapAPIRef.current = api;
                setIsLoaded(true);

                addListener('destroy', function(event: any) {
                    setIsReady(false);
                    setWidgetAPIIsReady(false);
                    setButtonIsVisible(false);
                    deferredInit.current = null;
                });
            };
            const script = document.createElement('script');
            script.defer = true;
            script.src = `https://widget.usersnap.com/global/load/${USERSNAP_GLOBAL_API_KEY}?onload=onUsersnapCXLoad`;
            document.getElementsByTagName('head')[0].appendChild(script);
        }

        let token: NodeJS.Timeout;
        const timeOutedBootstrap = () => {
            token = setTimeout(bootstrap, USERSNAP_BOOTSTRAP_TIMEOUT);
        };
        window.addEventListener('load', timeOutedBootstrap);

        return () => {
            clearTimeout(token);
            window.removeEventListener('load', timeOutedBootstrap);
        };
    }, [addListener]);

    useEffect(() => {
        if (isLoaded && deferredInit.current) {
            initFeedbackService(deferredInit.current.params);
        }
    }, [initFeedbackService, isLoaded]);

    useEffect(() => {
        document.documentElement.classList.toggle('with-feedback-btn', buttonIsVisible);
    }, [buttonIsVisible]);

    return (
        <UserFeedbackContext.Provider
            value={{
                API: UsersnapAPIRef.current,
                WidgetAPI: WidgetAPIRef.current,
                isLoaded,
                APIIsReady,
                widgetAPIIsReady,
                buttonIsVisible,
                initFeedbackService,
                destroyFeedbackService,
                showFeedbackButton,
                hideFeedbackButton,
                openFeedbackDialog,
                addListener
            }}
        >
            {children}
        </UserFeedbackContext.Provider>
    );
}

export const useUserFeedback = () => useContext(UserFeedbackContext);

export const useUserFeedbackControl = () => {
    const {initFeedbackService, destroyFeedbackService} = useUserFeedback();

    const initFeedbackServiceWithProfile = useCallback((profile?: ProfileFieldsFragment) => {
        const {__typename, fullName, id, email, ...userForReport} = profile || {};

        try {
            initFeedbackService({
                user: {
                    email: email || '',
                    userId: id
                },
                custom: profile && {
                    username: fullName,
                    ...userForReport
                }
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn('Feedback service couldn\'t init', e);
        }
    }, [initFeedbackService]);

    const resetFeedbackService = useCallback(() => {
        destroyFeedbackService().then(() => {
            initFeedbackServiceWithProfile();
        }).catch((e) => {
            // eslint-disable-next-line no-console
            console.warn('Feedback service couldn\'t init', e);
        });
    }, [destroyFeedbackService, initFeedbackServiceWithProfile]);

    return {initFeedbackServiceWithProfile, resetFeedbackService};
};
