import {lazy} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

//Config
import {
    emailConfirmRoute,
    forgotPasswordRoute,
    homeRoute,
    linkedInRoute,
    loginRoute,
    notFoundRoute,
    previewComponentsRoute,
    registerChooseTypeRoute,
    registerEnterpriseRoute,
    registerRouteBase,
    registerTalentRoute,
    resetPasswordRoute,
    TALENT_ROUTE_NAMESPACE,
    ENTERPRISE_ROUTE_NAMESPACE,
    deleteProfileSuccessRoute
} from '~config/routes';
// Utils
import {chunkErrorHandler, cleanUp} from '~utils/chunkErrorHandler';


// Dynamic imports
const LinkedInPopUp = lazy(() => import('react-linkedin-login-oauth2').then(({LinkedInPopUp}) => ({default: LinkedInPopUp})).then(cleanUp).catch(chunkErrorHandler));

const RegisterType = lazy(() => import('~pages/RegisterType').then(cleanUp).catch(chunkErrorHandler));
const RegisterTalent = lazy(() => import('~pages/RegisterTalent').then(cleanUp).catch(chunkErrorHandler));
const RegisterEnterprise = lazy(() => import('~pages/RegisterEnterprise').then(cleanUp).catch(chunkErrorHandler));
const Login = lazy(() => import('~pages/Login').then(cleanUp).catch(chunkErrorHandler));
const EmailConfirm = lazy(() => import('~pages/EmailConfirm').then(cleanUp).catch(chunkErrorHandler));
const ResetPassword = lazy(() => import('~pages/ResetPassword').then(cleanUp).catch(chunkErrorHandler));
const ForgotPassword = lazy(() => import('~pages/ForgotPassword').then(cleanUp).catch(chunkErrorHandler));
const NoMatch = lazy(() => import('~pages/NoMatch').then(cleanUp).catch(chunkErrorHandler));
const PreviewComponents = lazy(() => import('~pages/PreviewComponents').then(cleanUp).catch(chunkErrorHandler));
const TalentRoutes = lazy(() => import('~pages/TalentRoutes').then(cleanUp).catch(chunkErrorHandler));
const EnterpriseRoutes = lazy(() => import('~pages/EnterpriseRoutes').then(cleanUp).catch(chunkErrorHandler));
const DeleteProfileSuccess = lazy(() => import('~pages/DeleteProfileSuccess').then(cleanUp).catch(chunkErrorHandler));



export default function Routes() {
    return (
        <Switch>
            <Redirect exact from={homeRoute} to={loginRoute}/>
            <Redirect exact from={registerRouteBase} to={registerChooseTypeRoute}/>
            <Route exact path={registerChooseTypeRoute} component={RegisterType}/>
            <Route exact path={registerTalentRoute} component={RegisterTalent}/>
            <Route exact path={registerEnterpriseRoute} component={RegisterEnterprise}/>
            <Route exact path={loginRoute} component={Login}/>
            <Route exact path={forgotPasswordRoute} component={ForgotPassword}/>
            <Route exact path={notFoundRoute} component={NoMatch}/>

            {process.env.REACT_APP_ONBOARDING_STEPS_DEV_HELPER === 'on' && (
                <Route exact path={previewComponentsRoute} component={PreviewComponents}/>
            )}

            <Route exact path={linkedInRoute} component={LinkedInPopUp}/>
            <Route exact path={emailConfirmRoute} component={EmailConfirm}/>
            <Route exact path={resetPasswordRoute} component={ResetPassword}/>
            <Route exact path={deleteProfileSuccessRoute} component={DeleteProfileSuccess}/>

            <Route path={TALENT_ROUTE_NAMESPACE} component={TalentRoutes}/>

            <Route path={ENTERPRISE_ROUTE_NAMESPACE} component={EnterpriseRoutes}/>

            <Redirect to={notFoundRoute}/>
        </Switch>
    );
}
