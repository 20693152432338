import { useRef, useEffect } from 'react';



function useFirstRender() {
    const firstRender = useRef(true);

    useEffect(() => {
        firstRender.current = false;
    }, []);

    return firstRender.current;
}

export default useFirstRender;
