import {storageAvailable} from './helpers';



const LOCAL_STORAGE_NAMESPACE = 'Talentprise';

/**
 * Fallback realization of localStorage or sessionStorage if they are restricted in browser
 */
class InMemoryStorage implements Storage {
        _data: Record<string, string> = {};
        setItem(id: string, val: string) { return this._data[id] = String(val); }
        getItem(id: string) { return Object.prototype.hasOwnProperty.call(this._data, id) ? this._data[id] : undefined; }
        removeItem(id: string) { return delete this._data[id]; }
        clear() { return this._data = {}; }
        get length() { return Object.keys(this._data).length;}
        key(index: number) {return Object.keys(this._data)[index];}
}

class LStorage {
    static IS_AVAILABLE = storageAvailable('localStorage');

    name = LOCAL_STORAGE_NAMESPACE;

    localStorage = LStorage.IS_AVAILABLE ? localStorage : new InMemoryStorage();

    private _parseSafely = (val: string, storeName: string) => {
        try {
            return JSON.parse(val);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn('LStorage._parseSafely() didn\'t succeed to parse data from storage');
            this.removeFromStorage(storeName);
            return null;
        }
    };

    isAvailable = () => {
        return LStorage.IS_AVAILABLE;
    };

    getFromStorage = (storeName: string) => {
        try {
            return this.localStorage.getItem(`${this.name}/${storeName}`);
        } catch {
            return null;
        }
    };

    getParsedFromStorage = <T>(storeName: string): T | null => {
        try {
            return this._parseSafely(this.getFromStorage(storeName), storeName) as T;
        } catch {
            return null;
        }
    };

    addToStorage = (storeName: string, payload: any) => {
        try {
            this.localStorage.setItem(`${this.name}/${storeName}`, JSON.stringify(payload));
        } catch {
            // eslint-disable-next-line no-console
            console.warn('localStorage.setItem() doesn\'t exist because localStorage API isn\'t available');
        }
    };

    removeFromStorage = (storeName: string) => {
        try {
            this.localStorage.removeItem(`${this.name}/${storeName}`);
        } catch {
            // eslint-disable-next-line no-console
            console.warn('localStorage.removeItem() doesn\'t exist because localStorage API isn\'t available');
        }
    };
}

export default new LStorage();
