import {lazy} from 'react';

//Hooks
import {useAuthContext} from '~context/auth';
//Types
import {ProfileTypeEnum} from '~graphqlResources';
// Utils
import {chunkErrorHandler, cleanUp} from '~utils/chunkErrorHandler';


// Dynamic imports
const SearchTalentForm = lazy(() => import('~pages/enterprise/EnterpriseSearchTalents/SearchTalentForm').then(cleanUp).catch(chunkErrorHandler));



export default function HeaderSearch() {
    const {profile} = useAuthContext();

    const formIsVisible = profile?.type === ProfileTypeEnum.ENTERPRISE && profile?.onboarded;

    if (!formIsVisible) return null;

    return <SearchTalentForm/>;
}
