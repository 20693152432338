import type {RetryLink} from '@apollo/client/link/retry';



export const RETRY_LINK_CONFIG: RetryLink.Options = {
    delay: {
        initial: 300,
        jitter: true
    },
    attempts: {
        max: 7
    }
};
