import cn from 'classnames';

//Types
import {ReactMouseEventCb} from '~types';



interface IHamburgerBtnProps {
    onClick: ReactMouseEventCb<HTMLButtonElement>
    isActive?: boolean
}

const HamburgerBtn = ({onClick, isActive}: IHamburgerBtnProps) => (
    <button
        type="button"
        className={cn('hamburger-btn', {'is-active': isActive})}
        onClick={onClick}
        aria-label={`${isActive ? 'close' : 'open'} menu`}
    >
        <span className="hamburger-btn__line"/>
        <span className="hamburger-btn__line"/>
        <span className="hamburger-btn__line"/>
    </button>
);

export default HamburgerBtn;
