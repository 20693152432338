import {useEffect, useState} from 'react';
import {useCommonModals} from '~context/commonModals';

// Utils
import LStorage from '~utils/LStorage';



export default function useLocalStorageNotAvailableAlert(displayAlert = true) {
    const {openInfoModal} = useCommonModals();

    const [notified, setNotified] = useState(() => LStorage.isAvailable());

    useEffect(() => {
        if (!notified && displayAlert) {
            openInfoModal({
                title: 'LocalStorage is disabled',
                modalBody: 'LocalStorage is disabled in your browser. Please, enable it for correct functionality of the website.',
                onConfirm: () => setNotified(true)
            });
        }
    }, [displayAlert, notified, setNotified, openInfoModal]);
}
