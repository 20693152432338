import {
    FieldMergeFunction,
    FieldPolicy,
    FieldReadFunction,
    TypePolicies
} from '@apollo/client';
import { SafeReadonly } from '@apollo/client/cache/core/types/common';
import uniqWith from 'lodash.uniqwith';

// Types
import {
    ExperiencesType,
    GetChatMessagesQuery,
    GetChatsQuery,
    GetNotificationsQuery,
    HeadhunterPoolDataType,
    Maybe,
    PaginationMeta,
    TalentCertificatesType,
    TalentEducationsType,
    TalentItSkillsType,
    TalentLanguagesType
} from '~graphqlResources';



const readFromField = (
    fieldName: string
): FieldReadFunction<string | number, string> => (_, { readField }) =>
    readField(fieldName);

const extendOptionFields = {
    fields: {
        label: readFromField('name'),
        value: readFromField('id')
    }
};

const getOffset = ({
    page = 1,
    limit = 10
}: {
    page?: number;
    limit?: number;
}) => (page - 1) * limit;

const getHeadhunterPoolData: FieldPolicy<HeadhunterPoolDataType> = {
    keyArgs: ['hhPoolId', 'sort', 'filters'],
    merge(existing, incoming, { args: { pagination } }) {
        let mergedItems = existing?.talents?.items
            ? existing.talents.items.slice(0)
            : [];

        if (incoming?.talents.items) {
            for (let i = 0; i < incoming.talents.items.length; ++i) {
                mergedItems[getOffset(pagination) + i] =
                    incoming.talents.items[i];
            }

            //TODO: rewrite and test this logic, for now was used rough workaround for known issue with "talent privacy
            // updating do not affects headhunter pool data"

            //fix issues when number of items inside "incoming" differs from number of items inside "existing".
            //This leads to the duplicate items inside "mergedItems"
            mergedItems = uniqWith(
                mergedItems,
                (a: any, b: any) => a?.__ref && b?.__ref && a.__ref === b.__ref
            );
            //Or to the unwanted items which wrongly still exists at the end of "mergedItems"
            const currentPageExistingItems = mergedItems.slice(
                getOffset(pagination),
                getOffset(pagination) + (pagination?.limit ?? 10)
            );
            if (
                incoming.talents.items.length < currentPageExistingItems.length
            ) {
                mergedItems.splice(
                    getOffset(pagination) + 1,
                    currentPageExistingItems.length -
                        incoming.talents.items.length
                );
            }
        }

        return {
            ...incoming,
            talents: {
                ...incoming.talents,
                items: mergedItems
            }
        };
    },
    read(existing, { args: { pagination } }) {
        if (!existing) return;
        const offset = getOffset(pagination);
        const items = existing.talents.items.slice(
            offset,
            offset + pagination.limit
        );

        return {
            ...existing,
            talents: {
                ...existing.talents,
                items,
                // Rewrite pagination data to handle Paginator state with cached items
                meta: {
                    ...existing.talents.meta,
                    currentPage: pagination.page,
                    itemsPerPage: pagination.limit,
                    itemCount: items.length,
                    totalPages: Math.ceil(
                        existing.talents.meta.totalItems / pagination.limit
                    )
                }
            }
        };
    }
};

const concatPaginatedFieldPolicy: FieldPolicy<
    TalentEducationsType &
        ExperiencesType &
        TalentLanguagesType &
        TalentCertificatesType &
        TalentItSkillsType
> = {
    keyArgs: false,
    merge(existing, incoming) {
        return {
            ...incoming,
            items:
                existing?.items && incoming?.meta?.currentPage !== 1
                    ? [...existing?.items, ...incoming?.items]
                    : incoming?.items,
            meta: incoming?.meta || existing?.meta
        };
    }
};

type PaginatedType<T = any> = {
    items?: Maybe<Array<T>>;
    meta: PaginationMeta;
};

const pseudoCursorPaginationForChatsMerge = <T extends PaginatedType>(
    existing: SafeReadonly<T | undefined>,
    incoming: SafeReadonly<T>
): ReturnType<FieldMergeFunction<T>> => {
    // Handle accidentally overflowing current page which was set by subscribeToMore function
    if (incoming.meta.currentPage > incoming.meta.totalPages)
        return {
            ...existing,
            meta: {
                ...incoming.meta,
                currentPage: incoming.meta.totalPages
            }
        };

    // XXX: This pagination partially depends on subscribeToMore realization
    if (existing?.meta.currentPage === incoming.meta.currentPage) {
        if (
            incoming.meta.itemCount > incoming.meta.itemsPerPage &&
            incoming.meta.itemCount % incoming.meta.itemsPerPage === 0
        ) {
            return {
                ...incoming,
                meta: {
                    ...incoming.meta,
                    itemCount: incoming.meta.itemsPerPage,
                    currentPage: incoming.meta.currentPage + 1
                }
            };
        }

        return incoming;
    }

    return {
        ...incoming,
        items:
            existing?.items && incoming?.meta?.currentPage !== 1
                ? [
                    ...existing?.items,
                    ...incoming?.items.slice(
                        existing?.meta.itemCount - incoming.meta.itemsPerPage
                    )
                ]
                : incoming?.items,
        meta: incoming?.meta || existing?.meta
    };
};

const concatPaginatedChatMessagesFieldPolicy: FieldPolicy<
    GetChatMessagesQuery['getListOfMessages']
> = {
    keyArgs: ['chatId'],
    merge: pseudoCursorPaginationForChatsMerge
};

const concatPaginatedChatsFieldPolicy: FieldPolicy<
    GetChatsQuery['getListOfChats']
> = {
    keyArgs: false,
    merge: pseudoCursorPaginationForChatsMerge
};

const concatPaginatedNotificationsFieldPolicy: FieldPolicy<
    GetNotificationsQuery['getNotifications']
> = {
    keyArgs: false,
    merge: pseudoCursorPaginationForChatsMerge
};

export const typePolicies: TypePolicies = {
    UniversityCourseType: extendOptionFields,
    UniversityDegreeType: extendOptionFields,
    UniversityType: extendOptionFields,
    ExpertiseType: extendOptionFields,
    SkillType: extendOptionFields,
    ItSkillLevelType: extendOptionFields,
    ItSkillType: extendOptionFields,
    CertificateType: extendOptionFields,
    LanguageType: extendOptionFields,
    LanguageLevelType: extendOptionFields,
    AssessmentQuestionType: extendOptionFields,
    // Experience
    EmployerType: extendOptionFields,
    EmployerIndustryType: extendOptionFields,
    EmployerLabelType: extendOptionFields,
    EmployerSizeType: extendOptionFields,
    EmployerTypeType: extendOptionFields,
    OccupationType: extendOptionFields,
    NameWithIdResponse: { ...extendOptionFields, keyFields: ['id', 'name'] },
    HeadhunterPoolType: extendOptionFields,
    CampusType: extendOptionFields,
    CvEntity: { ...extendOptionFields, keyFields: ['id', 'name'] },
    Query: {
        fields: {
            getHeadhunterPoolData,
            getEnterpriseNoteByTalent: concatPaginatedFieldPolicy,
            getTalentEducations: concatPaginatedFieldPolicy,
            getExperiences: concatPaginatedFieldPolicy,
            getTalentItSkills: concatPaginatedFieldPolicy,
            getTalentCertificates: concatPaginatedFieldPolicy,
            getTalentLanguages: concatPaginatedFieldPolicy,
            getListOfMessages: concatPaginatedChatMessagesFieldPolicy,
            getListOfChats: concatPaginatedChatsFieldPolicy,
            getNotifications: concatPaginatedNotificationsFieldPolicy
        }
    }
};

export default typePolicies;
