import {useCallback, useEffect, useRef} from 'react';
import debounce from 'lodash.debounce';
import debouncePromise from 'debounce-promise';



interface IDebounceOptions {
    leading?: boolean
    trailing?: boolean
    maxWait?: number
}

function useIsMounted() {
    const isMountedRef = useRef(true);
    useEffect(() => {
        return () => {
            isMountedRef.current = false;
        };
    }, []);
    return () => isMountedRef.current;
}

function useDebounce(cb: (...args : any[]) => any, delay: number = 0, debounceOptions?: IDebounceOptions) {
    const options: IDebounceOptions = {
        leading: false,
        trailing: true,
        ...debounceOptions
    };
    const inputsRef = useRef({cb, delay});
    const isMounted = useIsMounted();

    useEffect(() => {
        inputsRef.current = { cb, delay };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(
        debounce(
            (...args) => {
                // Don't execute callback, if (1) component in the meanwhile
                // has been unmounted or (2) delay has changed
                if (inputsRef.current.delay === delay && isMounted()) inputsRef.current.cb(...args);
            },
            delay,
            options
        ),
        [delay, debounce]
    );
}

export default useDebounce;




interface IDebouncePromiseOptions {
    leading?: boolean | undefined;
    accumulate?: boolean | undefined;
}

export function useDebouncePromise(cb: (...args : any[]) => any, delay: number = 0, debounceOptions?: IDebouncePromiseOptions) {
    const inputsRef = useRef({cb, delay});
    const isMounted = useIsMounted();

    useEffect(() => {
        inputsRef.current = { cb, delay };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(
        debouncePromise(
            (...args) => {
                // Don't execute callback, if (1) component in the meanwhile
                // has been unmounted or (2) delay has changed
                if (inputsRef.current.delay === delay && isMounted()) return inputsRef.current.cb(...args);
            },
            delay,
            debounceOptions
        ),
        [delay, debouncePromise]
    );
}
