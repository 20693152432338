import { gql } from '@apollo/client';



export default gql`
    extend type UniversityCourseType {
        value: String!
        label: String!
    }
    extend type UniversityDegreeType {
        value: String!
        label: String!
    }
    extend type UniversityType {
        value: String!
        label: String!
    }
    extend type ExpertiseType {
        value: String!
        label: String!
    }
    extend type SkillType {
        value: String!
        label: String!
    }
    extend type ItSkillLevelType {
        value: String!
        label: String!
    }
    extend type ItSkillType {
        value: String!
        label: String!
    }
    extend type AssessmentQuestionType {
        value: String!
        label: String!
    }
    extend type CertificateType {
        value: String!
        label: String!
    }
    extend type LanguageType {
        value: String!
        label: String!
    }
    extend type LanguageLevelType {
        value: String!
        label: String!
    }
    extend type EmployerType {
        value: String!
        label: String!
    }
    extend type EmployerIndustryType {
        value: String!
        label: String!
    }
    extend type EmployerLabelType {
        value: String!
        label: String!
    }
    extend type EmployerSizeType {
        value: String!
        label: String!
    }
    extend type EmployerTypeType {
        value: String!
        label: String!
    }
    extend type OccupationType {
        value: String!
        label: String!
    }
    extend type NameWithIdResponse {
        value: String!
        label: String!
    }
    extend type HeadhunterPoolType {
        value: String!
        label: String!
    }
    extend type CampusType {
        value: String
        label: String
    }
    extend type CVEntity {
        value: String
        label: String
    }
`;
