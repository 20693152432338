import { Fragment, ReactNode, MouseEvent, lazy, Suspense } from 'react';
import * as Sentry from '@sentry/react';

//Config
import {homeRoute} from '~config/routes';
// Utils
import {chunkErrorHandler, cleanUp} from '~utils/chunkErrorHandler';
//Components
import LandingLink from '~components/LandingLink';


// Dynamic imports
const ResultScreen = lazy(() => import('~components/ResultScreen').then(cleanUp).catch(chunkErrorHandler));


interface IErrorScreenProps {
    error: Error;
    componentStack: string | null;
    eventId: string | null;
    resetError(): void;
}

const reloadPage = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    window.location.reload();
};

function isChunkLoadErrorHandled(error: Error): boolean {
    const chunkFailedMessage = /Loading chunk [\w.-]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
        window.location.reload();
        return true;
    }

    return false;
}

function ErrorScreen({ error, componentStack, resetError }: IErrorScreenProps) {
    if (isChunkLoadErrorHandled(error)) {
        // Do not render error page if this error have been handled
        return null;
    }

    return (
        <Fragment>
            <div>You have encountered an error</div>
            <div>{error.toString()}</div>
            <div>{componentStack}</div>
            <button
                onClick={() => {
                    resetError();
                }}
            >
                Click here to reset!
            </button>

            <Suspense fallback="">
                <ResultScreen className="error-screen" result="fail" title="Unfortunately you have encountered an error">
                    <div>Sorry for that, we are looking to fix this issue as soon as possible</div>

                    <div className="error-screen__actions">
                        <a href={homeRoute} onClick={reloadPage}>
                        Try reloading the page
                        </a>
                        <span> or </span>
                        <LandingLink>go to the home page</LandingLink>
                    </div>
                </ResultScreen>
            </Suspense>
        </Fragment>
    );
}

const renderErrorScreen = (props: IErrorScreenProps) => <ErrorScreen {...props}/>;



interface IErrorCatchProps {
    children: ReactNode
}

export default function ErrorCatch({children}: IErrorCatchProps) {
    return (
        <Sentry.ErrorBoundary fallback={renderErrorScreen} showDialog>
            {children}
        </Sentry.ErrorBoundary>
    );
}
