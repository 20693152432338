import * as Sentry from '@sentry/react';
import type {Event} from '@sentry/react';
import type {CaptureContext} from '@sentry/types';
import {ExtraErrorData as ExtraErrorDataIntegration} from '@sentry/integrations';

//Config
import packageJson from '../../package.json';
import { HOST, PROJECT_NAME, SENTRY_DSN } from '~config/constants';
//Utils
import {cookiesAreEnabled} from '~utils/helpers';
import LStorage from '~utils/LStorage';



export function initRemoteReporter() {
    Sentry.init({
        dsn: SENTRY_DSN,
        enabled: process.env.NODE_ENV === 'production',
        release: `${PROJECT_NAME}@${packageJson.version}`,
        environment: HOST,
        ignoreErrors: ['Non-Error promise rejection captured'],
        integrations: [new ExtraErrorDataIntegration({depth: 9})],
        normalizeDepth: 10
    });
    unbindRemoteReporterUser();
}



const browserStorageAvailability = {
    cookieIsAvailable: cookiesAreEnabled(),
    localStorageIsAvailable: LStorage.isAvailable()
};



interface IReporterUser {
    email?: string
    username?: string
    id?: string
    [userEntry: string]: string | number | boolean
}

export function bindRemoteReporterUser(user: IReporterUser = {}) {
    Sentry.configureScope((scope) => {
        scope.setUser({
            ...user,
            userState: 'Logged in',
            ...browserStorageAvailability
        });
    });
}



export function unbindRemoteReporterUser() {
    Sentry.configureScope((scope) => {
        scope.setUser({
            userState: 'Guest',
            ...browserStorageAvailability
        });
    });
}



interface ICaptureExceptionExtraData {
    extra?: Record<string, any>
    tags?: Event['tags']
}

export interface IReporterCaptureExceptionParams {
    error: any
    errorScope?: string
    extraData?: ICaptureExceptionExtraData
    captureContext?: CaptureContext
}

export function captureException({error, errorScope = 'UNDETERMINED', extraData = {}, captureContext}: IReporterCaptureExceptionParams) {
    Sentry.withScope((scope) => {
        const {tags = {}, extra = {}} = extraData;

        scope.setTags({
            ...tags,
            ERROR_SCOPE: errorScope
        });

        scope.setExtras({
            ...extra
        });

        Sentry.captureException(error, captureContext);
    });
}
