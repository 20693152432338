import {useState} from 'react';
import verge from 'verge';

//Hooks
import useDebounce from '~hooks/useDebounce';
import useOnWindowResize from '@rooks/use-on-window-resize';
import useOnUnmount from '~hooks/useOnUnmount';
//Config
import {CSSBreakpoints} from '~config/constants';



interface IUseDimensionsState {
    vh: number
    vw: number
    isdesktop: boolean
    islarge: boolean
    ismobile: boolean
    issmall: boolean
    istablet: boolean
    istiny: boolean
    iswide: boolean
}

const calculateDimensions = (viewportW: number, viewportH: number): IUseDimensionsState => {
    return {
        vh: viewportH,
        vw: viewportW,
        ...Object
            .entries(CSSBreakpoints)
            .reduce<Record<string, boolean>>((acc, [key, val]) => {
                acc[`is${key}`] = viewportW < val;
                return acc;
            }, {})
    } as IUseDimensionsState;
};

export default function useDimensions() {
    const [dimensions, setDimensions] = useState<IUseDimensionsState>(
        () => calculateDimensions(verge.viewportW(), verge.viewportH())
    );

    const onResizeDebounced = useDebounce(() => {
        setDimensions(calculateDimensions(verge.viewportW(), verge.viewportH()));
    }, 100);

    useOnWindowResize(onResizeDebounced, undefined, true);

    useOnUnmount(() => onResizeDebounced.cancel());

    return dimensions;
}
