import {useLocation} from 'react-router-dom';
import cn from 'classnames';

//Config
import {PAGES_WITH_FOOTER} from '~config/constants';
import {
    DOCUMENT_LINKS_PRIVACY_POLICY,
    DOCUMENT_LINKS_TERMS_AND_CONDITIONS,
    THIRD_PARTY_LINKS_FACEBOOK,
    THIRD_PARTY_LINKS_INSTAGRAM,
    THIRD_PARTY_LINKS_LINKEDIN,
    THIRD_PARTY_LINKS_MEDIUM,
    THIRD_PARTY_LINKS_TWITTER
} from '~config/resources';
//Components
import LandingLink from '~components/LandingLink';
//Icons
import FacebookIcon from '~icons/FacebookIcon';
import InstagramIcon from '~icons/InstagramIcon';
import LinkedinIcon from '~icons/LinkedinIcon';
import LogoIcon from '~icons/LogoIcon';
import MediumIcon from '~icons/MediumIcon';
import TwitterIcon from '~icons/TwitterIcon';
//Types
import SwitchUserLinks from '~pages/Home/SwitchUserLinks';



const socialLinks = [
    {
        id: 'Medium',
        icon: <MediumIcon/>,
        link: THIRD_PARTY_LINKS_MEDIUM
    },
    {
        id: 'Facebook',
        icon: <FacebookIcon/>,
        link: THIRD_PARTY_LINKS_FACEBOOK
    },
    {
        id: 'Twitter',
        icon: <TwitterIcon/>,
        link: THIRD_PARTY_LINKS_TWITTER
    },
    {
        id: 'Linkedin',
        icon: <LinkedinIcon/>,
        link: THIRD_PARTY_LINKS_LINKEDIN
    },
    {
        id: 'Instagram',
        icon: <InstagramIcon/>,
        link: THIRD_PARTY_LINKS_INSTAGRAM
    }
];

const currentYear = new Date().getUTCFullYear();

export default function AppFooter() {
    const {pathname} = useLocation();
    const isHidden = !PAGES_WITH_FOOTER.includes(pathname);

    return (
        <footer className={cn('site-footer', {'is-hidden': isHidden})}>
            <div className="site-footer__logo">
                <LandingLink><LogoIcon/></LandingLink>
            </div>

            <div className="site-footer__nav">
                <div className="site-footer__nav-col">
                    <SwitchUserLinks linkClassName="site-footer__link"/>
                </div>

                <div className="site-footer__nav-col">
                    <a
                        href={DOCUMENT_LINKS_PRIVACY_POLICY}
                        className="site-footer__link site-footer__link--light"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Privacy Policy
                    </a>

                    <a
                        href={DOCUMENT_LINKS_TERMS_AND_CONDITIONS}
                        className="site-footer__link site-footer__link--light"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Terms & Conditions
                    </a>

                    <div>©Talentprise LLC. All rights Reserved. {currentYear}</div>
                </div>

            </div>

            <div className="site-footer__social-links">
                {socialLinks.map(({id, link, icon}) => (
                    <a
                        key={id}
                        href={link}
                        target="_blank"
                        aria-label={id}
                        rel="noopener noreferrer"
                    >
                        {icon}
                    </a>
                ))}
            </div>
        </footer>
    );
}
