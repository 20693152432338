import { useLocation, Link, useHistory } from 'react-router-dom';

//GraphQL
import {
    ProfileTypeEnum,
    SubscriptionFieldsFragment,
    SubscriptionNotificationFieldsFragment,
    SubscriptionStatusEnum
} from '~graphqlResources';
//Types
import { ProfileFieldsFragment } from '~graphqlResources';
import { logoutFnType } from '~context/auth/types';
import { ReactMouseEventCb } from '~types';
// Config
import {
    enterpriseDashboardRoute,
    enterpriseEditRouteBase,
    enterpriseJobPostRoute,
    enterpriseMyJobPostsRoute,
    enterpriseMyTalentPoolRoute,
    enterpriseSubscriptionRoute,
    enterpriseTalentBoardRoute,
    homeRoute,
    profileEditRouteBase,
    talentDashboardRoute,
    talentJobsRoute,
    talentMyJobsRoute
} from '~config/routes';
import HeaderCredits from '~components/AppHeader/HeaderCredits';



interface IHeaderMobileUserProps {
    profile: ProfileFieldsFragment;
    logout: logoutFnType;
    subscription:
        | SubscriptionFieldsFragment
        | SubscriptionNotificationFieldsFragment;
}

export default function HeaderMobileUser({
    profile,
    subscription,
    logout
}: IHeaderMobileUserProps) {
    const { pathname } = useLocation();
    const history = useHistory();
    const logoutUser: ReactMouseEventCb<HTMLAnchorElement> = (e) => {
        e.preventDefault();
        logout({
            onFinish: () => {
                if (pathname !== homeRoute) history.push(homeRoute);
            }
        });
    };
    const isLinkVisible = () =>
        subscription &&
        subscription.status === SubscriptionStatusEnum.ACTIVE &&
        subscription.availableCredits !== 0;
    return (
        <>
            {profile.type === ProfileTypeEnum.ENTERPRISE && profile.onboarded && (
                <>
                    <HeaderCredits/>

                    {isLinkVisible() && (
                        <>
                            <Link
                                className="site-header__link"
                                to={enterpriseTalentBoardRoute}
                            >
                                Talent board
                            </Link>
                            <Link
                                className="site-header__link"
                                to={enterpriseJobPostRoute}
                            >
                                Job post
                            </Link>
                            <Link
                                className="site-header__link"
                                to={enterpriseDashboardRoute}
                            >
                                AI Headhunter
                            </Link>
                            <Link
                                className="site-header__link"
                                to={enterpriseMyTalentPoolRoute}
                            >
                                My talent pool
                            </Link>
                        </>
                    )}
                    <Link
                        className="site-header__link"
                        to={enterpriseEditRouteBase}
                    >
                        Account Settings
                    </Link>
                    {isLinkVisible() && (
                        <Link
                            className="site-header__link"
                            to={enterpriseMyJobPostsRoute}
                        >
                            My Job Posts
                        </Link>
                    )}

                    <Link
                        to={enterpriseSubscriptionRoute}
                        className="site-header__link"
                    >
                        Subscription
                    </Link>
                </>
            )}

            {profile.type === ProfileTypeEnum.TALENT && profile.onboarded && (
                <>
                    <Link
                        to={talentDashboardRoute}
                        className="site-header__link"
                    >
                        Dashboard
                    </Link>
                    <Link to={talentJobsRoute} className="site-header__link">
                        Jobs
                    </Link>
                    <Link
                        to={profileEditRouteBase}
                        className="site-header__link"
                    >
                        Account Settings
                    </Link>
                    <Link className="site-header__link" to={talentMyJobsRoute}>
                        My Jobs
                    </Link>
                </>
            )}

            <Link to="#" onClick={logoutUser} className="site-header__link">
                Log out
            </Link>
        </>
    );
}
